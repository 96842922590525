import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ReactSVG } from 'react-svg'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { delay, countTokens } from '../../classes/Util.js'
import AICheck from '../../assets/Icons/AICheck.svg'
import Spin from '../../assets/Icons/Spin.svg'
import Cross from '../../assets/Icons/Cross.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Stop from '../../assets/Icons/Stop.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import Category from '../../assets/Icons/UserSaid.svg'
import OpenFile from '../../assets/Icons/OpenFile.svg'
import File from '../../assets/Icons/File.svg'
import Image from '../../assets/Icons/Image.svg'
import Save from '../../assets/Icons/SaveCommand.svg'
import Undo from '../../assets/Icons/Redo.svg'
import Redo from '../../assets/Icons/Undo.svg'

import Send from '../../assets/Icons/Send.svg'
import Cut from '../../assets/Icons/Share.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Share from '../../assets/Icons/Share.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import AISaid from '../../assets/Icons/AISaid.svg'
import CheckMark from '../../assets/Icons/Tick.svg'
import EditIcon from '../../assets/Icons/Edit.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import Alert from '../../assets/Icons/Alert.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Question from '../../assets/Icons/Question.svg'
import Marker from '../../assets/Icons/Marker.svg'
import Gear from '../../assets/Icons/Settings.svg'


export class DeleteButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  trash = async () => {
    if (!this.state.confirmDelete) {
      this.state.confirmDelete = true
      this.forceUpdate()
      return
    }
    this.state.busy = true
    this.forceUpdate()
    await this.props.trash()
    this.state.busy = false
    this.state.confirmDelete = null
    this.forceUpdate()
  }

  cancelDelete = async () => {
    this.setState({
      confirmDelete: false
    })
  }

  render() {
    const { label } = this.props
    let deleteButton
    let deleteIcon = this.state.busy ? Spin : (this.props.icon || Trash)
    const trash = () => this.trash()
    let Delete = this.props.Delete || 'Delete'
    let className='threadDeleteButton'
    if (label) {
      className += ' threadDeleteButtonWithLabel'
    }
    if (this.state.confirmDelete) {
      deleteButton = <div key='delete' className='threadDeleteButtonConfirm' onClick={trash}>Confirm {Delete}<div className='keyboardMenuItemIcon'><ReactSVG src={deleteIcon}/></div></div>
      if (!this.props.noClickAway) {
        deleteButton = <ClickAwayListener onClickAway={this.cancelDelete}>
                         {deleteButton}
                       </ClickAwayListener>
        
      }
    } else {
      deleteButton = <div key='del' className={className}><SimpleButton label={label} action={trash} icon={deleteIcon}/></div>
    }
    return <div className='deleteButtonHolder'>{deleteButton}</div>
  }
}

export const GearButton = props => {
  return <SimpleButton legacyIconSize icon={Gear} {...props}/>
}

export const GearIcon = props => {
  return <SimpleIcon legacyIconSize src={Gear} {...props}/>
}

export const LegacyButton = props => {
  return <SimpleButton legacyIconSize {...props}/>
}

export class SimpleIcon extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    const { src, size, legacyIconSize } = this.props
    let style
    let className = 'simpleIcon'
    if (size) {
      const { w, h } = size
      style = {
        height: h,
        width: w,
        minWidth: w
      }
      className += ' simpleIconWithSize'
    }
    if (legacyIconSize) {
      className += ' legacyIconSize'
    }
    return <div className={className} style={style}><ReactSVG src={src}/></div>
  }
}

export class SimpleButton extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  setRef = ref => {
    this.ref = ref
  }

  componentWillUnmount() {
    if (this.timeout1) {
      console.log("unmount autorepeat")
      clearTimeout(this.timeout1)
    }
  }

  action = async () => {
    if (!this.props.action) return
    this.state.busy = true
    this.forceUpdate()
    await this.props.action()
    this.state.busy = false
    this.forceUpdate()
  }
  
  render() {
    const props = this.props
    let { icon, label } = props
    let action
    let className = 'simpleButton'
    if (label) {
      if (typeof(label) !== 'string') {
        className += ' simpleButtonLabelComponent'
      } else {
        className += ' simpleButtonWithLabel'
      }
    }
    if (icon) {
      className += ' simpleButtonWithIcon'
    }
    if (props.action) {
      action = async () => {
        this.state.busy = true
        setTimeout(() => this.forceUpdate())
        //this.ref.scrollIntoView()
        await props.action()
        this.state.busy = false
        this.forceUpdate()
      }
    } else{
      className += ' simpleButtonInactive'
    }
    if (this.state.busy) icon = Spin
    let longPressOps
    let onClick
    if (this.props.longPress) {
      longPressOps = this
      className += ' simpleButtonLongPressable'
    } else if (this.props.autoRepeat) {
      className += ' simpleButtonAutorepeat'
      const onHold = () => {
        console.log("onHold", this.down)
        clearTimeout(this.timeout1)
        props.action()
        this.timeout1 = setTimeout(onHold, 100)
      }
      const hold = () => {
        onHold()
      }
      const release = () => {
        this.down = false
        clearTimeout(this.timeout1)
        this.timeout = null
      }
      longPressOps = {
        handleMouseDown: e => {
          e.preventDefault()
          e.stopPropagation()
          hold()
        },
        handleMouseUp: e => {
          e.preventDefault()
          e.stopPropagation()          
          release()
        },
        handleMouseLeave: e => {
          release()
        },
        handleTouchStart: e => {
          e.preventDefault()
          e.stopPropagation()          
          hold()
        },
        handleTouchEnd: e => {
          e.preventDefault()
          e.stopPropagation()
          release()
        }
      }
    } else {
      longPressOps = {}
      if (this.props.keepFocus) {
        onClick = (event) => {
          event.preventDefault()
          this.action()
        }
      } else {
        onClick = this.action
      }
    }
    const legacyIconSize = !this.state.busy && this.props.legacyIconSize
    return <div ref={this.setRef}
                className={className}
                onClick={onClick}
                onMouseDown={longPressOps.handleMouseDown}
                onMouseUp={longPressOps.handleMouseUp}
                onMouseLeave={longPressOps.handleMouseLeave}
                onTouchStart={longPressOps.handleTouchStart}
                onTouchEnd={longPressOps.handleTouchEnd}
           >
             {icon && <SimpleIcon size={this.props.iconSize} key='icon' src={icon} legacyIconSize={legacyIconSize}/>}
             {label &&<div key='label' className='simpleButtonLabel'>
                        {label}
                      </div>}
           </div>

  }

  startPressTimer = () => {
    const { pressDuration = 300 } = this.props
    this.state.pressed = true
    this.setState({ isLongPress: false })
    this.timerRef = setTimeout(() => {
      this.setState({ isLongPress: true })
      if (navigator.vibrate) {
        navigator.vibrate(200);
      } else {
      }
      console.log("long press called")
      this.props.longPress()
    }, pressDuration);
  }

  cancelPressTimer = () => {
    this.state.pressed = false
    clearTimeout(this.timerRef)
    if (this.props.longPress && this.state.isLongPress) {
      function clearSelection() {
        if (window.getSelection) {
          // For most modern browsers
          window.getSelection().removeAllRanges()
        } else if (document.selection) {
          // For older IE browsers
          document.selection.empty()
        }
      }
      setTimeout(clearSelection, 60)
    } else {
      this.action()
    }
  };

  handleMouseDown = (e) => {
    this.startPressTimer();
    e.preventDefault()
  };

  handleMouseUp = (e) => {
    e.preventDefault()
    this.cancelPressTimer();
  }

  handleTouchStart = (e) => {
    e.preventDefault()
    this.startPressTimer();
  };

  handleTouchEnd = (e) => {
    e.preventDefault()
    this.cancelPressTimer();
  };

  
}
