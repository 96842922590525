import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindCallback, of, concat, from, Subject, merge as mergeN, combineLatest } from 'rxjs'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { BnLabel1, BnLabel2 } from '../Label'
import { UComponent, BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { parsePhoneNumber, BnInputField, BnInputFieldSeparator } from '../TextInput'
import { KeyboardButton, KeyboardButton1 } from '../Keyboard'
import { readTextFile, copyToClipboard, toThen, fromNow, delay, capitalize } from '../../classes/Util.js'
import { InMemorySearch } from '../../classes/InMemorySearch.js'
import { Keyboard } from '../Keyboard'
import { getComponents, GearButton, FileChooser, ModelConfig, DeleteButton, SimpleIcon, SimpleButton, RadioButtons, FineTuningTask, ChatGPT, SearchField } from '../ChatGPT'
import { GetStarted, KeyboardLogin } from '../KeyboardLogin'
import Gear from '../../assets/Icons/Settings.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Paste from '../../assets/Icons/Paste.svg'
import Import from '../../assets/Icons/Paste.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Folder from '../../assets/Icons/Folder.svg'
import OpenFile from '../../assets/Icons/OpenFile.svg'
import File from '../../assets/Icons/File.svg'
import Save from '../../assets/Icons/SaveCommand.svg'
import Share from '../../assets/Icons/Share.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import Pen from '../../assets/Icons/Edited.svg'
import Create from '../../assets/Icons/Create.svg'
import Edit from '../../assets/Icons/SavedCommands.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import Expand from '../../assets/Icons/Expand.svg'
import Pack from '../../assets/Icons/Pack.svg'
import Buy from '../../assets/Icons/Buy.svg'
import Profile from '../../assets/Icons/Profile.svg'
import Spin from '../../assets/Icons/Spin.svg'
import AISaid from '../../assets/Icons/AISaid.svg'
import ToolServerIcon from '../../assets/Icons/ToolServer.svg'
import Plus from '../../assets/Icons/Plus.svg'

import Attunewise from '../../assets/Icons/Platforms/Attunewise.svg'
import Writer from '../../assets/Icons/Platforms/Writer.svg'
import HF from '../../assets/Icons/Platforms/HuggingFace.svg'
import Anthropic from '../../assets/Icons/Platforms/Anthropic.svg'
import Mistral from '../../assets/Icons/Platforms/MistralLogo.svg'
import Google from '../../assets/Icons/Platforms/Google.svg'
import Gemini from '../../assets/Icons/Platforms/Gemini.svg'
import Databricks from '../../assets/Icons/Platforms/Databricks.svg'
import Microsoft from '../../assets/Icons/Platforms/Microsoft.svg'
import Cohere from '../../assets/Icons/Platforms/Cohere.svg'
import DeepSeek from '../../assets/Icons/Platforms/Deepseek.svg'
import Yi from '../../assets/Icons/Platforms/Yi.svg'
import Alibaba from '../../assets/Icons/Platforms/Alibaba.svg'
import Amazon from '../../assets/Icons/Platforms/Amazon.svg'
import Nvidia from '../../assets/Icons/Platforms/Nvidia.svg'
import BFL from '../../assets/Icons/Platforms/BFL.svg'
import Reka from '../../assets/Icons/Platforms/Reka.svg'
import xAI from '../../assets/Icons/Platforms/xAI.svg'
import xI from '../../assets/Icons/Platforms/xI.svg'
import Meta from '../../assets/Icons/Platforms/Meta.svg'
import MetaAI from '../../assets/Icons/Platforms/MetaAI.svg'
import Claude from '../../assets/Icons/Platforms/Claude.svg'
import OpenAI from '../../assets/Icons/Platforms/OpenAI.svg'
import CustomProvider from '../../assets/Icons/Platforms/Custom.svg'
import { WordPackPurchase } from '../Words'
import HomeNewUser from '../../assets/Icons/Guide0012x.png'
import Cross from '../../assets/Icons/Cross.svg'
import Update from '../../assets/Icons/Update.svg'
import HeroImage from '../../assets/Icons/HeroImage.png'
import ClickAwayListener from 'react-click-away-listener'
import { ModelsView, ModelIcon, ModelLabel, ModelVendor, Model, formatPrice } from '../ChatGPT/ModelsMenu.js'
import { ActionMenu } from './ActionMenu.js'
import { Slider } from '../ChatGPT/Slider.js'
import { startOfDay, startOfWeek, startOfMonth,  endOfDay, Calendar } from './Usage.js'
import { Markdown } from '../ChatGPT/Markdown.js'
import { SystemPromptEditor } from '../SystemPrompts'
import { Toolsets, ToolServer } from '../Tools'
import { getFineTuningJobInfo, DatasetsView, DatasetExamplesView, DatasetFineTuningView } from './Datasets.js'
import { Crossfade } from './Crossfade.js'
import phone from 'phone';
import OpenAIAPI from 'openai'
import moment from 'moment'
import { ExpandableCardList } from './card-grid.js'
import './index.css'



class Detail extends Component {

  className = 'details'

  componentDidMount() {
    setTimeout(() => {
      this.className = 'details detailsFadeIn'
      this.forceUpdate()
    })
  }

  onAnimationStart = e => {
  }

  onAnimationEnd = e => {

  }
  
  render() {
    return <div className={this.className} onAnimationEnd={this.onAnimationEnd} onAnimationStart={this.onAnimationStart}>
             {this.props.children}
           </div>
  }
}

const getRenderFileSystemTask = ({icon}) => {
  return (options) => {
    const { file, draggable, onDragStart, title, titleEditor, onClick, actions, action, choose} = options
    let date
    date = fromNow(file.lastUpdated)
    let dateComp
    dateComp = <div className='toolsetDate'>{date}</div>
    const toolset = file
    let fileIcon = icon
    if (file.isFolder) {
      fileIcon = Folder
    }
    let { displayInfo } = file
    if (!displayInfo) {
      displayInfo = {
        heading: file.name || file.title || title,
        summary: file.summary || '' 
      }
    }
    const  {heading, summary} = displayInfo
    return <div
             key={toolset.id}
             draggable={draggable}
             onDragStart={onDragStart}
             className='toolsetTask'>
             <div className='toolsetTop'>
               <div  className='toolsetLeft' >
	         <SimpleIcon src={fileIcon}/>
               </div>
               <div className='toolsetMiddle' onClick={onClick || action}>
                 <div className='toolsetHeader'>
                   <div className='toolsetHeading'>
                     {title || titleEditor}
                   </div>
                   {dateComp}
                 </div>
                 <div className='toolsetSummary'>
                   {summary}
                 </div>
               </div>
               <div className='toolsetRight'>
                 <ActionMenu className='toolsetActions' actions={actions}/>
               </div>
             </div>
             <div className='toolsetBottom'>
               {choose && <div className='doneButton toolsetSelectButton'>
                            <SimpleButton label="Select" action={choose}/>
                          </div>}
             </div>
           </div>
  }
}


const renderFileSystemPreview = (files, {icon, legacyIconSize, open, emptyTitle}) => {
  return <div className='recentUsage toolsPreview'>
    {
      files.map(file => {
        const { isFolder, name } = file
        const title = name
        let fileIcon = icon
        let fileIconSize = legacyIconSize
        if (isFolder) {
          fileIcon = Folder
          fileIconSize = false
        }
        const date = fromNow(file.lastUpdated)
        let dateComp
        dateComp = <div className='toolsetDate'>{date}</div>
        const titleDiv = <div className='toolsetTitle'>
                           {title || (isFolder ? "New Folder" : emptyTitle)}
                         </div>
        const onClick = () => {
          open({toolset: file})
        }
        let displayInfo  = file.displayInfo || { heading: name }
        const { heading } = displayInfo;
        return <div className='toolsetPreview' onClick={onClick}>
                 <div  className='toolsetLeft'>
                   <div className='toolsetLeftTopRow'>
                     <div className='toolsetLeftTopRowLeft'>
	               <SimpleIcon src={fileIcon} legacyIconSize={fileIconSize}/>
                       {titleDiv}
                     </div>
                     <div className='toolsetTopRowRight'>{dateComp}</div>
                   </div>
                 </div>
                 <div className='datasetPreviewRow2'>
                   {heading}
                 </div>
               </div>
      })
    }
  </div>
}
    
    
export class CreditsPreview extends Component {
  render() {
    const { used, available, purchased } = this.props
    let usedStyle = {
      width: `${100*(used / purchased)}%`
    }
    let availableStyle = {
      width: `${100*(available / purchased)}%`
    }
    const usedDiv = <div className='creditsUsed' style={usedStyle}/>
    const availableDiv = <div className='creditsAvailable' style={availableStyle}/>
    const left = <div className='creditsLeft'>
                   {usedDiv}{availableDiv}
    </div>
    const info = (label, value, className) => {
      const num = Math.round(value).toLocaleString()
      if (!className) {
        className = 'creditsInfo'
      } else {
        className = 'creditsInfo ' + className
      }
      return <div className={className}>
               <div className='creditsInfoLabel'>
                 {label}
               </div>
               <div className='creditsInfoValue'>
                 {num}
               </div>
             </div>
    }
    const right = <div className='creditsRight'>
                    {info("Available", available, 'availableCredits')}
                    {info("Used", used, 'usedCredits')}
                    {info(this.props.Purchased, purchased, 'purchasedCredits')}
                  </div>
    return <div className='creditsPreview'>
             {left}
             {right}
           </div>
  }
}


export class HomeCard extends Component {

  constructor (props) {
    super(props)
    this.state = {
    }
  }
  onAction = () => {
    if (this.props.select) {
      this.props.select(this.props.label, this.props.action)
    } else {
      this.props.action()
    }
  }

  setRef = ref => {
    this.ref = ref
  }
  
  render() {
    let content = this.props.children
    const title = this.props.buttonLabel
    const action = this.props.action
    let className= 'homeCard'
    if (!this.props.card && (!content || (Array.isArray(content) && content.length === 0))) {
      className += ' homeCardEmpty'
    }
    const isButton = this.props.isButton
    if (isButton) {
      className +=' homeCardButton'
    }
    const icon = this.props.icon || Right
    const renderCard = (opts) => {
      const {onAction} = opts
      const onClick = () => onAction()
      return <div className={className} ref={this.setRef} style={this.state.style}>
             <div className='homeCardTitleContainer'>
               <div className='homeCardTitleBar' onClick={onClick}>
                 <div className='homeCardTitle'>{title}</div>
                 <SimpleButton icon={icon} action={onAction}/>
               </div>
               <div className='homeCardContent'>
                 {content}
               </div>
             </div>
             </div>
    }
    if (false && isDesktop()) {
      return <ExpandableCard renderCard={renderCard}/>
    }
    return renderCard({onAction: this.onAction})
  }
}


class ApiKeyInput extends Component {

  getForm = () => this.props.form
  onChange = (name, value) => this.props.onChange(name, value)

  render() {
    const { name, label, placeholder, title, save } = this.props
    return <div className='keyboardHomePhone warnSublabel'>
             <div className='settingsSectionHeader'>{title}</div>
             <HomeInput label={label} placeholder={placeholder} form={this.getForm()} name={name} className='' sublabel={''} onChange={this.onChange}/>
             <KeyboardButton
               label='Save'
               icon={Save}
               action={
                 () => save(name, this.getForm()[name])
               }/>
           </div>
  }
}

const ImportedModel = props => {
  const { model, importModel, deleteModel, isImported } = props
  const date = fromNow(model.ts)
  let button
  if (isImported) {
    button = <DeleteButton icon={Cross} trash={deleteModel}/>
  } else {
    button = <SimpleButton icon={Custom} label="Import" action={importModel}/>
  }
  return <div className='importedModel'>
           <div className='importedModelTopRow'>
             <Model single={true} model={model}/>
             {button}
           </div>
         </div>
}

class CustomProviderView extends BnSubpage {
  constructor(props) {
    super(props)
    this.state = {
      models: [],
    }
  }
  
  deleteModel = async model => {
    return await this.props.deleteModel(model)
  }

  importModel = async model => {
    return await this.props.importModel(model)
  }

  isImported = model => {
    const models = this.props.provider.models || []
    //////debugger
    return models.find(x => x === model.id)  
  }
  
  componentDidMount() {
    const { endpoint, apiKey } = this.props.provider
    this.api = new OpenAIAPI({apiKey, baseURL: endpoint, dangerouslyAllowBrowser: true})
    this.api.models.list().then(({data}) => {
      data.sort((x, y) => y.created - x.created)
      this.setState({
        models: data
      })
    }).catch(err => {
      //////debugger
      this.setState({
        error: "Can't access models at this endpoint: "+err.message
      })
      
    })
  }

  renderContent() {
    const models = this.state.models.filter(x => x.id.indexOf('badnano') < 0)
    const { label } = this.props.provider
    return <div className='providerView'>
             {
               models.map(data => {
                 const modelId = data.id
                 const deleteModel = () => this.deleteModel(data)
                 const importModel = () => this.importModel(data)
                 const isImported = this.isImported(data)
                 const model = {
                   title: modelId,
                   modelId: modelId,
                   id: modelId,
                   name: modelId,
                   vendor: label,
                   getIcon: () => Import,
                   contexts: [{
                     input: 4,
                     output: 4,
                     price: {
                       input: 0,
                       output: 0
                     }
                   }]
                 }
                 return <ImportedModel key={modelId} model={model}
                                       deleteModel={deleteModel}
                                       importModel={importModel}
                                       isImported={isImported}/>
               })
             }
           </div>
  }
}

class ProviderView extends BnSubpage {

  constructor(props) {
    super(props)
  }
  
  models = []
  fineTunedModels = {}

  deleteModel = async model => {
    await this.props.me.deleteImportedModel(this.props.provider, model)
  }

  importModel = async model => {
    //////debugger
    await this.props.me.importModel(this.props.provider, model)
  }
  
  componentDidMount()
  {
    let observe
    //////debugger
    if (this.props.provider === 'hf') {
      observe = this.props.me.observeImportedModels
    } else {
      observe = this.props.me.observeFineTunedModels
    }
    this.sub = observe().pipe(filter(x => {
      return (x.model.providerId || x.model.vendorId) === this.props.provider
    })).subscribe(change => {
      const { type, model } = change
      if (!model.providerId && !model.vendorId) {
        //console.log("no vendor", model)
        return
      }
      if (type === 'removed') {
        delete this.fineTunedModels[model.id]
      } else {
        model.getIcon = () => this.props.icon
        model.getModelIcon = this.props.modelIcon && (() => this.props.modelIcon)
        this.fineTunedModels[model.id] = model
      }
      this.forceUpdateLater()
    })
    this.initModels()
  }

  async initModels() {
    if (this.props.serverSearch) {
      this.onSearch('')
      return
    }
    this.setState({
      busy: true
    })
    const response = await this.props.me.listModelsToImport(this.props.provider)
    this.models = response.models || []
    this.models.forEach(model => {
      model.getIcon = () => this.props.icon
      model.getModelIcon = this.props.modelIcon && (() => this.props.modelIcon)
      model.title = model.name
    })
    this.setState({
      busy: false
    })
  }

  refresh = async () => {
    this.initModels()
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  forceUpdateLater = () => {
    clearTimeout(this.updateTimer1)
    this.updateTimer1 = setTimeout(() => this.forceUpdate(), 200)
  }

  onSearch = async searchTerm => {
    this.state.searchTerm = searchTerm
    this.forceUpdate()
    if (this.props.serverSearch) {
      this.setState({busy: true})
      const response = await this.props.me.listModelsToImport(this.props.provider, searchTerm)
      if (searchTerm !== this.state.searchTerm) return
      this.models = response.models || []
      this.models.forEach(model => {
        model.getIcon = () => this.props.icon
        model.getModelIcon = this.props.modelIcon && (() => this.props.modelIcon)
        model.title = model.name
        //console.log(model)
      })
      this.setState({searchResults: this.models, busy: false})
    }
  }

  renderContent() {
    let models
    if (this.props.serverSearch) {
      const seen = {}
      models = Object.values(this.fineTunedModels).concat(this.state.searchResults || []).filter(x => {
        if (!seen[x.id]) {
          seen[x.id] = true
          return true
        }
      })
    } else {
      const seen = {}
      models = Object.values(this.fineTunedModels).concat(this.models).filter(x => {
        if (!seen[x.id]) {
          seen[x.id] = true
          return true
        }
      })
      if (this.state.searchTerm) {
        function formatDate(model) {
          if (model.isFinetune) {
            const momentDate = moment(model.ts)
            const currentYear = moment().year();
            
            // Check if the year of the date matches the current year
            if (momentDate.year() === currentYear) {
              return momentDate.format('MM/DD');
            } else {
              return momentDate.format('MM/DD/YY');
            }
          }
        }
        const searchIndex = new InMemorySearch({id: 'id',
                                                fields: ['modelId', 'name', 'date'],
                                                documents: models.map(model => {
                                                  return {
                                                    id: model.id,
                                                    model,
                                                    modelId: model.modelId,
                                                    name: model.name,
                                                    date: formatDate(model)
                                                  }
                                                })
                                               })
        models = searchIndex.search(this.state.searchTerm+"*").map(x => x.model)
      } else {
        models.sort((x, y) => y.ts - x.ts)
      }
    }
    return <div className='providerView'>
             <div className='providerViewTopLine'>
               <SimpleButton icon={this.state.busy ? Spin : Update} action={this.refresh}/>
               <SearchField onSearch={this.onSearch} me={this.props.me}/>
             </div>
             <div className='providerViewModels'>
             {
               models.map(model => {
                 const deleteModel = () => this.deleteModel(model)
                 const importModel = () => this.importModel(model)
                 return <ImportedModel
                          key={model.id}
                          model={model}
                          deleteModel={deleteModel}
                          importModel={importModel}
                          isImported={this.fineTunedModels[model.id]} />
               })
             }
             </div>
           </div>
  }
}

class TopLevelModelsView extends ModelsView {

  constructor (props) {
    super(props)
    this.state.form = {}
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.keys !== prevProps.keys) {
      this.updateForm()
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.updateForm()
    this.forceUpdate()
  }

  updateForm = () => {
    //debugger
    const { countryCode, phoneNumber } = parsePhoneNumber(this.props.me.self.phoneNumber)
    this.set('countryCode', countryCode)
    this.set('phoneNumber', this.props.me.self.phoneNumber)

    this.set('email', this.props.me.self.email)
    this.set('name', this.props.me.self.displayName)

    //console.log("keys", this.props.keys)
    const openAI = this.props.keys.openAI || {}
    this.set('openaiApiKey', openAI.apiKey || '')
    const hf = this.props.keys.hf || {}
    this.set('hfToken', hf.token || '')
    const mistral = this.props.keys.mistral || {}
    this.set('mistralApiKey', mistral.apiKey || '')
    const fireworks = this.props.keys.fireworksAI || {}
    this.set('fireworksApiKey', fireworks.apiKey)
    this.set('fireworksAccountId', fireworks.accountId)
    const google = this.props.keys.google || { region: 'us-central1'}
    this.set('googleRegion', google.region)
    this.set('googleServiceAccountKey', google.serviceAccountKey)
    this.set('googleCloudStorageBucket', google.storageBucket)
    const bedrock = this.props.keys.bedrock || { region: 'us-west-2'}
    this.set('awsRegion', bedrock.region)
    this.set('awsSecretKeyId', bedrock.secretKeyId)
    this.set('awsSecretKey', bedrock.secretKey)
  }

  deleteCustomModel = async (provider, model) => {
    await this.props.me.deleteOpenAICompatibleAPIProviderModel(provider, model)
  }

  importCustomModel = async (provider, model) => {
    await this.props.me.importOpenAICompatibleAPIProviderModel(provider, model)
  }
  
  deleteModel = async (provider, model) => {
    await this.props.me.deleteImportedModel(provider, model)
  }

  importModel = async (provider, model) => {
    //////debugger
    await this.props.me.importModel(provider, model)
  }
  
  updateCustomProvider = async (provider) => {
    await this.props.me.updateOpenAICompatibleAPIProvider(provider)
  }

  deleteCustomProvider = async (provider) => {
    await this.props.me.deleteOpenAICompatibleAPIProvider(provider)
  }

  addOpenAICompatibleAPI = async () => {
    const label = this.get('openAICompatibleAPILabel')
    const endpoint = this.get('openAICompatibleAPIEndpoint')
    const apiKey = this.get('openAICompatibleAPIKey')
    const result = await this.props.me.createOpenAICompatibleAPIProvider({
      label,
      endpoint,
      apiKey
    })
    this.set('openAICompatibleAPILabel', '')
    this.set('openAICompatiableAPIEndpoint', '')
    this.set('openAICompatiableAPIKey', '')
  }
  
  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  onBack() {
    this.setState({
      detail: null
    })
  }

  openProvider = ({provider, id, title, name, icon}) => {
    let view
    let back
    let cancel
    if (isMobile()) {
      back = this.back
    } else {
      cancel = this.back
    }
    if (provider) {
      const deleteModel = model => this.deleteCustomModel(provider, model)
      const importModel = model => this.importCustomModel(provider, model)
      view = () => <CustomProviderView
                     key={provider.id}
                     importModel={importModel}
                     deleteModel={deleteModel}
                     me={this.props.me}
                     provider={provider}
                     title={provider.label}
                     back={back}
                     cancel={cancel}/>
    } else {
      const deleteModel = model => this.deleteModel(id, model)
      const importModel = model => this.importModel(id, model)
      view = () =>  <ProviderView
                      key={id}
                      deleteModel={deleteModel}
                      importModel={importModel}
                      serverSearch={id === 'hf'}
                      me={this.props.me}
                      provider={id}
                      title={title}
                      name={name}
                      icon={icon}
                      cancel={cancel}
                      back={back}/>
    }
    if (isMobile()) {
      this.setState({
        subpage: view
      })
    } else {
      this.setState({
        detail: view
      })
    }
  }

  saveOpenAIApiKey = async () => {
    const apiKey = this.get('openaiApiKey')
    await this.props.me.saveOpenAIApiKey({apiKey})
  }

  trashOpenAIProvider = async () => {
    await this.props.me.saveOpenAIApiKey({apiKey: null})
  }

  trashOpenMistralProvider = async () => {
    await this.props.me.saveMistralApiKey({mistralApiKey: null})
  }

  trashHuggingFaceProvider = async () => {
    await this.props.me.saveHuggingFaceToken({token: null})
  }

  saveHuggingFaceToken= async () => {
    const token = this.get('hfToken')
    await this.props.me.saveHuggingFaceToken({token})
  }

  saveFireworksAIApiKey = async () => {
    const apiKey = this.get('fireworksApiKey')
    const accountId = this.get('fireworksAccountId')
    await this.props.me.saveFireworksAIApiKey({apiKey, accountId})
  }
  trashFireworksAIProvider = async () => {
    await this.props.me.saveFireworksAIApiKey({apiKey: null, accountId: null})
  }

  saveGoogleServiceAccountKey = async () => {
    const serviceAccountKey = this.get('googleServiceAccountKey')
    const region = this.get('googleRegion')
    const storageBucket = this.get('googleCloudStorageBucket')
    await this.props.me.saveGoogleCredentials({serviceAccountKey, region, storageBucket})
  }

  saveBedrockCredentials = async () => {
    const region = this.get('awsRegion')
    const secretKeyId = this.get('awsSecretKeyId')
    const secretKey = this.get('awsSecretKey')
    await this.props.me.saveBedrockCredentials({region, secretKeyId, secretKey})
  }

  saveMistralApiKey = async () => {
    const apiKey = this.get('mistralApiKey')
    await this.props.me.saveMistralApiKey({apiKey})
  }
  copyField = async (field) => {
    const form = this.getForm()
    navigator.clipboard.writeText(form[field])
    await delay(0.5)
  }

  renderModelSettings = () => {
    if (!this.getForm()) {
      return null
    }
    const notOnOurServers = () => ''
    return <div className='topLevelModelSettings'>
             <div className='settingsInfoHeader'>
               To access your own fine-tuned models via this app, please provide the appropriate api key or credential for the given provider. Alternatively you may connect your own custom OpenAI Compatible API provider below.
             </div>
             <div className='keyboardHomePhone warnSublabel'>
               <div className='providerTopRow providerTopRow0'><SimpleIcon src={OpenAI}/><div className='settingsSectionHeader'>GPT Models</div><SimpleButton icon={Right} action={() => this.openProvider({id: 'openai', title: 'GPT Models', name: "OpenAI", icon: OpenAI})}/></div>
               <HomeInput label='openai api key' placeholder="OpenAI API Key" form={this.getForm()} name='openaiApiKey' className='' sublabel={notOnOurServers('api key')} onChange={this.onChange}/>
               <div className='customProviderButtons'>
                 <KeyboardButton label='Save' icon={Save} action={()=>this.saveOpenAIApiKey()}/>
                 {this.props.keys.openAI && this.props.keys.openAI.apiKey &&
                  <DeleteButton trash={this.trashOpenAIProvider}/>}
               </div>
             </div>
             <div className='keyboardHomePhone warnSublabel'>
               <div className='providerTopRow'><SimpleIcon src={Gemini}/><div className='settingsSectionHeader'>Gemini Models</div><SimpleButton icon={Right} action={() => this.openProvider({id: 'google', title: 'Gemini Models', name: "Gemini", icon: Gemini})}/></div>
               
               <HomeInput label='vertex ai region' placeholder="Vertex AI Region" form={this.getForm()} name='googleRegion' className='' sublabel={notOnOurServers('credentials')} onChange={this.onChange}/>
               <div className='keyboardHomeAccountSpacer0'/>
               <HomeInput label='vertex ai service account key' placeholder="Google Service Account Key" form={this.getForm()} name='googleServiceAccountKey' className='' sublabel={notOnOurServers('credentials')} onChange={this.onChange}/>
               <div className='keyboardHomeAccountSpacer0'/>
               <HomeInput label='vertex ai storage bucket' placeholder="Google Cloud Storage Bucket" form={this.getForm()} name='googleCloudStorageBucket' className='' sublabel={notOnOurServers('credentials')} onChange={this.onChange}/>
               <div className='customProviderButtons'>
                 <KeyboardButton label='Save' icon={Save} action={()=>this.saveGoogleServiceAccountKey()}/>
                 {this.props.keys.google && <DeleteButton trash={this.trashGeminiProvider}/>}
               </div>
             </div>
             <div className='keyboardHomePhone warnSublabel'>
               <div className='providerTopRow providerTopRow0'><SimpleIcon src={HF}/><div className='settingsSectionHeader'>Hugging Face Models</div><SimpleButton icon={Right} action={() => this.openProvider({id: 'hf', title: 'Hugging Face Models', name: "Hugging Face", icon: HF})}/></div>
               <HomeInput label='hugging face token' placeholder='Hugging Face Token' form={this.getForm()} name='hfToken' className='' sublabel={notOnOurServers('api key')} onChange={this.onChange}/>
               <div className='customProviderButtons'>
                 <KeyboardButton label='Save' icon={Save} action={()=>this.saveHuggingFaceToken()}/>
                 {this.props.keys.hf && this.props.keys.hf.token && 
                  <DeleteButton trash={this.trashHuggingFaceProvider}/>}
               </div>
             </div>
             <div className='keyboardHomePhone warnSublabel'>
               <div className='providerTopRow'><SimpleIcon src={Meta}/><div className='settingsSectionHeader'>LLama Models</div><SimpleButton icon={Right} action={() => this.openProvider({id: 'fireworksAI', title: 'LLama Models', name: "Meta", icon: Meta})}/></div>
               
               <HomeInput label='fireworks ai account' placeholder="FireworksAI Account Id" form={this.getForm()} name='fireworksAccountId' className='' sublabel={notOnOurServers('api key')} onChange={this.onChange}/>
               <div className='keyboardHomeAccountSpacer0'/>
               <HomeInput label='fireworks ai api key' placeholder="FireworksAI API Key" form={this.getForm()} name='fireworksApiKey' className='' sublabel={notOnOurServers('api key')} onChange={this.onChange}/>
               <div className='customProviderButtons'>
                 <KeyboardButton label='Save' icon={Save} action={()=>this.saveFireworksAIApiKey()}/>
                 {this.props.firewoksAI && this.props.keys.fireworksAI.apiKey && this.props.keys.fireworksAI.accountId &&
                  <DeleteButton trash={this.trashFireworksAIProvider}/>}
               </div>
             </div>
             <div className='keyboardHomePhone warnSublabel'>
               <div className='providerTopRow'><SimpleIcon src={Mistral}/><div className='settingsSectionHeader'>Mistral Models</div><SimpleButton icon={Right} action={() => this.openProvider({id: 'mistral', title: 'Mistral Models', name: "Mistral", icon: Mistral})}/></div>
               <HomeInput label='mistral api key' placeholder="Mistral API Key" form={this.getForm()} name='mistralApiKey' className='' sublabel={notOnOurServers('api key')} onChange={this.onChange}/>
               <div className='customProviderButtons'>
                 <KeyboardButton label='Save' icon={Save} action={()=>this.saveMistralApiKey()}/>
                 {this.props.keys.mistral && this.props.keys.mistral.apiKey && 
                  <DeleteButton trash={this.trashMistralProvider}/>}
               </div>
             </div>
             <div className='keyboardHomePhone warnSublabel' style={{display: 'none'}}>
               <div className='providerTopRow'><SimpleIcon src={Claude}/><div className='settingsSectionHeader'>Claude Models</div><SimpleButton icon={Right} action={() => this.openProvider({id: 'aws-claude', title: 'Claude Models', name: "Claude", icon: Claude})}/></div>
               <HomeInput label='AWS bedrock region' placeholder="AWS Region" form={this.getForm()} name='awsRegion' className='' sublabel={notOnOurServers('credentials')} onChange={this.onChange}/>
               <div className='keyboardHomeAccountSpacer0'/>
               <HomeInput label='AWS bedrock secret key id' placeholder="AWS Secret Key Id" form={this.getForm()} name='awsSecretKeyId' className='' sublabel={notOnOurServers('credentials')} onChange={this.onChange}/>
               <div className='keyboardHomeAccountSpacer0'/>
               <HomeInput label='AWS bedrock secret key' placeholder="AWS Secret Key" form={this.getForm()} name='awsSecretKey' className='' sublabel={notOnOurServers('credentials')} onChange={this.onChange}/>
               <div className='customProviderButtons'>
                 <KeyboardButton label='Save' icon={Save} action={()=>this.saveBedrockCredentials()}/>
                 {this.props.keys.bedrock && this.props.keys.bedrock.awsSecretKey && 
                  <DeleteButton trash={this.trashAWSProvider}/>}
               </div>
             </div>
             
             <div className='keyboardHomePhone warnSublabel'>
               <div className='providerTopRow'><SimpleIcon src={CustomProvider}/><div className='settingsSectionHeader'>Custom Providers</div></div>
               <HomeInput label='Display Name' placeholder="Name" form={this.getForm()} name='openAICompatibleAPILabel' className='' sublabel={notOnOurServers('credentials')} onChange={this.onChange}/>
               <div className='keyboardHomeAccountSpacer0'/>
               <HomeInput label='OpenAI Compatible API Endpoint' placeholder="OpenAI Compatible API Base URL" form={this.getForm()} name='openAICompatibleAPIEndpoint' className='' sublabel={notOnOurServers('credentials')} onChange={this.onChange}/>
               <div className='keyboardHomeAccountSpacer0'/>
               <HomeInput label='OpenAI Compatible API Key' placeholder="OpenAI Compatible API Key" form={this.getForm()} name='openAICompatibleAPIKey' className='' sublabel={notOnOurServers('credentials')} onChange={this.onChange}/>
               <div className='simpleHomeButton'>
                 <SimpleButton label='Add' icon={Plus} legacyIconSize action={()=>this.addOpenAICompatibleAPI()}/>
               </div>
               <div className='customProviderSpacer1'/>
               <div className='customProvidersList'>
                 {
                   this.props.customProviders.map(provider => {
                     const { label, apiKey, endpoint } = provider
                     const form = provider
                     const onChange = (key, value) => {
                       form[key] = value
                       this.forceUpdate()
                     }
                     const save = async () => {
                       await this.updateCustomProvider(provider)
                     }
                     const trash = async () => {
                       await this.deleteCustomProvider(provider)
                     }
                     return <div className='keyboardHomePhone warnSublabel'>
                              <div className='providerTopRow'><SimpleIcon src={CustomProvider}/><div className='settingsSectionHeader'>{provider.label} Models </div><SimpleButton icon={Right} action={() => this.openProvider({provider})}/></div>
                              <HomeInput label='Display Name' placeholder="Name" form={form} name='label' className='' sublabel={notOnOurServers('credentials')} onChange={onChange}/>
                              <div className='keyboardHomeAccountSpacer0'/>
                              <HomeInput label='OpenAI Compatible API Endpoint' placeholder="OpenAI Compatible API Base URL" form={form} name='endpoint' className='' sublabel={notOnOurServers('credentials')} onChange={onChange}/>
                              <div className='keyboardHomeAccountSpacer0'/>
                              <HomeInput label='OpenAI API Compatible API Key' placeholder="OpenAI API Compatible API Key" form={form} name='apiKey' className='' sublabel={notOnOurServers('credentials')} onChange={onChange}/>
                              <div className='customProviderButtons'>
                                <KeyboardButton label='Save' icon={Save} action={save}/>
                                <DeleteButton trash={trash}/>
                              </div>
                            </div>
                   })
                 }
                </div>
             </div>
           </div>
  }

  renderContent() {
    const renderConfig = (model) => {
      if (!model.opts) {
        model.opts = {
          temperature: 1,
          top_p: 1
        }
      }
      const setTemp = (temp) => {
        model.opts.temperature = temp
        this.forceUpdate()
      }
      const getTemp = () => {
        return model.opts.temperature
      }
      const getTop_p = () => {
        return model.opts.top_p
      }
      const setTop_p = (top_p) => {
        model.opts.top_p = top_p
        this.forceUpdate()
      }
      const getTop_k = () => {
        return model.opts.top_k || 50
      }
      const setTop_k = (top_k) => {
        model.opts.top_k = top_k
        this.forceUpdate()
      }
      const copy = async () => {
        await copyToClipboard(model.id)
      }
      return <div className='modelConfig'>
               <div className='tempSlider'><Slider label="Temp" onChange={setTemp} value={getTemp()} bounds={[0, 2]}/></div>
               <div className='tempSlider'><Slider label="Top p" onChange={setTop_p} value={getTop_p()} bounds={[0, 1]}/></div>
               <div className='tempSlider'><Slider label="Top k" onChange={setTop_k} value={getTop_k()} bounds={[1, 100]}/></div>
               <div className='tempSlider copyButton'>
                 <SimpleButton label='Copy' icon={Copy} action={copy}/>
               </div>
      </div>

    }
    return <div className='topLevelModel bnSubpageTopLevel'>
             {super.renderContent()}
             {this.renderModelSettings()}
           </div>
  }
}


const formatJob = (job, file) => {
  const { id, model, created_at, finished_at, fine_tuned_model, status, hyperparameters, trained_tokens } = job
  const t = `- **Model**: ${model}
- **Dataset**: ${file}`
  return t
}

const TITLE = 'Empty Discussion'

export const getWord = me => {
  let word = 'word'
  let Word = 'Word'
  word = 'credit'
  Word = 'Credit'
  return { word, Word }
}

let isDemo = true
const demoFilter = model => {
    return true
}

export const Vendors = [
  {
    name: "Attunewise",
    getIcon: () => Attunewise,
    id: 'attunewise'
    
  },
  {
    id: 'openai',
    name: "OpenAI",
    getIcon: () => OpenAI,
  },
  {
    id: 'anthropic',
    name: "Anthropic",
    getIcon: () => Anthropic
  },
  {
    name: "Google",
    getIcon: () => Google,
    id: 'google'
    
  },
  {
    id: 'meta',
    name: "Meta",
    getIcon: () => Meta
  },
  {
    id: 'mistral',
    name: "Mistral",
    getIcon: () => Mistral
  },
  {
    name: "Hugging Face",
    getIcon: () => HF,
    id: 'hf'
  },
  {
    name: "Custom",
    getIcon: () => CustomProvider,
    id: 'custom'
  },
  {
    id: 'xai',
    name: "xAI",
    getIcon: () => xI
  },
  {
    id: 'writer',
    name: "Writer",
    getIcon: () => Writer
  },
  {
    name: "Amazon",
    getIcon: () => Amazon,
    id: 'amazon'
    
  },
  {
    id: 'nvidia',
    name: "Nvidia",
    getIcon: () => Nvidia
  },
  {
    id: 'blackforestlabs',
    name: "BlackForestLabs",
    getIcon: () => BFL
  },
  {
    id: 'alibaba',
    name: "Alibaba",
    getIcon: () => Alibaba
  },
  {
    id: 'reka',
    name: "Reka",
    getIcon: () => Reka
  },
  {
    id: 'yi',
    name: "Yi",
    getIcon: () => Yi
  },
  {
    id: 'deepseek',
    name: "DeepSeek",
    getIcon: () => DeepSeek
  },
  {
    id: 'microsoft',
    name: "Microsoft",
    getIcon: () => Microsoft
  },
  {
    id: 'cohere',
    name: "Cohere",
    getIcon: () => Cohere
  },
  {
    id: 'databricks',
    name: "Databricks",
    getIcon: () => Databricks
  },
]

export const resolveModelId = id => {
  return id
}

const isModel =  (x, y) => {
  let id = resolveModelId(y)
  return x.id === id
}

const allModels = (isModelSelected, selectModel) => [
  {
    id: 'attunewise-gpt-3.5-turbo',
    label: 'Small',
    isSelected: () => isModelSelected('attunewise-gpt-3.5-turbo'),
    select: () => { selectModel('attunewise-gpt-3.5-turbo') },
    title: "Small",
    //getModelIcon: () => OpenAI,
    getIcon: () => Attunewise,
    isOpenSource: () => false,
    vendor: "Attunewise",
    requiresAdmin: false,
    getSize: ()  => 'small',
    contexts: [
      {
        input: 8,
        output: 4,
        price: {input: 1.50, output: 3.00}
      }]
  },
  {
    id: 'attunewise',
    label: 'Mini',
    isSelected: () => isModelSelected('attunewise'),
    select: () => { selectModel('attunewise') },
    title: "Mini",
    //getModelIcon: () => OpenAI,
    getIcon: () => Attunewise,
    isOpenSource: () => false,
    vendor: "Attunewise",
    getSize: ()  => 'small',
    contexts: [
      {
        input: 128,
        output: 16,
        price: {input: .3, output: 1.2}
      }
      ]
  },
  {
    id: 'attunewise-large',
    label: 'Large',
    isSelected: () => isModelSelected('attunewise-large'),
    select: () => { selectModel('attunewise-large') },
    title: "Large",
    //getModelIcon: () => OpenAI,
    getIcon: () => Attunewise,
    isOpenSource: () => false,
    vendor: "Attunewise",
    getSize: ()  => 'large',
    contexts: [
      {
        input: 128,
        output: 16,
        price: {input: 3.75, output: 15}
      }
      ]
  },
  {
    id: 'attunewise-gemini-1.5',
    label: 'Gemini 1.5',
    isSelected: () => isModelSelected('attunewise-gemini-1.5'),
    select: () => { selectModel('attunewise-gemini-1.5') },
    title: "Flash",
    requiresAdmin: false,
    //getModelIcon: () => Gemini,
    isOpenSource: () => false,
    getIcon: () => Attunewise,
    vendor: "Attunewise",
    price: { input: 0.75, output: 0.15 },
    getSize: ()  => 'small',
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: .075, output: .15 }
      },
      {
        input: 1000,
        output: 8,
        price: { input: 0.15, output: 0.60 }
      }]
  },
  {
    id: 'attunewise-mistral-large-2',
    label: 'Large',
    isSelected: () => isModelSelected('attunewise-mistral-large-2'),
    select: () => { selectModel('attunewise-mistral-large-2') },
    title: "Mistral Large",
    getModelIcon: () => Mistral,
    isOpenSource: () => false,
    getIcon: () => Attunewise,
    vendor: "Attunewise",
  },
  {
    id: 'claude-3-opus',
    label: 'Claude 3 Opus',
    isSelected: () => isModelSelected('claude-3-opus'),
    select: () => { selectModel('claude-3-opus') },
    title: "Claude 3 Opus",
    getModelIcon: () => Claude,
    isOpenSource: () => false,
    getIcon: () => Anthropic,
    getSize: () => 'large',
    vendor: "Anthropic",
    vision: true,
    contexts: [
      {
        input: 200,
        output: 4,
        price: { input: 15, output: 75 },
      }
      ]
  },
  {
    id: 'claude-3.5-sonnet',
    label: 'Claude 3.5 Sonnet',
    isSelected: () => isModelSelected('claude-3.5-sonnet'),
    select: () => { selectModel('claude-3.5-sonnet') },
    title: "Claude 3.5 Sonnet",
    getModelIcon: () => Claude,
    getIcon: () => Anthropic,
    vendor: "Anthropic",
    isOpenSource: () => false,
    getSize: () => 'large',
    vision: true,
    contexts: [
      {
        input: 200,
        output: 8,
        price: { input: 3, output: 15 },
      }
      ]
  },
  {
    id: 'claude-3-sonnet',
    label: 'Claude 3 Sonnet',
    isSelected: () => isModelSelected('claude-3-sonnet'),
    select: () => { selectModel('claude-3-sonnet') },
    title: "Claude 3 Sonnet",
    getModelIcon: () => Claude,
    getIcon: () => Anthropic,
    vendor: "Anthropic",
    isOpenSource: () => false,
    getSize: () => 'medium',
    vision: true,
    contexts: [
      {
        input: 200,
        output: 8,
        price: { input: 3, output: 15 },
      }
      ]
  },
  {
    id: 'claude-3-haiku',
    label: 'Claude 3 Haiku',
    isSelected: () => isModelSelected('claude-3-haiku'),
    select: () => { selectModel('claude-3-haiku') },
    title: "Claude 3 Haiku",
    getModelIcon: () => Claude,
    getIcon: () => Anthropic,
    vendor: "Anthropic",
    getSize: ()  => 'small',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 200,
        output: 4,
        price: { input: .25, output: 1.25 },
      }
      ]
  },
  {
    id: 'claude-3.5-haiku',
    label: 'Claude 3.5 Haiku',
    isSelected: () => isModelSelected('claude-3.5-haiku'),
    select: () => { selectModel('claude-3.5-haiku') },
    title: "Claude 3.5 Haiku",
    getModelIcon: () => Claude,
    getIcon: () => Anthropic,
    vendor: "Anthropic",
    getSize: ()  => 'small',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 200,
        output: 4,
        price: { input: .25, output: 1.25 },
      }
      ]
  },
  {
    id: 'gpt-4',
    label: 'GPT-4',
    isSelected: () => isModelSelected('gpt-4'),
    select: () => { selectModel('gpt-4') },
    title: "GPT-4",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: () => 'large',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 10, output: 30 },
      }
      ]
  },
  {
    id: 'gpt-4-turbo',
    label: 'GPT-4 Turbo',
    isSelected: () => isModelSelected('gpt-4-turbo'),
    select: () => { selectModel('gpt-4-turbo') },
    title: "GPT-4 Turbo",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: () => 'large',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 10, output: 30 },
      }
      ]
  },
  {
    id: 'gpt-4o',
    label: 'GPT-4o',
    isSelected: () => isModelSelected('gpt-4o'),
    select: () => { selectModel('gpt-4o') },
    title: "GPT-4o",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: () => 'large',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 5, output: 10 },
      }
      ]
  },
  {
    id: 'chatgpt-4o',
    label: 'GPT-4o',
    isSelected: () => isModelSelected('chatgpt-4o'),
    select: () => { selectModel('chatgpt-4o') },
    title: "ChatGPT-4o",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: () => 'large',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 5, output: 10 },
      }
      ]
  },
  {
    id: 'gpt-4o-mini',
    label: 'GPT-4o Mini',
    isSelected: () => isModelSelected('gpt-4o-mini'),
    select: () => { selectModel('gpt-4o-mini') },
    title: "GPT-4o Mini",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: ()  => 'small',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 16,
        price: { input: .15, output: .6 },
      }]
  },
  {
    id: 'gpt-4o-mini',
    label: 'GPT-4o Mini',
    isSelected: () => isModelSelected('gpt-4o-mini'),
    select: () => { selectModel('gpt-4o-mini') },
    title: "GPT-4o Mini",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: ()  => 'small',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 16,
        price: { input: .15, output: .6 },
      }]
  },
  {
    id: 'o1-mini',
    label: 'O1 Mini',
    isSelected: () => isModelSelected('o1-mini'),
    select: () => { selectModel('o1-mini') },
    title: "O1 Mini",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: ()  => 'small',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 16,
        price: { input: 3, output: 12 },
      }]
  },
  {
    id: 'o1',
    label: 'O1',
    isSelected: () => isModelSelected('o1'),
    select: () => { selectModel('o1') },
    title: "O1",
    getIcon: () => OpenAI,
    getModelIcon: () => null,
    vendor: "OpenAI",
    getSize: ()  => 'large',
    isOpenSource: () => false,
    vision: true,
    contexts: [
      {
        input: 128,
        output: 16,
        price: { input: 15, output: 60 },
      }]
  },
  {
    id: 'gpt-3.5-turbo',
    label: 'GPT-3.5 Turbo',
    isSelected: () => isModelSelected('gpt-3.5-turbo'),
    select: () => { selectModel('gpt-3.5-turbo') },
    title: "GPT-3.5 Turbo",
    getModelIcon: () => null,
    isOpenSource: () => false,
    getIcon: () => OpenAI,
    getSize: ()  => 'small',
    vendor: "OpenAI",
    contexts: [
      {
        input: 16,
        output: 4,
        price: { input: .5, output: 1.5},
      }]
  },
  {
    id: 'davinci-002',
    label: 'Davinci 002',
    isSelected: () => isModelSelected('davinci-002'),
    select: () => { selectModel('davinci-002') },
    title: "Davinci 002",
    getModelIcon: () => null,
    isOpenSource: () => false,
    getIcon: () => OpenAI,
    getSize: ()  => 'small',
    isBase: true,
    vendor: "OpenAI",
    contexts: [
      {
        input: 16,
        output: 4,
        price: { input: 2, output: 2},
      }]
  },
  {
    id: 'llama-3.1-405b',
    label: 'LLama-3 405B',
    isSelected: () => isModelSelected('llama-3.1-405b'),
    select: () => { selectModel('llama-3.1-405b') },
    title: "LLama 3.1 405B",
    getIcon: () => Meta,
    getModelIcon: () => MetaAI,
    getSize: () => 'large',
    isOpenSource: () => true,
    vendor: "Meta",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 3, output: 3 },
      }]
  },
  {
    id: 'llama-3.2-90b',
    getIcon: () => Meta,
    getModelIcon: () => MetaAI,
  },
  {
    id: 'llama-3.2-11b',
    getIcon: () => Meta,
    getModelIcon: () => MetaAI,
  },
  {
    id: 'llama-3.2-3b',
    getIcon: () => Meta,
    getModelIcon: () => MetaAI,
  },
  {
    id: 'llama-3.2-1b',
    getIcon: () => Meta,
    getModelIcon: () => MetaAI,
  },
  {
    id: 'llama-3.1-405b-base',
    label: 'LLama-3 405B Base',
    isSelected: () => isModelSelected('llama-3.1-405b-base'),
    select: () => { selectModel('llama-3.1-405b-base') },
    title: "LLama 3.1 405B Base",
    getIcon: () => Meta,
    isOpenSource: () => true,
    getModelIcon: () => MetaAI,
    getSize: () => 'large',
    isBase: true,
    vendor: "Meta",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 3, output: 3 },
      }
      ]
  },
  {
    id: 'llama-3.1-70b',
    label: 'LLama-3.1 70B',
    isSelected: () => isModelSelected('llama-3.1-70b'),
    select: () => { selectModel('llama-3.1-70b') },
    title: "LLama 3.1 70B",
    getIcon: () => Meta,
    getModelIcon: () => MetaAI,
    isOpenSource: () => true,
    getSize: ()  => 'medium',
    vendor: "Meta",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: .99, output: .99 },
      }
      ]
  },
  {
    id: 'llama-3.3-70b',
    label: 'LLama-3.3 70B',
    isSelected: () => isModelSelected('llama-3.3-70b'),
    select: () => { selectModel('llama-3.3-70b') },
    title: "LLama 3.3 70B",
    getIcon: () => Meta,
    getModelIcon: () => MetaAI,
    isOpenSource: () => true,
    getSize: ()  => 'medium',
    vendor: "Meta",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: .99, output: .99 },
      }
      ]
  },
  {
    id: 'llama-3.1-8b',
    label: 'LLama-3.1 8B',
    isSelected: () => isModelSelected('llama-3.1-8b'),
    select: () => { selectModel('llama-3.1-8b') },
    title: "LLama 3.1 8B",
    getIcon: () => Meta,
    isOpenSource: () => true,
    getModelIcon: () => MetaAI,
    getSize: ()  => 'small',
    vendor: "Meta",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: .22, output: .22 },
      }
      ]
  },
  {
    id: 'gemini-1.5-pro',
    label: 'Gemini 1.5 Pro',
    isSelected: () => isModelSelected('gemini-1.5-pro'),
    select: () => { selectModel('gemini-1.5-pro') },
    title: "Gemini 1.5 Pro",
    getModelIcon: () => Gemini,
    getIcon: () => Google,
    vendor: "Google",
    isOpenSource: () => false,
    getSize: () => 'large',
    price: { input: 7, output: 21 },
    vision: true,
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: 1.25, output: 3.75 }
      },
      {
        input: 2000,
        output: 8,
        price: { input: 2.5, output: 7.5 }
      }]
  },
  {
    id: 'gemini-1.5-flash',
    label: 'Gemini 1.5 Flash',
    isSelected: () => isModelSelected('gemini-1.5-flash'),
    select: () => { selectModel('gemini-1.5-flash') },
    title: "Gemini 1.5 Flash",
    getModelIcon: () => Gemini,
    getIcon: () => Google,
    vendor: "Google",
    isOpenSource: () => false,
    price: { input: 0.075, output: 0.15 },
    getSize: ()  => 'small',
    vision: true,
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: .01875, output: .0375 }
      },
      {
        input: 1000,
        output: 8,
        price: { input: 0.15, output: 0.60 }
      }]
  },
  {
    id: 'gemini-2.0-flash-exp',
    label: 'Gemini 2.0 Flash',
    isSelected: () => isModelSelected('gemini-2.0-flash-exp'),
    select: () => { selectModel('gemini-2.0-flash-exp') },
    title: "Gemini 1.5 Flash",
    getModelIcon: () => Gemini,
    getIcon: () => Google,
    vendor: "Google",
    isOpenSource: () => false,
    price: { input: 0.075, output: 0.15 },
    getSize: ()  => 'small',
    vision: true,
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: .01875, output: .0375 }
      },
      {
        input: 1000,
        output: 8,
        price: { input: 0.15, output: 0.60 }
      }]
  },
  {
    id: 'gemini-1.5-flash-8b',
    label: 'Gemini 1.5 Flash 8B',
    isSelected: () => isModelSelected('gemini-1.5-flash-8b'),
    select: () => { selectModel('gemini-1.5-flash-8b') },
    title: "Gemini 1.5 Flash 8B",
    getModelIcon: () => Gemini,
    getIcon: () => Google,
    vendor: "Google",
    isOpenSource: () => false,
    price: { input: 0.075, output: 0.15 },
    getSize: ()  => 'small',
    vision: true,
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: .01875, output: .0375 }
      },
      {
        input: 1000,
        output: 8,
        price: { input: 0.15, output: 0.60 }
      }]
  },
  {
    id: 'gemma-2-27b',
    label: 'Gemma 27B',
    isSelected: () => isModelSelected('gemma-2-27b'),
    select: () => { selectModel('gemma-2-27b') },
    title: "Gemma 2 27B",
    getModelIcon: () => Gemini,
    getSize: () => 'medium',
    getIcon: () => Google,
    isOpenSource: () => true,
    vendor: "Google"
  },
  {
    id: 'gemma-2-9b',
    label: 'Gemma 9B',
    isSelected: () => isModelSelected('gemma-2-9b'),
    select: () => { selectModel('gemma-2-9b') },
    title: "Gemma 2 9B",
    isOpenSource: () => true,
    getModelIcon: () => Gemini,
    getSize: () => 'small',
    getIcon: () => Google,
    vendor: "Google"
  },
  {
    id: 'paligemma',
    label: 'Pali Gemma',
    isSelected: () => isModelSelected('paligemma'),
    select: () => { selectModel('paligemma') },
    title: "Pali Gemma",
    getModelIcon: () => Gemini,
    isOpenSource: () => true,
    getSize: () => 'small',
    getIcon: () => Google,
    vendor: "Google",
    vision: true
  },
  {
    id: 'mistral-large',
    label: 'Large 2',
    isSelected: () => isModelSelected('mistral-large'),
    select: () => { selectModel('mistral-large') },
    title: "Large 2",
    getIcon: () => Mistral,
    getSize: () => 'large',
    isOpenSource: () => true,
    vendor: "Mistral",
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: 3, output: 9 },
      }]
  },
  {
    id: 'codestral',
    label: 'Medium',
    isSelected: () => isModelSelected('codestral'),
    select: () => { selectModel('codestral') },
    title: "Codestral 22B",
    getIcon: () => Mistral,
    getSize: () => 'medium',
    isOpenSource: () => true,
    vendor: "Mistral"
  },
  {
    id: 'mistral-nemo',
    label: 'Nemo 12B',
    isSelected: () => isModelSelected('mistral-nemo'),
    select: () => { selectModel('mistral-nemo') },
    title: "Nemo 12B",
    getIcon: () => Nvidia,
    getSize: () => 'small',
    isOpenSource: () => true,
    vendor: "Mistral",
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: 0.3, output: 0.3 },
      }]
  },
  {
    id: 'codestral-mamba',
    label: 'Medium',
    isSelected: () => isModelSelected('codestral-mamba'),
    select: () => { selectModel('codestral-mamba') },
    title: "Codestral Mamba 7B",
    getIcon: () => Mistral,
    getSize: () => 'small',
    isOpenSource: () => true,
    vendor: "Mistral",
    contexts: [
      {
        input: 128,
        output: 8,
        price: { input: 1, output: 3 },
      }]
  },
  {
    id: 'nemotron-4-340b',
    label: 'Nemotron 340B',
    isSelected: () => isModelSelected('nemotron-4-340b'),
    select: () => { selectModel('nemotron-4-340b') },
    title: "Nemotron 340B",
    getIcon: () => Nvidia,
    getSize: ()  => 'large',
    isOpenSource: () => true,
    vendor: "Nvidia"
  },
  {
    id: 'yi-large',
    label: 'Large',
    isSelected: () => isModelSelected('yi-large'),
    select: () => { selectModel('yi-large') },
    title: 'Large',
    getIcon: () => Yi,
    getSize: ()  => 'medium',
    isOpenSource: () => true,
    vendor: "Yi",
    contexts: [{
      input: 32,
      output: 4,
      price: { input: 3, output: 3 }
    }]
  },
  {
    id: 'qwen-72b',
    label: 'Qwen 72B',
    isSelected: () => isModelSelected('qwen-72b'),
    select: () => { selectModel('qwen-72b') },
    title: 'Qwen 72B',
    getIcon: () => Alibaba,
    getSize: ()  => 'medium',
    isOpenSource: () => true,
    vendor: "Alibaba",
    contexts: [{
      input: 32,
      output: 4,
      price: { input: .9, output: .9 }
    }]
  },
  {
    id: 'deepseek-chat',
    label: 'Chat',
    isSelected: () => isModelSelected('deepseek-chat'),
    select: () => { selectModel('deepseek-chat') },
    title: "Chat",
    getIcon: () => DeepSeek,
    getSize: ()  => 'medium',
    isOpenSource: () => true,
    vendor: "DeepSeek",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 0.14, output: 0.28 },
      }]
  },
  {
    id: 'deepseek-reasoner',
    label: 'R1',
    isSelected: () => isModelSelected('deepseek-reasoner'),
    select: () => { selectModel('deepseek-reasoner') },
    title: "R1",
    getSize: ()  => 'medium',
    getIcon: () => DeepSeek,
    isOpenSource: () => true,
    price: { input: 0.14, output: 0.28 },
    vendor: "DeepSeek",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 0.14, output: 0.28 },
      }]
  },
  {
    id: 'reka-core',
    label: 'Core',
    isSelected: () => isModelSelected('reka-core'),
    select: () => { selectModel('reka-core') },
    title: "Core",
    getIcon: () => Reka,
    getSize: ()  => 'large',
    vendor: "Reka",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 3, output: 15 },
      }
    ],
    isOpenSource: () => false,
    vision: true
  },
  {
    id: 'reka-flash',
    label: 'Flash',
    isSelected: () => isModelSelected('reka-flash'),
    select: () => { selectModel('reka-flash') },
    title: "Flash",
    getSize: ()  => 'small',
    getIcon: () => Reka,
    vendor: "Reka",
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: .8, output: 2 },
      }
    ],
    isOpenSource: () => false,
    vision: true
  },
  {
    id: 'phi-3-medium',
    label: 'Phi-3 Medium',
    isSelected: () => isModelSelected('phi-3-medium'),
    select: () => { selectModel('phi-3-medium') },
    title: "Phi-3 Medium",
    getSize: ()  => 'medium',
    getIcon: () => Microsoft,
    isOpenSource: () => true,
    vendor: "Microsoft"
  },
  {
    id: 'phi-3-mini',
    label: 'Phi-3 Mini',
    isSelected: () => isModelSelected('phi-3-mini'),
    select: () => { selectModel('phi-3-mini') },
    title: "Phi-3 Mini",
    getSize: ()  => 'small',
    getIcon: () => Microsoft,
    isOpenSource: () => true,
    vision: true,
    vendor: "Microsoft"
  },
  {
    id: 'cohere-command-r+',
    label: 'Command R+',
    isSelected: () => isModelSelected('cohere-command-r+'),
    select: () => { selectModel('cohere-command-r+') },
    title: "Command R+",
    getSize: ()  => 'medium',
    getIcon: () => Cohere,
    isOpenSource: () => true,
    contexts: [
      {
        input: 128,
        output: 4,
        price: { input: 3, output: 15 },
      }
    ],
    vendor: "Cohere"
  },
  {
    id: 'dbrx-instruct',
    label: 'Instruct',
    isSelected: () => isModelSelected('dbrx-instruct'),
    select: () => { selectModel('dbrx-instruct') },
    title: "DBRX Instruct",
    getSize: ()  => 'medium',
    getIcon: () => Databricks,
    isOpenSource: () => true,
    contexts: [
      {
        input: 32,
        output: 4,
        price: { input: 1.2, output: 1.2 },
      }
    ],
    vendor: "Databricks"
  }].map(m  => {
    m.isModel = y => isModel(m, y)
    return m
  })

const fineTuneModels = (get, set) => allModels(get, set).filter(m => m.id === 'attunewise'
                                                                ||
                                                                m.id === 'gpt-3.5-turbo'
                                                                ||
                                                                m.id === 'gpt-4o-mini'
                                                               )

const VendorIcons = {}
const ModelIcons = {}
Vendors.forEach((v, i) => {
  v.sortOrder = i
  VendorIcons[v.id] = v
})

allModels().forEach((m, i) => {
  m.sortOrder = i
  ModelIcons[m.id] = m
})

export const getModelIcon = (vendorId, modelId) => {
  const model = ModelIcons[modelId]
  if (model) {
    return () => model.getModelIcon && model.getModelIcon() || model.getIcon()
  }
  if (!model) {
    if (modelId.startsWith('gemini')) {
      return () => Gemini
    }
  }
  return VendorIcons[vendorId].getIcon
}

const ModelTabs = props => {
  const { tabs, selection, select } = props
  return <div className='savedButtonTabs'>
           {
             tabs.map(tab => {
               const selected = tab.selector === selection
               const onClick = () => {
                 select(tab.selector)
               }
               return <ModelTab icon={tab.icon} label={tab.label} onClick={onClick} selected={selected}/>
             })
           }
         </div>
}

const ModelTab = props => {
  const { selected, icon, label, onClick } = props
  let className = 'savedButtonTab'
  if (selected) {
    className += ' savedButtonTabSelected'
  }
  return <div className={className} onClick={onClick}>
           <div className='savedButtonTabIcon'>
             <ReactSVG src={icon}/>
           </div>
           <div className='savedButtonTabLabel'>
             {label}
           </div>
         </div>
}



export const HomeInput = props => {
  const { form, formErr, onChange, name, type, autocomplete, label, sublabel, placeholder } = props
  let className = 'homeButton'
  if (props.className) className += ' ' + props.className
  return <div className={className}>
           <div className='homeButtonLabels'>
             <div className='homeButtonLabel'>{label}</div>
             <div className='homeButtonSublabel'>{sublabel}</div>
           </div>
           <BnInputField  name={name} label={placeholder} formErr={formErr} form={form} type={type} onChange={onChange} autoComplete={autocomplete} busy={form.busy}/>
         </div>
}

const HomeLabel = props => {
  const { label, content } = props
  return <div className='homeButton'>
           <div className='homeButtonLabels'>
             <div className='homeButtonLabel'>{label}</div>
           </div>
           {content}
         </div>
  }


export class HomeButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }    
  }

  onClick = async () => {
    if (this.state.busy) return
    this.state.busy = true
    this.forceUpdate()
    if (this.props.action) await this.props.action()
    this.state.busy = false
    this.forceUpdate()
  }
  
  render() {
    const props = this.props
    const busy = this.props.busy || this.state.busy
    const  right = (busy) ? <ReactSVG src={Spin}/> : props.right
    let className = 'homeButton'
    if (props.className) {
      className += ' ' + props.className
    }
    let icon
    if (props.icon) {
      if (this.state.busy) {
        icon = Spin
      } else {
        icon = props.icon
      }
      className += ' homeButtonWithIcon'
    }
    if (this.props.className) {
      className += ' ' + this.props.className
    }
    return <div className={className + " homeButtonOld"}>
             <div className='homeButtonLabels'>
               <div className='homeButtonLabel'>{props.label}</div>
               <div className='homeButtonSublabel'>{props.sublabel}</div>
             </div>
             <div className='homeButtonButton' onClick={this.onClick}>
               <div className='homeButtonButtonLeft'>
                 {icon && <div className='homeButtonButtonIcon'>
                   <ReactSVG src={icon}/>
                  </div>}
                 <div className='homeButtonButtonLabel'>
                   {props.buttonLabel}
                 </div>
               </div>
               <div className='homeButtonButtonRight'>
                 {this.props.action && <SimpleIcon src={Right}/>}
               </div>
             </div>
           </div>
  }
}

const formatAmount = (amount, fixed = 0) => {
  if (amount > 1000000) {
    return Math.round(amount / 1000000).toFixed(1) + 'M'
  }
  if (amount > 1000) {
    return Math.round(amount/1000) + 'K'
  }
  if (amount >= 25) {
    fixed = 0
  }
  return parseFloat(amount.toFixed(fixed)).toString()
}

const Stat = props => {
  let className = 'keyboardHomeStat'
  if (props.className) {
    className += ' ' + props.className
  }
  return <div className={className}>
           <div className='keyboardHomeStatLabel'>
             {props.label}
             </div>
           <div className='keyboardHomeStatValue'>
             {formatAmount(props.value, 2)}
             </div>
           </div>
}


export class Checkbox extends Component {
  render() {
    let button
    if (this.props.selected) {
      button = <div className='keyboardCheckboxSelected' onClick={this.props.toggle}>
                 <div className='keyboardCheckboxLeft'/>
                 <div className='keyboardCheckboxOn'>{this.props.on || 'On'}</div>
               </div>
      
    } else {
      button = <div className='keyboardCheckboxUnselected' onClick={this.props.toggle}>
                 <div className='keyboardCheckboxOff'>{this.props.off || 'Off'}</div>
                 <div className='keyboardCheckboxOffButton'/>
               </div>
      
    }
    return <div className='keyboardCheckbox'>
             <div className='keyboardCheckboxLabel'>
               {this.props.label}
             </div>
             {button}
             <div className='keyboardCheckboxRight'/>
           </div>

  }
}

export class Account extends BnSubpage {

  constructor (props) {
    super(props)
  }

  customProviders = {}

  componentDidUpdate(prevProps) {
    if (this.props.keys !== prevProps.keys) {
      this.updateForm()
    }
  }

  componentDidMount() {
    this.updateForm()
    this.forceUpdate()
  }

  updateForm = () => {
    const { countryCode, phoneNumber } = parsePhoneNumber(this.props.me.self.phoneNumber)
    this.set('countryCode', countryCode)
    this.set('phoneNumber', this.props.me.self.phoneNumber)
    if (this.props.me.isSignedInAnonymously()) {
      this.signUp()
    }
    this.set('email', this.props.me.self.email)
    this.set('name', this.props.me.self.displayName)

    //console.log("keys", this.props.keys)
    const openAI = this.props.keys.openAI || {}
    this.set('openaiApiKey', openAI.apiKey || '')
    const hf = this.props.keys.hf || {}
    this.set('hfToken', hf.token || '')
    const mistral = this.props.keys.mistral || {}
    this.set('mistralApiKey', mistral.apiKey || '')
    const fireworks = this.props.keys.fireworksAI || {}
    this.set('fireworksApiKey', fireworks.apiKey)
    this.set('fireworksAccountId', fireworks.accountId)
    const google = this.props.keys.google || { region: 'us-central1'}
    this.set('googleRegion', google.region)
    this.set('googleServiceAccountKey', google.serviceAccountKey)
    this.set('googleCloudStorageBucket', google.storageBucket)
    const bedrock = this.props.keys.bedrock || { region: 'us-west-2'}
    this.set('awsRegion', bedrock.region)
    this.set('awsSecretKeyId', bedrock.secretKeyId)
    this.set('awsSecretKey', bedrock.secretKey)
  }

  deleteCustomModel = async (provider, model) => {
    await this.props.me.deleteOpenAICompatibleAPIProviderModel(provider, model)
  }

  importCustomModel = async (provider, model) => {
    await this.props.me.importOpenAICompatibleAPIProviderModel(provider, model)
  }
  
  deleteModel = async (provider, model) => {
    await this.props.me.deleteImportedModel(provider, model)
  }

  importModel = async (provider, model) => {
    //////debugger
    await this.props.me.importModel(provider, model)
  }
  
  updateCustomProvider = async (provider) => {
    await this.props.me.updateOpenAICompatibleAPIProvider(provider)
  }

  deleteCustomProvider = async (provider) => {
    await this.props.me.deleteOpenAICompatibleAPIProvider(provider)
  }

  addOpenAICompatibleAPI = async () => {
    const label = this.get('openAICompatibleAPILabel')
    const endpoint = this.get('openAICompatibleAPIEndpoint')
    const apiKey = this.get('openAICompatibleAPIKey')
    const result = await this.props.me.createOpenAICompatibleAPIProvider({
      label,
      endpoint,
      apiKey
    })
    this.set('openAICompatibleAPILabel', '')
    this.set('openAICompatiableAPIEndpoint', '')
    this.set('openAICompatiableAPIKey', '')
  }
  
  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  onBack() {
    this.setState({
      detail: null
    })
  }

  openProvider = ({provider, id, title, name, icon}) => {
    let view
    let back
    let cancel
    if (isMobile()) {
      back = this.back
    } else {
      cancel = this.back
    }
    if (provider) {
      const deleteModel = model => this.deleteCustomModel(provider, model)
      const importModel = model => this.importCustomModel(provider, model)
      view = () => <CustomProviderView
                     key={provider.id}
                     importModel={importModel}
                     deleteModel={deleteModel}
                     me={this.props.me}
                     provider={provider}
                     title={provider.label}
                     back={back}
                     cancel={cancel}/>
    } else {
      const deleteModel = model => this.deleteModel(id, model)
      const importModel = model => this.importModel(id, model)
      view = () =>  <ProviderView
                      key={id}
                      deleteModel={deleteModel}
                      importModel={importModel}
                      serverSearch={id === 'hf'}
                      me={this.props.me}
                      provider={id}
                      title={title}
                      name={name}
                      icon={icon}
                      cancel={cancel}
                      back={back}/>
    }
    if (isMobile() || !this.props.openDetail) {
      this.setState({
        subpage: view
      })
    } else {
      this.setState({
        detail: view
      })
    }
  }

  saveOpenAIApiKey = async () => {
    const apiKey = this.get('openaiApiKey')
    await this.props.me.saveOpenAIApiKey({apiKey})
  }

  trashOpenAIProvider = async () => {
    await this.props.me.saveOpenAIApiKey({apiKey: null})
  }

  trashOpenMistralProvider = async () => {
    await this.props.me.saveMistralApiKey({mistralApiKey: null})
  }

  trashHuggingFaceProvider = async () => {
    await this.props.me.saveHuggingFaceToken({token: null})
  }

  saveHuggingFaceToken= async () => {
    const token = this.get('hfToken')
    await this.props.me.saveHuggingFaceToken({token})
  }

  saveFireworksAIApiKey = async () => {
    const apiKey = this.get('fireworksApiKey')
    const accountId = this.get('fireworksAccountId')
    await this.props.me.saveFireworksAIApiKey({apiKey, accountId})
  }
  trashFireworksAIProvider = async () => {
    await this.props.me.saveFireworksAIApiKey({apiKey: null, accountId: null})
  }

  saveGoogleServiceAccountKey = async () => {
    const serviceAccountKey = this.get('googleServiceAccountKey')
    const region = this.get('googleRegion')
    const storageBucket = this.get('googleCloudStorageBucket')
    await this.props.me.saveGoogleCredentials({serviceAccountKey, region, storageBucket})
  }

  saveBedrockCredentials = async () => {
    const region = this.get('awsRegion')
    const secretKeyId = this.get('awsSecretKeyId')
    const secretKey = this.get('awsSecretKey')
    await this.props.me.saveBedrockCredentials({region, secretKeyId, secretKey})
  }

  saveMistralApiKey = async () => {
    const apiKey = this.get('mistralApiKey')
    await this.props.me.saveMistralApiKey({apiKey})
  }

  updateEmail = async () => {
  }

  updateDisplayName = async () => {
  }

  updatePhoneNumber = async () => {
    const form = this.getForm()
    let phoneNumber = form.phoneNumber
    let countryCode = form.countryCode
    const converted = phone(phoneNumber);
    if (!converted.length) {
      formErr = {field: 'phoneNumber', message: 'Invalid phone number.'};
      this.forceUpdate()
      return
    } else {
      phoneNumber = converted[0];
    }
    await this.props.me.updatePhoneNumber(phoneNumber)
  }

  signOut = async () => {
    this.props.back()
    await delay(1.0);
    await this.props.me.signOut()
  }


  deleteAccount = async () => {
    if (!this.state.confirmDeleteAccount) {
      this.setState({
        confirmDeleteAccount: true
      })
    } else {
      await this.props.me.deleteAccount()
    }
  }

  cancelDeleteAccount = () => {
    this.setState({
      confirmDeleteAccount: false
    })
  }

  copyField = async (field) => {
    const form = this.getForm()
    navigator.clipboard.writeText(form[field])
    await delay(0.5)
  }

  renderContent() {
    const APP = 'this app'
    const { word, Word } = getWord(this.props.me)
    let deleteAccountClass = 'keyboardHomeAccountDelete'
    let deleteAccountLabel = 'Delete Account'
    if (this.state.confirmDeleteAccount) {
      deleteAccountClass += ' keyboardHomeAccountDeleteConfirm'
      deleteAccountLabel = 'Confirm Delete Account'
    }
    let isSignedIn
    let hasPhone
    let hasEmail
    let hasDisplayName
    if (this.props.me) {
      isSignedIn = !this.props.me.isSignedInAnonymously()
      hasPhone = true
      hasEmail = true
      hasDisplayName = true
    }
    const renderAccountInfo = () =>  <div className='keyboardHomeAccountInfo'>
                                       <div className='keyboardHomePhone'>
                                         <HomeInput placeholder='Name' type='name' autocomplete='name' label='name' name='name' form={this.getForm()} formErr={this.getFormErr()} onChange={undefined /*this.onChange*/}/>
                                         <KeyboardButton label='Update' icon={Update} action={this.updateDisplayName}/>
                                       </div>
                                       <div className='keyboardHomePhone'>
                                         <HomeInput placeholder='Phone Number' type='tel' autocomplete='tel' name='phoneNumber' label='phone number' name='phoneNumber' form={this.getForm()} formErr={this.getFormErr()} onChange={undefined /*this.onChange*/}/>
                                         <KeyboardButton label='Update' icon={Update} action={this.updatePhoneNumber}/>
                                       </div>
                                       <div className='keyboardHomePhone'>
                                         <HomeInput placeholder='Email Address' type='email' autocomplete='email' label='email' name='email' form={this.getForm()} formErr={this.getFormErr()} onChange={undefined /*this.onChange*/}/>
                                         <KeyboardButton label='Update' icon={Update} action={this.updateEmail}/>
                                       </div>
                                     </div>
    let accountInfo = () => renderAccountInfo()
    ////debugger
    if (!isMobile() && !this.state.detail && this.props.openDetail) {
      const accountInfoDiv = () => <div className='accountInfoDesktop'>
                               <div className='accountInfoTitle'>Account Information</div>
                                     {accountInfo()}
                                   </div>
      accountInfo = this.props.openDetail(accountInfoDiv)
    } else {
      accountInfo = accountInfo()
    }
    const notOnOurServers = credentials => '' //`We do not send your ${credentials} to our servers`
    return <div className='keyboardHome keyboardHomeAccount bnSubpageTopLevel'>
             <div className='keyboardHomeContent'>
               {accountInfo}
               {!(this.props.openDetail || isMobile()) && <div className='keyboardHomeAccountSpacer1'/>}
               <HomeButton className='homeButtonSignOut' label='sign out of your account' buttonLabel='Sign out' action={this.signOut} icon={Profile}/>
               <div className='keyboardHomeAccountSpacer1'/>
               <ClickAwayListener onClickAway={this.cancelDeleteAccount}>
                 <div className={deleteAccountClass}>
                   <HomeButton
                     icon={Cross} label='permanently close account'
                     sublabel={`All data will be erased from our servers and you will need to create a new account to continue using ${APP}`}
                     buttonLabel={deleteAccountLabel} action={this.deleteAccount}/>
                 </div>
               </ClickAwayListener>
               {this.state.detail && this.props.openDetail(this.state.detail)}
             </div>
           </div>
  }
}

const uadd2 = (u1, u2) => {
  const result = {}
  const acc = u => {
    for (const id in u) {
      result[id] = result[id] || {inputTokens: 0, outputTokens: 0}
      result[id].inputTokens += u[id].inputTokens
      result[id].outputTokens += u[id].outputTokens
    }
  }
  acc(u1)
  acc(u2)
  return result
}

const uadd = (u1, u2) => {
  return {
    inputTokens: (u1.inputTokens || 0) + (u2.inputTokens || 0),
    outputTokens: (u1.outputTokens || 0) + (u2.outputTokens || 0),
  }
}

export class Usage extends BnSubpage {

  constructor (props) {
    super(props)
    this.usages = {}
    this.state = {
      events: [],
      currentDate: this.props.initialDate || new Date(),
      view: 'month'
    }
  }

  observeCurrentUsage = () => {
    let date = this.state.currentDate
    const month = String(date.getMonth() + 1)
    const year = String(date.getFullYear())
    console.log({month, currentMonth: this.currentMonth})
    debugger
    if (this.currentYear !== year ||
        this.currentMonth !== date.getMonth()) {
      this.currentYear = year
      this.currentMonth = month
      if (this.sub) {
        this.sub.unsubscribe()
      }
      this.usages = {}
      this.sub = this.props.me.observeUsage({year, month}).subscribe(change => {
        const { type, usage } = change
        if (type === 'removed') {
          delete this.usages[usage.id]
        } else {
          this.usages[usage.id] = usage
        }
        debugger
        this.updateUsageLater()
      })
      this.updateUsageLater()
    } else {
      this.updateUsage()
    }
  }

  updateUsageLater = () => {
    clearTimeout(this.updateUsageTimeout)
    this.updateUsageTimeout = setTimeout(this.updateUsage, 200)
  }

  updateUsage = () => {
    const d = startOfDay(this.state.currentDate).getTime()
    const w = startOfWeek(this.state.currentDate).getTime()
    const m = startOfMonth(this.state.currentDate).getTime()
    const filt = event => {
      const { daily } = event
      const { ts } = daily
      const c = { d: startOfDay(ts).getTime(),
                  w: startOfWeek(ts).getTime(),
                  m: startOfMonth(ts).getTime()
                }
      switch (this.state.view) {
        case 'day':
          return c.d === d
        case 'week':
          return c.w === w
        case 'month':
          return c.m === m
        default:
          //////debugger
      }
    }
    const usages = Object.values(this.usages)
    console.log({usages})
    const events = usages.map((elem) => {
      const { id, ts } = elem
      const start = startOfDay(new Date(ts))
      return {
        id,
        start,
        text: '',
        daily: elem
      }
    }).filter(filt)
    console.log({events})
    this.setState({
      events
    })
  }

  getPrices = () => {
    this.modelPrices = {}
    //////debugger
    for (const model of this.props.models) {
      let { price, contexts, id } = model
      if (contexts) {
        price = contexts[0].price
      }
      if (price) {
        this.modelPrices[model.id] = {
          input: price.input / (1000*1000),
          output: price.output / (1000*1000)
        }
      } else {
        //////debugger
      }
    }
    //console.log("MODEL_PRICES", this.modelPrices)
    this.forceUpdate()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.models.length != this.props.models.length) {
      this.getPrices()
    }
  }

  componentDidMount() {
    this.getPrices()
    this.observeCurrentUsage()
  }

  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  onBack() {
  }

  getUsage = model => {
    return { input: 0, output: 0 }
  }

  onViewChange = view => {
    this.setState({
      view
    }, this.observeCurrentUsage)
  }

  onDayChange = (date) => {
    this.setState({
      currentDate: new Date(date)
    }, this.observeCurrentUsage)
  }

  renderDetail() {
    return <div className='desktopCalendarView'>
             {this.renderCalendar()}
           </div>
  }

  renderCalendar = () => {
    const calendar = <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={this.state.events} onDayChange={this.onDayChange} initialView={'month'} viewSelection={['day', 'week', 'month']} />
    const calendarDiv = <div className='desktopCalendar'>
                          {calendar}
                        </div>
    return calendarDiv
  }
  
  renderContent() {
    let vendors = {}
    let vendorList = this.props.vendors
    const selectedVendors = {}
    const isModelSelected = model => false
    const models = this.props.models
    const modelById = []
    const vendorByName = {}
    for (const vendor of vendorList) {
      vendorByName[vendor.name] = vendor
    }
    let vendorItems = []
    const overall = this.state.events.map(event => event.daily)
    const perModel = {}
    const perVendor = {}
    const prices = this.modelPrices || {}
    const getPrice1 = id => {
      return prices[id] || { input: 0, output: 0 }
    }
    const total = {
      input: 0,
      output: 0
    }
    for (const model of models) {
      modelById[model.id] = model
      let arr = vendors[model.vendor]
      if (!arr) {
        vendors[model.vendor] = arr = []
      }
      arr.push(model)
    }
    for (const item of overall) {
      for (const id in item.usage) {
        const usage = item.usage[id]
        if (!usage.inputTokens) {
          //////debugger
        }
        if (!perModel[id]) {
          perModel[id] = { input: 0, output: 0} 
        }
        const price = getPrice1(id)

        total.input += price.input * usage.inputTokens 
        total.output += price.output * usage.outputTokens

        perModel[id].input += price.input * usage.inputTokens 
        perModel[id].output += price.output * usage.outputTokens
        const model = modelById[id]
        if (!model) {
          //console.error("model not found", id)
          continue
        }
        const vendor = vendorByName[model.vendor]
        if (!vendor) {
          //////debugger
          continue
        }
        if (!perVendor[vendor.id]) {
          perVendor[vendor.id] = { input: 0, output: 0} 
        }
        perVendor[vendor.id].input += price.input * usage.inputTokens 
        perVendor[vendor.id].output += price.output * usage.outputTokens
      }
    }
    //console.log("PER VENDOR", perVendor)
    //console.log("PER MODEL", perModel)
    const getPrice = model => {
      return perModel[model.id] || { input: 0, output: 0 }
    }
    const fmt = price => formatPrice(price, 2)
    for (const vendor of vendorList) {
      let models = vendors[vendor.name] || []
      models = models.filter(model => {
        return perModel[model.id]
      })
      let price = perVendor[vendor.id]
      vendorItems.push(<ModelVendor
                         key={vendor.id}
                         preview={true}
                         aggregatePrice={price}
                         open={false}
                         key={vendor.name}
                         vendor={vendor}
                         me={this.props.me}
                         models={models}
                         formatPrice={fmt}
                         getPrice={getPrice}/>)
    }
    let calStyle
    let className = 'attunewiseUsage'
    if (this.props.preview) {
      vendorItems = vendorItems.slice(0, 5)
      calStyle = { display: 'none' }
      className += ' attunewiseUsagePreview'
    }
    let calendar
    if (!isDesktop() || this.props.includeCalendar) {
      calendar = this.renderCalendar()
    }
    return <div className='attunewiseUsageView bnSubpageTopLevel'>
             <div className='attunewiseUsageCal' style={calStyle}>
               {calendar}
             </div>
             <div className={className}>
               <div className='attunewiseTotalUsage'>
                 <div className='attunewiseTotalUsageLabel'>Total</div>
                 <div className='attunewiseTotalUsageValue'><div className='modelPrice'>${fmt(total.input)}/{fmt(total.output)}</div></div>
               </div>
               {vendorItems}
             </div>
           </div>
  }

  render() {
    if (this.props.preview) {
      return this.renderContent()
    }
    return super.render()
  }
}



class GPT extends BnSubpage {

  constructor(props) {
    super(props)
    this.state.opacity = 0
  }

  overrideCSS = () => {
    //console.log('overrideCSS')
    const cssOverrides = `

#__next > div.relative.z-0.flex.h-full.w-full.overflow-hidden > div > main > div.flex.h-full.flex-col > div.flex-1.overflow-hidden > div > div.flex.h-full.flex-col.items-center.justify-center.text-token-text-primary > div.relative > div > div {


}
#__next > div.relative.z-0.flex.h-full.w-full.overflow-hidden > div > main > div.flex.h-full.flex-col > div.flex-1.overflow-hidden > div > div.flex.h-full.flex-col.items-center.justify-center.text-token-text-primary > div.flex.flex-col.items-center.gap-2 > div.flex.items-center.gap-1.text-token-text-tertiary > div > div.text-sm.text-token-text-tertiary {
  display: none !important;
}

#__next [aria-haspopup="menu"] + div {
    display: none !important;
}

#__next [aria-haspopup="dialog"] {
    display: none !important;
}

#__next > div.relative.z-0.flex.h-full.w-full.overflow-hidden > div > div.text-token-primary.sticky.top-0.z-10.flex.min-h-\[40px\].items-center.justify-center.border-b.border-token-border-medium.bg-token-main-surface-primary.pl-1.md\:hidden > div.absolute.bottom-0.right-0.top-0.flex.items-center > button {
    display: none !important;
}

nav[aria-label="Chat history"] > div:last-child {
    display: none !important;
}

nav[aria-label="Chat history"] .sticky > div {
    display: none !important;
}

.sticky + div {
    display: none !important;
}

form + div {
    visibility: hidden !important;
}

#a:not([href*='/g/g-lLczqaVIq-assistant']) {
    display: none !important;
}
`
    this.webview.insertCSS(cssOverrides)
    //this.webview.openDevTools()
    setTimeout(() => {
      this.setState({
        opacity: 1
      })
    }, 500)
  }
  setWebView = webview => {
    //console.log('onReady', webview)
    this.webview = webview
  }

  renderContent() {
    const style={
      opacity: this.state.opacity,
    }    
    return <div className='gpt' style={style}>
             <div className='gptBack'>
               <KeyboardButton1 className='gptBackButton' icon={Cross} action={this.props.goBack}/>
               </div>
             {<ElectronWebView allowpopups onDidFinishLoad={this.overrideCSS} ref={this.setWebView} src={'https://chat.openai.com/g/g-lLczqaVIq-assistant'}/>}
           </div>
  }
}


export class Home extends UComponent {
  constructor (props) {
    super(props)
    this.state = {
      nonFree: false,
      wordsUsed: 0,
      wordsPurchased: 0,
      buttons: [],
      writeButtons: [],
      savedButtonSelector: 'create',
      selectedModel: localStorage.getItem("assistantModel") || 'gpt-4',
      form: {
        claudeApiKey: localStorage.getItem('claude-api-key')
      },
      datasets: [],
      systemPrompts: [],
      customProviders: [],
      models: [],
      toolsets: [],
      toolServers: [],
      assistants: []
    }
  }

  isInline() {
    return false
  }


  copyDatasetExample = async (chatGPT, task) => {
    const { title, description } = task
    await delay(0.3)
    this.state.clipboardTask = {
      get: async () => {
        const messages = await this.props.me.getDatasetExampleMessages(task)
        return { title, description, messages }
      }
    }
    this.forceUpdate()
  }

  cutDatasetExample = async (chatGPT, task) => {
    const messages = await this.props.me.getDatasetExampleMessages(task)
    const { title, description } = task
    this.state.clipboardTask = {
      get: async () => {
        return {title, description, messages}
      }
    }
    await delay(0.3)
    this.forceUpdate()
  }

  getPasteDatasetExample = () => {
    if (this.state.clipboardTask) {
      return this.pasteDatasetExample
    }
  }
  
  pasteDatasetExample = async (dataset) => {
    const {title, description,  messages} = await this.state.clipboardTask.get()
    await this.props.me.addDatasetExample({dataset: dataset.id, title, description, messages})
  }


  copyPlaygroundExample = (chatGPT, task) => {
    const { title, description } = task
    this.state.clipboardTask = {
      get: async () => {
        const messages = await this.props.me.getPlaygroundMessages(task)
        return { title, description, messages }
      }
    }
    this.forceUpdate()
  }

  cutPlaygroundExample = async (chatGPT, task) => {
    const messages = await this.props.me.getPlaygroundMessages(task)
    const { title, description } = task
    this.clipboardTask = {
      get: async () => {messages, title, description }
    }
    this.forceUpdate()
  }

  cutSystemPrompt = async ({parent, child}) => {
    await this.props.me.removeSystemPrompt({child: child.id, parent: parent.id})
    this.systemPromptClipboard = systemPrompt
  }

  copySystemPrompt = async systemPrompt => {
    this.systemPromptClipboard = systemPrompt
    this.forceUpdate()
  }

  getPastePlaygroundExample = () => {
    if (this.state.clipboardTask) {
      return this.pastePlaygroundExample
    }
  }

  pastePlaygroundExample = async (chatGPT) => {
    const { messages, title, description } = await this.state.clipboardTask.get()
    await this.props.me.addPlaygroundExample({messages, title, description})
  }

  renderMobile() {
    let subpage = this.state.subpage ? this.state.subpage() : null
    const content = this.renderCards()
    return  <BnPage me={this.props.me} subpage={subpage} safeArea={true}>
              {content}
             </BnPage>

           
  }

  setDetailRef = ref => {
    this.detailRef = ref
  }

  render() {
    if (isMobile()) {
      return <div className='homeMobile'>
               {this.renderMobile()}
             </div>
    }
    let className = 'homeDesktopMain'
    if (true || this.state.wide) {
      className += ' homeDesktopMainWide'
    }
    let subpage = this.state.subpage
    let style = { width: 0} 
    let className1 = 'homeDesktopCardLayout'
    let className2 = 'homeDesktopMain'
    if (subpage) {
      className1 = 'homeDesktopPage'
      style = { width: '100%' }
    }
    return <div className='homeDesktop'>
             <div className={className1}>
               {this.renderMobile()}
             </div>
           </div>
    
  }

  setCrossFade = ref => {
    this.crossFade = ref
  }

  renderFail() {
    if (isMobile()) {
      return <div className='homeMobile'>
               {this.renderMobile()}
             </div>
    }
    let className = 'homeDesktopMain'
    if (true || this.state.wide) {
      className += ' homeDesktopMainWide'
    }
    return <div className='homeDesktop'>
             <div className='homeDesktopSidebar'>
               {this.renderMobile()}
             </div>
             <div className='homeDesktopMain'>
               {this.state.detail}
             </div>
           </div>
  }

  back = () => {
    this.selectedCardLabel =null
    this.setState({
      subpage: null
    }) 
  }

  signUp = async (options= { title: "Sign-in" }, andThen) => {
    const { title } = options
    const back = () => {
      if (this.back) this.back()
    }
    const next = () => {
      //////debugger
      back()
      andThen()
    }
    //////debugger
    this.setState({
      subpage: () => <KeyboardLogin title={title} onCreate={this.loginInProgress} me={this.props.me} safeArea={false} back={back} next={next}/>
    })
  }

  isSignedIn = () => !this.props.me.isSignedInAnonymously()

  ensureSignedIn = (options) => {
    if (this.isSignedIn()) {
      return Promise.resolve(true)
    }
    return new Promise(resolve  => {
      this.signUp(options, () => {
        if (this.isSignedIn()) {
          resolve(false)
        } else {
          if (this.back) this.back()
        }
      })
    })
  }

  openAccount = async (options = {title: "Settings"}) => {
    const { title } = options
    if (this.isSignedIn()) {
      this.setState({
        subpage: () => <Account title={title}
                                openDetail={this.openDetail}
                                customProviders={this.state.customProviders}
                                keys={this.state.keys || {}}
                                me={this.props.me}
                                getSetting={this.props.getSetting}
                                toggleSetting={this.props.toggleSetting}
                                
                                back={this.back}/>
      })
      return
    }
    this.ensureSignedIn()
  }

  

  openUsage = async (options = {}) => {
    this.setState({
      subpage: () => <Usage
                       includeCalendar={options.includeCalendar}
                       title={"Usage"}
                       models={this.state.models.map(x => {
                         x.isSelected = () => false
                         x.select = () => {}
                         return x
                       })}
                       vendors={this.state.vendors}
                       me={this.props.me}
                       back={this.back}/>
    })
  }


  openSystemPrompts = async (opts = {}) => {
    const cut = this.cutSystemPrompt
    const copy = this.copySystemPrompt
    let { onCreate, toolset } = opts
    if (!onCreate) {
      onCreate = comp => {
        if (toolset) comp.openToolset(toolset)
      }
    }
    const paste = async ({parent}) => {
      const child = this.systemPromptClipboard
      debugger
      if (parent && child) {
        await this.systemPromptFuncs.addParent({child: child, parent})
        this.forceUpdate()
      }
    }
    let back = this.back
    if (toolset) {
      this.systemPromptFuncs.open(toolset)
      back = () => {
        this.systemPromptFuncs.close(toolset)
        this.back()
      }
    }
    const renderFile = ({
      file,
      back,
    }) => {
      const systemPrompt = file
      const commitEdit = async (updates) => {
        const {
          name,
          toolset,
          content
        } = updates
        if (name !== undefined) {
          systemPrompt.name = systemPrompt.title = name
        }
        if (content !== undefined) {
          systemPrompt.content = content
        }
        if (toolset) {
          systemPrompt.toolset = {
            id: toolset.id,
            name: toolset.name
          }
        } else {
          if (toolset !== undefined) {
            systemPrompt.toolset = null
          }
        }
        await this.props.me.saveSystemPrompt(systemPrompt)
        //this.back()
        this.forceUpdate()
      }
      const chooseToolset = () => {
        debugger
      }
      return <SystemPromptEditor
               save={commitEdit}
               me={this.props.me}
               title={systemPrompt.title || systemPrompt.name}
               copy={this.props.copy}
               selectToolsets={this.getRenderToolsets}
               systemPrompt={systemPrompt}
               back={back}/>
    }
    let selectedToolset = toolset
    let selectToolset = (toolset) => {
      selectedToolset = toolset
      this.forceUpdate()
    }
    this.setState({
      subpage: () => <Toolsets
                       emptyTitle="New System Prompt"
                       field="systemPrompt"
                       selectToolset={selectToolset}
                       selectedToolset={selectedToolset}
                       newButton= {action => <SimpleButton icon={AISaid} label="New System Prompt" action={action}/>}
                       title={"System Prompts"}
                       cut={cut}
                       onCreate={onCreate}
                       copy={copy}
                       clipboard={this.systemPromptClipboard}
                       paste={paste}
                       me={this.props.me}
                       renderTask={getRenderFileSystemTask({icon: AISaid })}
                       renderFile={renderFile}
                       fileTypes=".json,.jsonl,.md,.txt"
                       observeToolsets={this.systemPromptFuncs.observe}
                       createNewToolset={this.systemPromptFuncs.createNew}
                       createNewToolsetFolder={this.systemPromptFuncs.createNewFolder}
                       saveToolset={this.systemPromptFuncs.save}
                       deleteToolset={this.systemPromptFuncs.remove}
                       uploadToolset={this.systemPromptFuncs.upload}
                       addParent={this.systemPromptFuncs.addParent}
                       back={this.back}/>
    })
  }

  openGenerators = (opts = {}) => {
    const { onCreate } = opts
    this.setState({
      subpage: () => this.openFileSystem({
        fileSystem: this.generatorFuncs,
        back: this.back,
        files: this.state.generators,
        onCreate,
        renderFile: ({
          file,
          back
        }) => {
          
        }
      })
    })
  }


  openDiscussions = (opts = {}) => {
    const { onCreate } = opts
    this.setState({
      subpage: () => this.openFileSystem({
        fileSystem: this.playgroundFuncs,
        back: this.back,
        files: this.state.discussions,
        onCreate,
        renderFile: ({
          file,
          back
        }) => {
          
        }
      })
    })
  }
  


  openFileSystem = ({
    title,
    fileSystem,
    files,
    select,
    selected,
    choose,
    clipboard,
    icon,
    onCreate,
    cut,
    copy,
    renderFile,
    back,
  }) => {
    return <Toolsets
             emptyTitle={fileSystem.emptyTitle}
             field={fileSystem.field}
             select={select}
             selected={selected}
             newButton= {action => <SimpleButton icon={icon} label={fileSystem.newTitle} action={action}/>}
             title={title}
             cut={cut}
             onCreate={onCreate}
             copy={copy}
             clipboard={clipboard}
             toolsets={files}
             me={this.props.me}
             renderTask={getRenderFileSystemTask({icon})}
             renderFile={renderFile}
             chooseToolset={choose}
             observeToolsets={fileSystem.observe}
             createNewToolset={fileSystem.createNew}
             createNewToolsetFolder={fileSystem.createNewFolder}
             saveToolset={fileSystem.save}
             deleteToolset={fileSystem.remove}
             uploadToolset={fileSystem.upload}
             addParent={fileSystem.addParent}
             back={back}/>
  }


  getRenderToolsets = (opts = {}) => {
    let { onCreate, toolset, chooseToolset, cut, copy, getClipboard, paste } = opts
    if (!onCreate) {
      onCreate = comp => {
        if (toolset) comp.openToolset(toolset)
      }
    }
    let back = opts.back || this.back
    if (toolset) {
      this.toolsetFuncs.open(toolset)
      back = () => {
        this.toolsetFuncs.close(toolset)
        this.back()
      }
    }
    let selectedToolset = toolset
    const selectToolset = (toolset) => {
      debugger
      selectedToolset = toolset
      this.forceUpdate()
    }
    if (opts.chooseToolset) {
    }
    if (getClipboard) console.log({clipboard: getClipboard()})
    return () => <Toolsets
                   key={opts.key}
                   emptyTitle="New Toolset"
                   field="toolset"
                   chooseToolset={opts.chooseToolset}
                   selectToolset={selectToolset}
                   selectedToolset={selectedToolset}
                   newButton= {action => <GearButton label="New Toolset" action={action}/>}
                   title={opts.title || "Toolsets"}
                   cut={cut}
                   onCreate={onCreate}
                   copy={copy}
                   paste={paste}
                   clipboard={getClipboard ? getClipboard() : undefined}
                   toolsets={this.state.toolsets}
                   me={this.props.me}
                   renderTask={getRenderFileSystemTask({icon: Gear, legacyIconSize: true})}
                   observeToolsets={this.observeToolsets}
                   createNewToolset={this.toolsetFuncs.createNew}
                   createNewToolsetFolder={this.toolsetFuncs.createNewFolder}
                   saveToolset={this.toolsetFuncs.save}
                   deleteToolset={this.toolsetFuncs.remove}
                   uploadToolset={this.toolsetFuncs.upload}
                   addParent={this.toolsetFuncs.addParent}
                   back={back}/>
  }


  toolsetClipboard = null

  cutToolset = (folder, toolset) => {
    if (folder) {
      this.toolsetFuncs.removeParent({child: toolset, parent: folder})
    }
    this.toolsetClipboard = toolset
    this.forceUpdate()
  }
  
  copyToolset = toolset => {
    this.toolsetClipboard = toolset
    this.forceUpdate()
  }

  pasteToolset = async ({parent}) => {
    const child = this.toolsetClipboard
    debugger
    if (parent && child) {
      await this.toolsetFuncs.addParent({child: child, parent})
      this.forceUpdate()
    }
  }

  openToolsets = async (opts = {}) => {
    const { onCreate } = opts
    const cut = this.cutToolset
    const copy = this.copyToolset
    const paste = this.pasteToolset
    const getClipboard = () =>  this.toolsetClipboard
    this.setState({
      subpage: this.getRenderToolsets({cut, copy, getClipboard, paste, onCreate})
    })
  }
  
  
  openToolServers = async (opts = {}) => {
    let { onCreate, toolset, cut, copy } = opts
    if (!onCreate) {
      onCreate = comp => {
        if (toolset) comp.openToolset(toolset)
      }
    }
    const renderFile = ({
      file,
      back,
    }) => {
      return <ToolServer
               title={file.name}
               me={this.props.me}
               toolServer={file}
               back={back}
             />
    }
    
    this.setState({
      subpage: () => <Toolsets
                       emptyTitle="New Tool Server"
                       field={'toolServer'}
                       title={"Tool Servers"}
                       newButton= {action => <SimpleButton legacyIconSize={true} icon={ToolServerIcon} label="New Tool Server" action={action}/>}
                       cut={cut}
                       onCreate={onCreate}
                       copy={copy}
                       clipboard={this.toolServerClipboard}
                       toolsets={this.state.toolServers}
                       me={this.props.me}
                       renderTask={getRenderFileSystemTask({icon: ToolServerIcon, legacyIconSize: true})}
                       renderFile={renderFile}
                       observeToolsets={this.observeToolServers}
                       createNewToolset={this.toolServerFuncs.createNew}
                       createNewToolsetFolder={this.toolServerFuncs.createNewFolder}
                       saveToolset={this.toolServerFuncs.save}
                       deleteToolset={this.toolServerFuncs.remove}
                       uploadToolset={this.toolServerFuncs.upload}
                       addParent={this.toolServerFuncs.addParent}
                       back={this.back}/>
    })
  }
  


  buy = async () => {
    this.ensureSignedIn({title: "Purchase Credits"}).then(() => {
      const { word, Word } = getWord(this.props.me)
      this.setState({
        subpage: () => <WordPackPurchase title={`${Word}s`}
                                         isInline={this.isInline()}
                                         getSetting={this.props.getSetting}
                                         toggleSetting={this.props.toggleSetting}
                                         me={this.props.me} back={this.back}
                                         available={this.state.wordsPurchased - this.state.wordsUsed}
                                         used={this.state.wordsUsed}
                                         purchased={this.state.wordsPurchased}
                                         Purchased={this.state.nonFree ? 'Purchased' : 'Free'}
                                         availableFmt={formatAmount(Math.max(this.state.wordsPurchased - this.state.wordsUsed, 0))}/>
      })
    })
  }


  openModels = async (options = {category: 'models', preview: false, selectable: true}) => {
    const { category, preview, selectable } = options
    const renderConfig = (model) => {
      if (!model.opts) {
        model.opts = {
          temperature: 1,
          top_p: 1
        }
      }
      const setTemp = (temp) => {
        model.opts.temperature = temp
        this.forceUpdate()
      }
      const getTemp = () => {
        return model.opts.temperature
      }
      const getTop_p = () => {
        return model.opts.top_p
      }
      const setTop_p = (top_p) => {
        model.opts.top_p = top_p
        this.forceUpdate()
      }
      const getTop_k = () => {
        return model.opts.top_k || 50
      }
      const setTop_k = (top_k) => {
        model.opts.top_k = top_k
        this.forceUpdate()
      }
      const copy = async () => {
        await copyToClipboard(model.id)
      }
      return <div className='modelConfig'>
               <div className='tempSlider'><Slider label="Temp" onChange={setTemp} value={getTemp()} bounds={[0, 2]}/></div>
               <div className='tempSlider'><Slider label="Top p" onChange={setTop_p} value={getTop_p()} bounds={[0, 1]}/></div>
               <div className='tempSlider'><Slider label="Top k" onChange={setTop_k} value={getTop_k()} bounds={[1, 100]}/></div>
               <div className='tempSlider copyButton'>
                 <SimpleButton label='Copy' icon={Copy} action={copy}/>
               </div>
      </div>

    }
    this.setState({
      subpage: () => <TopLevelModelsView
                       inline={true}
                       placeholder={"Available Models"}
                       models={(isSelected, select) => {
                         //////debugger
                         return this.getModels().map( x => {
                           if (x.providerId) {
                             //////debugger
                           }
                           try {
                             x.isSelected = () => isSelected(x.id)
                             x.select = () => select(x.id)
                           } catch(err) {
                             //////debugger
                           }
                           return x
                         })
                       }}
                       configure={renderConfig}
                       onOptionsChanged={() => this.forceUpdate()}
                       category={'models'}
                       observeOptions={this.props.observeOptions}
                       saveOptions={this.props.saveOptions}
                       me={this.props.me}
                       title={"Models"}
                       me={this.props.me}
                       getModels={this.getModels}
                       customProviders={this.state.customProviders}
                       keys={this.state.keys || {}}
                       me={this.props.me}
                       getSetting={this.props.getSetting}
                       toggleSetting={this.props.toggleSetting}
                       category={category}
                       observeOptions={this.props.me.observeModelOptions}
                       saveOptions={this.props.me.saveModelOptions}
                       me={this.props.me}
                       prices={this.prices}
                       vendors={this.filterVendors(this.state.vendors || [])}
                       menuActive={true}
                       preview={preview}
                       selectable={selectable}
                       back={this.back}/>
                                
    })
  }

  openPlaygrounds = opts => {
    let { onCreate, discussion } = opts
    if (!onCreate) {
      onCreate = comp => {
        if (discussion) comp.openToolset(discussion)
      }
    }
    let back = this.back
    if (discussion) {
      this.playgroundFuncs.open(discussion)
      back = () => {
        this.playgroundFuncs.close(discussion)
        this.back()
      }
    }
    let selectedDiscussion = discussion
    let selectDiscussion = (discussion) => {
      selectedDiscussion = discussion
      this.forceUpdate()
    }
    this.setState({
      subpage: () => <Toolsets
                       emptyTitle="New Discussion"
                       field="discussion"
                       selectToolset={selectDiscussion}
                       selectedToolset={selectedDiscussion}
                       newButton= {action => <SimpleButton icon={Hashtag} label="New Discussion" action={action}/>}
                       title={"Playgrounds"}
                       cut={cut}
                       onCreate={onCreate}
                       copy={copy}
                       clipboard={this.toolClipboard}
                       toolsets={this.state.toolsets}
                       me={this.props.me}
                       renderTask={getRenderFileSystemTask({icon: Gear, legacyIconSize: true})}
                       observeToolsets={this.observeToolsets}
                       createNewToolset={this.toolsetFuncs.createNew}
                       createNewToolsetFolder={this.toolsetFuncs.createNewFolder}
                       saveToolset={this.toolsetFuncs.save}
                       deleteToolset={this.toolsetFuncs.remove}
                       uploadToolset={this.toolsetFuncs.upload}
                       addParent={this.toolsetFuncs.addParent}
                       back={this.back}/>
    })
  }
  

  openChat = async (task) => {
    const modelsById = {}
    for (const model of allModels()) {
      modelsById[model.id] = model
    }
    let fileChooser
    const setFileChooser = ref => fileChooser = ref
    const handleDataTransfer = async (event, transfer) => {
      if (transfer.files.length > 0) {
        for (const file of transfer.files) {
          ////debugger
          let errors = []
          let lineno = 1
          const err = message => {
            errors.push(file.name + ": Line " +lineno+": "+message)
          }
          let messages
          let discussions
          if (true || file.type.startsWith("application/json")) {
            try {
              messages = JSON.parse(await readTextFile(file))
              discussions=[{messages}]
            } catch (error) {
              err("cannot parse file")
              console.error(error)
              try {
                discussions = file.split('\n').filter(x=>x).map(x => JSON.parse(x))
              } catch (error) {
                console.error(error)
                err("cannot parse file")                
              }
            }
          }
          let title = file.name
          if (discussions) {
            ////debugger
            for (const {messages} of discussions) {
              for (const message of messages) {
                let { role, content, tool_call_id, tool_calls, models } = message
                switch (role) {
                  case 'assistant':
                  case 'user':
                  case 'system':
                  case 'tool':
                    break
                  default:
                    return
                }
                if (tool_calls) {
                  let error
                  if (!Array.isArray(tool_calls)) {
                    error = true
                  } else {
                    tool_calls.forEach(toolCall => {
                      const { type } = toolCall
                      if (type !== 'function' || !toolCall.function) {
                        error = true
                      }
                    })
                  }
                  err("invalid tool calls")
                }
                if (models) {
                  let error 
                  if (!Array.isArray(models)) {
                    error = true
                  } else {
                    models = models.map(x => {
                      if (role === 'assistant') {
                        const { content, correction, model } = x
                        return { content, correction, model }
                        if (!this.models[model]) {
                          error = true
                        }
                      } else {
                        if (typeof x !== 'string' || !this.models[x]) {
                          error = true
                      }
                      }
                      return x
                    })                    
                  }
                  if (error) {
                    err("invalid models")
                  }
                }
              }
              const task = await this.props.me.addPlaygroundExample({messages, title, description: "Processing file..."})
              await delay(1)
              try {
                await this.props.me.updateTaskSummary(task, true)
              } catch (err) {
                await updateTask(task.id, { description: "Loaded {messages.length} messages" })
              }
            }
          } else {
            const task = await this.props.me.addPlaygroundExample({messages: [], title, description: errors.join('\n\n')})
          }
        }
      }
    }
    const exportDiscussion = async () => {
    }
    const getNewButton = (chatGPT, defaultNewAction) => {
      const actions = [
        {
          icon: Hashtag,
          label: "New Discussion",
          action: defaultNewAction
        }
      ]
      actions.push({
        icon: Share,
        label: "Export Discussion",
        action: exportDiscussion
      })
      actions.push({
        button: close => <FileChooser label="Import File" handleDataTransfer={handleDataTransfer} action={close} fileTypes={'.json,.jsonl'}/>
      })
      let paste = this.getPastePlaygroundExample()
      if (paste) {
        actions.push({
          icon: Paste,
          label: "Paste",
          action: paste
        })
      }
      return <ActionMenu actions={actions} position={'bottom left'}/>
    }
    const selectSystemPrompt = async (chatGPT, task, systemPrompt) => {
      await this.props.me.savePlaygroundSystemPrompt(task, systemPrompt)
      this.forceUpdate()
    }
    const deleteSystemPrompt = async (chatGPT, task) => {
      await selectSystemPrompt(chatGPT, task, null)
    }
    const Config = {}
    const renderModelConfig = model => {
      return <div key={model.id} className='continueFineTuneConfig'>
               <ModelConfig onCreate={
                              ref => {
                                Config[model.id] = ref
                              }
                            }
                            model={model}/>
             </div>
    }
    const onCreateChatGPT = chatGPT => {
      ////debugger
      if (task) {
        chatGPT.selectThread(task)
      } else {
        if (isDesktop()) {
          if (this.state.recentTasks.length > 0) {
            ////debugger
            chatGPT.selectThread(this.state.recentTasks[0])
          }
        }
      }
      this.forceUpdate()
    }
    const cutConversation = async (chatGPT, task, message, direction) => {
      const { description, title } = task
      const { messages, cut } = await this.props.me.cutConversation(task.id, message.id, direction)
      if (cut) {
        this.state.clipboardTask = {
          get: async () => {
            return {task, description, messages: cut}
          }
        }
      }
      return messages
    }
    const render = () =>  {
      return <ChatGPT
               openFileSystem={this.openFileSystem}
               systemPromptFileSystem={this.systemPromptFuncs}
               onCreate={onCreateChatGPT}
               configure={renderModelConfig}
               resource={'playground'}
               copySystemPrompt={this.copySystemPrompt}
               cutSystemPrompt={this.cutSystemPrompt}
               deleteSystemPrompt={deleteSystemPrompt}
               selectSystemPrompt={selectSystemPrompt}
               systemPrompts={this.state.systemPrompts}
               getNewButton={getNewButton}
               clipboardTask={this.state.clipboardTask}
               pasteTask={this.getPastePlaygroundExample()}
               copyTask={this.copyPlaygroundExample}
               cutConversation={cutConversation}
               cutTask={this.cutPlaygroundExample}
               key={'playground'}
               availableCredits={Math.max(this.state.wordsPurchased - this.state.wordsUsed, 0)}
               prices={this.prices}
               vendors={this.state.vendors}
               me={this.props.me}
               goBack={this.back}
               defaultModel={chatGPT => 'Attunewise Mini'}
               deleteTask ={
                 async (chatGPT, task) => {
                   await this.props.me.deleteTask(task)
                 }
               }
               getTitle={(chatGPT) => {
                 if (chatGPT.state.selectedTask) {
                   let title = 'Discussion'
                   if (isDesktop()) {
                     title = chatGPT.state.selectedTask.title
                   }
                   return chatGPT.state.systemPromptSelected ? "System Prompt" : chatGPT.state.judgeChat ? 'Judge': title
                 }
               }}
               getTasksTitle={
                 chatGPT => "Playground"
               }
               getButtonLabel ={(chatGPT, message) => {
                 if (chatGPT.state.judgeChat) {
                   return "Judge"
                 }
                 else if (!chatGPT.state.sending && message.inReplyTo) {
                   return 'Replay'
                 }
                 return 'Send'
               }}
               models={
                 (chatGPT, isSelected, select) => this.getModels().map(x => {
                   x.isSelected = () => isSelected(x.id)
                   x.select = () => select(x.id)
                   return x
                 })
               }
               isSearchFieldVisible={(chatGPT, messages) => {
                 return chatGPT.state.slide === 0
               }}
               observeTaskMessages={this.props.me.observeTaskMessages}
               observeTasks={this.props.me.observeTasks}
               observeRecentTasks={this.props.me.observeRecentTasks}
               deleteChatMessage={(chatGPT, messageId) => this.props.me.deleteChatMessage(messageId)}
               getHistory={(chatGPT, task, earliest, limit) => this.props.me.getHistory(task, earliest, limit)}
               searchChatMessages={this.props.me.searchChatMessages}
               searchTasks={this.props.me.searchTasks}
               streamChat={(chatGPT, x, opts)=>this.props.me.streamChat(x, opts)}
               createNewTask={this.props.me.createNewTask}
               uploadFile={this.props.me.uploadFile}
               onCloseTask={async (chatGPT, task) => {
                 const { updated } = await this.props.me.updateTaskSummary(task)
                 if (updated) chatGPT.updateTask(updated)
               }}
             />
    }
    this.setState({
      subpage: render
    })
  }


  openFineTuning = async (job) => {
    const projects = this.state.projects || []
    const onCreate = comp => {
      setTimeout(() => {
        ////debugger
        comp.selectJob(job)
      }, 400)
    }
    const openJob = async ({dataset, fineTuningJob, model}) => {
      dataset = await this.props.me.resolveDataset(dataset.id)
      let fineTunedModel
      if (fineTuningJob.job.fine_tuned_model) {
        fineTunedModel = this.getModels().find(x => x.id === fineTuningJob.job.fine_tuned_model)
      }
      return (back) => () => <DatasetExamplesView
                               openDetail={this.openDetail}
                               fineTunedModel={fineTunedModel}
                               fineTuningJob={fineTuningJob}
                               copyTask={this.props.copyTask}
                               key={dataset.id}
                               title={<ModelLabel model={fineTunedModel || model}/>}
                               dataset={dataset}
                               getModels={this.getModels}
                               me={this.props.me}
                               back={back}
                               vendors={this.state.vendors}
                               />
    }
    this.setState({
      subpage: () => {
        const models = this.getModels()
        return <DatasetFineTuningView
                 onCreate={onCreate}
                 openDetail={this.openDetail}
                 key='fine-tuning-top'
                 openJob={openJob}
                 title={"Fine-tuning Jobs"}
                 back={this.back}
                 me={this.props.me}
                 getModels={this.getModels}
                 resolveModel = {id => models.find(model => model.id === id)}
                 vendors={this.state.vendors}
                 fineTunedModels={this.getFineTunedModels()}
        />
      }
    })
  }

  openDataset = dataset => {
    this.openDatasets({
      onCreate: comp => {
        if (dataset) {
          comp.openDataset({dataset})
        }
      }
    })
  }

  openDatasets = async (opts = {}) => {
    const { onCreate } = opts
    this.setState({
      subpage: () => <DatasetsView
                       openDetail={this.openDetail}
                       onCreate={onCreate}
                       systemPrompts={this.state.systemPrompts}
                       clipboardTask={this.state.clipboardTask}
                       copyTask={this.copyDatasetExample}
                       cutTask={this.cutDatasetExample}
                       pasteTask={this.getPasteDatasetExample()}
                       parents={[]}
                       getModels={this.getModels}
                       prices={this.prices}
                       datasets={this.state.datasets}
                       models={this.getModels()}
                       fineTunedModels={this.getFineTunedModels()}
                       vendors={this.state.vendors || []}
                       me={this.props.me}
                       back={this.back}
                       title={'Training Data'}/>
    })
  }
  
  renderModelOptions = (options = {key: 'recentModels', category: 'models', preview: true, vendorOnly: false, unselectable: false}) => {
    const { key, category, preview, unselectable, vendorOnly } = options
    const onOptionsChanged = () => {
      //////debugger
      this.forceUpdate()
    }
    return <div className='modelsPreviewScroller'>
             <ModelsView 
               key={key}
               models={(isSelected, select) => {
                 return this.getModels().map( x => {
                   if (x.providerId) {
                   }
                   try {
                     x.isSelected = () => isSelected(x.id)
                     x.select = () => select(x.id)
                   } catch(err) {
                     //////debugger
                   }
                   return x
                 })
               }}
               onOptionsChanged={onOptionsChanged}
               category={category}
               observeOptions={sub => {
                 return {
                   subscribe: sub => this.props.me.observeModelOptions(category).subscribe(change => {
                     //////debugger
                     sub(change)
                   })
                 }
               }}
               me={this.props.me}
               prices={this.prices}
               vendors={this.filterVendors(this.state.vendors|| [])}
               menuActive={true}
               unselectable={unselectable}
               vendorOnly={vendorOnly}
               preview={preview}/>
      </div>
  }


  handleImportDataset = async (event, transfer) => {
    if (transfer.files.length > 0) {
      event.preventDefault();
      const parent = undefined
      let opener
      this.openDatasets({
        onCreate: comp => {
          opener = comp
        }
      })
      for (const file of transfer.files) {
        const dataset = await this.props.me.createNewDataset({name: file.name, summary: "Loading data, please wait..."})
        await this.props.me.uploadDataset({dataset: dataset.id, file})
        ////debugger
        opener.openDataset({dataset})
      }
      return true;
    } else {
      const uriList = transfer.getData('text/uri-list')
      if (uriList) {
        event.preventDefault()
        // Split the URI list by newline to handle multiple URLs
        const urls = uriList.split('\n').filter(url => url.trim() !== '')
        //console.log('URLs detected:', urls)
        for (const url of urls) {
          await this.props.me.uploadDataset({url})
        }
      }
    }
  }

  renderContent() {
    const selectModel = model => {
      this.setState({
        selectedModel: model
      })
      this.props.me.saveChatSettings({
        model
      })
    }
    const models = [
      {
        label: 'GPT-4',
        vendor: 'openai',
        selected: this.state.selectedModel === 'gpt-4',
        select: () => { selectModel('gpt-4', 'openai') },
        title: "GPT-4 Turbo",
        icon: OpenAI
      },
      {
        label: 'Claude-3',
        vendor: 'anthropic',
        selected: this.state.selectedModel === 'claude-3',
        select: () => { selectModel('claude-3', 'anthropic') },
        title: "Claude-3",
        icon: Anthropic
      }
    ]
    const modelButtons = <RadioButtons buttons={models}/>
    const { word, Word } = getWord(this.props.me)
    let openLinkedIn, openTwitter, openFacebook, openTikTok
    const s = (window.innerWidth - 20) / 240
    let style = {
      transform: `scale(${s},${s})`,
    }
    const { wordsUsed, wordsPurchased } = this.state 
    let available = Math.max(wordsPurchased - wordsUsed, 0)
    //console.log({available, wordsUsed})
    if (isNaN(available)) {
      available = 0
    }
    let className = 'keyboardWindow keyboardHome'
    let isLowMessage
    if (wordsPurchased > 0) {
      const isLow = available < 10
      const form = this.state.form
      if (available === 0) {
        isLowMessage = `You\'re out of ${word}s! You can purchase additional ${word}s for your account below.`
      } else if (isLow) {
        isLowMessage = `${Word}s are running low! You can purchase additional ${word}s for your account below.`
      }
      if (isLowMessage) {
        className += ' keyboardHomeWordPacksLowStatus'
      }
    }
    let openAIStatusClass = ''
    let openAIStatusDescription = 'Systems Operational'
    let provider = 'All'
    if (this.state.openAIStatus) {
      let { indicator, description } = this.state.openAIStatus
      openAIStatusClass = ' openAIStatus' + capitalize(indicator) 
      openAIStatusDescription = description.replace(/(Partially|All) /g, '');
      if (openAIStatusDescription !== 'Systems Operational') {
        provider = 'OpenAI'
      }
    }

    let blurbStyle = {
      fontSize: (Math.min(window.innerWidth, 600)*0.5 / 240) * 16
    }

    const playground = async (task) => {
      await this.openChat(task)
    }

    const newPlayground = async () => {
      const task = await this.props.me.createNewTask()
      await this.openChat(task)
    }

    const newDiscussion = async () => {
      const task = await this.props.me.createNewDiscussion()
      await this.openDiscussion(task)
    }

    const datasets= async () => {
      await this.openDataset(this.state.recentDatasets[0])
    }

    const finetuning = async () => {
      await this.openFineTuning()
    }

    const account = async () => {
      this.openAccount()
    }

    const usage = async () => {
      this.openUsage()
    }

    const systemPrompts = async () => {
      this.openSystemPrompts()
    }


    const toolsets = async () => {
      this.openToolsets()
    }

    const newSystemPrompt = async () => {
      this.openSystemPrompts({
        onCreate: async comp => {
          const systemPrompt = await this.props.me.createNewSystemPrompt()
          //////debugger
          comp.openSystemPrompt(systemPrompt)
        }
      })
    }
    
    let askLabel = 'reliable answers'
    let askButtonLabel = 'Ask'
    let title = TITLE
    title = "Attunewise.ai"
    let willRedirect = false

    const used = this.state.wordsUsed
    const purchased = this.state.wordsPurchased

    let Settings = 'Settings'
    ////debugger
    if (this.props.me.isSignedInAnonymously()) {
      Settings = "Sign-in"
    }

    return <div className={className}>
             <div className='keyboardHomeHeader'>
               <div className='keyboardHeaderTitle'>{title}</div>
             </div>
             <div className='keyboardHomeTop'>
               <div className='keyboardHomeShape'>
<svg id="HeroMask" data-name="Hero" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 558 203">
	<defs>
		<clipPath id="shape">
			<polygon points=".359 43.07 65.272 155.504 37.969 202.795 222.403 202.795 130.186 43.07 .359 43.07" />
			<polygon points="394.23 154.515 471.333 21.328 281.753 21.328 293.949 .205 115.766 .205 204.858 154.515 394.23 154.515" />			
		</clipPath>
	</defs>

  <image href={HeroImage} clipPath="url(#shape)" />
			
	<g className="heroMaskLeft">
	  <polygon points=".359 43.07 65.272 155.504 37.969 202.795 222.403 202.795 130.186 43.07 .359 43.07" style={{fill:"#086461;"}} opacity="0.9" />
	</g>
			
	<g className="heroMaskMiddle">
	  <polygon points="394.23 154.515 471.333 21.328 281.753 21.328 293.949 .205 115.766 .205 204.858 154.515 394.23 154.515" style={{fill:"#086461;"}} opacity="0.9" />
	</g>
			
  <g className={"heroMaskRight" + openAIStatusClass}>
	  <polygon points="481.15 21.408 404.301 154.515 558 154.515 481.15 21.408" style={{fill:"#0A807E;"}} />
          <g transform='translate(481.15, 148.515)'>
                      <text className={'openAIStatusDescriptionOpenAI'}>{provider}</text>
                       <text className={'openAIStatusDescription'}>{openAIStatusDescription}</text>
                     </g>
	</g>
</svg>
                 <div className='keyboardHomeStats'>
                   <Stat className='statAvailable' label={`${word}s available`} value={available}/>
                   <Stat className='statUsed' label='used' value={used}/>
                   <Stat className='statPurchased' label={this.state.nonFree ? 'purchased' : 'free'} value={purchased}/>

                 </div>
               </div>
             </div>
             <div className='keyboardHomeBottom'>
             </div>
             <div className='homeFooterMargin'/>
           </div>
  }

  renderCards = () => {
    const selectModel = model => {
      this.setState({
        selectedModel: model
      })
      this.props.me.saveChatSettings({
        model
      })
    }
    const models = [
      {
        label: 'GPT-4',
        vendor: 'openai',
        selected: this.state.selectedModel === 'gpt-4',
        select: () => { selectModel('gpt-4', 'openai') },
        title: "GPT-4 Turbo",
        icon: OpenAI
      },
      {
        label: 'Claude-3',
        vendor: 'anthropic',
        selected: this.state.selectedModel === 'claude-3',
        select: () => { selectModel('claude-3', 'anthropic') },
        title: "Claude-3",
        icon: Anthropic
      }
    ]
    const modelButtons = <RadioButtons buttons={models}/>
    const { word, Word } = getWord(this.props.me)
    let openLinkedIn, openTwitter, openFacebook, openTikTok
    const s = (window.innerWidth - 20) / 240
    let style = {
      transform: `scale(${s},${s})`,
    }
    const { wordsUsed, wordsPurchased } = this.state 
    let available = Math.max(wordsPurchased - wordsUsed, 0)
    //console.log({available, wordsUsed})
    if (isNaN(available)) {
      //////debugger
    }
    let className = 'keyboardWindow keyboardHome'
    let isLowMessage
    if (wordsPurchased > 0) {
      const isLow = available < 10
      const form = this.state.form
      if (available === 0) {
        isLowMessage = `You\'re out of ${word}s! You can purchase additional ${word}s for your account below.`
      } else if (isLow) {
        isLowMessage = `${Word}s are running low! You can purchase additional ${word}s for your account below.`
      }
      if (isLowMessage) {
        className += ' keyboardHomeWordPacksLowStatus'
      }
    }
    let openAIStatusClass = ''
    let openAIStatusDescription = 'Systems Operational'
    let provider = 'All'
    if (this.state.openAIStatus) {
      let { indicator, description } = this.state.openAIStatus
      openAIStatusClass = ' openAIStatus' + capitalize(indicator) 
      openAIStatusDescription = description.replace(/(Partially|All) /g, '');
      if (openAIStatusDescription !== 'Systems Operational') {
        provider = 'OpenAI'
      }
    }

    let blurbStyle = {
      fontSize: (Math.min(window.innerWidth, 600)*0.5 / 240) * 16
    }

    const playground = async (task) => {
      await this.openChat(task)
    }

    const newPlayground = async () => {
      const task = await this.props.me.createNewTask()
      await this.openChat(task)
    }

    const newDiscussion = async () => {
      const task = await this.playgroundFuncs.createNew()
      await this.openDiscussions({
        onCreate: comp => {
          comp.openToolset(task)
        }
      })
    }

    const datasets= async () => {
      await this.openDatasets()
    }

    const discussions= async () => {
      await this.openDiscussions()
    }

    const generators = async () => {
      this.openGenerators()
    }

    const finetuning = async (job) => {
      await this.openFineTuning(job)
    }

    const account = async () => {
      this.openAccount()
    }

    const usage = async () => {
      this.openUsage()
    }

    const systemPrompts = async () => {
      this.openSystemPrompts()
    }

    const toolsets = async () => {
      this.openToolsets()
    }

    const assistants = async () => {
      this.openAssistants()
    }

    const toolservers = async () => {
      this.openToolServers()
    }

    const newSystemPrompt = async () => {
      this.openSystemPrompts({
        onCreate: async comp => {
          const systemPrompt = await this.props.me.createNewSystemPrompt()
          //////debugger
          comp.open(systemPrompt)
        }
      })
    }
    const newToolset = async () => {
      this.openToolsets({
        onCreate: async comp => {
          const toolset = await this.toolsetFuncs.createNew()
          //////debugger
          comp.open(toolset)
        }
      })
    }
    const newGenerator = async () => {
      this.openGenerators({
        onCreate: async comp => {
          const generator = await this.generatorFuncs.createNew()
          //////debugger
          comp.open(generator)
        }
      })
    }
    const newToolServer = async () => {
      this.openToolServers({
        onCreate: async comp => {
          const toolset = await this.toolServerFuncs.createNew()
          //////debugger
          comp.open(toolset)
        }
      })
    }
    let askLabel = 'reliable answers'
    let askButtonLabel = 'Ask'
    let title = TITLE
    title = "Attunewise.ai"
    let willRedirect = false

    const used = this.state.wordsUsed
    const purchased = this.state.wordsPurchased

    let Settings = 'Settings'
    if (this.props.me.isSignedInAnonymously()) {
      Settings = "Sign-in"
    }
    let className1 = 'attunewiseHomeButtons'
    let selectedCard
    const select = (label, action) => {
      action()
    }
    const renderCardArray = () => {
      return [
        <HomeCard
          key='playground'                                                
          label="Inference" buttonLabel='Playground' action={playground} select={select}>

                   <div className='inferenceRecent'>
                   {
                     (this.state.recentTasks || []).map(task => {
                       const date = fromNow(task.lastUpdated)
                       const onClick = () => playground(task)
                       return <div key={task.id} className='taskTitle' onClick={onClick}>
                                <div  className='taskTitleLeft'>
	                          <div className='keyboardMenuItemIcon'><ReactSVG src={Hashtag}/></div>
                                </div>
	                        <div className='keyboardMenuItemLabel taskDescriptionLabel'>
                                  {task.title || "Empty Discussion"}
                                </div>
                                <div className='taskDate'>{date}</div>
                                <div className='keyboardMenuItemRight'>
                                </div>
                              </div>
                     })
                   }
                     <div className='bigButton'>
                       <SimpleButton legacyIconSize icon={Plus} action={newPlayground}/>
                     </div>
                   </div>
                 </HomeCard>,
        <HomeCard
          key='discussions'                              
          select={select}
          label="Discussions" buttonLabel='Discussions' action={discussions}>
          <div className='inferenceRecent'>
          {this.state.recentDiscussions &&
                    <div className='recentUsage datasetsRecent'>
                      {
                        this.state.recentDiscussions.map(discussion => {

                        })
                      }
                    </div>
          }
            <div className='bigButton'>
              <SimpleButton legacyIconSize icon={Plus} action={newDiscussion}/>
            </div>
          </div>
           </HomeCard>,
        <HomeCard label="Available Models" buttonLabel='Models'
          key='models'                                                
          select={select}
                  action={() => this.openModels()}>
                   <div className='recentUsage'>
                     {this.renderModelOptions()}
                   </div>
                 </HomeCard>,
        <HomeCard
          key='datasets'                              
          select={select}
          label="Optimize Models" buttonLabel='Training Data' action={datasets}>
                   {this.state.recentDatasets &&
                    <div className='recentUsage datasetsRecent'>
                      {
                        this.state.recentDatasets.map(dataset => {
                          let icon 
                          let legacyIconSize
                          const { isFolder, examples, generatingDataset, isHuggingFace } = dataset
                          if (generatingDataset) {
                            icon = Spin
                          } else if (isFolder) {
                            icon = Folder
                          } else if (isHuggingFace) {
                            icon = HF
                          } else {
                            icon = File
                            legacyIconSize = true
                          }
                          const open = () => this.openDataset(dataset)
                          const nameDiv = <div className='projectName'>{dataset.name}</div>
                          const date = fromNow(dataset.lastUpdated)
                          let displayInfo  = dataset.displayInfo || {heading: dataset.name }
                          let { heading } = displayInfo;
                          return <div key={dataset.id}
                                      className='datasetPreview' onClick={open}>
                                   <div className='datasetPreviewRow1'>
                                     <div className='datasetPreviewLeft'>
                                       <SimpleIcon legacyIconSize={legacyIconSize} src={icon}/>
                                       <div className='projectTitle'>
                                         {nameDiv}
                                       </div>
                                     </div>
                                     <div className='datasetPreviewRight'>
                                       <div className='projectDate'>{date}</div>
                                     </div>
                                   </div>
                                   <div className='datasetPreviewRow2'>
                                     {heading}
                                   </div>
                                 </div>
                                 })
                      }
                    </div>}
                   <div className='bigButton'>
                     <FileChooser handleDataTransfer={this.handleImportDataset}/>
                   </div>
                 </HomeCard>,
        <HomeCard
          key='fineTuning'                    
          select={select}
          label="Fine-tuning" buttonLabel='Fine-tuning jobs' action={
                         () => finetuning(this.state.recentFineTuningJobs[0])
                       }>
                   {this.state.recentFineTuningJobs &&
                    <div className='recentUsage recentFineTuningJobs'>
                      {
                        this.state.recentFineTuningJobs.map(fineTuningJob => {
                          const { job, name, platform, events } = fineTuningJob
                          let model
                          if (fineTuningJob.outputModel) {
                            model = this.getModel(fineTuningJob.outputModel)
                          } else {
                            model = this.getModel(fineTuningJob.model)
                          }
                          let legacyIconSize = true
                          let icon = File
                          const { message, status, trainedTokens, dur, price, eta } = getFineTuningJobInfo(model, fineTuningJob)
                          if (status.toLowerCase() === 'running') {
                            icon = Spin
                            legacyIconSize = false
                          }
                          let className = 'fineTuningJobPreview'
                          className = className + ' ' + className + "-" + status.toLowerCase()
                          let event = (events && events[0]) || {}
                          const date = fromNow(fineTuningJob.lastUpdated)
                          const open = () => {
                            return finetuning(fineTuningJob)
                          }
                          return <div key={fineTuningJob.id} className={className} onClick={open}>
                                   <div className='fineTuningJobPreviewTop'>
                                     <div className='fineTuningJobPreviewLeft'>
                                       <div className='fineTuningJobPreviewDataset'>
                                         <SimpleIcon legacyIconSize={legacyIconSize} src={icon}/>
                                         <div className='projectName'>{name}</div>
                                       </div>
                                       <div key='model' className='projectModelRow'>
                                         <ModelIcon model={model}/>
                                         <ModelLabel model={model}/>
                                       </div>
                                     </div>
                                     <div className='fineTuningJobPreviewRight'>
                                       <div className='fineTuningJobPreviewRightContent'>
                                         <div className='modelPrice'>{price}</div>
                                         <div className='projectDate'>{eta || date}</div>
                                       </div>
                                     </div>
                                   </div>
                                   <div className='fineTuningJobPreviewBottom'>
                                     {event.message}
                                     {false && eta && <div className='fineTuningJobETA'>ETA {eta}</div>}
                                   </div>
                                 </div>
                        })
                      }
                    </div>}
                   <div/>
             </HomeCard>,
        <HomeCard
          key='systemPrompts'          
          select={select}
          label="system" buttonLabel='System Prompts' action={systemPrompts}>
                   <div className='recentUsage systemPromptsPreview'>
                   {
                     (this.state.systemPrompts || []).map(systemPrompt => {
                       const { isFolder, title, name } = systemPrompt
                       let icon
                       if (isFolder) {
                         icon = Folder
                       } else {
                         icon = AISaid
                       }
                       const date = fromNow(systemPrompt.lastUpdated)
                       let dateComp
                       dateComp = <div className='systemPromptDate'>{date}</div>
                       const titleDiv = <div className='systemPromptTitle'>
                                          {title || name || "New System Prompt"}
                                        </div>
                       const onClick = () => {
                         this.openSystemPrompts({toolset: systemPrompt})
                       }
                       const displayInfo  = systemPrompt.displayInfo || { heading: name }
                       const { heading } = displayInfo;
                       return <div className='systemPromptPreview' onClick={onClick}>
                                <div  className='systemPromptLeft'>
                                  <div className='systemPromptLeftTopRow'>
                                    <div className='systemPromptLeftTopRowLeft'>
	                              <SimpleIcon src={icon}/>
                                      {titleDiv}
                                    </div>
                                    <div className='systemPromptTopRowRight'>{dateComp}</div>
                                  </div>
                                </div>
                                <div className='datasetPreviewRow2'>
                                  {heading}
                                </div>
                              </div>
                     })
                   }
                     <div className='bigButton'>
                       <SimpleButton legacyIconSize icon={Plus} action={newSystemPrompt}/>
                     </div>
                     
                   </div>
                 </HomeCard>,
        <HomeCard
          key='toolSets'
          select={select}
          label="toolsets" buttonLabel='Toolsets' action={toolsets}>
          {
            renderFileSystemPreview(this.state.toolsets || [], { emptyTitle: "New Toolset", icon: Gear, legacyIconSize: true, open: this.openToolsets})
          }
          <div className='bigButton'>
            <SimpleButton legacyIconSize icon={Plus} action={newToolset}/>
          </div>
        </HomeCard>,
        <HomeCard
          key='toolServers'
          select={select}
          card label="toolServers" buttonLabel='Tool Servers' action={toolservers}>
            {
              renderFileSystemPreview(this.state.toolServers || [], { emptyTitle: "New Tool Server", icon: ToolServerIcon, legacyIconSize: true, open: this.openToolServers})
            }
            <div className='bigButton'>
              <SimpleButton legacyIconSize icon={Plus} action={newToolServer}/>
            </div>
          </HomeCard>,

        <HomeCard
          key='generator'          
          select={select}
          label="system" buttonLabel='Generators' action={generators}>
                   <div className='recentUsage systemPromptsPreview'>
                   {
                     (this.state.recentGenerators || []).map(generator => {
                       const { isFolder, title } = generator
                       let icon
                       if (isFolder) {
                         icon = Folder
                       } else {
                         icon = AISaid
                       }
                       const date = fromNow(generator.lastUpdated)
                       let dateComp
                       dateComp = <div className='systemPromptDate'>{date}</div>
                       const titleDiv = <div className='systemPromptTitle'>
                                          {title}
                                        </div>
                       const onClick = () => {
                         this.openGenerator({toolset: generator})
                       }
                       return <div className='systemPromptPreview' onClick={onClick}>
                                <div  className='systemPromptLeft'>
                                  <div className='systemPromptLeftTopRow'>
                                    <div className='systemPromptLeftTopRowLeft'>
	                              <SimpleIcon src={icon}/>
                                      {titleDiv}
                                    </div>
                                    <div className='systemPromptTopRowRight'>{dateComp}</div>
                                  </div>
                                </div>
                              </div>
                     })
                   }
                     <div className='bigButton'>
                       <SimpleButton legacyIconSize icon={Plus} action={newGenerator}/>
                     </div>
                     
                   </div>
                 </HomeCard>,
        <HomeCard
          key='usage'
          select={select}
          label="track costs" buttonLabel='Usage' action={usage}>
                   <div className='recentUsage'>
                     {this.props.me.self && <Usage
                                              key='recentUsage'
                       preview
                       models={this.state.models.map(x => {
                              x.isSelected = () => false
                              x.select = () => {}
                              return x
                            })}
                            vendors={this.state.vendors || []}
                            me={this.props.me}
                                            />}
                   </div>
                 </HomeCard>,
        <HomeCard
          key="credits"
          select={select}
          className={'wordPackBuyButton' + (
                               isLowMessage ? ' wordPackBuyButtonLow' : '')}  icon={Right} label={`add ${word}s to your account`} buttonLabel={`${Word}s`} action={this.buy}>
                   <CreditsPreview
                     available={this.state.wordsPurchased - this.state.wordsUsed}
                     used={this.state.wordsUsed}
                     purchased={this.state.wordsPurchased}
                     Purchased={this.state.nonFree ? 'Purchased' : 'Free'}
                   />
                 </HomeCard>,
                          
        <HomeCard key='account' label="Account" buttonLabel={Settings} action={account} select={select}>
                   <div/>
                 </HomeCard>]

    }
    let cards = renderCardArray()
    let cardLayout = cards
    if (isDesktop()) {
      let col1 = cards.slice(0, 3)
      let col2 = cards.slice(3, 6)
      let col3 = cards.slice(6)
      cardLayout = <div className='cards3Cols'>
                     {
                       [col1, col2, col3].map(col => {
                         return <div className='cardCol'>{col}</div>
                       })
                     }
                   </div>
    }
    return <div className={className1}>
                 {isLowMessage && <div key='lowBro' className='keyboardHomeWordPacksLow'>
                                    <div className='keyboardHomeWordPacksLowIcon'>
                                    <ReactSVG src={AISaid}/>
                                    </div>
                                    <div className='keyboardHomeWordPacksLowMessage'>
                                      {isLowMessage}
                                    </div>
                                  </div>}
             {cardLayout}
               </div>
  }
    


  selectPage = page => {
    if (page === 'ask') {
      this.openChat()
    } else {
      this.back()
    }
  }

  getModel = modelId => {
    const model = this.models[modelId] || this.fineTunedModels[modelId]
    const m = ModelIcons[model.rootModel || model.id]
    const v = VendorIcons[model.providerId || model.vendorId]
    model.getIcon = v.getIcon
    model.getModelIcon = m && m.getModelIcon
    model.sortOrder = m && m.sortOrder || -1
    model.getSize = () => model.size
    model.isModel = y => isModel(model, y)
    return model
  }


  filterModels(models) {
    return models
  }

  getModels = () => {
    const models = this.filterModels(Object.values(this.models)).map(x => {
      const model = JSON.parse(JSON.stringify(x))
      const v = VendorIcons[model.providerId || model.vendorId]
      if (!v) {
        debugger
      }
      const m = ModelIcons[model.rootModel || model.id]
      model.getIcon = v.getIcon
      model.getModelIcon = m && m.getModelIcon
      model.sortOrder = m && m.sortOrder || -1
      model.getSize = () => model.size
      model.isModel = y => isModel(model, y)
      return model
    })
    models.sort((x, y) => x.sortOrder - y.sortOrder)
    //console.log({models})
    return models
  }

  getFineTunedModels = () => {
    const models = Object.values(this.fineTunedModels).map(model => {
      model = JSON.parse(JSON.stringify(model))
      const v = VendorIcons[model.providerId || model.vendorId]
      const m = ModelIcons[model.rootModel || model.id]
      model.getIcon = v.getIcon
      model.getModelIcon = m.getModelIcon
      model.sortOrder = m.sortOrder
      model.getSize = () => model.size
      model.isModel = y => isModel(model, y)
      return model
    })
    models.sort((x, y) => y.ts - x.ts)
    //console.log({models})
    return models
  }

  updateModelsLater = () => {
    this.updateLater('models', this.updateModels)
  }

  updateModels = () => {
    const models = this.getModels()
    this.setState({
      models
    })
  }

  updateProjectsLater = () => {
    this.updateLater('projects', this.updateProjects)
  }

  updateProjects = () => {
    const projects = Object.values(this.projects)
    this.setState({
      projects
    })
  }

  updateDatasetsLater = () => {
    this.updateLater('datasets', this.updateDatasets)
  }

  updateDatasets = () => {
    const datasets = Object.values(this.datasets)
    this.setState({
      datasets
    })
  }
  
  
  updateVendorsLater = () => {
    this.updateLater('vendors', this.updateVendors)
  }

  filterVendors(vendors) {
    return vendors
  }

  updateVendors = () => {
    const vendors = Object.values(this.vendors).map(vendor => {
      const v = VendorIcons[vendor.id]
      vendor.getIcon = v.getIcon
      vendor.sortOrder = v.sortOrder
      return vendor
    })
    vendors.sort((x, y) => x.sortOrder - y.sortOrder)
    this.setState({
      vendors
    })
  }

  updateSystemPromptsLater = () => {
    this.updateLater('systemPrompts', this.updateSystemPrompts)
  }

  updateSystemPrompts = () => {
    const systemPrompts = Object.values(this.systemPrompts)
    systemPrompts.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      systemPrompts
    })
  }

  resolvePrice = model => this.prices[model]
  
  updatePricesLater = () => {
    this.updateLater('prices', this.updatePrices)
  }

  updatePrices = () => {
    const prices = Object.values(this.prices)
    this.setState({
      prices
    })
  }

  updatePurchasesLater = () => {
    this.updateLater('purchases', this.updatePurchases)
  }

  purchases = {}

  updatePurchases = () => {
    //debugger
    const purchases = Object.values(this.purchases)
    let purchased = 0
    for (const purchase of purchases) {
      if (purchase.price > 0) {
        this.state.nonFree = true
      }
      purchased += purchase.creditCount
    }
    console.log({purchased})
    this.setState({
      wordsPurchased: purchased
    })
  }
  

  vendors = {}
  models = {}
  projects = {}
  datasets = {}
  fineTunedModels = {}
  customProviders = {}
  fineTunedModelsSubject = new Subject()
  prices = {}
  purchases = {}
  
  componentDidMount() {
    this.selfSub = this.props.me.observeSelf().subscribe(self => {
      if (self) {
        this.initListeners()
      } else {
        this.deinitListeners()
      }
    })
    this.props.onCreate(this)
  }

  recentPlayground = {}
  updateRecentTasks = () => {
    const tasks = Object.values(this.recentPlayground)
    tasks.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      recentTasks: tasks
    })
  }

  recentDiscussions = {}
  updateRecentDiscussions = () => {
    const tasks = Object.values(this.recentDiscussions)
    tasks.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      recentDiscussions: tasks
    })
  }

  recentPrompts = {}
  updateRecentPrompts = () => {
    const tasks = Object.values(this.recentPrompts)
    tasks.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      systemPrompts: tasks
    })
  }

  recentToolsets = {}
  updateRecentTools = () => {
    ////debugger
    const tasks = Object.values(this.recentToolsets)
    tasks.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      toolsets: tasks
    })
  }

  recentAssistants = {}
  updateRecentAssistants = () => {
    const tasks = Object.values(this.recentAssistants)
    tasks.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      assistants: tasks
    })
  }

  recentToolServers = {}
  updateRecentToolServers = () => {
    const tasks = Object.values(this.recentToolServers)
    tasks.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      toolServers: tasks
    })
  }

  recentSystemPrompts = {}
  updateRecentSystemPrompts = () => {
    const tasks = Object.values(this.recentSystemPrompts)
    tasks.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      systemPrompts: tasks
    })
  }

  recentGenerators = {}
  updateRecentGenerators = () => {
    const tasks = Object.values(this.recentGenerators)
    tasks.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      recentGenerators: tasks
    })
  }

  recentDatasets = {}
  updateRecentDatasets = () => {
    const datasets = Object.values(this.recentDatasets)
    datasets.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      recentDatasets: datasets
    })
  }
  
  recentFineTuningJobs = {}
  updateRecentFineTuningJobs = () => {
    const fineTuningJobs = Object.values(this.recentFineTuningJobs)
    fineTuningJobs.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      recentFineTuningJobs: fineTuningJobs
    })
  }

  recentlyViewedToolsets = {}
  updateRecentlyViewedToolsets = () => {
    const results = Object.values(this.recentlyViewedToolsets)
    results.sort((x, y) => y.lastUpdated - x.lastUpdated)
    this.setState({
      recentlyViewedToolsets: results
    })
  }

  initListeners = () => {
    this.playgroundFuncs = this.props.me.getPlaygroundFuncs()
    this.observeDiscussions = (opts) => this.playgroundFuncs.observe(opts).pipe(map(x => {
      const { discussion } = x
      discussion.title = discussion.title || discussion.name
      return x
    }))
    this.generatorFuncs = this.props.me.getGeneratorFuncs()
    this.observeGenerators = (opts) => this.generatorFuncs.observe(opts).pipe(map(x => {
      const { generator } = x
      generator.title = generator.title || generator.name
      return x
    }))
    this.toolsetFuncs = this.props.me.getToolsetFuncs()
    this.observeToolsets = (opts) => this.toolsetFuncs.observe(opts).pipe(map(x => {
      const { toolset } = x
      toolset.title = toolset.title || toolset.name
      return x
    }))
    this.systemPromptFuncs = this.props.me.getSystemPromptFuncs()
    this.observeSystemPrompts = (opts) => this.systemPromptFuncs.observe(opts).pipe(map(x => {
      const { type, systemPrompt } = x
      if (!systemPrompt) {
        debugger
      }
      systemPrompt.title = systemPrompt.title || systemPrompt.name
      return x
    }))    
    this.toolServerFuncs = this.props.me.getToolServerFuncs()
    this.observeToolServers = (opts) => this.toolServerFuncs.observe(opts).pipe(map(x => {
      const { type, toolServer } = x
      toolServer.title = toolServer.title || toolServer.name
      return x
    }))    
    this.deinitListeners()
    this.recentlyViewedToolsetsSub = this.toolsetFuncs.observeRecentlyViewed().subscribe(viewed => {
      this.recentlyViewedToolsets[viewed.id] = viewed
      this.updateLater('recentlyViewedToolsets', this.updateRecentlyViewedToolsets)
    })
    this.toolsSub = this.observeToolsets({limit: 4}).subscribe(change => {
      const { type, toolset } = change
      if (type === 'removed') {
        delete this.recentToolsets[toolset.id]
      } else {
        this.recentToolsets[toolset.id] = toolset
      }
      this.updateLater('recentToolsets', this.updateRecentTools)
    })
    this.systemPromptsSub = this.observeSystemPrompts({limit: 4}).subscribe(change => {
      const { type, systemPrompt } = change
      if (type === 'removed') {
        delete this.recentSystemPrompts[systemPrompt.id]
      } else {
        this.recentSystemPrompts[systemPrompt.id] = systemPrompt
      }
      this.updateLater('recentSystemPrompts', this.updateRecentSystemPrompts)
    })
    this.generatorsSub = this.observeGenerators({limit: 4}).subscribe(change => {
      const { type, generator } = change
      debugger
      if (type === 'removed') {
        delete this.recentGenerators[generator.id]
      } else {
        this.recentGenerators[generator.id] = generator
      }
      this.updateLater('recentGenerators', this.updateRecentGenerators)
    })
    this.discussionsSub = this.observeDiscussions({limit: 4}).subscribe(change => {
      const { type, discussion } = change
      if (type === 'removed') {
        delete this.recentDiscussions[discussion.id]
      } else {
        this.recentDiscussions[discussion.id] = discussion
      }
      this.updateLater('recentDiscussions', this.updateRecentDiscussions)
    })
    this.toolServersSub = this.observeToolServers({limit: 4}).subscribe(change => {
      const { type, toolServer } = change
      if (type === 'removed') {
        delete this.recentToolServers[toolServer.id]
      } else {
        this.recentToolServers[toolServer.id] = toolServer
      }
      this.updateLater('recentToolServers', this.updateRecentToolServers)
    })
    this.fineTuningJobsSub = this.props.me.observeDatasetFineTuningJobs({limit: 4}).subscribe(change => {
      const { type, fineTuningJob } = change
      if (type === 'removed') {
        delete this.recentFineTuningJobs[fineTuningJob.id]
      } else {
        this.recentFineTuningJobs[fineTuningJob.id] = fineTuningJob
      }
      this.updateLater('recentFineTuningJobs', this.updateRecentFineTuningJobs)
    })
    this.datasetsSub = this.props.me.observeDatasets({limit: 4}).subscribe(change => {
      const { type, dataset } = change
      if (type === 'removed') {
        delete this.recentDatasets[dataset.id]
      } else {
        this.recentDatasets[dataset.id] = dataset
      }
      this.updateLater('recentDatsets', this.updateRecentDatasets)
    })
    this.modelsSub = this.props.me.observeModelOptions('models').subscribe(({options}) => {
      this.setState({
        modelOptions: options
      })
    })
    this.playgroundSub = this.props.me.observeRecentTasks({limit: 4}).subscribe(change => {
      const { type, task } = change
      if (type === 'removed') {
        delete this.recentPlayground[task.id]
      } else {
        this.recentPlayground[task.id] = task
      }
      this.updateLater('recentTasks', this.updateRecentTasks)
    })
    
    this.statusSub = this.props.me.observeOpenAIStatus().subscribe(openAIStatus => {
      if (this.state.openAIStatus &&
          this.state.openAIStatus.indicator === openAIStatus.indicator
          &&
          this.state.openAIStatus.description === openAIStatus.description
         ) {
        return
      }
      this.setState({
        openAIStatus
      })
    })
    this.keysSub = this.props.me.observeKeys().subscribe(data => {
      this.setState({
        keys: data
      })
    })
    this.creditsSub = this.props.me.observeCredits().subscribe(data => {
      let { used, purchased } = data
      if (!used) used = 0
      if (!purchased) purchased = 0
      this.setState({
        wordsUsed: used * 100
      })
    })
    this.purchasesSub = this.props.me.observePurchases().subscribe(change => {
      const { type, purchase } = change
      if (type === 'removed') {
        delete this.purchases[purchase.id]
      } else {
        this.purchases[purchase.id] = purchase
      }
      this.updatePurchasesLater()
    })
    this.vendorsSub = this.props.me.observeVendors().subscribe(change => {
      const { type, vendor } = change
      if (type === 'removed') {
        delete this.vendors[vendor.id]
      } else {
        this.vendors[vendor.id] = vendor
      }
      this.updateVendorsLater()
    })
    this.pricesSub = this.props.me.observePrices().subscribe(change => {
      const { type, price } = change
      if (type === 'removed') {
        delete this.prices[price.id]
      } else {
        this.prices[price.id] = price
      }
      this.updatePricesLater()
    })
    this.modelsSub = this.props.me.observeModels().subscribe(change => {
      const { type, model } = change
      model.title = model.name // bw compatibilty for now
      //console.log("model", model)
      if (type === 'removed') {
        delete this.models[model.id]
      } else {
        this.models[model.id] = model
      }
      this.updateModelsLater()
    })
    this.importedModelsSub = this.props.me.observeImportedModels().subscribe(change => {
      const { type, model } = change
      model.title = model.name // bw compatibilty for now
      //console.log("model", model)
      if (type === 'removed') {
        delete this.models[model.id]
      } else {
        this.models[model.id] = model
      }
      this.updateModelsLater()
    })
    this.finetuneModelsSub = this.props.me.observeFineTunedModels().subscribe(change => {
      const { type, model } = change
      if (type === 'removed') {
        delete this.models[model.id]
        delete this.fineTunedModels[model.id]
      } else {
        this.models[model.id] = model
        this.fineTunedModels[model.id] = model
      }
      this.fineTunedModelsSubject.next(change)
      this.updateModelsLater()
    })
    this.redirSubj = this.props.me.observeRedirect().subscribe(redirect => {
      this.buy()
      if (redirect.error) {
        //////debugger
      }
    })
    this.customProvidersSub = this.props.me.observeOpenAICompatibleAPIProviders().subscribe(change => {
      const { type, provider } = change
      if (type === 'removed') {
        if (provider.models) {
          for (const model of provider.models) {
            delete this.models[model.id]
          }
        }
        delete this.customProviders[provider.id]
      } else {
        if (provider.models) for (const modelId of provider.models) {
          const model = {
            id: 'custom:'+this.props.me.self.uid+':'+provider.id+':'+modelId,
            modelId,
            providerId: 'custom',
            vendor: provider.label,
            vendorId: provider.id,
            name: modelId,
            title: modelId
          }
          this.models[model.id] = model
        }
        this.customProviders[provider.id] = provider
      }
      this.updateCustomProvidersLater()
    })
  }
  
  updateCustomProvidersLater = () => {
    clearTimeout(this.updateCustomProvidersTimeout)
    this.updateCustomProvidersTimeout = setTimeout(this.updateCustomProviders, 200)
  }

  getCustomProviders = () => {
    const result = Object.values(this.customProviders)
    result.sort((x, y) => {
      return y.lastUpdated - x.lastUpdated
    })
    return result
  }

  updateCustomProviders = () => {
    const customProviders = this.getCustomProviders()
    this.updateModels()
    this.setState({
      customProviders
    })
  }

  
  componentWillUnmount() {
    this.deinitListeners()
    this.selfSub.unsubscribe()
    this.selfSub = null
  }

  deinitListeners() {
    this.generators = {}
    this.purchases = {}
    this.recentPlayground = {}
    this.recentPrompts = {}
    this.recentToolsets = {}
    this.recentDatasets = {}
    this.recentFineTuningJobs = {}
    this.fineTunedModels = {}
    this.state.wordsPurchased = 0
    this.state.fineTuningJobs = []
    this.state.systemPrompts = []
    this.state.recentDatasets = []
    this.state.recentTasks = []
    this.state.recentGenerators = []
    this.state.recentFineTuningJobs = []
    this.state.wordsUsed = 0
    if (this.systemPromptsSub) this.systemPromptsSub.unsubscribe()
    if (this.fineTuningJobsSub) this.fineTuningJobsSub.unsubscribe()
    if (this.datasetsSub) this.datasetsSub.unsubscribe()
    if (this.modelsSub) this.modelsSub.unsubscribe()
    if (this.playgroundSub) this.playgroundSub.unsubscribe()
    if (this.keysSub) this.keysSub.unsubscribe()
    if (this.statusSub) this.statusSub.unsubscribe()
    if (this.purchasesSub) this.purchasesSub.unsubscribe()
    if (this.vendorsSub) this.vendorsSub.unsubscribe()
    if (this.modelsSub) this.modelsSub.unsubscribe()
    if (this.pricesSub) this.pricesSub.unsubscribe()
    if (this.creditsSub) this.creditsSub.unsubscribe()
    if (this.redirSub) this.redirSub.unsubscribe()
    if (this.customProvidersSub) {
      this.customProvidersSub.unsubscribe()
    }
    this.systemPromptsSub = null
    this.statusSubj = null
    this.purchasesSub = null
    this.vendorsSub = null
    this.modelsSub = null
    this.pricesSub = null
    this.creditsSub = null
    this.state.wordsPurchased = 0
    this.state.wordsUsed = 0
  }

  onNotEnoughTokens = () => {
  }
               
}
