import { delay } from '../../classes/Util.js'

/*
 * Finds the closest scrollable ancestor of an element.
 * @param {Element} element - The target element.
 * @returns {Element|Window} - The nearest scrollable ancestor or the window if none found.
 */
function getScrollableParent(element) {
  if (!(element instanceof Element)) {
    throw new Error('The provided target is not a DOM element.');
  }
  const overflowRegex = /(auto|scroll|hidden)/;

  let parent = element.parentElement;

  while (parent) {
    const style = window.getComputedStyle(parent);
    const overflowY = style.overflowY;
    const overflowX = style.overflowX;

    if (overflowRegex.test(overflowY) || overflowRegex.test(overflowX)) {
      return parent;
    }
    parent = parent.parentElement;
  }
  debugger
  return null
}
/**
 * Easing function: easeInOutCubic
 * @param {number} t - Progress of the animation (0 to 1)
 * @returns {number} - Eased progress
 */
function easeInOutCubic(t) {
  return t < 0.5
    ? 4 * t * t * t
    : 1 - Math.pow(-2 * t + 2, 3) / 2;
}

/**
 * Calculates the position of an element relative to a container.
 * @param {Element} target - The target element.
 * @param {Element|Window} container - The scrollable container.
 * @returns {{ top: number, left: number }} - The top and left positions.
 */
function getRelativePosition(target, container) {
  const src = target.getBoundingClientRect()
  const dst = (container instanceof Element) ? container.getBoundingClientRect() : {
    top: 0,
    left: 0
  }
  return {
    top: src.top - dst.top,
    left: src.left - dst.left,
    bottom: src.bottom - dst.top
  }
}

export async function scrollIntoView(target, options = {}, wasCancelled, debug) {
  const scroll = getScrollableParent(target);
  const rect = target.getBoundingClientRect();
  const scrollRect = scroll.getBoundingClientRect();
  const top = rect.top - scrollRect.top + scroll.scrollTop;
  const bottom = rect.bottom - scrollRect.top + scroll.scrollTop;
  const { scrollTop, clientHeight } = scroll;
  
  if (debug) console.log({ scrollTop, clientHeight, scrollBottom: scrollTop + clientHeight, top, bottom });
  
  if (rect.height > scroll.clientHeight) {
    options.block = 'start';
    if (debug) console.log("Element is bigger than viewport");
  } else if (bottom >= (scrollTop + clientHeight)) {
    options.block = 'center';
    if (debug) console.log("Element is below viewport");
  } else if (top <= scrollTop) {
    options.block = 'center';
    if (debug) console.log("Element is above viewport");
  } else {
    options.block = 'nearest';
    if (debug) console.log("Element is in viewport");
  }
  if (true) {
    target.scrollIntoView(options)
    await delay(0.5)
    return 
  }
  return new Promise((resolve, reject) => {
    if (!(target instanceof Element)) {
      reject(new Error('Target must be a DOM element.'));
      return;
    }

    const {
      duration = options.behavior === 'instant' ? 0 : 400,
      offset = 0,
      easing = easeInOutCubic,
      block = 'start', // 'start', 'center', 'end', 'nearest'
      inline = 'start', // 'start', 'center', 'end', 'nearest'
    } = options;

    const scrollableParent = getScrollableParent(target);
    if (!scrollableParent) {
      reject(new Error('No scrollable parent found.'));
      return;
    }

    // Translate coordinates of the target relative to the scrollable parent
    const { top: targetTop, left: targetLeft } = translateCoordinates(target, scrollableParent);

    const { height: targetHeight, width: targetWidth } = target.getBoundingClientRect();
    const { clientHeight: parentHeight, clientWidth: parentWidth, scrollTop, scrollLeft } = scrollableParent;

    // Determine vertical alignment
    const destinationY = calculateAlignment({
      position: targetTop,
      elementSize: targetHeight,
      containerSize: parentHeight,
      scrollOffset: scrollTop,
      alignment: block,
      offset,
    });

    // Determine horizontal alignment
    const destinationX = calculateAlignment({
      position: targetLeft,
      elementSize: targetWidth,
      containerSize: parentWidth,
      scrollOffset: scrollLeft,
      alignment: inline,
      offset,
    });

    // Animate scrolling
    animateScroll({
      scrollableParent,
      startX: scrollLeft,
      startY: scrollTop,
      destinationX,
      destinationY,
      duration,
      easing,
      wasCancelled,
      resolve,
    });
  });
}

/**
 * Translates the coordinates of a target element to its scrollable parent's coordinate system.
 * @param {Element} target - The target element.
 * @param {Element} scrollableParent - The scrollable parent.
 * @returns {{ top: number, left: number }} - Translated coordinates.
 */
function translateCoordinates(target, scrollableParent) {
  const targetRect = target.getBoundingClientRect();
  const parentRect = scrollableParent.getBoundingClientRect();

  return {
    top: targetRect.top - parentRect.top + scrollableParent.scrollTop,
    left: targetRect.left - parentRect.left + scrollableParent.scrollLeft,
  };
}

/**
 * Calculates the scroll position based on alignment.
 * @param {Object} params - Parameters for the alignment calculation.
 * @param {number} params.position - The position of the target element.
 * @param {number} params.elementSize - The size of the target element (height or width).
 * @param {number} params.containerSize - The size of the scrollable parent (height or width).
 * @param {number} params.scrollOffset - The current scroll offset of the parent.
 * @param {string} params.alignment - The alignment option ('start', 'center', 'end', 'nearest').
 * @param {number} params.offset - Additional offset to apply.
 * @returns {number} - The calculated scroll position.
 */
function calculateAlignment({ position, elementSize, containerSize, scrollOffset, alignment, offset }) {
  switch (alignment) {
    case 'start':
      return position + offset;

    case 'center':
      return position - (containerSize / 2 - elementSize / 2) + offset;

    case 'end':
      return position - (containerSize - elementSize) + offset;

    case 'nearest':
      if (position < scrollOffset) {
        // Element is above the viewport
        return position + offset;
      } else if (position + elementSize > scrollOffset + containerSize) {
        // Element is below the viewport
        return position - (containerSize - elementSize) + offset;
      }
      // Element is already in view
      return scrollOffset;

    default:
      return position + offset;
  }
}

/**
 * Animates scrolling to the target destination.
 * @param {Object} params - Parameters for the animation.
 * @param {Element} params.scrollableParent - The scrollable parent.
 * @param {number} params.startX - The starting horizontal scroll position.
 * @param {number} params.startY - The starting vertical scroll position.
 * @param {number} params.destinationX - The target horizontal scroll position.
 * @param {number} params.destinationY - The target vertical scroll position.
 * @param {number} params.duration - The duration of the scroll animation (ms).
 * @param {function} params.easing - The easing function for the animation.
 * @param {function} params.wasCancelled - A function to check if the scroll was cancelled.
 * @param {function} params.resolve - A function to resolve the Promise when the animation completes.
 */
function animateScroll({
  scrollableParent,
  startX,
  startY,
  destinationX,
  destinationY,
  duration,
  easing,
  wasCancelled,
  resolve,
}) {
  let startTime = null;

  function step(currentTime) {
    if (wasCancelled && wasCancelled()) {
      console.log("CANCELLED")
      resolve();
      return;
    }

    if (!startTime) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    const easedProgress = easing(progress);

    const currentX = startX + (destinationX - startX) * easedProgress;
    const currentY = startY + (destinationY - startY) * easedProgress;

    scrollableParent.scrollTo(currentX, currentY);

    if (progress < 1) {
      window.requestAnimationFrame(step);
    } else {
      resolve();
    }
  }

  window.requestAnimationFrame(step);
}
