import React, { useRef, useEffect, Component } from 'react'
import ReactDOM from 'react-dom'
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import { ReactSVG } from 'react-svg'
import { makeTextPlain, walkDOM, Keyboard, parseCode, renderCode, KeyboardAutocomplete, Document, InputControl, KeyboardButton, KeyboardButton1, KeyboardTitle } from '../Keyboard'
import { UComponent, BnPage, BnSubpage } from '../Page'
import { UIButton } from '../chat/components/Button'
import { UIOKCancel } from '../chat/components/OKCancel'
import { isSafari, isChrome, isMobile, isDesktop } from '../../classes/Platform.js'
import { FadeComponents, FadeSpan } from '../FadeSpan'
import AICheck from '../../assets/Icons/AICheck.svg'
import Spin from '../../assets/Icons/Spin.svg'
import Cross from '../../assets/Icons/Cross.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Stop from '../../assets/Icons/Stop.svg'
import Empty from '../../assets/Icons/Empty.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import Down from '../../assets/Icons/Down.svg'
import Up from '../../assets/Icons/Up.svg'
import Category from '../../assets/Icons/UserSaid.svg'
import OpenFile from '../../assets/Icons/OpenFile.svg'
import File from '../../assets/Icons/File.svg'
import Image from '../../assets/Icons/Image.svg'
import Save from '../../assets/Icons/SaveCommand.svg'
import Undo from '../../assets/Icons/Redo.svg'
import Redo from '../../assets/Icons/Undo.svg'
import Send from '../../assets/Icons/Send.svg'
import Cut from '../../assets/Icons/Share.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Share from '../../assets/Icons/Share.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import AISaid from '../../assets/Icons/AISaid.svg'
import CheckMark from '../../assets/Icons/Tick.svg'
import EditIcon from '../../assets/Icons/Edit.svg'
import Plus from '../../assets/Icons/Plus.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import Alert from '../../assets/Icons/Alert.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Question from '../../assets/Icons/Question.svg'
import Marker from '../../assets/Icons/Marker.svg'
import Gear from '../../assets/Icons/Settings.svg'
import { EditMenu } from '../Home/EditMenu.js'
import moment from 'moment'
import { langs } from '../../classes/Lang.js'
import {SpectrumAnalyzer} from '../SpectrumAnalyzer'
import ClickAwayListener from 'react-click-away-listener'
import { MarkdownNew, Markdown, containsLatex, containsMarkdown } from './Markdown.js'
import { getPortal } from '../Client'
import { useSwipeable } from 'react-swipeable'
import { parseTable, renderTable, renderTableMarkdown } from '../Keyboard/Table.js'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {materialLight as CodeStyle} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { markdownToTxt } from './MarkdownToTxt.js'
import { jsonrepair } from 'jsonrepair'
import Popup from 'reactjs-popup';
import TurndownService from 'turndown'
import Settings from '../../assets/Icons/Settings.svg'
import { Slider } from './Slider.js'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Scrollbar, Mousewheel } from 'swiper/modules';
import { Dots } from '../Dots'
import { Calendar } from '../Home/Usage.js'
import { ActionMenu } from '../Home/ActionMenu.js'
import { formatPrice, ModelLabel, Model, ModelVendor, ModelsMenu, ModelsView } from './ModelsMenu.js'
import { resolveModelId } from '../Home'
import { delay, countTokens, scrollIntoView, scrollOnKeyDown, startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfDay} from '../../classes/Util.js'
import { InMemorySearch } from '../../classes/InMemorySearch.js'
import { InfiniteScroll } from '../Scrolling'
import yaml from 'js-yaml'
import 'swiper/css'; // basic Swiper styles
import 'swiper/css/navigation'; // if you need navigation buttons
import './index.css'
import './text.css'
const Home = Left
const End = Right

/**
 * Convert JSON to Markdown
 * @param {Object} json - JSON object to convert
 * @param {number} level - Current nesting level (for indentation)
 * @returns {string} - Markdown string
 */
function jsonToMarkdown(json, level = 0) {
    const indent = '  '.repeat(level);
    let markdown = '';

    if (Array.isArray(json)) {
        json.forEach(item => {
            markdown += `${indent}- ${typeof item === 'object' ? '' : item}\n`;
            if (typeof item === 'object') {
                markdown += jsonToMarkdown(item, level + 1);
            }
        });
    } else if (typeof json === 'object') {
        Object.keys(json).forEach(key => {
            const value = json[key];
            if (typeof value === 'object' && value !== null) {
                markdown += `${indent}- **${key}**:\n`;
                markdown += jsonToMarkdown(value, level + 1);
            } else {
                markdown += `${indent}- **${key}**: ${value}\n`;
            }
        });
    } else {
        markdown += `${indent}${json}\n`;
    }

    return markdown;
}

const MIN_ANIM_DUR = 1.0

class Autoheight extends Component {
  setRef = (ref) => {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.ref)
      if (ref) {
        this.resizeObserve.observe(ref)
      }
    }
    this.ref = ref
  }
  componentDidMount() {
    this.resizeObserver = new ResizeObserver(entries => {
      if (this.props.swiper) {
        if (this.props.swiper.activeIndex === this.props.index) {
          requestAnimationFrame(() => {
            this.props.swiper.update()
            this.props.swiper.updateAutoHeight()
          })
        }
      }
    })
    this.resizeObserver.observe(this.ref)
  }
  
  componentWillUnmount() {    
    this.resizeObserver.disconnect()
  }
  render() {
    return <div className='autoheight' ref={this.setRef}>
             {this.props.children}
             </div>
  }
  
}

export class ImageComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onError = async () => {
    let replacement = []
    try {
      this.setState({
        replacement
      })
      // Perform HEAD request to determine Content-Type
      const response = await fetch(url, { method: 'HEAD' });
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.startsWith('video')) {
        replacement = <div className='aiLink'><video className='aiLinkImg' src={src} onError={this.onError}/></div>        
      } else {
      }
    } catch (err) {
      console.error('Error fetching Content-Type:', err);
    }
    this.setState({
      replacement
    })
  }

  render() {
    if (this.state.replacement) {
      return this.state.replacement
    }
    const { src } = this.props
    return <div className='aiLink'><img className='aiLinkImg' src={src} onError={this.onError}/></div>
  }
}


class FadeSpanOld extends React.Component {

  setRef=ref => {
    this.ref = ref
  }
  
  componentWillUnmount() {
    ////console.log("FadeSpan unmounted", this.props.debugKey)
  }
  
  componentDidMount() {
    ////console.log("FadeSpan mounted", this.props.debugKey)
    requestAnimationFrame(() => {
      const el = this.ref
      if (el) {
        el.style.transition = 'opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0)'
        const delay = (this.props.index * 1) + 'ms'
        el.style.transitionDelay = delay
        //console.log("animation delay", this.props.debugKey, this.props.index, delay)
        requestAnimationFrame(() => {
          if (this.ref) {
            this.ref.style.opacity = 1
          }
        });
      }
    });
  }
  
  render() {
    return (
      <span
        id={this.props.id}
        ref={this.setRef}
        style={{ 
          opacity: 0,
        }}
      >
        {this.props.children}
      </span>
    );
  }
}

export class FadeComponentsOld {
  // Track position within current render
  constructor({id}) {
    this.id = id
  }
  reset = (fade) => {
    this.index = 0
    this.keyIndex = 0
    this.fade = fade
  }
  index = 0
  keyIndex = 0
  fade = true
  
  nextKey = (node) => {
    if (!this.fade) {
      return undefined
    }
    if (true) {
      this.keyIndex++
      return 'fade-key-'+this.keyIndex
    }
    const { line, column, offset } = node.position.start;
    // Use offset if available for more granularity
    return this.id + `-pos-${line}-${column}-${offset}`;
  }
  wrapWords = (children) => {
    if (!this.fade) {
      return children
    }
    if (!children) return null;
    if (Array.isArray(children)) {
      return children.map(this.wrapWords)
    }
    else if (typeof children === 'string') {
      let split = ''
      return children.split(split).map(char => {
        const index = this.index
        this.index++
        const key = this.id + '-fade-span-' + index
        return <FadeSpan key={key} index={index}>{char}</FadeSpan>;
      });
    }
    return children;
  };
  CodeBlock = ({className, node, children}) => {
    let lang
    const text = (typeof children == 'string' && children)  || ''
    const multiline = text.indexOf('\n') > 0
    ////////console.log("CodeBlock", children)
    let clazzName = 'aiCode'
    if (!multiline) {
      clazzName = 'aiCode aiCodeInline'
    }
    const copy = async () => {
      try {
        navigator.clipboard.writeText(text)
      } catch (ignored) {
        //////console.log(text)
      }
      await delay(0.5);
    }
    if (children && children.indexOf('%') >= 0) {
      try {
        children = decodeURIComponent(children)
      } catch (err) {
        console.error(err)
      }
    }
    const key = this.nextKey(node)
    return <div key={key}className={clazzName}>
             {this.wrapWords(children)}
             {multiline && <div className='codeCopy'><SimpleButton icon={Copy} action={copy}/></div>}
           </div>
  }
  p= opts => {
    const { node, children, ...props } = opts;
    const key = this.nextKey(node)      
    //console.log({node})
    useEffect(() => {
        //console.log('p mounted ' + key);
      return () => console.log('p unmounting ' + key);
    }, []);
    // Create a stable key for the paragraph based on its content
    return <p key={key} {...props}>{this.wrapWords(children)}</p>
  }
  blockquote= opts => {
    const { node, children, ...props } = opts;
    return <blockquote key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</blockquote>
  }
  h1= opts => {
    const { node, children, ...props } = opts;
    return <h1 key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</h1>
  }
  h2= opts => {
    const { node, children, ...props } = opts;
    return <h2 key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</h2>
  }
  h3= opts => {
    const { node, children, ...props } = opts;
    return <h3 key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</h3>
  }
  h4= opts => {
    const { node, children, ...props } = opts;
    return <h4 key={this.nextKey(node)} {...props}>{this.wrapWords(children)}</h4>
  }
  code= opts => this.CodeBlock(opts)
  
  pre=opts => {
    const { node, children, ...props } = opts;
    return <pre key={this.nextKey(node)}  {...props}>{this.wrapWords(children)}</pre>
  }
  em= opts => {
    const { node, children, ...props } = opts;
    return <em key={this.nextKey(node)}  {...props}>{this.wrapWords(children)}</em>
  }
  del= opts => {
    const { node, children, ...props } = opts;
    return <del key={this.nextKey(node)}  {...props}>{this.wrapWords(children)}</del>
  }
  hr= opts => {
    const { node, children, ...props } = opts;
    return <hr key={this.nextKey(node)}  {...props}>{this.wrapWords(children)}</hr>
  }
  strong= opts => {
    const { node, children, ...props } = opts;
    return <strong key={this.nextKey(node)}  {...props}>{this.wrapWords(children)}</strong>
  }
  ol= opts => {
    const { node,children, ...props } = opts;
    const key = this.nextKey(node)
    let className;
    if (children.filter(x => x.props && x.props.node.tagName === 'li').length === 1) {
      className = 'olSingle';
    }
    return <ol  key={key} className={className} {...props}>{this.wrapWords(children)}</ol>
  }
  ul= opts => {
    const { node, children,...props } = opts;
    let className;
    if (children && children.filter(x => x.props && x.props.node.tagName === 'li').length === 1) {
      className = 'olSingle';
    }
    const key = this.nextKey(node)
    useEffect(() => {
        //console.log('ul mounted ' + key);
      return () => console.log('ul unmounting ' + key);
    }, []);
    return <ul  key={key} className={className} {...props}>{this.wrapWords(children)}</ul>
  }
  li = opts => {
    let { node, children, ...props } = opts;
    const key = this.nextKey(node)
    const ref = useRef(null);
    const wrapped = this.wrapWords(children)
    let delay = (this.index * 1)+'ms'
    useEffect(() => {
      console.log('li mounted ' + key);
      if (ref.current) {
        ref.current.style.animationDelay = delay
      }
      return () => console.log('li unmounting ' + key);
    }, []);
    return <li /* className='li-fade-in' */
               key={key} {...props}>{wrapped}</li>
  }
  img = ({node, src, alt}) => {
    return <ImageComp key={this.nextKey(node)} src={src}/>
  }
  a = props => {
    const { node, children } = props;
    const { href } = node.properties;
      if (!href || !href.startsWith) {
        return null;
      }
      const title = '';
    const prefix = "ai://?q=";
    let onClick;
    let tooltip;
    if (href.startsWith(prefix)) {
        let q = (href + ' ' + (title || '')).substring(prefix.length);
    } else {
      tooltip = href;
      onClick = e => {
        openLink(href);
      }
    }
    const nop = () => {};
    let enter;
    let leave;
    return (
        <div 
          key={this.nextKey(node)}
          onPointerEnter={enter} 
          onPointerLeave={leave}
          className="aiLink" 
          onMouseDown={onClick}
        >
          {this.wrapWords(children)}
        </div>
      );
  }
}



export const getComponents = ({openLink} = {}) => {
    const components = {
      p0: opts => {
        const { node, ...props } = opts
        return <div className={'para'} {...props}/>
      },
      table: opts => {
        const { node, ...props} = opts
        debugger
        return <table {...props} />
      },
      td: opts => {
        const { node, ...props} = opts
        debugger
        return <td {...props} />
      },
      th: opts => {
        const { node, ...props} = opts
        debugger
        return <th {...props} />
      },
      pre: PreBlock,
      code: CodeBlock,
      check: CheckMarkComp,
      ol: opts => {
        const { node, ...props } = opts
        let className
        if (props.children.filter(x => x.props && x.props.node.tagName === 'li').length === 1) {
          className = 'olSingle'
        }
        return <ol className={className} {...props}/>
      },
      ul: opts => {
        const { node, ...props } = opts
        let className
        if (props.children.filter(x => x.props && x.props.node.tagName === 'li').length === 1) {
          className = 'olSingle'
        }
        return <ul className={className} {...props}/>
      },
      li: opts => {
        let { node, children } = opts
        if (Array.isArray(children)) {
          children = children.filter((x, i) => i > 0 || x[i] !== '\n')
        }
        if (children && (children[0] === '\n' || children.length > 1)) {
          return <li className='liFun'><div className='liContent'>{children}</div></li>
        } else {
          const { node, ...props } = opts
          return <li {...props}/>
        }
      },
      img: ({src, alt}) => {
        return <ImageComp src={src}/>
      },
      a: props => {
        const { node, children } = props
        const { href, name } = node.properties
        if (!href || !href.startsWith) {
          return null
        }
        const title = ''
        const prefix = "ai://?q="
        let onClick
        let tooltip
        if (href.startsWith(prefix)) {
          let q = (href + ' ' + (title || '')).substring(prefix.length)
        } else {
          tooltip = href
          onClick = e => {
            openLink(href)
          }
        }
        const nop = () => {
        }
        let enter
        let leave
        return <a id={name} onPointerEnter={enter} onPointerLeave={leave}
                    className='aiLink' onMouseDown={onClick}>{children}</a>
      }
    }
    return components
  }

const insertImage = (img) => {
  const sel = window.getSelection();
  if (sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    range.insertNode(img);
    const space = document.createTextNode('\u200B');
    range.insertNode(space);      
    // Move the range after the inserted image
    range.setStartAfter(img);
    range.setEndAfter(img);
    
    // Update the selection
    sel.removeAllRanges();
    sel.addRange(range);
  } else {
    // If the rangeCount is 0, append the image at the end
    const space = document.createTextNode('\u200B');
    range.insertNode(space);      
    range.insertNode(img);
    const range = document.createRange();
    range.setStartAfter(img);
    range.setEndAfter(img);
    sel.removeAllRanges();
    sel.addRange(range);
  }
}

const getEditedContent = (editable) => {
  let content  = []
  let result = ''
  const flush = () => {
    if (result) {
      result = result.replace(/\n•[ ]+/g, "\n•  ");
      content.push({
        type: "text",
        text: result
      })
      result = ''
    }
  }
  const apply = node => {
    walkDOM(node, n => {
      if (n instanceof HTMLVideoElement) {
      }
      else if (n instanceof HTMLImageElement) {
        const url = n.src
        flush()
        content.push({
          type: "image_url",
          image_url: {
            url
          }
        })
      } else if (n.nodeName == "WBR") {
      } else if (n.nodeName == "BR") {
        result += "\n";
      } else if (n.nodeType == 3 && !(n.parentElement instanceof HTMLSpanElement)) {
        let textContent = n.textContent.replace(nbsp, " ");
        result += textContent;
        if (n.parentElement !== node && n.parentElement instanceof HTMLDivElement) {
          result += '\n'
        }
      }
    })
    flush()
  }
  apply(editable)
  return content
}


const getReplies = message => {
  if (message.role === 'assistant') {
    if (message.model) {
      return [message].concat(message.models || [])
    }
    return message.models || []
  }
  return []
}

export const TRAIN = {
  title: "Train",
  name: "Train",
  id: 'train',
  getIcon: () => CheckMark,
  vendor: '',
  vendorId: '',
  isModel:id =>  id === 'train'
}

export const REJECT = {
  title: "Reject",
  name: "Reject",
  id: 'reject',
  getIcon: () => Cross,
  vendor: '',
  vendorId: '',
  isModel:id =>  id === 'reject'
}

export const pasteText = e => {
  e.preventDefault();
  let plainText = e.clipboardData.getData('text/plain')
  if (!plainText) {
    const text = e.clipboardData.getData('text/html')
    plainText = makeTextPlain(text)
  }
  document.execCommand("insertText", false, plainText);
}

const sortModels = (x, y) => {
  if (x.id === 'train') return -1
  if (y.id === 'train') return 1
  const cmp = x.title.localeCompare(y.title)
  if (cmp) return cmp
  if (x.isFinetune && !y.isFinetune) {
    return 1
  }
  if (y.isFinetune && !x.isFinetune) {
    return -1
  }
  return y.ts - x.ts
}


export class ModelConfig extends Component {

  constructor(props) {
    super(props)
    this.state = {
      temperature: 1,
      top_p: 1,
      top_k: .5,
    }
  }

  componentDidMount() {
    this.props.onCreate(this)
  }

  getOpts = () => {
    const opts = {}
    for (const opt in this.state) {
      opts[opt] = Math.round(this.state[opt])
    }
    return opts
  }

  setTemperature = (temperature) => {
    this.setState({temperature})
  }

  getTemperature = () => {
    return this.state.temperature
  }
  
  setTop_p = (top_p) => {
    this.setState({
      top_p
    })
  }

  getTop_p = () => {
    return this.state.top_p
  }

  setTop_k = (top_k) => {
    this.setState({
      top_k
    })
  }

  getTop_k = () => this.state.top_k

  renderModelConfig() {
    return <div className='modelConfig'>
             <div className='tempSlider'><Slider label='Temp' onChange={this.setTemperature} value={this.getTemperature()} bounds={[0, 2]}/></div>
             <div className='tempSlider'><Slider label='Top p' onChange={this.setTop_p} value={this.getTop_p()} bounds={[0, 1]}/></div>
             <div className='tempSlider'><Slider label='Top k' onChange={this.setTop_k} value={this.getTop_k()} bounds={[0, 1]}/></div>
           </div>
  }

  render() {
    return this.renderModelConfig()
  }
}


class ChatPage extends BnSubpage {

  openSubpage = f => {
    this.setState({
      subpage: f
    })
  }


  componentDidMount() {
    this.props.chatGPT.chatPage = this
    //////debugger
  }

  renderContent() {
    return <div className='chatPageContent'>
             {this.props.chatGPT.renderChat()}
           </div>
  }
  
}


class MessageContainer extends Component {
  constructor(props) {
    super(props)
  }

  setRef = ref => {
    this.ref = ref
  }

  componentDidUpdate() {
    this.checkCreateObserver()
  }
    

  componentDidMount() {
    this.checkCreateObserver()
  }

  checkCreateObserver = () => {
    if (!this.ref || !this.props.scrollRef) return
    if (this.observer) return
    const cb = (entries) => {
      entries.forEach(entry => {
        let visible = entry.isIntersecting
        if (entry.isIntersecting) {
          entry.target.classList.remove('message-fade-out')
        } else {
          entry.target.classList.add('message-fade-out')
        }
      })
    }
    this.observer = new IntersectionObserver(cb, {
      root: this.props.scrollRef,
      rootMargin: '100px 0px',
      threshold: 0
    });
    this.observer.observe(this.ref)
  }

  componentWillUnmount() {
    if (this.observer) this.observer.disconnect()
  }
  
  render() {
    return <div ref={this.setRef} className='chatGptChatMessageBody'>
             {this.props.children}
             </div>
  }
}

export class PlayButton extends Component {

  constructor (props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.playing !== this.props.playing) {
      this.setState({
        playing: this.props.playing
      })
    }
  }

  componentDidMount() {

  }

  play = async () => {
    if (this.state.playing) {
      await this.props.stop()
      this.state.playing = false
      this.forceUpdate()
    } else {
      this.state.playing = true
      this.props.play()
      this.forceUpdate()
    }
  }
  
  render() {
    let icon = Send
    if (this.state.playing && !this.props.playing) {
      icon = Spin
    } else if (this.props.playing) {
      icon = Stop
    }
    return <div className='playButton'>
             <SimpleButton icon={icon} action={this.play}/>
           </div>
  }
}

export class SearchField extends Component {


  constructor (props) {
    super(props)
    this.state = {
    }
  }
  
  onSearch = term => {
    return this.props.onSearch(term)
  }

  clear = () => {
    this.searchField.clear()
  }

  getText = () => this.searchField.getText()

  setText = (text) => {
    this.searchField.setText(text, false)
  }

  getMarkdown = () => {
    const contents = this.searchField.getContent()
    const markdown = contents.map(content => {
      switch (content.type) {
        case 'text':
          return content.text
        case 'image_url':
          return `![](${content.image_url.url})`
      }
    }).join('')
    //console.log({markdown})
    return markdown
  }

  insertImage = url => {
    return this.searchField.insertImage(url)
  }

  insertTextAtCaret = (text) => {
    this.searchField.insertTextAtCaret(text, true, true)
  }

  focus = () => {
    this.searchField.focus()
  }

  setCaretPosition = pos => {
    this.searchField.setCaretPosition(pos)
  }

  setInputControl = editor => {
    if (editor) {
      //////debugger
      this.sub = editor.observeEditorHeight().subscribe(this.onEditorHeightChanged)
    } else {
      if (this.sub) this.sub.unsubscribe()
    }
  }

  setSearchEditor = editor => {
    this.searchField = editor
  }
  
  onEditorHeightChanged = h => {
    if (this.props.onEditorHeightChanged) {
      this.props.onEditorHeightChanged(Math.max(h - 90, 40))
    }
  }

  search = searchTerm => {
    this.searchField.setText(searchTerm)
  }

  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }
  
  render() {
    let searchTerm = ''
    const clear = () => {
      this.searchField.clear()
      this.onSearch('')
    }
    const onFocus = () => {
    }
    const onBlur = () => {
    }
    const onInput = () => {
      this.onSearch(this.getMarkdown())
    }
    const onPaste = e => {
      if (this.props.onPaste) this.props.onPaste(e)
      setTimeout(onInput, 33)
    }
    let style
    let style2
    let filler
    let busy
    let icon = this.props.icon
    let label = this.props.label
    let action = this.props.action
    let button
    if (action) {
      button = <SimpleButton legacyIconSize={this.props.legacyIconSize} icon={icon} action={action} label={label}/>
    }
    let middleLeft = button
    return <div className='searchFieldInput'>
             <div className='chatHeader' style={style}>
               <div className='chatHeaderFiller'>{filler}</div>
             </div>
             <div className='inputControlContainer' style={style2}>
               <InputControl
                 onClick={this.props.onClick}
                 ref={this.setInputControl}
                 placeholder={this.props.placeholder || "Search"}
                 key={'search'}                 
                 busy={busy}
                 middleLeft={middleLeft}
                 me={this.props.me}
                 onSetEditor={this.setSearchEditor}
                 downward={true}
                 onClear={clear}
                 onFocus={onFocus}
                 onBlur={onBlur}
                 onInput={onInput}
                 onPaste={onPaste}
                 onKeyDown={this.props.onKeyDown}
                 menu={this.props.menu}
               />
             </div>
           </div>
  }
}

// Function to select all content of the contenteditable div
function divSelectAll(div) {
  if (div && div.isContentEditable) {
    // Create a range object
    const range = document.createRange();
    
    // Select the entire content of the div
    range.selectNodeContents(div);
    
    // Clear any existing selection
    const selection = window.getSelection();
    selection.removeAllRanges();
    
    // Add the new range (i.e., select the div's content)
    selection.addRange(range);
  }
}


export class DeleteButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  trash = async () => {
    if (!this.state.confirmDelete) {
      this.state.confirmDelete = true
      this.forceUpdate()
      return
    }
    this.state.busy = true
    this.forceUpdate()
    const start = Date.now()
    await this.props.trash()
    const end = Date.now()
    const elapsed = (end - start) / 1000
    const dur = MIN_ANIM_DUR - elapsed
    if (dur > 0) {
      await delay(dur)
    }
    this.state.busy = false
    this.state.confirmDelete = null
    this.forceUpdate()
  }

  cancelDelete = async () => {
    this.setState({
      confirmDelete: false
    })
  }

  render() {
    const { label } = this.props
    let deleteButton
    let deleteIcon = this.state.busy ? Spin : (this.props.icon || Trash)
    const trash = () => this.trash()
    let Delete = this.props.Delete || 'Delete'
    let className='threadDeleteButton'
    if (label) {
      className += ' threadDeleteButtonWithLabel'
    }
    if (this.state.confirmDelete) {
      deleteButton = <div key='delete' className='threadDeleteButtonConfirm'><SimpleButton label='Confirm?' icon={deleteIcon} action={trash}/></div>
      if (!this.props.noClickAway) {
        deleteButton = <ClickAwayListener onClickAway={this.cancelDelete}>
                         {deleteButton}
                       </ClickAwayListener>
        
      }
    } else {
      deleteButton = <div key='del' className={className}><SimpleButton label={label} action={trash} icon={deleteIcon}/></div>
    }
    return <div className='deleteButtonHolder'>{deleteButton}</div>
  }
}

export const GearButton = props => {
  return <SimpleButton legacyIconSize icon={Gear} {...props}/>
}

export const GearIcon = props => {
  return <SimpleIcon legacyIconSize src={Gear} {...props}/>
}

export const LegacyButton = props => {
  return <SimpleButton legacyIconSize {...props}/>
}

export class Navigation extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { length, pos, setPos } = this.props
    //////console.log("Navigation", this.props)
    let leftStyle
    let rightStyle
    const left = () => setPos(pos-1)
    const right = () => setPos(pos+1)
    const hidden = {
      visibility: 'hidden'
    }
    if (pos === 0) {
      leftStyle = hidden
    }
    if (pos === length-1) {
      rightStyle = hidden
    }
    return <div className='swiperNav'>
             <div className='swiperNavButton' style={leftStyle} onClick={left}>
               <ReactSVG src={Left}/>
             </div>
             <Dots
               margin={8}
               length={length}
               position={pos}
               positionChangeListener={setPos}
               clickable={true}
             />
             <div className='swiperNavButton' style={rightStyle} onClick={right}>
               <ReactSVG src={Right}/>
             </div>
           </div>
  }
}


class JudgementComp extends Component {
  constructor (props) {
    super(props)
  }

  componentDidUpdate(prevProps, prevState) {
    if (super.componentDidUpdate) super.componentDidUpdate(prevProps, prevState)
    this.updateSwiper()
  }

  onSelectionChange = e => {
    if (!this.swiper) return
    const selection = document.getSelection();
    if (selection.toString().length > 0) {
      this.swiper.disable()
    } else {
      this.swiper.enable()
    }
  }

  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
    if (isMobile()) {
      document.addEventListener('selectionchange', this.onSelectionChange)
    }
  }

  componentWillUnmount() {
    if (this.props.onCreate) {
      this.props.onCreate(null)
    }
    if (isMobile()) {
      document.removeEventListener('selectionchange', this.onSelectionChange)
    }
  }

  swiper = null
  onSwiper = x => {
    this.swiper = x
  }

  updateSwiper = () => {
    if (this.swiper) {
      try {
        this.swiper.update()
        this.swiper.updateAutoHeight();
      } catch (err) {
        console.warn(err)
      }
    }
  }

  selectJudge = model => {
    //////console.log("selectJudge", model)
    const judgements = this.getJudgements()
    for (let i = 0; i< judgements.length; i++) {
      if (judgements[i].judge === model.id) {
        this.swiper.slideTo(i)
        //////console.log("selectJudge", i, model.name)
        this.forceUpdate()        
        return
      }
    }
    
  }

  onSlideChange = (e) => {
    //////console.log("judge onSlideChange", e)
    this.props.onSlideChange()
  }

  getPos = () => this.swiper ? this.swiper.activeIndex : 0
  setPos = pos => this.swiper && this.swiper.slideTo(pos)


  getJudgements = () => {
    ////console.log("getJudgements", this.props.message.judgement)
    let { judge, ratings, judgements } = this.props.message.judgement
    if (!judgements) {
      judgements = [{
        judge,
        ratings
      }]
    }
    const sortedJudgements = [].concat(judgements)
    sortedJudgements.sort((x, y) => {
      const a = this.props.resolveModel(x.judge)
      const b = this.props.resolveModel(y.judge)
      return a.name.localeCompare(b.name)
    })
    //////////debugger
    return sortedJudgements
  }
  
  render() {
    let judgeViews = []
    const judgements = this.getJudgements()
    ////console.log({judgements})
    const { model } = this.props
    for (const j of judgements) {
      const { judge, ratings } = j
      const judgement = ratings.find(x => x.model === model)
      if (!judgement) {
        console.error("rating not found", judge, model)
        continue
      } else {
        const { rating, review, reward, claims, susClaims } = judgement
        let score
        if (reward) {
          score = reward.score
        }
        const judgeModel = this.props.resolveModel(judge)
        const icon = (judgeModel.getModelIcon && judgeModel.getModelIcon()) || judgeModel.getIcon()
        const judgeView = <div className='modelJudgementInline'>
                            <div className='modelJudgementHeader'>
                              <div className='modelJudgementHeaderLeft'>
                                <SimpleIcon src={icon}/>
                                <div className='modelTitle'>{judgeModel.name}</div>
                              </div>
                              <div className='modelJudgementHeaderRight'>
                                {`Rating: ${rating}/10`}
                              </div>
                            </div>
                            <div className='modelJudgementText'>
                              {review}
                              {susClaims && susClaims.length > 0 && <div className='claims'>
                                                                      Corrections:<br/>
                                                                      {
                                                                        susClaims.map((x, i) => {
                                                                          const { claim, sus } = x
                                                                          return <div className='claim'>
                                                                                 <div className='claimClaim'>{i+1}. {claim}<br/>{sus}</div></div>
                                                                        })
                                                                      }
                                                                    </div>
                              }
                            </div>
                          </div>
        judgeViews.push({judge, judgeView})
      }
    }
    const handleTouchStart = e => {
      if (this.props.onTouchStart) this.props.onTouchStart(e)
    }
    const handleTouchEnd = e => {
      if (this.props.onTouchEnd) this.props.onTouchEnd(e)
    }
    if (judgeViews.length === 0) {
      return null
    }
    if (judgeViews.length === 1) {
      return judgeViews[0].judgeView
    }
    return <div className='judgementSwiper'>
             <Swiper
               preventClicks={false}
               onSwiper={this.onSwiper}
               nested={true}
               modules={[Mousewheel]}
               allowTouchMove={!isDesktop()}
               onSlideChange={this.onSlideChange}
               onTouchStart={handleTouchStart}
               onTouchEnd={handleTouchEnd}
               mousewheel={isDesktop() ? { forceToAxis: true, releaseOnEdges: true  } : undefined}
             >
               {
                 judgeViews.map(v => {
                   return <SwiperSlide key={v.judge}>{v.judgeView}</SwiperSlide>
                 })
               }
             </Swiper>
             <Navigation
             length={judgeViews.length}
             pos={this.getPos()}
             setPos={this.setPos}
             />
           </div>
  }
}


class ModelSelection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showOptions: false
    }
  }
  render() {
    return <div className='modelSelection'>
             {
               this.props.models.map(x => {
                 let { model, selected, active, menu } = x
                 let fractionUsed
                 const isTrain = model.id === 'train'
                 const isReject = model.id === 'reject'
                 const isTrainOrReject = isTrain || isReject
                 if (isTrainOrReject) {
                   active = false
                 } else {
                   const usage = this.props.currentUsage && this.props.currentUsage[model.id] || {
                     inputTokens: 0,
                     outputTokens: 0
                   }
                   const { inputTokens, outputTokens, reasoningTokens } = usage
                   fractionUsed = (inputTokens + outputTokens - (reasoningTokens || 0)) / (model.contexts[0].input * 1000)
                 }
                 const icon = (model.getModelIcon && model.getModelIcon()) || model.getIcon()
                 let action
                 if (this.props.action) {
                   action = async () => {
                     await this.props.action(model)
                     await delay(0.3)
                   }
                 }
                 let className = 'modelSelectionModel'
                 if (selected) {
                   if (active) {
                     className += ' modelSelectionModelActiveSelected'
                   } else {
                     className += ' modelSelectionModelSelected'
                   } 
                 } else {
                   className += ' modelSelectionModelActive'
                 }
                 let trash
                 let label = <ModelLabel model={model}/>
                 let toggleSelection
                 if (!isTrainOrReject) {
                   if (menu) {
                   } else {
                     if (!active) {
                       toggleSelection = () => this.props.toggleSelection(x)
                     } else {
                       trash = () => {
                         this.props.trash(x)
                       }
                     }
                   }
                 }
                 const showModelOptions = () => {
                   this.setState({
                     modelOptions: model
                   })
                 }
                 let config
                 let longPress
                 if (!isTrainOrReject) {
                   let className = 'modelConfigPopup'
                   if (active) {
                     if (selected) {
                       className += ' modelConfigPopupActiveSelected'
                     } else {
                       className += ' modelConfigPopupActive'
                     }
                     longPress = () => this.props.toggleSelection({model})                     
                   } else if (selected) {
                     className += ' modelConfigPopupSelected'
                   }
                   const trigger = <div id={'modelMenuTrigger'}>
                                     <GearButton/>
                                   </div>
                   let className0 = 'modelConfigPopupContainer'
                   if (isMobile()) {
                     className0 += ' modelConfigPopupMobile'
                   }
                   config = <Popup closeOnDocumentClick position='bottom' trigger={trigger}>
                              {close =>
                                <div className={className0}>
                                  <div className={className}>
                                    <ModelConfig onCreate={() => {} } model={model}/>
                                  </div>
                                </div>
                              }
                            </Popup>
                 }
                 const pct = Math.round((fractionUsed || 0) * 100)
                 return <div className={className}>
                          <SimpleButton key={model.id}
                                        icon={icon}
                                        label={label}
                                        longPress={longPress}
                                        action={action}/>
                          {pct >= 1 && <div className='contextSize'>
                                         {pct}%
                                       </div>}
                          <div className='modelSelectionGap'/>
                          {config}
                          <div className='modelSelectionGap'/>
                          {menu}
                          {trash && <DeleteButton icon={Cross} trash={trash}/>}
                          {toggleSelection && <SimpleButton icon={Cross} action={toggleSelection}/>}
                          </div>
               })
             }
             <div className='selectionAddButton'>
               <SimpleButton keepFocus legacyIconSize icon={Plus} action={() => this.props.add()}/>
             </div>
           </div>
  }
}

class Popup1 extends Component {

  
  renderPopup = () => {
    return this.props.popup && ReactDOM.createPortal(this.props.popup, getPortal())
  }
  
  render() {
    return [this.props.children, this.renderPopup()]
  }
  
}




const turndownService = new TurndownService()

class MessageBody extends Component {

  constructor(props) {
    super(props)
    this.state = {
      transition: false
    }
    this.read = {}
  }

  
  onSwiper = swiper => {
    this.swiper = swiper
    if (swiper) {
      let index = 0
      for (const reply of this.getReplies()) {
        if (reply.model === this.props.selectedModelIndex) {
          break
        }
        index++
      }
      //console.log("MESSAGE BODY selectedModelIndex", index)
      if (index > 0) {
        this.blockNotifs = true
        this.swiper.slideTo(index, 0)
        this.swiper.updateAutoHeight()
        this.blockNotifs = false
      }
    }
  }

  selectReply = i => {
    if (this.swiper.activeIndex !== i) {
      this.swiper.slideTo(i)
    } else {
      this.onSlideChange()
    }
  }


  selectModel = (model, speed) => {
    if (!this.swiper) {
      return
    }
    if (typeof model === 'number') {
      return this.swiper.slideTo(model, speed)
    }
    const replies = this.getReplies()
    let index = 0
    for (const reply of replies) {
      if (model.isModel(reply.model)) {
        this.swiper.slideTo(index, speed)
        break
      }
      index++
    }
  }

  updateSwiper = () => {
    const { swiper } = this
    if (swiper) {
      try {
        swiper.updateAutoHeight();
      } catch (err) {
        ////debugger
        console.warn(err)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (super.componentDidUpdate) {
      super.componentDidUpdate(prevProps, prevState)
    }
    this.updateSwiper()
  }

  componentDidMount() {
    //////////console.log("MOUNTED Message Body", this.props.msg.message.id, this.props.key1 )
    this.props.onCreate(this)
    if (isMobile()) {
      document.addEventListener('selectionchange', this.onSelectionChange)
    }
  }

  onSelectionChange = e => {
    const selection = document.getSelection();
    if (selection.toString().length > 0) {
      if (this.swiper) this.swiper.disable()
    } else {
      if (this.swiper) this.swiper.enable()
    }
  }

  componentWillUnmount() {
    if (isMobile()) {
      document.removeEventListener('selectionchange', this.onSelectionChange)
    }
  }

  onSlideChangeTransitionStart = e => {
    //////////console.log("slide transition start")
    if (this.blockNotifs) return
    this.state.transition = true
    this.props.onSlideTransitionStart()
  }

  onSlideChangeTransitionEnd = e => {
    if (this.blockNotifs) return
    this.state.transition = false
    ////////console.log("****slide transition end scroll into view")
    this.props.onSlideTransitionEnd()
  }

  onSlideChange = e => {
    this.state.transition = false
    const activeIndex = this.swiper.activeIndex
    this.setState({
      activeIndex
    })
    if (this.props.onSelectReply) this.props.onSelectReply(activeIndex, this.read[activeIndex])
    this.read[activeIndex] = true
  }

  judges = {}
  setJudge = (model, x) => {
    if (x) {
      this.judges[model] = x
    } else {
      delete this.judges[model]
    }
  }

  selectJudgeIndex = judge => {
    for (const model in this.judges) {
      this.judges[model].selectJudge(judge)
    }
  }

  scrollIntoView = () => {
    //////debugger
    if (this.ref) {
      //this.ref.scrollIntoView({block: 'nearest', behavior: 'smooth' })
    }
  }

  setRef = ref => {
    //////debugger
    this.ref = ref
  }

  onJudgementStart = e => {
    this.swiper.disable()
  }

  onJudgementEnd = e => {
    if (this.swiper) this.swiper.enable()
  }

  onJudgementSlideChange = e => {
    this.forceUpdate()
  }

  getReplies = () => {
    const { message } = this.props
    const self = this
    let replies = []
    if (this.state.replay === 'attunewise' || message.content) {
      replies.push(message)
    }
    replies = replies.concat(message.models ||[])
    const { inReplyTo } = this.props
    if (inReplyTo) {
      const modelIds = inReplyTo.models
      if (modelIds) {
        replies = modelIds.map(id => {
          let reply = replies.find(x => x.model === id)
          if (!reply) {
            reply = {
              model: id,
              content: ''
            }
          }
          return reply
        })
      }
    }
    replies = this.props.participants.map(p => {
      if (p.id === message.model) {
        return message
      }
      if (message.models) {
        const found = getReplies(message).find(x => x.model === p.id)
        if (found) {
          return found
        } else {
          //////////debugger
        }
      }
      const self = this
      return { model: p.id, content: '', usage: { inputTokens: 0, outputTokens: 0 } }
    })
    if (false) replies.sort((x, y) => {
      const a = this.props.resolveModel(x.model)
      const b = this.props.resolveModel(y.model)
      if (!a) return b
      if (!b) return a
      return a.title.localeCompare(b.title)
    })
    return replies
  }

  render() {
    const replies = this.getReplies()
    ////console.log("REPLIES", replies)
    const selection = this.props.selection
    let className = 'keyboardEditDocument'
    const bodies = replies.map((message, i) => {
      let {model, content}  = message
      if (!content) {
        //content = "Model did not respond."
        content = ''
        ////debugger
      }
      let judgement
      if (!model) {
        model = 'train' // hack
      }
      //////console.log('judging', this.props.judging, "judgement", this.props.message.judgement)
      if (this.props.judging && this.props.message.judgement) {
        judgement = <JudgementComp key={this.props.message.id}
                                   onSlideChange={this.onJudgementSlideChange}                                   
                                   onTouchStart={this.onJudgementStart}
                                   onTouchEnd={this.onJudgementEnd}
                                   onCreate={(judge) => this.setJudge(model, judge)}
                                   onSelectModel={this.selectModel}
                                   model={model}
                                   resolveModel={this.props.resolveModel}
                                   message={this.props.message}
                    />
      }
      const body = this.props.renderBody(message, model, content, judgement, {
        forceUpdate: () => {
          this.props.purgeCache(this.props.message)
        },
        setEditing: isEditing => {
          if (this.swiper) {
            if (isEditing) {
              this.swiper.disable()
            } else {
              this.swiper.enable()
            }
          }
        }
      })
      return body
    })
    if (true && bodies.length === 1) {
      return <div className='singleMsgBody'>{bodies[0]}</div>
    }
    const render1Body = (body, i) => {
      const reply = replies[i]
      return <SwiperSlide key={'swiper-'+reply.model}>
               <Autoheight index={i} swiper={this.swiper}>
                 {body}
               </Autoheight>
             </SwiperSlide>
    }
    return <div key={'body-'+this.props.key1} className='messageBodySwiperContainer' ref={this.setRef}>
             <div className='messageBodyDotsContainer'>
               <Navigation
                 length={bodies.length}
                 pos={this.state.activeIndex || 0}
                 setPos={pos => {
                   if (this.swiper.activeIndex !== pos) {
                     this.swiper.slideTo(pos)
                   } else {
                     this.props.selectModel(pos)
                   }
                 }}
               />
             </div>
             <Swiper
               speed={600}
               preventClicks={false}
               nested={true}
               modules={[Mousewheel]}
               allowTouchMove={!isDesktop()}
               mousewheel={isDesktop() ? { forceToAxis: true, thresholdDelta: 6, releaseOnEdges: true  } : undefined}
               onSwiper={this.onSwiper}
               onSlideChange={this.onSlideChange}
               onSlideChangeTransitionStart={this.onSlideChangeTransitionStart}
               onSlideChangeTransitionEnd={this.onSlideChangeTransitionEnd}
               observe={true}
               observeParent={true}
               autoHeight={this.props.autoHeight}>
               {
                 bodies.map((body, i) => render1Body(body, i))
               }
             </Swiper>
           </div>
  }
}

class UserSaidComp extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMessage !== this.props.selectedMessage) {
      //this.checkScrollIntoView()
    }
  }

  checkScrollIntoView = () => {
  }

  componentDidMount() {
    this.id = this.props.message.id
    this.props.onCreate(this, this.id)
    this.checkScrollIntoView()
  }

  componentWillUnmount() {
    this.props.onDelete(this.id)
  }

  scrollIntoView = () => {
    if (this.ref) {
      //console.log("scroll into view")
      //this.ref.scrollIntoView({block: 'start', behavior: 'smooth'})
    } else {
      //debugger
    }
  }

  setRef = ref => {
    //////debugger
    this.ref = ref
  }

  setEditable = ref => {
    if (ref && ref !== this.editable) {
      this.editable = ref
      ref.innerText = this.props.message.content
      setTimeout(() => this.editable.focus(), 100)
      //divSelectAll(this.editable)
      if (isDesktop()) {
        this.editable.addEventListener('keydown', this.handleKeyDown)
      }
    }
    if (!ref) {
      if (isDesktop()) {
        this.editable.removeEventListener('keydown', this.handleKeyDown)
      }
      this.editable = null
    }
  }

  handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.commitEdit()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      this.cancelEdit()
    }
  }

  files = {}

  commitEdit = async () => {
    //////debugger
    if (!this.state.editing) {
      return
    }
    let contents = getEditedContent(this.editable)
    let content = ''
    ////console.log({contents})
    ////console.log({files: this.files})
    for (const element of contents) {
      if (element.type === 'image_url') {
        const { file, uploaded } = this.files[element.image_url.url]
        const downloadURL = await uploaded
        content += `![${file.name}](${downloadURL})`
      } else {
        content += element.text
      }
    }
    this.files = {}
    this.state.editing = false
    this.forceUpdate()
    if (this.props.message.content !== content) {
      await this.props.saveEdit(content)
      this.props.purgeCache(this.props.message)
    }
  }

  cancelEdit = async() => {
    this.state.editing = false
    this.props.purgeCache(this.props.message)
    this.forceUpdate()
  }
  
  render() {
    const { message, components, isJudge } = this.props
    const trash = async () => {
      await this.props.deleteChatMessage(message.id)
      this.props.purgeCache(message)
    }
    const deleteButton = <DeleteButton trash={trash}/>
    let content = message.content
    let contentDiv
    if (!this.state.editing) {
      if (message.storage) {
        const len = message.contentLength
        content += '\n... ' + (len - content.length).toLocaleString() + " more characters ..."
        contentDiv = content
      } else {
        contentDiv = <Markdown key={message.id} components={components}>{content}</Markdown>
      }
    } else {
      const stopEditing = async () => {
        if (!this.state.editing) {
          return
        }
        this.state.editing = false
        if (isMobile()) {
          await this.commitEdit()
        } else {
          this.cancelEdit()
        }
      }
      const cancelEdit = async () => {
        this.cancelEdit()
      }
      const onPaste = async e => {
        if (e.clipboardData.files && e.clipboardData.files.length > 0) {
          debugger
          e.preventDefault()
          for (const file of e.clipboardData.files) {
            const blobUrl = URL.createObjectURL(file)
            const img = document.createElement('img')
            img.src = blobUrl
            this.files[blobUrl] = {
              file,
              uploaded: this.props.uploadFile(file)
            }
            insertImage(img)
          }
        } else {
          pasteText(e)
        }
      }
      contentDiv = <div className='editableContentContainer'>
                     <div ref={this.setEditable} className='editableContent editableContentUser'
                          onPaste={onPaste}
                          contentEditable={true}
                          onBlur={this.commitEdit}>
                     </div>
                   </div>
      contentDiv = <ClickAwayListener mouseEvent={'mousedown'} onClickAway={this.commitEdit}>
                     {contentDiv}
                   </ClickAwayListener>
    }
    let judgeIcon = this.props.judging ? Spin : Right
    let judge
    const hasReplies = message => {
      for (const id in this.received) {
        if (this.received[id].inReplyTo === message.id) return true
      }
    }
    const nop = async () => {}
    if (!isJudge) {
      if (this.state.editing) {
        judge = nop
      } else {
        judge = this.props.judge
      }
    }
    const onSwipe = e => {
      this.setState({
        swipeMessage: message
      })
    }
    let edit 
    let trashMessage
    if (!isJudge) {
      const isTrain = false//(message.models && message.models.find(x => x === 'train'))
      if (!isTrain) {
        edit = async () => {
          this.props.purgeCache(message)
          this.setState({
            editing: true
          })
        }
        trashMessage = async () => {
          await this.props.deleteChatMessage(message.id)
          this.props.purgeCache(message)
        }
      }
    }
    const isTrain = message.model === 'train'
    let toggleJson = this.props.toggleJson
    let cutMessage
    let copyMessage
    if (this.props.cutMessage) {
      cutMessage = () => this.props.cutMessage(message)
    }
    if (this.props.copyMessage) {
      copyMessage = () => this.props.copyMessage(message)
    }
    const copy = () => this.props.copy(message.content)
    const trimUp = () => this.props.cutConversation(message, 'up')
    const trimDown = () => this.props.cutConversation(message, 'down')
    const split  = () => {
      debugger
      return this.props.cutConversation(message, 'split')
    }
    let actions =  []
    if (trimUp) {
      actions.push({
        button: (close) => <DeleteButton icon={Up} label='Cut Above' trash={
                                           async () => {
                                             await trimUp()
                                             close()
                                           }
                                         }/>
      })
    }
    if (trimUp) {
      actions.push({
        icon: Left,
        label: "Split Here",
        action: split
      })
    }
    if (trimDown) {
      actions.push({
        button: (close) => <DeleteButton icon={Down} label='Cut Below' trash={
                                           async () => {
                                             await trimDown()
                                             close()
                                           }
                                         }/>
      })
    }
    actions.push({
      icon: judgeIcon,
      action: judge,
      label: "Judge"
    })
    actions.push({
      icon: EditIcon,
      action: edit,
      label: "Edit"
    })
    if (cutMessage) {
      actions.push({
        icon: Cut,
        action: cutMessage,
        label: "Cut"
      })
    }
    actions.push({
      icon: Copy,
      action: copy,
      label: "Copy"
    })
    actions.push({
      button: (close) => <DeleteButton icon={Cut} label='Cut' trash={
                                         async () => {
                                           await cut()
                                           close()
                                         }
                                       }/>

    })
    if (trashMessage) {
      actions.push({
        button: (close) => <DeleteButton label='Delete' trash={
                                           async () => {
                                             await trashMessage()
                                             close()
                                           }
                                         }/>
      })
    }
    let user = <div
                 data-message-id={message.id}
                 key='usermsg'
                 className='keyboardEditInstruction aiUser'
                 ref={this.setRef}
               >
                 <div className='keyboardEditIconAndInstruction'>
                   <div className='keyboardEditInstructionLeftIcon'>
                     <SimpleIcon src={UserSaid}/>
                   </div>
                   <div className='keyboardEditInstructionText' key={'user-'+message.id}>
                     {contentDiv}
                   </div>
                   <div className='rightColumn1 copyAISaid'>
                     <ActionMenu
                       className='aiUserEditMenu'
                       actions={actions}
                     />
                   </div>
                 </div>
               </div>
    return user
  }
}

class SystemSaidComp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      editing: false
    }
  }

  setEditable = ref => {
    if (ref) {
      if (ref !== this.editable) {
        if (this.editable) {
          this.editable.removeEventListener('keydown', this.handleKeyDown)
        }
        this.editable = ref
        if (isDesktop()) {
          this.editable.addEventListener('keydown', this.handleKeyDown)
        }
      }
      this.editable.innerText = this.props.task.systemPrompt ? this.props.task.systemPrompt.content : ''
      this.editable.focus()
    } else {
      if (isDesktop()) {
        if (this.editable) {
          this.editable.removeEventListener('keydown', this.handleKeyDown)
        }
      }
      this.editable = null
    }
  }

  handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.commitEdit()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      this.cancelEdit()
    }
  }

  commitEdit = async () => {
    const content = this.editable.innerText
    const model = this.state.editing
    this.state.editing = false
    this.forceUpdate()
    await this.props.saveEdit(content)
  }

  cancelEdit = async() => {
    this.state.editing = false
    this.forceUpdate()
  }

  
  render() {
    const { components, task, openSystemPrompt } = this.props
    if (task.systemPrompt) {
      delSysPrompt = this.deleteSystemPrompt
    }
    const systemPrompt = task.systemPrompt || {
      title: 'System',
      content: ''
    }
    const { title, content } = systemPrompt
    const editSystemPrompt = async () => {
      this.setState({
        editing: true
      })
    }
    return  <div className='systemInstructionMessage'>
              <div className='systemInstructionMessageLeft'>
                <div className='systemInstructionMessageLeftTop'>
                  <SimpleIcon src={AISaid}/>
                  <div className='systemInstructionTitle'>{title}</div>
                </div>
                <div className='systemInstructionMessageContent'>
                  {this.state.editing ?
                   <div key='editableSystem' contentEditable={true} ref={this.setEditable}/> :
                   <Markdown key='system' components={components}>{content}</Markdown>}
                </div>
              </div>
              <div className='systemInstructionMessageRight'>
                {delSysPrompt && <SimpleButton key='del' icon={Cross} action={delSysPrompt}/>}
                <SimpleButton key='edit' icon={EditIcon} action={editSystemPrompt}/>
                <SimpleButton key='right' icon={Right} action={openSystemPrompts}/>
              </div>
            </div>
  }
}

class AISaidComp extends Component {
  constructor(props) {
    super(props)
    this.state = {activeIndex: 0}
    this.isToolUseOpen = {}
    this.toolUse = {}
  }

  onSelectReply = (index)  => {
    this.state.activeIndex = index
    if (this.props.onSelectReply) this.props.onSelectReply(index)
    this.forceUpdate()
  }

  componentDidMount() {
    this.id = this.props.message.id
    this.props.onCreate(this, this.id)
    if (this.props.selected) {
      this.scrollIntoView()
    }
  }

  componentWillUnmount() {
    this.props.onDelete(this.id)
  }

  setBody = body => {
    this.messageBody = body
  }

  scrollIntoView = () => {
    ///this.messageBody.scrollIntoView()
  }

  selectModel = (index, animate) => {
    this.messageBody.selectModel(index, animate)
  }

  updateSwiper = () => {
    this.messageBody.updateSwiper()
  }

  selectJudgeIndex = index => {
    this.messageBody.selectJudgeIndex(index)
  }

  setEditable = (ref, init) => {
    if (ref) {
      if (ref !== this.editable) {
        if (this.editable) {
          this.editable.removeEventListener('keydown', this.handleKeyDown)
        }
        if (!this.resizeObserver) {
          this.resizeObserver = new ResizeObserver(entries => {
            clearTimeout(this.resizeTimeout)
            this.resizeTimeout = setTimeout(() => {
              if (this.messageBody && this.messageBody.swiper) this.messageBody.swiper.updateAutoHeight()
            }, 16)
          })
        } else {
          this.resizeObserver.unobserve(this.editable)
        }
        this.editable = ref
        ref.innerText = init || ''
        this.resizeObserver.observe(this.editable)
        if (isDesktop()) {
          this.editable.addEventListener('keydown', this.handleKeyDown)
        }
      }
      this.editable.focus()
    } else {
      if (isDesktop()) {
        if (this.editable) {
          this.editable.removeEventListener('keydown', this.handleKeyDown)
        }
      }
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
      this.editable = null
    }
  }

  handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.commitEdit()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      this.cancelEdit()
    }
  }

  commitEdit = async () => {
    const content = this.editable.innerText
    const model = this.state.editing
    this.state.editing = null
    this.forceUpdate()
    await this.props.saveEdit({model, content})
    this.props.purgeCache(this.props.message)
    this.messageBody.swiper.enable()
  }

  cancelEdit = async() => {
    this.state.editing = false
    this.props.purgeCache(this.props.message)
    this.forceUpdate()
    this.messageBody.swiper.enable()
  }

  modelText = {}

  resetModelText = () => {
    const { message } = this.props
    if (message.stream) {
      message.content = message.stream
      delete message.stream
    }
    if (message.models) message.models.forEach(reply => {
      if (reply.stream) {
        setTimeout(() => {
          reply.content = reply.stream
          delete reply.stream
        }, 400)
      }
    })
    this.forceUpdate()
  }

  comps = {}
  getComponents = (reply) => {
    if (!reply.stream) {
      return getComponents()
    }
    if (!this.comps[reply.model]) {
      this.comps[reply.model] = new FadeComponents({id: this.props.message.id + '-' + reply.model})
    }
    const result = this.comps[reply.model]
    result.reset(reply.stream)
    return result
  }
  
  appendModelText = (model, text) => {
    const reply = this.getReply(model)
    if (!reply.stream) {
      reply.stream = ''
    }
    reply.stream += text
    this.forceUpdate()
  }

  appendToTranscript = ({model, addedToTranscript}) => {
    const reply = this.getReply(model)
    if (!reply.addedToTranscript) {
      reply.addedToTranscript = []
    }
    if (reply.stream) {
      reply.addedToTranscript.push({
        message: {
          role: 'assistant',
          content: reply.stream
        }
      })
      reply.stream = ''
    }
    reply.addedToTranscript = reply.addedToTranscript.concat(addedToTranscript)
    this.forceUpdate()
  }

  getModelText = model => {
  }

  clearModelText = model => {
    const reply = this.getReply(model)
    if (reply) {
      reply.content = ''
      delete reply.corrected
    }
  }

  updateAutoHeight = () => {
    if (this.messageBody.swiper) {
      this.messageBody.swiper.updateAutoHeight()
    }
  }

  setModelText = (model, text) => {
    const elem = this.modelText[model]
    elem.updateMarkdown(text)
    const reply = this.getReply(model)
    reply.content = text
    delete reply.corrected
    this.forceUpdate(this.updateAutoHeight)
  }

  getReply = (model) => {
    if (this.props.message.model === model) return this.message
    let found
    if (!this.props.message.models) {
      this.props.message.models = [found = {
        model,
        content: ''
      }]
    }
    if (!found) {
      found = this.props.message.models.find(x => x.model === model)
      if (!found) {
        this.props.message.models.push(found = {
          model,
          content: ''
        })
      }
    }
    return found
  }

  setModelTextComp = (model, comp, text) => {
    if (this.modelText[model] !== comp) {
      this.modelText[model] = comp
    }
  }

  render() {
    const message = this.props.message
    let replies = []
    if (this.state.replay === 'attunewise'
        ||
        message.model === 'train'
        ||
        message.content) {
      replies.push(message)
    }
    replies = replies.concat(message.models || [])
    let selection = this.state.activeIndex
    const opts = this.props.opts
    const { isLast, isJudge } = opts
    let retry = this.props.retry
    const pause = this.props.pause
    let del
    const visibleReply = replies[selection]
    //////////console.log("visibleReply", visibleReply && visibleReply.uid, this.props.me.self.uid)
    let action3
    let icon3
    let clazz3
    let { role, content, code, serverError, isStreaming, model } = message
    const isDataset = model === 'train'
    const renderBody = (reply, model, text, judgement, parent) => {
      //console.log("renderBody", {message, reply})
      const components = this.getComponents(reply)
      const { addedToTranscript } = reply
      const isTrain = model === 'train'
      const isReject = model === 'reject'
      const isTrainOrReject = isTrain || isReject
      let defaulted = isTrainOrReject || (model === 'train' && !text)
      if (this.state.editing !== model && !text && message.model === 'train') {
        ////console.log("MESSAGE", message)
        ////////debugger
        if (message.corrected) {
          text = message.corrected.disclaimer || message.corrected.content
        } else {
          text = message.content
          defaulted = true
        }
      }
      if (reply.stream) {
        text = reply.stream
        defaulted = false
      }
      if (!text) {
        const found = replies.find(x => x.content)
        if (found) {
          text = found.content
          defaulted = true
        }
      }
      let icon2 = Copy
      let className = 'keyboardEditDocument'
      let icon1 = this.props.getModelIcon(model)
      if (this.props.isStreaming(message.id, model)) {
        icon1 = Spin
      }
      let leftIconClassName = 'chatGPTLeftIcon copyAISaid'
      const modelTitle = <ModelLabel model={this.props.resolveModel(model)}/>
      let copy
      if (this.props.copy) {
        copy = async () => {
          return await this.props.copy(text)
        }
      }
      let edit
      let editIcon = EditIcon
      if (!judgement) {
        edit = async () => {
          let editing = !this.state.editing
          this.state.editing = editing ? model : null
          parent.setEditing(editing)
          this.props.purgeCache(message)
          this.forceUpdate(() => {
            if (this.editable) {
              this.editable.focus()
            }
          })
          await delay(0.3)
        }
      }
      let contentEditable
      let contentDiv
      let undo
      let redo
      let save
      let cancel
      let stopEditing
      let cancelEditing
      let replay
      let stopReplay = this.props.stop
      let playing = this.state.replay === reply.model
      if (!judgement && !isTrain) {
        replay = async () => {
          if (this.state.replay) {
            return
          }
          this.setState({
            replay: reply.model
          })
          try {
            this.clearModelText(reply.model)
            await this.props.replay(reply)
          } catch (err) {
            console.error(err)
          }
          this.setState({
            replay: null
          })
          parent.forceUpdate()
        }
      }
      let deleteContent
      if (!isTrain) {
        if (replay || reply.corrected || reply.content) {
          deleteContent = async () => {
            debugger
            await this.props.saveEdit({ model, content: '', corrected: null })
            this.props.purgeCache(message)
          }
        }
      }
      let corrected = reply.corrected
      let disclaimer
      let judge
      let corrector
      let crossedOut
      if (corrected) {
        if (corrected.corrected && corrected.corrected.disclaimer) {
          corrector = corrected.model
          text += "\n\n" + corrected.content
          corrected = corrected.corrected
          ////////debugger
        }
        disclaimer = corrected.disclaimer
        if (isTrain) {
          crossedOut = text
          text = disclaimer || corrected.content
          if (crossedOut === text) {
            crossedOut = ''
          }
          ////////debugger
          judge = this.props.resolveModel(corrected.judge)
          deleteContent = async () => {
            await this.props.saveEdit({model, content: reply.content, corrected: null})
            this.props.purgeCache(message)
          }
        } else {
          if (disclaimer) {
            crossedOut = text
            text = disclaimer
          }
          judge = this.props.resolveModel(corrected.judge)
        }
      }
      if (this.state.editing !== model) {
        let cert
        if (judge) {
          cert = <div className='certifiedJudgement'>
                   <div className='certifiedJudge'>
                     <SimpleIcon src={AICheck}/>{judge && <ModelLabel model={judge}/>}
                   </div>
                   <div className='certifiedSummary'>{corrected.summary}</div>
                 </div>
        }
        if (corrected) {
          let answerType = disclaimer ? 'answerYellow' : 'answerGreen'
          contentDiv = <div className='hallucinatedAnswerContainer'>
                         {crossedOut && <div className='answerRed'>
                                          <Markdown components={components}>{crossedOut}</Markdown>
                                        </div>}
                         <div className={answerType}>
                           <Markdown components={components}>{text}</Markdown>
                           {cert}                           
                         </div>
                       </div>
        } else {
          //contentDiv = <MarkdownNew components={components} markdown={text || ''}/>
          if (addedToTranscript) {
            let toolUse = []
            const findToolCall = id => {
              for (const {message} of addedToTranscript) {
                if (!message) continue
                const { tool_calls } = message
                if (tool_calls) {
                  const tool_call = tool_calls.find(x => x.id === id)
                  if (tool_call) {
                    return tool_call
                  }
                }
              }
              return 
            }
            const findToolResult = id => {
              for (const {message} of addedToTranscript) {
                if (!message) continue
                const { tool_call_id } = message
                if (tool_call_id === id) {
                  return message
                }
              }
              return null
            }
            const contents = []
            const flushToolUse = () => {
              if (toolUse.length > 0) {
                const ids = toolUse[0].id
                const isOpen = this.isToolUseOpen[ids]
                const toggleToolUse = async () => {
                  this.isToolUseOpen[ids] = !this.isToolUseOpen[ids]
                  this.forceUpdate()
                }
                let s = toolUse.length > 1 ? 's' : ''
                debugger
                let title = toolUse.length === 1 ? ('Use tool ' + toolUse[0].name + '.') : ("Use " + toolUse.length + " tools.")
                let div = toolUse.map(x => x.content)
                if (toolUse.length > 1) {
                  if (!this.toolUse[ids]) {
                    this.toolUse[ids] = {pos: 0}
                  }
                  const setPos = pos => {
                    this.toolUse[ids].pos = pos
                    this.toolUse[ids].swiper.slideTo(pos)
                    this.forceUpdate()
                  }
                  const getPos = () => {
                    const swiper = this.toolUse[ids].swiper
                    return (swiper && swiper.activeIndex) || this.toolUse[ids].pos
                  }
                  const onSwiper = swiper => {
                    this.toolUse[ids].swiper = swiper
                    if (swiper) {
                      swiper.updateAutoHeight()
                    }
                  }
                  const onSlideChange = e => {
                    const swiper = this.toolUse[ids].swiper
                    this.toolUse[ids].pos = swiper.activeIndex
                    if (swiper) swiper.updateAutoHeight()
                    this.forceUpdate()
                  }
                  div = <div className='toolUsesContent'>
                          <Swiper
                            autoHeight={true}
                            onSwiper={onSwiper}
                            preventClicks={false}
                            nested={true}
                            modules={[Mousewheel]}
                            allowTouchMove={!isDesktop()}
                            mousewheel={isDesktop() ? { forceToAxis: true, releaseOnEdges: true  } : undefined}
                          >
                            {
                              toolUse.map(x => {
                                return <SwiperSlide>{x.content}</SwiperSlide>
                              })
                            }
                          </Swiper>
                          <Navigation
                            length={toolUse.length}
                            pos={getPos()}
                            setPos={setPos}
                          />
                        </div>
                }
                const toolUseDiv = <div key='toolUseDiv' className='toolUseDiv'>
                                     <div className='toolUseHeader'>
                                       <div className='toolUseHeaderTitle'>{title}</div>
                                       <div className='toolUseDisclosure'>
                                         <SimpleButton icon={isOpen ? Up : Down} action={toggleToolUse}/>
                                       </div>
                                     </div>
                                     {isOpen && <div key='toolUseContent' className='toolUseContent'>
                                                  {div}
                                                </div>}
                                   </div>
                
                contents.push(toolUseDiv)
                toolUse = []
              }
            }
            let lastContent
            for (const { message, usage } of addedToTranscript) {
              if (!message) continue              
              let { tool_calls, tool_call_id, content } = message
              let tool_call
              if (tool_calls) {
                const result = findToolResult(tool_calls[0].id)
                if (!result) {
                  debugger
                  tool_call = tool_calls[0]
                }
              }
              if (content && !tool_call_id && !tool_call) {
                if (lastContent === content) { // fix me!!!
                  //debugger
                  continue
                }
                lastContent = content
                flushToolUse()
                contents.push(<Markdown components={components}>{content}</Markdown>)
              } else {
                lastContent = ''
              }
              let callMarkdown
              let resultMarkdown
              if (tool_call_id) {
                tool_call = findToolCall(tool_call_id)
                if (!tool_call) {
                  debugger
                }
              }
              if (tool_call) {
                debugger
                const args = JSON.parse(tool_call.function.arguments || "{}")
                if (name === 'repl') {
                  callMarkdown = "```\n"+ code.replaceAll('\n\n', '\n')+"\n```"
                } else {
                  callMarkdown = `${name} (${jsonToMarkdown(args)})`
                }
              }
              if (tool_call_id && tool_call) {
                let parsed
                try {
                  parsed = JSON.parse(content)
                  content = jsonToMarkdown(parsed)
                } catch (ignored) {
                }
                const { name } = tool_call.function
                if (name == 'repl' && parsed) {
                  const { result, output, errors } = parsed
                  resultMarkdown = ''
                  let sep = ''
                  if (result) {
                    resultMarkdown += "## Result:\n"+"```\n" + result+"\n```"
                    sep = '\n\n'
                  }
                  if (output) {
                    resultMarkdown += sep
                    resultMarkdown += "## Output log:\n"+"```\n" + output+"\n```"
                    sep = '\n\n'
                  }
                  if (errors && errors.length > 0) {
                    resultMarkdown += sep
                    resultMarkdown += "## Error log:\n"+"```\n" + errors.join('\n')+"\n```"
                  }
                } else {
                  resultMarkdown = content ? content.trim() : ''
                }
              }
              if (tool_call) {
                debugger
                let toolCallDiv = <div className='toolCall'>
                                    <div className='toolCallCall'>
                                      <Markdown components={components}>{callMarkdown}</Markdown>
                                    </div>
                                    {resultMarkdown && <div className='toolCallResult'>
                                                         <Markdown components={components}>{resultMarkdown}</Markdown>
                                                       </div>}
                                    
                                  </div>
                toolUse.push({id: tool_call.id, name:tool_call.function.name, content: toolCallDiv})
              }
            }
            flushToolUse()
            contentDiv = contents
          } else {
            if (text) {
              contentDiv = <Markdown key1={reply.stream ? 'stream': undefined} key={message.id} components={components}>{text}</Markdown>
            }
          }
        }
      } else {
        undo = async () => {
        }
        redo = async () => {
        }
        save = async () => {
        }
        stopEditing = () => {
          if (isMobile()) {
            this.commitEdit()
          } else {
            cancelEditing()
          }
          parent.setEditing(false)
        }
        cancelEditing = () => {
          parent.setEditing(false)
          this.setState({
            editing: false
          })
        }
        if (disclaimer) {
          let disclaimerText = disclaimer
          const disclaimerDiv = <div className='editableContentContainer'>
                         <div key='editable' ref={ref => this.setEditable(ref, disclaimerText)} className='editableContent'
                              onPaste={pasteText}
                              contentEditable={true}
                              onBlur={stopEditing}/>
                       </div>
          contentDiv = <div key='disclaimed' className='hallucinatedAnswerContainer'>
                         <div className='answerRed'>
                           <Markdown components={components}>{text}</Markdown>
                         </div>
                         <div className='answerYellow'>
                           {disclaimerDiv}
                         </div>
                         
                       </div>
        } else {
          contentDiv =
            <ClickAwayListener mouseEvent={'mousedown'} onClickAway={cancelEditing}>
              <div key='editable' className='editableContentContainer'>
                <div ref={ref => this.setEditable(ref, text)} className='editableContent'
                     onPaste={pasteText}
                     contentEditable={true}
                     onBlur={stopEditing}>
                  {text}
                  </div>
              </div>
            </ClickAwayListener>
        }
      }
      let correct
      if (typeof reply.content !== 'string') {
        reply.content = ''
        debugger
      }
      if (!isJudge && !isTrain && reply.content.trim()) {
        correct = () => this.props.correct(reply)
      }
      const apply = async () => {
        await this.props.apply({reply, model})
        this.messageBody.swiper.slideTo(0)
      }
      let middleColumn = 'middleColumn1'
      if (defaulted) {
        middleColumn += ' defaultAnswer'
      }
      const actions = []
      if (deleteContent) {
        actions.push({
          icon: Cross,
          label: "Clear",
          action: deleteContent
        })
      }
      actions.push({
        icon: Copy,
        label: "Copy",
        action: copy
      })
      if (edit) {
        actions.push({
          icon: EditIcon,
          label: "Edit",
          action: edit
        })
      }
      if (replay) {
        actions.push({
          icon: Send,
          label: "Replay",
          action: replay
        })
      }
      if (isDataset && !defaulted && correct) {
        actions.push({
          icon: AICheck,
          action: correct,
          label: "Fact Check"
        })
      }
      if (isDataset && !defaulted && !isTrain && apply) {
        actions.push({
          icon: Hashtag,
          label: "Train",
          action: apply
        })
      }
      if (!contentDiv) {
        contentDiv =  <div className='emptyAssistantBody'/>
        ////////debugger
      }
      //////console.log({contentDiv})
      return <div className='horizontalTextLayout'>
               <div className='leftColumn1'>
                 <div className='keyboardEditInstructionLeftIcon'>
                   <SimpleIcon src={icon1}/>
                 </div>
               </div>
               <div className={middleColumn}>
                 <div className='chatGptChatMessageHeaderTopic2'>{modelTitle}</div>
                 {judgement}
                 {contentDiv}
              </div>
               <div className='rightColumn1 copyAISaid'>
                 <ActionMenu className='aiSaidMenu' actions={actions}/>
               </div>
             </div>
    }
    
    const renderBody1 = () => {
      let autoHeight = replies.length > 1 && replies[selection] && replies[selection].id !== this.streamingId
      return <MessageBody
               selected={this.props.selected}
               selectedModelIndex={this.props.selectedModelIndex}
               participants={this.props.participants}
               onCreate={this.setBody}
               selectModel={this.props.selectModel}
               onSlideTransitionStart={this.props.onSlideTransitionStart}
               onSlideTransitionEnd={this.props.onSlideTransitionEnd}
               getModelIcon={this.props.getModelIcon}
               resolveModel={this.props.resolveModel}
               getModelTitle={this.props.getModelTitle}
               selection={selection}
               onSelectReply={this.onSelectReply}
               message={message}
               judging={this.props.judging}
               inReplyTo={this.props.inReplyTo}
               autoHeight={true}
               purgeCache={this.props.purgeCache}
               apply={({reply, model}) => this.props.apply({message, reply, model})}
               renderBody={renderBody}/>

    }
    let leftIconClassName = 'chatGPTLeftIcon copyAISaid'
    let className = 'horizontalMessageLayout chatMessageFromGpt'
    return <div key={'reply-'+message.id}
                data-message-id={message.id}
                className={className}>
             {renderBody1()}
           </div>
  }
  
}

export class FileChooser extends Component {

  setFileChooser = ref => {
    this.fileChooser = ref
  }

  componentDidMount() {
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  reset = () => {
    this.fileChooser.value = ''
  }

  chooseFile = () => this.fileChooser.click()

  render() {
    let { button, icon, label, handleDataTransfer, fileTypes, action } = this.props
    const noAction = (async () => {})
    if (!icon) {
      icon = OpenFile
      button = <LegacyButton icon={icon} label={label} action={noAction}/>
    }
    if (!button) {
      button = <SimpleButton icon={icon} label={label} action={noAction}/>
    }
    const open = async (event) => {
      this.fileChooser.click()
    }
    const onChange = async (event) => {
      if (action) action()
      handleDataTransfer(event, event.target)
      this.fileChooser.value = ''
    }
    const fileChooser = 
      <div className='fileChooser' onClick={open}>
        <input
          ref={this.setFileChooser}
          type={"file"}
          accept={fileTypes}
          style={{ display: 'none' }} // Hides the file input
          onChange={onChange}
        />                      
        {button}
      </div>
    return fileChooser
  }
}


export class SimpleIcon extends Component {
  constructor (props) {
    super(props)
  }

  afterInjection = svg => {
    this.svg = svg
  }
  setRef = ref => {
    this.ref = ref
  }
  render() {
    const { src, size, legacyIconSize } = this.props
    let style
    let className = 'simpleIcon'
    if (false && this.svg && this.ref) {
      const [x, y, w, h] = this.svg.attributes.viewBox.value.split(" ").map(x => parseFloat(x))
      const box = this.ref.getBoundingClientRect()
      if (w === box.width) {
        this.svg.style.width = box.width + 'px'
        this.svg.style.height = box.height + 'px'
      }
    }
    if (size) {
      const { w, h } = size
      style = {
        height: h,
        width: w,
        minWidth: w
      }
      className += ' simpleIconWithSize'
    }
    if (legacyIconSize) {
      className += ' legacyIconSize'
    }
    return <div ref={this.setRef} className={className} style={style}><ReactSVG afterInjection={this.afterInjection} src={src}/></div>
  }
}

export class SimpleButton extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  setRef = ref => {
    this.ref = ref
  }

  componentWillUnmount() {
    if (this.timeout1) {
      ////console.log("unmount autorepeat")
      clearTimeout(this.timeout1)
    }
  }

  action = async () => {
    if (this.state.busy) return
    if (!this.props.action) return
    this.state.busy = true
    this.forceUpdate()
    const start = Date.now()
    await this.props.action()
    const end = Date.now()
    const elapsed = (end - start) / 1000
    if (elapsed > 0.1) {
      const dur = MIN_ANIM_DUR - elapsed
      if (dur > 0) {
        await delay(dur)
      }
    }
    this.state.busy = false
    this.forceUpdate()
  }
  
  render() {
    const props = this.props
    let { icon, label } = props
    let action
    let className = 'simpleButton'
    if (label) {
      if (typeof(label) !== 'string') {
        className += ' simpleButtonLabelComponent'
      } else {
        className += ' simpleButtonWithLabel'
      }
    } else {
      className += ' simpleButtonNoLabel'
    }
    if (icon) {
      className += ' simpleButtonWithIcon'
    }
    if (props.action) {
      action = async () => {
        this.state.busy = true
        setTimeout(() => this.forceUpdate())
        //this.ref.scrollIntoView()
        await props.action()
        this.state.busy = false
        this.forceUpdate()
      }
    } else{
      className += ' simpleButtonInactive'
    }
    if (this.props.busy || this.state.busy) icon = Spin
    let longPressOps
    let onClick
    if (this.props.longPress) {
      longPressOps = this
      className += ' simpleButtonLongPressable'
    } else if (this.props.autoRepeat) {
      className += ' simpleButtonAutorepeat'
      const onHold = () => {
        ////console.log("onHold", this.down)
        clearTimeout(this.timeout1)
        props.action()
        this.timeout1 = setTimeout(onHold, 100)
      }
      const hold = () => {
        onHold()
      }
      const release = () => {
        this.down = false
        clearTimeout(this.timeout1)
        this.timeout = null
      }
      longPressOps = {
        handleMouseDown: e => {
          e.preventDefault()
          e.stopPropagation()
          hold()
        },
        handleMouseUp: e => {
          e.preventDefault()
          e.stopPropagation()          
          release()
        },
        handleMouseLeave: e => {
          release()
        },
        handleTouchStart: e => {
          e.preventDefault()
          e.stopPropagation()          
          hold()
        },
        handleTouchEnd: e => {
          e.preventDefault()
          e.stopPropagation()
          release()
        }
      }
    } else {
      longPressOps = {}
      if (this.props.keepFocus) {
        onClick = (event) => {
          event.preventDefault()
          this.action()
        }
      } else {
        onClick = this.action
      }
    }
    const legacyIconSize = !this.state.busy && this.props.legacyIconSize
    return <div ref={this.setRef}
                className={className}
                onClick={onClick}
                onMouseDown={longPressOps.handleMouseDown}
                onMouseUp={longPressOps.handleMouseUp}
                onMouseLeave={longPressOps.handleMouseLeave}
                onTouchStart={longPressOps.handleTouchStart}
                onTouchEnd={longPressOps.handleTouchEnd}
           >
             {icon && <SimpleIcon size={this.props.iconSize} key='icon' src={icon} legacyIconSize={legacyIconSize}/>}
             {label &&<div key='label' className='simpleButtonLabel'>
                        {label}
                      </div>}
           </div>

  }

  startPressTimer = () => {
    const { pressDuration = 300 } = this.props
    this.state.pressed = true
    this.setState({ isLongPress: false })
    this.timerRef = setTimeout(() => {
      this.setState({ isLongPress: true })
      if (navigator.vibrate) {
        navigator.vibrate(200);
      } else {
      }
      ////console.log("long press called")
      this.props.longPress()
    }, pressDuration);
  }

  cancelPressTimer = () => {
    this.state.pressed = false
    clearTimeout(this.timerRef)
    if (this.props.longPress && this.state.isLongPress) {
      function clearSelection() {
        if (window.getSelection) {
          // For most modern browsers
          window.getSelection().removeAllRanges()
        } else if (document.selection) {
          // For older IE browsers
          document.selection.empty()
        }
      }
      setTimeout(clearSelection, 60)
    } else {
      this.action()
    }
  };

  handleMouseDown = (e) => {
    this.startPressTimer();
    e.preventDefault()
  };

  handleMouseUp = (e) => {
    e.preventDefault()
    this.cancelPressTimer();
  }

  handleTouchStart = (e) => {
    e.preventDefault()
    this.startPressTimer();
  };

  handleTouchEnd = (e) => {
    e.preventDefault()
    this.cancelPressTimer();
  };

  
}

export const Checkbox = props => {
  const { icon, selected, toggle, label, legacyIconSize } = props
  let className = 'simpleCheckbox'
  if (icon) {
    className += ' simpleCheckboxWithIcon'
  }
  if (selected) {
    className += ' simpleCheckboxSelected'
  }
  if (props.className) {
    className += ' ' + props.className
  }
  if (legacyIconSize) {
    className += ' legacyIconSize'
  }
  return <div className={className} onClick={toggle}>
           {icon && <div className='simpleCheckboxIcon'><ReactSVG src={icon}/></div>}
           {label}
           </div>
}


class MarkdownBody extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.updateBody()
  }

  updateBody = () => {
    if (true) return
    const ref = this.ref
    // hack for markdown rendering multiple text elements in sequence without line breaks
    if (ref) {
      const element = ref
      const isNested = n => {
        let result = false
        walkDOM(n, x => {
          if (n !== x && x.nodeName === 'LI') {
            result = true
          }
        })
        return result
      }
      if (true) {
        const lis = element.querySelectorAll('li')
        for (const li of lis) {
          const nested = isNested(li)
          ////////console.log("li height", li.offsetHeight, 'nested', nested, li.textContent)
          if (li.offsetHeight > 20 && !nested) {
            if (li.firstChild.nodeName !== 'DIV' && li.firstChild.nodeType !== Node.TEXT_NODE || !li.firstChild.textContent.trim()) {
              const wrapper = document.createElement('div');
              wrapper.className = 'liContainer ' + 'liContainer' + li.parentNode.nodeName
              // Move all children of the li into the new div
              while (li.firstChild) {
                wrapper.appendChild(li.firstChild);
              }
              // Append the new div to the li
              li.appendChild(wrapper);
            }
          }
        }
      }
      element.childNodes.forEach((node, i) => {
        if (i > 0 && node.previousSibling &&
            node.previousSibling.nodeType === Node.TEXT_NODE &&
            node.nodeType === Node.TEXT_NODE) {
            if (node.textContent === '\n') {
              const br1 = document.createElement('br')
              const br2 = document.createElement('br')
              const inserted = node.parentNode.insertBefore(br1, node)
              inserted.parentNode.insertBefore(br2, inserted)
              node.textContent = ""
            }
        }
      })
    }
  }

  setBodyRef = ref => {
    this.ref = ref
  }

  render() {
    return <div className='keyboardEditDocumentTextInline' ref={this.setBodyRef}>
             {this.props.children}
           </div>
  }
}



function escapeUnbalancedDollars(text) {
  let dollarsIndices = [];
  for (let i = 0; i < text.length; i++) {
    if (text[i] === '$') dollarsIndices.push(i);
  }

  // Convert the string to an array to allow modifications
  let escapedTextArray = [...text];
  let skipNext = false;
  
  for (let i = 0; i < dollarsIndices.length - 1; i++) {
    if (skipNext) {
      skipNext = false;
      continue;
    }

    let currentIdx = dollarsIndices[i];
    let nextIdx = dollarsIndices[i + 1];

    // Simple rule: if there's any character other than space between the `$` pair, consider it math
    if (nextIdx - currentIdx > 1 && !text.substring(currentIdx + 1, nextIdx).trim().length === 0) {
      // This pair is considered a valid math expression, so skip the next `$`
      skipNext = true;
    } else {
      // Escape the current `$` since it doesn't form a valid math expression
      escapedTextArray[currentIdx] = '\\$';
    }
  }

  // If the last `$` wasn't skipped (and exists), it's unpaired and should be escaped
  if (!skipNext && dollarsIndices.length > 0) {
    escapedTextArray[dollarsIndices[dollarsIndices.length - 1]] = '\\$';
  }

  return escapedTextArray.join('');
}




const replaceImgWithMarkdown = (htmlString) => {
  if (htmlString.indexOf('<img') >= 0) {
    ////////////////debugger
    const regex = /<img .*\/?>/g
    return htmlString.replace(regex, (match) => {
      //////////console.log("match", match)
      return turndownService.turndown(match)
    });
  }
  return htmlString
}


export function fromNow(date) {
  const fromNow = moment(date).fromNow().replace(/ ago/, '')
  const fixed = fromNow
        .replace(/.*a few seconds.*/, 'now')
        .replace(/.*a minute.*/, '1m')
        .replace(/.*an hour.*/, '1h')
        .replace(/.*a day.*/, '1d')
        .replace(/.*a month.*/, '1mo')
        .replace(/.*a year.*/, '1y')
        .replace(/ minutes?/, 'm')
        .replace(/ hours?/, 'h')
        .replace(/ days?/, 'd')
        .replace(/ months?/, 'mo')
        .replace(/ years?/, 'y')
  ////////////console.log('fromNow', fromNow, '=>', fixed)
  return fixed
}

export function toThen(date) {
  const fromNow = moment(date).fromNow().replace(/ ago/, '')
  const fixed = fromNow
        .replace(/.*a few seconds.*/, 'now')
        .replace(/.*a minute.*/, '1m')
        .replace(/.*an hour.*/, '1h')
        .replace(/.*a day.*/, '1d')
        .replace(/.*a month.*/, '1mo')
        .replace(/.*a year.*/, '1y')
        .replace(/ minutes?/, 'm')
        .replace(/ hours?/, 'h')
        .replace(/ days?/, 'd')
        .replace(/ months?/, 'mo')
        .replace(/ years?/, 'y')
  if (fixed.startsWith("in ")) {
    return fixed
  }
  return 'in ' + fixed
}

export function toNow(date) {
  return moment(date).toNow()
}


//

const JSON_parse = input => {
  try {
    return JSON.parse(jsonrepair(input))
  } catch (err) {
    return eval(input)
  }
}

const nbsp = new RegExp(String.fromCharCode(160), "gi");

const USE_GPT4 = new URLSearchParams(window.location.search).get('gpt-4')


export class RadioButtonsOld extends Component {
  render() {
    const isSmall = this.props.small
    const buttons = this.props.buttons.map(button => {
      const { selected, label } = button
      if (selected) {
        return <div className='keyboardRadioButtonSelected' onClick={button.select}>
                 <div className='keyboardRadioButtonIcon'><ReactSVG src={button.icon}/></div>
                 {label && <div className='keyboardRadioButtonOn'>{button.label}</div>}
               </div>
        
      } else {
        return <div className='keyboardRadioButtonUnselected' onClick={button.select}>
                 <div className='keyboardRadioButtonIcon'><ReactSVG src={button.icon}/></div>
                 {label && <div className='keyboardRadioButtonOff'>{button.label}</div>}
               </div>
      }
    });
    return <div className={'keyboardRadioButton' +  (isSmall ? ' keyboardRadioButtonSmall' : '')}>
             {this.props.label &&<div className='keyboardRadioButtonLabel keyboardRadioButton'>
               {this.props.label}
                                 </div>}
             {buttons}
             <div className='keyboardRadioButtonRight'/>
           </div>

  }
}

export class RadioButtons extends Component {
  render() {
    const isSmall = this.props.small
    const buttons = this.props.buttons.map(button => {
      const { selected, label, icon, select } = button
      return <Checkbox icon={icon} toggle={select} label={label} selected={selected}/>
    });
    return <div className={'keyboardRadioButton' +  (isSmall ? ' keyboardRadioButtonSmall' : '')}>
             {this.props.label &&<div className='keyboardRadioButtonLabel keyboardRadioButton'>
               {this.props.label}
                                 </div>}
             {buttons}
             <div className='keyboardRadioButtonRight'/>
           </div>

  }
}


class CheckboxPopup extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  doRenderMenu = () => {
    const closeMenu = () => {
      this.state.menuActive = false
      his.forceUpdate()
    }
    return <ClickAwayListener onClickAway={closeMenu}>    
             <RadioButtons buttons={this.props.buttons}/>
           </ClickAwayListener>
  }

  render() {
    const onClick = async () => {
      setTimeout(() =>  {
        this.setState({
          menuActive: !this.state.menuActive
        })
      }, 50)
    }
    return <div className='chatGptFunctions'>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuDown : MenuUp} action={onClick}/>
             </div>
           </div>
  }
  
}


const encodeURIExt = uri => {
  if (!uri.endsWith("%3f") && !uri.endsWith("%3F")) {
    uri = encodeURI(uri)
  }
  return uri
}

const QUESTIONS = ['Does Australia really have pink lakes?',
                   'When and on what topic  was the NYT\'s first report on "artificial intelligence"?',
                   'Can spiders fly?']
const links = [`Does Australia really have [pink lakes?](ai://?q=${encodeURIComponent(QUESTIONS[0])})`,
               `When and on what topic was the NYT's [first report on "artificial intellgence"](ai://?q=${encodeURIComponent(QUESTIONS[1])})?`,
               `Can [spiders fly?](ai://?q=${encodeURIComponent(QUESTIONS[2])})`]

const generateBlurb = (pre) => {
  pre = pre || `Hello, ask me any questions you'd like. `
  return `${pre}Here are some examples to get you started:\n
${links.map((q, i) => `\n- ${q}`)}\n`.replace(/,\n/g, '\n')
}

const CodeBlock = ({className, node, children}) => {
  let lang
  const text = (typeof children == 'string' && children)  || ''
  const multiline = text.indexOf('\n') > 0
  ////////console.log("CodeBlock", children)
  let clazzName = 'aiCode'
  if (!multiline) {
    clazzName = 'aiCode aiCodeInline'
  }
  const copy = async () => {
    try {
      navigator.clipboard.writeText(text)
    } catch (ignored) {
      //////console.log(text)
    }
    await delay(0.5);
  }
  if (className && className.startsWith('lang-')) {
    lang = className.replace('lang-', '');
    return (
      <SyntaxHighlighter language={lang} style={CodeStyle} showLineNumbers={true}>
        {children}
      </SyntaxHighlighter>
    )
  } else {
    if (children && children.indexOf('%') >= 0) {
      try {
        children = decodeURIComponent(children)
      } catch (err) {
        console.error(err)
      }
    }
    return <div className={clazzName}>
             {children}
             {multiline && <div className='codeCopy'><SimpleButton icon={Copy} action={copy}/></div>}
           </div>
                             
  }
}

// markdown-to-jsx uses <pre><code/></pre> for code blocks.
const PreBlock = ({node, children, ...rest}) => {
  if ('type' in children && children ['type'] === 'code') {
    return CodeBlock(children['props']);
  }
  return <div className='aiPre' {...rest}>{children}</div>;
};

const consoleLog = (...args) => {
}

const debugLog = (...args) => {
  args.unshift("debug")
  ////console.log.apply(null, args)
}


class CheckMarkComp extends Component {
  render( ){
    return <div className='aiCheck'><ReactSVG src={AICheck}/></div>
  }
}

const decodeURIComponentExt = c => {
  try{
    let decoded = decodeURIComponent(c)
    return decoded.replace(/[+]/g, ' ')
  } catch (exc) {
    ////////////////////debugger
    return c
  }
}

   
export class Thread extends Component {
  setRef = ref => {
    this.ref = ref
  }
  scrollIntoView = () => { if (this.ref) this.ref.scrollIntoView({block: 'nearest'}) }
  componentDidUpdate(prevProps) {
    if (this.props.selected && !prevProps.selected) {
      this.scrollIntoView()
    }
  }
  render() {
    const thread = this.props.thread
    const onClick = (e) => {
      this.props.selectThread()
    }
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    let trash = this.props.trash
    let date
    if (thread.lastUpdated) {
      date = fromNow(thread.lastUpdated)
    }
    let blurb = ''
    let deleteButton
    let deleteIcon = thread.busy ? Spin : Trash
    let dateComp
    let content
    let markdown = this.props.markdown || 'Empty Discussion'
    if (thread.lastUpdated && thread.messages > 0) {
      if (thread.title) {
        markdown = `# ${thread.title || ''}\n${thread.description}`
      } else {
        markdown = thread.description
      }
    }
    const components = null
    let { usage, credits } = thread
    let dollars
    let total = (usage && usage.total) || credits
    if (total) {
      dollars = '$'+formatPrice(total)
    }
    content = <Markdown components={components}>{markdown}</Markdown>
    let actions =  [
    ]
    actions.push({
      icon: Cut,
      action: this.props.cut,
      label: "Cut"
    })
    actions.push({
      icon: Copy,
      action: this.props.copy,
      label: "Copy"
    })
    if (trash) {
      actions.push({
        button: (close) => <DeleteButton key={'deleteTask'} label='Delete' trash={
                                           async () => {
                                             //////debugger
                                             await trash()
                                             close()
                                           }
                                         }/>
      })
    }
    if (date) {
      dateComp = <div className='taskDate'>{date}</div>
    }
    if (this.props.renderTask) {
      return this.props.renderTask({
        task: this.props.thread, onClick, content, dollars, dateComp, actions
      })
    }
    let className1 = 'taskTitle'
    if (this.props.selected) {
      className1 += ' taskTitleSelected'
    }
    let open = this.props.open || onClick
    return <div ref={this.setRef} key={thread.id} className={className1} data-task-id={thread.id}>
             <div  className='taskTitleLeft'>
	       <div className='keyboardMenuItemIcon'><ReactSVG src={Hashtag}/></div>
             </div>
	     <div className='keyboardMenuItemLabel taskDescriptionLabel' onClick={onClick}>{content}</div>
             <div className='keyboardMenuItemRight'>
               <div className='modelPrice'>{dollars}</div>
               {dateComp}
               <div className='keyboardMenuItemActions'>
                 <ActionMenu actions={actions}/>
                 <div className='rightButton' style={(this.props.selected || isMobile()) ? null : {visibility: 'hidden'}}>
                   <SimpleButton icon={Right} action={open}/>
                 </div>
               </div>
             </div>
           </div>
}
}

class MessagesViewMessage extends Component {

  setRef = ref => {
    this.ref = ref
  }

  scrollIntoView = () => { if (this.ref) this.ref.scrollIntoView({block: 'nearest'}) }

  componentDidUpdate(prevProps) {
    if (this.props.isSelected && !prevProps.isSelected) {
      //////debugger
      this.scrollIntoView()
    }
  }

  componentDidMount() {
    if (this.props.isSelected) {
      //////debugger
      this.scrollIntoView()
    }
  }
  
  render() {
    const { message } = this.props
    const { user, assistant } = message
    let dateComp
    let dollars
    let onClick = (e) => {
      this.props.select()
    }
    let actions
    let icon = UserSaid
    let legacyIconSize
    const shorten = prompt => {
      const comps = prompt.split(/[.]|\n/).filter(x=>x.trim())
      return comps.length > 1 ? comps[0] + "..." : prompt
    }
    let assistantContent
    let assistantIcon
    /*
    const reply = getReplies(assistant).find(x => x.content)
    assistantContent = ''//shorten(reply.content)
    const model = this.props.resolveModel(reply.model)
    if (model) {
      assistantIcon = (model.getModelIcon && model.getModelIcon()) || model.getIcon()
      }
      */
    let className1 = 'toolsetTask toolsetMessageTask'
    if (this.props.isSelected) {
      //////debugger
      className1 += ' toolsetMessageTaskSelected'
    }
    return <div
             key={user.id}
             ref={this.setRef}
             data-message-id={user.id}
             className={className1} onClick={onClick} >
             <div  className='toolsetLeft'>
               <div className='toolsetLeftTopRow'>
                 <div className='toolsetLeftTopRowLeft'>
	           <SimpleIcon src={icon} legacyIconSize={legacyIconSize}/>
                   <div className='toolsetMiddleDescription'>
                     {shorten(user.content)}
                   </div>
                 </div>
                 <div className='toolsetTopRowRight'>{dateComp}</div>
               </div>
               {false && <div className='toolsetMiddle'>
                 <SimpleIcon src={assistantIcon}/>
                 <div className='toolsetMiddleDescription'>
                   {assistantContent}
                 </div>
                         </div>}
             </div>
             <div className='toolsetRight'>
               {actions && <ActionMenu className='toolsetActions' actions={actions}/>}
               {false && <SimpleButton icon={Right} action={onClick}/>}
             </div>
           </div>
  }
}

class MessagesView extends BnSubpage {

  constructor (props) {
    super(props)
  }

  onSearch = searchTerm => {
    searchTerm = searchTerm.trim()
    let searchResults
    if (searchTerm) {
      searchResults = this.searchIndex.search(searchTerm).map(result => {
        const { user, assistant } = result
        return { user, assistant} 
      })
    } else {
      searchResults = null
    }
    this.setState({
      searchTerm,
      searchResults
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedMessage !== prevProps.selectedMessage) {
      if (this.props.selectedMessage && this.messagesScroller) {
        this.messagesScroller.scrollToItem(this.props.selectedMessage)
      }
    }
  }

  componentDidMount() {
    const messages = this.getMessages()
    this.searchIndex = new InMemorySearch({
      id: 'id',
      fields: ['userContent', 'assistantContent'],
      documents: messages.map(({user, assistant}) => {
        return {
          user, assistant,
          id: user.id,
          userContent: user.content,
          assistantContent: getReplies(assistant).map(reply => {
            return reply.content
          }).join('\n')
        }
      })
    })
    if (!this.props.selectedMessage) {
      const messages = this.getMessages()
      if (messages.length > 0) {
        const last = messages[messages.length-1]
        this.props.select(last)
      }
    }
  }

  getMessages = () => {
    const messages = []
    let prev
    for (const message of this.props.messages) {
      switch (message.role) {
        case 'system':
          messages.push({
            system: message
          })
          break
        case 'assistant':
          {
            prev.assistant = message
          }
          break
        case 'user':
          messages.push(prev = {
            user: message
          })
          break
      }
    }
    return messages
  }

  setMessagesScroller = scroller => {
    this.messagesScroller = scroller
  }
  
  
  renderContent() {
    const messages = this.state.searchTerm ? this.state.searchResults: this.getMessages()
    let onKeyDown
    let autoFocus
    let tabIndex
    if (isDesktop()) {
      onKeyDown = scrollOnKeyDown({
        enter: () => {
        },
        getIndex: () => {
          //////debugger
          let selected
          if (this.props.selectedMessage) {
            selected = messages.find(x => x.user.id === this.props.selectedMessage.id)
          }
          if (selected) {
            return messages.indexOf(selected)
          }
          return -1
        },
        getLength: () => messages.length,
        setIndex: index => {
          //////debugger
          const newSelection = messages[index]
          if (newSelection && newSelection !== this.props.selectedMessage) {
            this.props.select(newSelection)
          }
        }
      })
      tabIndex = 0
      autoFocus = true
    }
    const renderMessages = messages => messages.map((message, i) => {
      const select = () => this.props.select(message)
      return <MessagesViewMessage
               key={message.user.id}
               message={message}
               select={select}
               isSelected={(this.props.selectedMessage &&
                            message.user &&
                            message.user.id &&
                            this.props.selectedMessage.id === message.user.id)}
               resolveModel={this.props.resolveModel}
             />
    })

    return <div
             className='chatGptMessagesViewContainer'
           >
             <SearchField onSearch={this.onSearch} placeholder="Search Messages"/>
             <div className='chatGptMessagesView'>
               <InfiniteScroll 
                 onCreate={this.setMessagesScroller}
                 onKeyDown={onKeyDown}
                 autoFocus={autoFocus}
                 tabIndex={tabIndex}
                 getId={item => item.id}
                 getSelector={id => `[data-message-id="${id}"]`}
                 items={messages}
                 renderItems={renderMessages}
                 pageSize={20}
               />
               </div>           
           </div>
  }
}

class Threads extends BnSubpage {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.onCreate(this)
  }

  onScroll = ({baseOffset, offset, scrollTop}) => {
    this.props.onScroll({
      view: this.props.view,
      baseOffset,
      offset,
      scrollTop
    })
  }
  
  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (prevProps.selectedTask !== this.props.selectedTask) {
      if (this.infiniteScroll) {
        this.infiniteScroll.scrollToItem(this.props.selectedTask)
      }
    }
  }

  isThreadOpen = () => this.state.subpage

  openThread = () => {
    this.props.chatGPT.cacheEnabled = true
    const closeThread = () => {
      this.props.chatGPT.cacheEnabled = false
      this.back()
    }
    this.openSubpage(() => {
      return <MessagesView
               selectedMessage={this.props.selectedMessage}
               select={x => this.props.selectMessage(x.user)}
               resolveModel={this.props.resolveModel}
               messages={this.props.getMessages()}
               me={this.props.me}
               back={this.back}
               title={'Messages'}/>
    })
  }

  openSubpage = subpage => {
    this.setState({
      subpage
    })
  }

    
  renderContent() {
    return <div className='threadsPage bnSubpageTopLevel'>
             {this.props.renderHeader()}
             {this.renderTasks()}
          </div>
  }

  setInfiniteScroll = ref => {
    this.infiniteScroll = ref
  }
  
  renderTasks = () => {
    const closeMenu = () => {
      //this.state.menuActive = false
      //this.forceUpdate()
    }
    let menuStyle
    let active = this.state.menuActive || this.props.selectedThread
    active = !this.props.selectedThread
    menuStyle = null
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    const x = 0
    const threads = this.props.threads
    let className1 = 'chatGptThreadsMenuInfiniteScroll'
    if (this.props.searchTerm) {
      className1 += ' chatGptThreadsMenu-search'
    } else {
      className1 += ' chatGptThreadsMenu-'+this.props.view
    }
    let tabIndex
    const renderThreads = threads => {
      return threads.map((thread, i) => {
        let prev = threads[i-1]
        let next = threads[i+1]
        const selectThread = () => {
          this.props.selectThread(thread)
        }
        const deleteTask = async () => {
          await this.props.deleteTask(thread)
        }
        let cancelDelete
        if (this.state.confirmDelete === thread.id) {
          cancelDelete = async () => {
            this.state.confirmDelete = null
            this.forceUpdate()
          }
        }
        let trash
        if (this.props.deleteTask) {
          trash = async () => {
            await this.props.deleteTask(thread)
            if (!isMobile()) {
              let newSelection = next || prev
              if(newSelection) {
                this.props.selectThread(newSelection)
              }
            }
          }
        }
        let copy
        let cut
        let paste
        if (this.props.cut) {
          cut = () => this.props.cut(thread)
        }
        if (this.props.copy) {
          copy = () => this.props.copy(thread)
        }
        if (this.props.paste) {
          paste = () => this.props.paste(thread)
        }
        let open
        if (isDesktop()) {
          open = () => {
            //////debugger
            if (this.props.chatGPT.state.selectedTask &&
                this.props.chatGPT.state.selectedTask.id == thread.id) {
              this.openThread(this.props.chatGPT)
            }
          }
        }
        return <Thread key={thread.id} thread={thread} selectThread={selectThread}
                       resolveModel={this.props.resolveModel}
                       open={open}
                       selected={
                         this.props.selectedThread && this.props.selectedThread.id == thread.id
                       }
                       cut={cut} copy={copy} paste={paste}
                       renderTask={this.props.renderTask}
                       trash={trash} cancelDelete={cancelDelete}/>
      })
    }
    const selectItem = item => {
      this.props.selectThread(item)
    }
    const selectedItem = this.state.selectedTask
    const onKeyDown = scrollOnKeyDown({
      getIndex: () => {
        if (this.props.selectedTask) {
          const selected = threads.find(x => x.id === this.props.selectedTask.id)
          if (selected) {
            return threads.indexOf(selected)
          }
          return -1
        }
      },
      getLength: () => threads.length,
      onHitStart: async () => {
        const { count, update } = await this.props.getBefore(11)
        if (count > 0) {
          update(() => {
            this.props.selectThread(this.props.threads[count-1])
          })
        }
      },
      onHitEnd: async () => {
        const offset = threads.length
        const { count, update } = await this.props.getAfter(11)
        if (count > 0) {
          update(() => {
            console.log('selecting thread', offset+1)
            this.props.selectThread(this.props.threads[offset+1])
          })
        }
      },
      setIndex: index =>  {
        const newSelection = threads[index]
        if (newSelection && newSelection.id !== this.props.selectedTask.id) {
          this.props.selectThread(newSelection)
        }
      }
    })
    return <div className={className1}>
             <InfiniteScroll
               onScroll={this.props.onScroll ? this.onScroll : undefined}
               onKeyDown={onKeyDown}
               autoFocus={isDesktop()}
               onCreate={this.setInfiniteScroll}
               key='threads'
               count={threads.length}
               items={threads}
               renderItems={renderThreads}
               getSelector={(id) => `[data-task-id="${id}"]`}
               getId={item => item.id}
               onLoadMore={
                 async (direction, pageSize) => {
                   if (direction === 'after') {
                     return await this.props.getAfter(pageSize)
                   } else {
                     return await this.props.getBefore(pageSize)
                   }
                 }
               }
             />
           </div>
  }
  
  
}


export class ChatGPT extends BnSubpage {

  constructor(props) {
    super(props)
    let assistantModel = localStorage.getItem("assistantModel")
    let tempStr = localStorage.getItem('temp')
    let temp = 1.0
    if (tempStr) {
      temp = parseFloat(tempStr)
      if (isNaN(temp)) {
        temp = 1.0
      }
    }
    let selectedModel  = assistantModel
    this.state = {
      recentLimit: 15,
      uploadError: '',
      sendError: '',
      active: true,
      uploads: [],
      slide: 0,
      edits: new Document(),
      completions: [],
      lang: {
        name: "English",
        dialect: "United States",
        iso: "en-US",
        hasVoice: true
      },
      role: 'user',
      threadBusy: false,
      swipeIndex: 0,
      assistantModel,
      selectedThreads: [],
      selectedModelIds: [],
      rawMessages: [],
      uiElements: { modelInfo: { price: 0, judgePrice: 0 }, participants: [] },
      searchResults: [],
      calendarView: this.props.calendarViewSelection ? this.props.calendarViewSelection[0] : 'recent',
      selectedDay: new Date(),
      topP: 1,
      topK: 50,
      temp: 1,
      taskOpacity: 0
    }
    this.getComponents = () => getComponents({openLink: url => this.props.me.openWindow(url)})
  }


  streaming = {}
  isStreaming = (id, model) => {
    return this.streaming.id === id &&
      this.streaming.model === model
  }

  
  hallucinationQuestions = {}

  hasTasks() {
    for (const id in this.tasks) {
      return true
    }
    return false
  }

  resolveModel = id => {
    if (id === 'train') {
      return TRAIN
    }
    if (id === 'reject') {
      return REJECT
    }
    this.getModels()
    return this.modelsById[id]
  }

  onDayChange = day => {
    //////console.log("day change", day)    
    this.setState({
      selectedDay: day
    }, () => this.updateTaskObserver())
  }


  initTasks = () => {
    //////////////debugger
    if (this.tasksSub) {
      return
    }
    this.tasks = {}
    ////////debugger
    this.updateTaskObserver()
  }

  updateTask = task => {
    this.tasks[task.id] = task
    if (this.state.selectedTask && this.state.selectedTask.id === task.id) {
      this.state.selectedTask = task
    }
    this.forceUpdate()
  }

  updateTaskObserver = () => {
    let observeTasks
    let current = startOfWeek(this.state.selectedDay).getTime()
    //////console.log("observeTasks", this.state.calendarView)
    if (this.state.calendarView === 'recent' ||
        this.state.calendarView === 'all' ) {
      this.currentWeek = undefined
      if (this.currentView === 'recent' || this.currentView === 'all') {
        if (this.tasksSub) {
          ////////console.log("not resubscribing", this.currentView, '==', 'recent')
          this.forceUpdateLater()
          return
        }
        //////console.log("subscribing", 'recent')
      }
      observeTasks = this.props.observeRecentTasks
    } else {
      if (this.currentWeek === current) {
        if (this.tasksSub) {
          ////////console.log("not resubscribing", 'currentWeek', this.currentWeek, '==', current)
          return
        }
      }
      ////////console.log("subscribing", current)
      this.currentWeek = current
      ////////console.log('set current week', this.currentWeek)
      observeTasks = () => this.props.observeTasks(current)
    }
    this.currentView = this.state.calendarView
    if (this.tasksSub) {
      ////////console.log("unsubscribing tasksSub")
      this.tasksSub.unsubscribe()
    }
    ////////console.log("subscribing", observeTasks)
    ////////debugger
    this.tasksSub = observeTasks().subscribe(change => {
      const { task } = change
      ////console.log("task", task)
      if (change.type === 'removed') {
        if (this.state.selectedTask &&
            this.state.selectedTask.id === task.id) {
          this.selectThread(null)
        }
        delete this.tasks[task.id]
      } else {
        this.tasks[task.id] = task
      }
      this.forceUpdateLater()
    })

  }

  getTools = () => {
    return Object.values(this.tools)
  }

  tools = {}

  currentTaskHasMessages = () => {
    const taskId = this.state.selectedTask.id
    for (const id in this.received) {
      if (this.received[id].task === taskId) {
        return true
      }
    }
    return false
  }

  deinitSelectedTask = () => {
    //////////////debugger
    this.state.taskOpacity = 0
    this.firstTime = false
    this.state.selectedMessage = null
    this.state.selectedModelIndex = ''
    this.state.recentLimit = 15
    this.state.selectedTask = null
    this.state.showSettings = false
    this.state.sendError = null
    this.invalid = {}
    this.received = {}
    this.cache = {}
    this.judgeCache = {}
    this.state.rawMessages = []
    this.state.uiElements = { modelInfo: { price: 0, judgePrice: 0 }, participants: [] }
    if (this.systemPromptSub) {
      this.systemPromptSub.unsubscribe()
      this.systemPromptSub = null
    }
    if (this.messagesSub) {
      this.messagesSub.unsubscribe()
      this.messageSub = null
    }
    if (this.threadsSub) {
      this.threadsSub.unsubscribe()
      this.threadsSub = null
    }
    this.received = {}
    this.chatThreads = {}
    this.forceUpdate()
  }


  observeSystemPrompt = () => {
    if (this.systemPromptSub) {
      this.systemPromptSub.unsubscribe()
      this.systemPromptSub = null
    }
    this.systemPrompts = {}
    if (this.state.selectedTask && this.state.selectedTask.systemPrompt) {
      this.systemPromptSub = this.props.me.observeSystemPrompt(this.state.selectedTask.systemPrompt.id).subscribe(change => {
        const { type, systemPrompt } = change
        debugger
        if (type !== 'removed') {
          this.systemPrompts[type] = systemPrompt
        } else {
          delete this.systemPrompts[type]
        }
        this.updateLater('systemPrompts', this.updateSystemPrompts)
      })
    }
  }

  updateSystemPrompts = () => {
    const prompts = Object.values(this.systemPrompts)
    let systemPrompt = ''
    let sep = ''
    for (const prompt of prompts) {
      if (prompt.content) {
        systemPrompt += sep
        systemPrompt += prompt.content
      }
    }
    this.setState({
      systemPrompt
    })
  }

  initSelectedTask = () => {
    if (this.state.selectedTask) {
      this.taskInit = Date.now()
      this.firstTime = true
      if (this.state.selectedTask.autoplay) {
        if (this.editor) {
          this.editor.setText(this.state.selectedTask.autoplay, false)
        }
      }
      this.messagesSub = this.props.observeTaskMessages({ task: this.state.selectedTask } ).subscribe(change => {
        consoleLog(change)
        const { message } = change
        if (change.type !== 'removed') {
          this.received[message.id] = message
          this.parseMessage(message)
        } else {
          delete this.received[message.id]
        }
        this.invalidateCache(message)
        this.updateMessagesLater()
      })
      this.observeSystemPrompt()
      this.updateUIElementsLater()
    }
  }

  checkSelectedModelLater = () => {
    clearTimeout(this.selectedModelTimeout)
    this.selectedModelTimeout = setTimeout(this.checkSelectedModel, 200)
  }

  hasMessages = () => {
    for (const id in this.received) {
      return true
    }
  }

  updateMessagesLater = () => {
    this.updateUIElementsLater()
  }

  checkSelectedModel = () => {
    this.forceUpdate(() => {
      if (this.hasMessages() && this.firstTime) {
        this.firstTime = !this.updateSelectedChatModel(this.getMessages())
      }
    })
  }

  init = () => {
    this.initTimeout = null
    this.deinit()
    this.initTasks()
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  deinit = () => {
    this.deinitSelectedTask()
    if (this.threadsSub) this.threadsSub.unsubscribe()
    if (this.messagesSub) this.messagesSub.unsubscribe()
    if (this.taskSub) this.taskSub.unsubscribe()
    this.purgeCache()
    this.received = {}
    this.chatThreads = {}
  }

  chatThreads = {}
  received = {}
  tasks = {}

  restartDelayApologyTimer() {
    clearTimeout(this.delayApologyTimeout)
    this.delayApologyTimeout = setTimeout(() => {
      this.forceUpdate()
    }, 15000)
  }

  componentDidUpdate(prevProps) {
    if (this.props.resource !== prevProps.resource) {
      this.init()
    }
  }

  componentDidMount() {
    ////console.log("CHATGPT", this.props)
    this.startTime = Date.now()
    if (this.props.task) {
      this.init()
      this.state.selectedTask = this.props.task
      this.initSelectedTask()
    }  else{ 
      this.initTimemout = setTimeout(this.init, 400)
      setTimeout(() => {
        this.setState({
          canShowBlurb: true
        })
      }, 200)
    }
    if (isMobile()) {
      document.addEventListener('selectionchange', this.onSelectionChange)
    }
  }

  onSelectionChange = e => {
    const selection = document.getSelection();
    if (selection.toString().length > 0) {
      if (this.swiper) {
        this.swiper.disable()
      }
      if (this.judgeSwiper) {
        this.judgeSwiper.disable()
      }
    } else {
      if (this.swiper) {
        try {
          this.swiper.enable()
        } catch (err) {
          console.error(err)
        }
      }
      if (this.judgeSwiper && this.judgeSwiper.params) {
        this.judgeSwiper.enable()
      }
    }
  }

  componentWillUnmount() {
    this.deinit()
    if (isMobile()) {
      document.removeEventListener('selectionchange', this.onSelectionChange)
    }
    if (this.threadsSub) this.threadsSub.unsubscribe()
    if (this.messagesSub) this.messagesSub.unsubscribe()
    clearInterval(this.interval)
    if (this.optionsSub) this.optionsSub.unsubscribe()
    if (this.props.onDelete) {
      this.props.onDelete(this)
    }
  }

  getActiveModels = (rawMessages) => {
    const active = {}
    for (let j = rawMessages.length-1; j >= 0; j--) {
      const message = rawMessages[j]
      let any = false
      if (message.content) {
        active[message.model] = true
        any = true
      }
      if (message.models) {
        for (const m of message.models) {
          if (m.content) {
            active[m.model] = true
            any == true
          }
        }
      }
      if (any) {
        break
      }
    }
    return Object.keys(active).map(id => this.resolveModel(id))
  }

  getMessages = () => {
    let messages
    messages = Object.values(this.received)
    const reply = {}
    for (const id in this.received) {
      const msg = this.received[id]
      if (msg.inReplyTo) {
        reply[msg.inReplyTo] = msg
      }
    }
    const getTs = msg => {
      if (true || !msg.inReplyTo) {
        return msg.ts
      }
      const inReplyTo = this.received[msg.inReplyTo]
      return inReplyTo ? inReplyTo.ts : msg.ts
    }
    messages.sort((x, y) => {
      const t1 = getTs(x)
      const t2 = getTs(y)
      const cmp = t1 - t2
      if (cmp === 0) {
        return x.from === this.props.me.self.uid ? -1 : 1
      }
      return cmp
    })
    if (this.props.messageFilter) {
      messages = messages.filter(message => this.props.messageFilter(message, this))
    }
    return messages
  }

  getThreadMessages = () => {
    if (!this.state.selectedThread) {
      return null
    }
    const messages = this.state.searchResults
    if (!messages) {
      return null
    }
    messages.sort((x, y) => {
      return x.ts - y.ts
    })
    return messages
  }

  getInReplyTo = message => {
    const { inReplyTo } = message
    return this.received[inReplyTo]
  }

  scrollPos = {}

  onScrollThreads = ({
    view,
    baseOffset,
    offset,
    scrollTop
  }) => {
    this.scrollPos[view] = {
      baseOffset,
      offset,
      scrollTop
    }
  }

  setMessages1 = ref => {
    //////////debugger
    this.messages1 = ref
  }

  setMessages2 = ref => {
    this.messages2 = ref
  }

  gotoTop = async () => {
    debugger
    this.invalidateCache()
    await this.messages1.scrollToTopItem()
  }

  pageUp = () => {
    this.invalidateCache()
    this.forceUpdate()
  }

  pageDown = () => {
    this.invalidateCache()
    this.forceUpdate()
  }

  gotoBottom = async () => {
    this.invalidateCache()
    debugger
    await this.messages1.scrollToBottomItem()
  }

  ask = async (topic, q, autoSend) => {
    if (this.received['pending']) {
      return
    }
    if (this.animating) {
      return
    }
    if (topic) {
      let currentTopic = this.getCurrentTopic()
      if (topic != currentTopic) {
        autoSend = false
        const thread = this.chatThreads[topic]
        this.selectThread(thread)
      }
    } else {
      autoSend = false
    }
    const result = this.editor.setText(q)
    if (isDesktop()) {
      this.setState({
        tooltip: ''
      })
    }
    if (autoSend) {
      this.sendChat()
    } 
  }

  renderAISaid = (inReplyTo, message, onClick, opts) => {
    return this.renderAISaidHoriz(inReplyTo, message, null, opts)
  }

  onSlideTransitionStart = message => {
  }
  
  onSlideTransitionEnd = message => {
  }

  userSaidComps = {}
  onCreateUserSaid = (comp, id) => {
    this.userSaidComps[id] = comp
  }

  onDeleteUserSaid = id => {
    delete this.userSaidComps[id]
  }


  aiSaidComps = {}
  
  onCreateAISaid = (comp, id) => {
    this.aiSaidComps[id] = comp
  }

  onDeleteAISaid = id => {
    delete this.aiSaidComps[id]
  }
  

  selectModelIndex = (index, speed) => {
    this.state.selectedModelIndex = index.id
    for (const id in this.aiSaidComps) {
      const x = this.aiSaidComps[id]
      x.selectModel(index, speed)
    }
    this.forceUpdate()
  }

  selectJudgeIndex = index => {
    for (const id in this.aiSaidComps) {
      const x = this.aiSaidComps[id]
      x.selectJudgeIndex(index)
    }
    this.forceUpdate()
  }

  renderAISaidHoriz = (inReplyTo, message, onClick, opts) => {
    const { isLast } = opts
    const pause = async () => {
      return await this.pauseChat()
      this.forceUpdate()
    }
    let del
    let copy = this.copyToClipboard
    opts = opts || {}
    const getModelIcon = model => {
      const m = this.modelsById[model]
      if (!m) {
        ////////debugger
        return null
      }
      return m.getModelIcon && m.getModelIcon() || m.getIcon()
    }
    const getModelTitle = model => {
      const m = this.resolveModel(model)
      if (m.isFinetune) {
        return <ModelLabel model={m}/>
      }
    }
    const { participants } = opts
    return <AISaidComp
             enableChat={this.enableChat}
             isStreaming={this.isStreaming}
             isLast={isLast} 
             selectedModelIndex={this.state.selectedModelIndex}
             participants={participants}
             saveEdit={({model, content}) => this.saveReplyEdit({message, model, content})}
             inReplyTo={inReplyTo}
             onCreate={this.onCreateAISaid}
             onDelete={this.onDeleteAISaid}
             selectModel={this.selectModelIndex}
             onSlideTransitionStart={()=>this.onSlideTransitionStart(message)}
             onSlideTransitionEnd={()=>this.onSlideTransitionEnd(message)}
             getModelIcon={getModelIcon}
             resolveModel={this.resolveModel}
             getModelTitle={getModelTitle}
             opts={opts}
             key={message.id}
             getComponents={this.getComponents}
             selected={this.state.selectedMessage && message.id === this.state.selectedMessage.id}
             message={message}
             received={this.received}
             opts={opts}
             saveMessageContent={this.saveMessageContent}
             me={this.props.me}
             pause={pause}
             del={del}
             copy={copy}
             judging={opts.isJudge}
             stop={this.stopChat}
             replay={(reply) => this.replay(message, reply)}
             correct={(reply) => this.correct(message, reply)}
             purgeCache={this.purgeCache}
             apply={this.props.applyReply && (({reply, body}) => this.applyReply({message, reply, body}))}
             getModelLabel={message => <ModelLabel model={this.resolveModel(message.model)}/>}
           />
  }

  enableChat = enabled => {
    this.state.chatDisabled = !enabled
    this.forceUpdate()
  }

  saveUserEdit = async (message, {content, json}) => {
    let changed = (content !== undefined && content !==  message.content) ||
        (json !== undefined && json !== message.json)
    if (!changed) {
      return
    }
    const task = this.state.selectedTask
    try {
      this.enableChat(false)
      if (this.props.saveUserEdit) {
        await this.props.saveUserEdit(this, {task, message, content, json})
      } else {
        await this.props.me.saveUserEdit({task, message, content, json})
      }
    } finally {
      this.enableChat(true)
    }
  }

  saveReplyEdit = async ({message, model, content}) => {
    const task = this.state.selectedTask
    try {
      this.enableChat(false)
      if (this.props.saveReplyEdit) {
        await this.props.saveReplyEdit(this, {task, model, message, content})
      } else {
        await this.props.me.saveReplyEdit({task, model, message, content})
      }
    } finally {
      this.enableChat(true)
    }
  }

  applyReply = async ({message, reply, model}) => {
    ////////debugger
    if (this.props.applyReply) {
      await this.props.applyReply(this, {message, reply, model})
    }
  }

  stopChat = async () => {
    const xhr = this.xhr
    this.xhr = null
    if (xhr) {
      xhr.abort()
      consoleLog("aborted xhr")
    } else {
    }
  }

  replay = async (message, reply) => {
    const model = this.resolveModel(reply.model)
    if (!model.prefill) {
      reply.content = ''
    }
    this.forceUpdate()
    const req = this.received[message.inReplyTo]
    debugger
    await new Promise((resolve, reject) => {
      return this.sendChat(req, {
        replay: reply.model,
        onFirstToken: resolve
      })
    })
  }

  correct = async (message, reply) => {
    if (reply.corrected) {
      reply.corrected = null
    } else {
      const req = this.received[message.inReplyTo]
      const judges = this.judgeView.getSelectedModelIds()
      const task = this.state.selectedTask
      if (this.props.correct) {
        await this.props.correct(this, {task, message: req, reply: message, model: reply.model, judges})
      }
      this.purgeCache(req)
    }
    this.purgeCache(message)
  }

  getJudgeConversation = () => {
    let judgeConversation = []
    const rawMessages = this.getMessages()
    for (const message of rawMessages) {
      judgeConversation.push(message)
      if (this.state.swipeMessage) {
        if (message.inReplyTo === this.state.swipeMessage.id) {
          break
        }
      }
    }
    return judgeConversation;
  }

  judge = async req => {
    this.setState({
      judging: true
    })
    //const conversation = this.getJudgeConversation()
    const userMsg = this.editor.getText()
    this.editor.clear()
    let reply = this.resolveReply(req)
    const judges = this.judgeView.getSelectedModelIds()
    let judgeFunc
    if (this.props.judge) {
      judgeFunc = (judges, req, reply, userMsg) => this.props.judge(this, judges, req, reply, userMsg)
    } else {
      judgeFunc = this.props.me.judge
    }
    await judgeFunc(judges, req, reply, userMsg)
    delete this.cache[req.id]
    delete this.cache[reply.id]
    this.state.judging = false
    this.forceUpdateLater()
  }

  cache = {}
  judgeCache = {}
  invalid = {}

  invalidateCache = msg => {
    if (!msg) {
      this.cache = {}
      this.judgeCache = {}
      return
    }
    const invalidate = id => {
      this.invalid[id] = true
      delete this.cache[id]
      delete this.judgeCache[id]
    }
    invalidate(msg.id)
    if (msg.inReplyTo) {
      invalidate(msg.inReplyTo)
    }
  }

  resolveReply = message => {
    for (const id in this.received) {
      const x = this.received[id]
      if (x.inReplyTo === message.id) {
        return x
      }
    }
  }

  resolveTopic = message => {
    const task = this.tasks[message.task]
    return null
  }

  renderSystemMessage = message => {
    let delSysPrompt
    const task = this.state.selectedTask
    if (task.systemPrompt) {
      delSysPrompt = this.deleteSystemPrompt
    }
    const systemPrompt = {
      title: task.systemPrompt ? task.systemPrompt.title : 'System',
      content: this.state.systemPrompt || ''
    }
    const { title, content } = systemPrompt
    const components = this.getComponents()
    const openSystemPrompts = async () => {
      this.openSystemPrompt()
    }
    const editSystemPrompt = async () => {
      if (task.systemPrompt) {
        
      }
    }
    const sysMessage = <div key='sysMessage'
                            data-message-id='system'
                            className='systemInstructionMessage'>
                           <div className='systemInstructionMessageLeft'>
                             <div className='systemInstructionMessageLeftTop'>
                               <SimpleIcon src={AISaid}/>
                               <div className='systemInstructionTitle'>{title}</div>
                             </div>
                             <div className='systemInstructionMessageContent'>
                               <Markdown components={components}>{content}</Markdown>
                             </div>
                           </div>
                         <div className='systemInstructionMessageRight'>
                           {delSysPrompt && <SimpleButton key='del' icon={Cross} action={delSysPrompt}/>}
                           <SimpleButton key='edit' icon={EditIcon} action={editSystemPrompt}/>
                           <SimpleButton key='right' icon={Right} action={openSystemPrompts}/>
                         </div>
                       </div>
    return sysMessage
  }

  renderN = 0
  cacheRenderN = 0
  renderChatMessage = (message, prev, next, mergedBody, opts) => {
    if (message.role === 'system') {
      return this.renderSystemMessage(message)
    }
    const { isJudge } = opts
    let cache = isJudge ? this.judgeCache : this.cache
    const cached = cache[message.id]
    this.renderN++
    if (false && cached) {
      this.cacheRenderN++
      if (cached.isMounted) {
        return cached
      }
    }
    //////console.log("renderChatMessage not cached", message.content)
    let className = 'chatGptChatMessageHeader'
    const msgBody = this.renderChatMessageBody(message, prev, next, mergedBody, opts)
    const result = <div key={message.id} className='chatGptChatMessageBody'>{msgBody}</div>
    delete this.invalid[message.id]
    cache[message.id] = result
    return result
  }

  isRecent = message => {
    const now = Date.now()
    return now - message.ts < 60000;
  }

  isToolCallComplete = (tool_call_id) => {
    const reply = Object.values(this.received).find(x => x.tool_call_id === tool_call_id)
    return reply
  }

  wasAnswered = (message) => {
    const reply = Object.values(this.received).find(x => x.inReplyTo === message.id)
    if (reply && reply.id == message.id + ".reply" && reply.text) {
      if (message.stream) {
        message.stream = null
        delete this.cache[message.id]
      }
    }
    //////////console.log("was answered", message.id, reply)
    return reply
  }

  onClickMessage = message => {
    const f = this.props.onClickMessage
    if (f) {
      f(message, this)
    }
  }

  purgeCache = message => {
    this.invalidateCache(message)
    this.forceUpdate(this.updateUIElementsLater)
  }


  isFromMe = message => message.role === 'user'

  toggleJson = message => {
    this.saveUserEdit(message, { json: !message.json })
  }

  cutConversation = async (message, direction) => {
    const messages = await this.props.cutConversation(this, this.state.selectedTask, message, direction)
    debugger
    this.received = {}
    messages.forEach(message => {
      this.received[message.id] = message
    })
    this.invalidateCache()
    debugger
    this.updateUIElements()
  }

  
  renderChatMessageBody = (message, prev, next, mergedBody, opts) => {
    const { isJudge, participants } = opts
    const components = this.getComponents(message)
    //////console.log("renderChatMessageBody", message, opts)
    if (message.role === 'user') {
      if (true) {
        const uploadFile = async file => {
          const ref = await this.props.uploadFile(file)
          return await ref.getDownloadURL()
        }
        return <UserSaidComp
                 selected={this.state.selectedMessage && message.id === this.state.selectedMessage.id}
                 selectedMessage={this.state.selectedMessage}
                 onCreate={this.onCreateUserSaid}
                 onDelete={this.onDeleteUserSaid}
                 uploadFile={uploadFile}
                 cutConversation={this.cutConversation}
                 deleteChatMessage={this.deleteChatMessage}
                 message={message}
                 toggleJson={() => this.toggleJson(message)}
                 components={components}
                 me={this.props.me}
                 isJudge={isJudge}
                 judging={this.props.judging}
                 purgeCache={(x) => {
                   this.purgeCache(x)
                   this.forceUpdate()
                 }}
                 copy={this.copyToClipboard}
                 saveEdit={(content)=>this.saveUserEdit(message, {content})}
                 judge={async () => {
                   this.state.swipeMessage = message
                   this.judgeSwiper.slideTo(1)
                   this.forceUpdate()
                 }}/>
                        
      }
      const trash = async () => {
        ////////debugger
        if (this.state.confirmDelete !== message.id) {
          this.purgeCache(message)
          this.setState({confirmDelete: message.id})
          return
        }
        try {
          message.busy = true
          this.forceUpdate()
          await this.props.me.deleteChatMessage(message.id)
          this.purgeCache(message)
        } finally {
          message.busy = false
          this.setState({confirmDelete: null})
        }
      }
      const now = Date.now()
      if (message.reaction && !message.reaction.split) {
        ////////////////////debugger
      }
      let action1
      let icon1
      let clazz1 = ''
      if (message.id !== 'pending') {
        action1 = trash
        icon1 = Trash
        clazz1 = 'userSaidDel'
      }
      let deleteButton
      let deleteIcon = message.busy ? Spin : Trash
      if (!isJudge) {
        deleteButton = trash && <DeleteButton trash={trash}/>
      }

      let markdown = replaceImgWithMarkdown(message.content|| '')
      //////////console.log("markdown", markdown)
      let content = markdown
      if (message.storage) {
        const len = message.contentLength
        content += '\n... ' + (len - content.length).toLocaleString() + " more characters ..."
      } else {
        content = <Markdown components={components}>{markdown.replaceAll('\n', '\n\n')}</Markdown>
      }
      let judgeIcon = this.props.judging ? Spin : Right
      let judge
      const hasReplies = message => {
        for (const id in this.received) {
          if (this.received[id].inReplyTo === message.id) return true
        }
      }
      if (!isJudge) {
        judge = async () => {
          this.state.swipeMessage = message
          this.judgeSwiper.slideTo(1)
          this.forceUpdate()
        }
      }
      const onSwipe = e => {
        this.setState({
          swipeMessage: message
        })
      }
      let edit 
      let trashMessage
      if (!isJudge) {
        edit = async () => {
        }
        trashMessage = async () => {
          if (this.props.deleteChatMessage) {
            await this.props.deleteChatMessage(this, message.id)
          } else {
            await this.props.me.deleteChatMessage(message.id)
          }
          this.purgeCache(message)
        }
      }
      let user = <div
                   key='usermsg'
                   className='chatMessageFromUser keyboardEditInstruction aiUser'
                 >
                   <div className='keyboardEditIconAndInstruction'>
                     <div className='keyboardEditInstructionLeftIcon'>
                       <ReactSVG src={UserSaid}/>
                     </div>
                     <div className='keyboardEditInstructionText' key={'user-'+message.id}>
                       {content}
                     </div>
                     <div className='rightColumn1 copyAISaid'>
                       {edit && <div key='edit' className='judgeButtonHolder'><KeyboardButton1 keepFocus icon={EditIcon} action={edit}/></div>}
                       {trashMessage && <DeleteButton trash={trashMessage}/>}
                       {judge && <div key='judge'  className='judgeButtonHolder'><KeyboardButton1 icon={judgeIcon} action={judge}/></div>}
                     </div>
                   </div>
                 </div>
      return user
    }
    const output = []
    let { content, role, task, ts, isStreaming } = message
    let text = content
    let outputTs = ts
    if (isStreaming && !text ) {
      text = 'Working.'
    }
    let images
    let noSpin = text
    const isLast = !next
    const inReplyTo = this.received[message.inReplyTo]
    const isTrain = inReplyTo && inReplyTo.model ===  'train'
    if (isJudge && message.judgement) {
      ////console.log({judgement: message.judgement})
      let { judgements } = message.judgement
      if (judgements) {
        ////console.log({judgements})
        const sortedJudgements = [].concat(judgements)
        sortedJudgements.sort((x, y) => {
          const a = this.resolveModel(x.judge)
          const b = this.resolveModel(y.judge)
          return a.name.localeCompare(b.name)
        })
        judgements = sortedJudgements
        ////console.log(judgements)
        const Avg = {
        }
        const trashJudge = async (judge) => {
          if (this.props.deleteJudge) {
            await this.props.deleteJudge(this, {task: this.state.selectedTask, model: judge, messageId: message.id})
          } else {
            await this.props.me.deleteJudgeFromTask({task: this.state.selectedTask.id, model: judge, messageId: message.id})
          }
        }
        for (const j of judgements) {
          const { judge, ratings } = j
          let seen = {}
          for (const r of ratings) {
            let { rating, model } = r
            if (!model && isTrain) { // hack
              model = 'train'
            }
            if (model && !seen[model]) {
              seen[model] = true
              if (!Avg[model]) {
                Avg[model] = 0
              }
              Avg[model] += (rating / judgements.length)
            }
          }
        }
        //////console.log({Avg})
        let winner
        let winners = []
        let rating = 0
        for (const model in Avg) {
          if (winner == undefined || Avg[model] >= rating) {
            winner = model
            if (Avg[model] === rating) {
              winners.push(model)
            } else {
              winners = [model]
            }
            rating = Avg[model]
          }
        }
        const selectModel = async (modelId) => {
          const resolved = this.resolveModel(modelId)
          this.selectModelIndex(resolved)
        }
        const selectJudge = async (judge) => {
          const resolved = this.resolveModel(judge)
          this.selectJudgeIndex(resolved)
        }
        const toButton = (modelId, noIcon, action) => {
          const m = this.resolveModel(modelId)
          if (!m) {
            ////////debugger
            return null
          }
          const icon = (m.getModelIcon && m.getModelIcon()) || m.getIcon()
          if (!action) {
            action = async () => {
              selectModel(modelId)
            }
          }
          let label = <ModelLabel model={m}/>
          if (noIcon) {
            return <div className='modelSelection2'>{label}</div>
          } else {
            return <div className='modelSelection1'><SimpleButton icon={icon} label={label}/></div>
          }
        }
        const ws = winners.map(x => toButton(x, true)).flatMap((x, i) => i === 0 ? x : [', ', x])
        for (const w of winners) {
          delete Avg[w]
        }
        const losers = Object.keys(Avg)
        losers.sort((x, y) => {
          return Avg[y] - Avg[x]
        })
        const ls = losers.map(loser => {
          let button = toButton(loser, true)
          if (!button) {
            return null
          }
          return <div className='judgeLoser'>{button}{` ${Math.round(Avg[loser] * 10)/10}/10`}</div>
        })
        let avgRating = 'rating'
        if (judgements.length > 1) {
          if (ws.length > 1) {
            avgRating = "average ratings of"
          } else {
            avgRating = "an average rating of"
          }
        }
        let answerIs = "answer is from"
        if (ws.length > 1) {
          answerIs = "answers are from"
        }
        output.push(<div className='judgementSummaries'>
                      {sortedJudgements.map(x => <div className='judgeSummary'>{toButton(x.judge, false)}<div className='judgeSummaryContent'>{x.summary}</div><div className='judgeDeleteButton'><DeleteButton icon={Cross} trash={() => trashJudge(x.judge)}/></div></div>)}
                    </div>)
        const textContent = [`The best ${answerIs} `,ws, ` with ${avgRating} ${Math.round(rating * 10)/10}/10.`]
        const text = <div className='judgeWinnerMsgText'>{textContent}</div>
        const div = <div className='judgeWinnerMsg'>
                      <div className='judgesLine'>
                        {sortedJudgements.map(x => toButton(x.judge, true,  async () => selectJudge(x.judge)))}
                      </div>
                       {text}
                      <div className='judgeLosers'>
                        {ls}
                      </div>
                    </div>
        output.push(div)
      }
    }
    const aiSaid = this.renderAISaid(inReplyTo, message, null, { isLast, isJudge, participants })
    output.push(aiSaid)
    return output
  }

  progressMessage = {}

    
  isTextMessage = (message, withReactions) => {
    return true
  }

  deleteChatMessage = messageId => {
    ////////debugger
    if (this.props.deleteChatMessage) {
      return this.props.deleteChatMessage(this, messageId)
    } else {
      return this.props.me.deleteChatMessage(messageId)
    }
  }

  deleteSystemPrompt = async () => {
    await this.props.deleteSystemPrompt(this, this.state.selectedTask)
  }

  openSystemPromptOld = () => {
    const back = () => this.threads.openSubpage(null)
    const select = async systemPrompt => {
      await this.props.selectSystemPrompt(this, this.state.selectedTask, systemPrompt)
      back()
    }
    const subpage = () => <SystemPrompts
                            copy={this.props.copySystemPrompt}
                            cut={this.props.cutSystemPrompt}
                            me={this.props.me}
                            title={'System Prompts'}
                            select={select}
                            systemPrompts={this.props.systemPrompts}
                            back={back}/>
    this.threads.openSubpage(subpage)
  }

  renderSystemPromptView = () => {
    const back = () => {
      this.setState({
        subpage: null
      })
    }
    const select = async systemPrompt => {
      await this.props.selectSystemPrompt(this, this.state.selectedTask, systemPrompt)
      back()
    }
    const onCreate = comp => {
      if (this.state.selectedTask.systemPrompt) {
        comp.openToolset(this.state.selectedTask.systemPrompt)
      }
    }
    return <div className='chatGptSystemPromptView'>
             {this.props.openFileSystem({
               onCreate,
               copy: this.props.copySystemPrompt,
               cut: this.props.cutSystemPrompt,
               title: 'System Prompts',
               choose: select,
               files: this.props.systemPrompts,
               fileSystem: this.props.systemPromptFileSystem,
               back,
             })
             }
           </div>
  }

  openSystemPrompt = () => {
    this.setState({
      subpage: this.renderSystemPromptView
    })
  }

  selectMessage = (selectedMessage, then, noScroll) => {
    if (this.state.selectedMessage !== selectedMessage) {
      this.setState({
        selectedMessage
      }, () => {
        if (selectedMessage && this.messages1) this.messages1.scrollToItem(selectedMessage, { block: 'center'})
        if (then) then()
      })
    } 
  }

  renderMessages = (rawMessages, opts) => {
    if (rawMessages.length === 0) return []
    this.renderN = 0
    this.cacheRenderN = 0
    const result = this.renderMessagesImpl(rawMessages, opts)
    const { renderN, cacheRenderN } = this
    //console.log("renderMessages", this.invalid, { renderN, cacheRenderN })
    return result
  }

  renderMessagesImpl = (rawMessages, {isJudge, participants}) => {
    if (!this.state.selectedTask) {
      return []
    }
    consoleLog("messages", rawMessages, { isJudge })
    let messages
    const mergedBody = {}
    messages = rawMessages
    const opts = { isJudge, participants }
    let seenSys = false
    let rendered =  messages.map((msg, i) => {
      if (msg.role === 'system') {
        seenSys = true
      }
      const prev = i > 0 ? messages[i-1] : null
      const next = i + 1 < messages.length ? messages[i+1] : null
      return this.renderChatMessage(msg, prev, next, mergedBody, opts)
    })
    return rendered
  }

  setSliderContainer = ref => {
    this.sliderContainer = ref
  }

  back = () => {
    this.setState({
      subpage: null,
      popup: null
    })
  }

  shareThread = async (thread, messages) => {
    await this.props.me.shareThread(thread, messages)
  }

  setFileChooser = ref => {
    this.fileChooser = ref
  }

  setCal = cal => {
    this.cal = cal
  }

  renderDiscussions = () => {
    const selectedThread = this.state.selectedTask
    let threads = this.getThreads()
    //////console.log('renderDicussions', {threads})
    let style
    let style3 = { visibility: 'hidden' }
    const onDayChange = day => {
      this.onDayChange(day)
    }
    let needsCal = true
    let day
    const onViewChange = view => {
      ////console.log("view change", view)
      let updateTaskObserver
      if (this.currentView !== view) {
        this.currentWeek = undefined
        updateTaskObserver = this.updateTaskObserver
      }
      this.setState({
        calendarView: view
      }, () => {
        updateTaskObserver()
        if (!this.scrollPos[view]) {
          this.scrollPos[view] = {
            baseOffset: 0,
            offset: 0,
            scrollTop: 0
          }
        }
        this.threads.infiniteScroll.setScrollPos(this.scrollPos[view])
      })
    }
    const events = threads.map(thread => {
      const ts = thread.lastUpdated
      if (day === undefined) {
        day = startOfDay(ts)
      } else if (startOfDay(ts) !== day){
        needsCal = true
      }
      return {
        id: thread.id,
        start: startOfDay(new Date(ts)),
        end: endOfDay(new Date(ts)),
        text: thread.description,
        daily: thread
      }
    })
    if (this.state.searchTerm) {
      needsCal = false
    }
    //////console.log("THREADS", this.state.calendarView, threads)
    const isRecent = this.state.calendarView === 'recent'
    let headerStyle = (needsCal) ? null: { display: 'none'}
    let cost = 0
    for (const thread of threads) {
      const { usage } = thread
      if (usage && usage.total) {
        cost += usage.total
      }
    }
    ////////console.log("COST", cost)
    let costView
    if (cost > 0) {
      costView = '$' + formatPrice(cost)
      }
    let lengthInfo
    if (!isRecent) {
      lengthInfo = `${threads.length} ${this.props.getDiscussionsLabel && this.props.getDiscussionsLabel() || 'Discussions'}`
    }
    let cut
      let copy
    let paste
    if (this.props.cutTask) {
      cut = task => this.props.cutTask(this, task)
    }
    if (this.props.copyTask) {
      copy = task => this.props.copyTask(this, task)
    }
    if (this.props.pasteTask) {
      paste = task => this.props.pasteTask(this, task)
    }
    let renderTask
    if (this.props.renderTask) {
      renderTask = (opts) => {
        return this.props.renderTask(this, opts)
      }
    }
    const renderDiscussionHeader = () => {
      return <div className='discussionHeader'>
               {this.renderDiscussionSearchField()}
               <div className='attunewiseDiscussionCal'>
                 <Calendar initialView={this.props.calendarViewSelection ? this.props.calendarViewSelection[0] : 'recent'} events={events} onCreate={this.setCal} onDayChange={onDayChange} onViewChange={onViewChange} viewSelection={this.props.calendarViewSelection || ['recent', 'week', 'day']}/>
               </div>
             </div>
    }
    return <Threads
             onScroll={this.onScrollThreads}
             getBefore={this.pullHistoryBefore} 
             getAfter={this.pullHistoryAfter}
             onCreate={this.onCreateThreads}
             back={this.goBack}
             title={this.props.getTasksTitle ? this.props.getTasksTitle(this) : "Playground"}
             renderHeader={renderDiscussionHeader}
             chatGPT={this}
             threads={this.getThreads()}
             selectedTask={this.state.selectedTask}
             openThread={this.openThread}
             resolveModel={this.resolveModel}               
             searchTerm={this.state.searchTerm}
             renderTask={renderTask}
             cut={cut}
             copy={copy}
             paste={paste}
             me={this.props.me}
             slide={1}
             view={this.state.calendarView}
             checkScrollBack={this.getThreadsHistory}
             selectThread={this.selectThread}
             selectedThread={selectedThread}
             selectMessage={this.selectMessage}
             selectedMessage={this.state.selectedMessage}
             getMessages={this.getMessages}
             threads={threads}
             deleteTask={this.props.deleteTask && this.deleteTask }/>
  }


  renderDetail() {
    if (isDesktop()) {
      return this.renderChat()
    }
  }


  renderContent() {
    return this.renderDiscussions()
  }


  onJudgeSwiper = swiper => {
    this.judgeSwiper = swiper
  }

  onJudgeSwipeTransitionStart = event => {
    ////////debugger
    if (this.judgeSwiper.activeIndex === 1) {
      this.setState({
        judgeSelected: true
      })
    }
  }

  onJudgeSwipeTransitionEnd = event => {
    if (this.judgeSwiper.activeIndex === 0) {
      this.setState({
        swipeMessage: null,
        judgeSelected: false
      })
    }
  }

   onSelectJudge = event => {
     this.setState({
       judgeChat: this.judgeSwiper.activeIndex === 1
     }, () => {
       //////console.log("judgeChat", this.state.judgeChat)
     })
     
  }

  setJudgeView = (view)  => {
    if (view !== this.judgeView) {
      this.judgeView = view
      this.updateUIElementsLater()
    }
  }

  setModelsView = (view)  => {
    if (view !== this.modelsView) {
      this.modelsView = view
      this.updateUIElementsLater()
    }
  }

  updateSelectedChatModel = (rawMessages) => {
    if (rawMessages.length === 0) return
    const activeModels = this.getActiveModels(rawMessages)
    let newSelection
    if (activeModels.length > 0) {
      activeModels.sort(sortModels)
      newSelection = activeModels[0]
    } else {
      const selectedModels = this.getSelectedModelIds()
      if (selectedModels.length > 0) {
        const models = selectedModels.map(x => this.resolveModel(x))
        models.sort(sortModels)
        newSelection = models[0]
      }
    }
    if (newSelection) {
      this.updateSelectedModelIndexLater(newSelection, false)
      return true
    }
    return false
  }

  updateSelectedModelIndexLater = (newSelection, animate) => {
    clearTimeout(this.modelIndexUpdater)
    this.modelIndexUpdater = setTimeout(() => {
      this.selectModelIndex(newSelection, 0)
      //setTimeout(this.messages1.scrollToBottomMessage, 400);
    }, 0)
  }

  updateUIElements = () => {
    clearTimeout(this.uiElementTimeout)
    if (!this.modelsView) {
      return
    }
    let input = 0
    let output = 0
    const all = {}
    let participants = []
    const selectedModelIds = this.getSelectedModelIds()
    const selectedModelCount = selectedModelIds.length
    const selectedIconByVendor = {}
    const vendorActive = {}
    const seen = {}
    const rawMessages = this.getMessages()
    const getAllModels = () => {
      const allModels = this.getModels()
      ////////console.log({allModels})
      for (const model of allModels) {
        if(model.id) {
          all[model.id] = model
        } else {
          //////////debugger
        }
      }
      const result = []
      const add = model => {
        if (model) {
          if (!seen[model]) {
            result.push(all[model])
            seen[model] = true
          }
        } else {
        }
      }
      for (const message of rawMessages) {
        if (message.role === 'assistant') {
          if (message.content) {
            add('attunewise')
          }
          if (true) {
            for (const reply of getReplies(message)) {
              add(reply.model)
            }
            if (message.judgement) {
              const { judge, judgements } = message.judgement
              if (judge) {
                add(judge)
              } else if (judgements) {
                for (const j of judgements) {
                  const { judge, ratings } = j
                  add(judge)
                }
              }
            }
          }
        }
      }
      return result
    }
    const models = getAllModels()
    const prices = {}
    for (const id in this.props.prices) {
      const price = this.props.prices[id]
      if (price.contexts) {
        ////////console.log("PRICE", price)
        prices[id] = price.contexts[0].price
     }
    }
    //////console.log({allModels: models})
    const modelsById = {}
    const vendorByName = {}
    for (const vendor of this.props.vendors) {
      vendorByName[vendor.name] = vendor
    }
    for (const model of models) {
      if (!model) {
        //////////debugger
        continue
      }
      modelsById[model.id]  = model
      if (model.contexts) {
        const price = model.contexts[0].price
        prices[model.id] = price
        input += price.input
        output += price.output
      } else {
        ////////console.log("no contexts", model)
      }
    }
    const calcPrices = (modelSrc, judgeSrc, rawMessages) => {
      ////console.log('calcPrices', rawMessages.length)
      const selectedIcons = []
      const selectedJudgeIcons = []
      const usage = {}
      const inTokens = {attunewise: 0}
      const outTokens = {attunewise: 0}
      let i = 0
      let i$ = 0
      let o$ = 0
      let ji$ = 0
      let jo$ = 0
      let seenModel = {}
      let seenJudge = {}
      for (const model of modelSrc.getSelectedModelsListFiltered()) {
        let m = seenModel[model.id]
        if (!m) {
          let menu
          if (this.props.fineTunedModel) {
            if (model.id === this.props.fineTunedModel.id) {
              const actions = []
              const models = this.getModels()
              let className = 'baseModelMenu'
              const active = modelsById[model.id]
              if (active) className += ' baseModelMenuActive'
              let x = models.find(y => y.id === model.baseModelId)
              ////////debugger
              while (x) {
                const apply = (x) => {
                  const icon = (x.getModelIcon && x.getModelIcon()) || x.getIcon()
                  const action = async (close) => {
                    ////////debugger
                    modelSrc.selectModel(x.id)
                    close()
                  }
                  actions.push({ button: close => <SimpleButton key={x.id} icon={icon} label={<ModelLabel model={x}/>} action={() => action(close)} />})
                }
                apply(x)
                if (!x.baseModelId) {
                  break
                }
                x = models.find(y => y.id === x.baseModelId)
              }
              if (actions.length > 0) {
                menu = <ActionMenu className={className} actions={actions} position = 'bottom right'/>
              }
            }
          }
          seenModel[model.id] = m = { model, selected: true, active: false, menu}
          selectedIcons.push(m)
        } else {
          m.selected = true
        }
      }
      if (this.state.judgeSelected && judgeSrc) for (const model of judgeSrc.getSelectedModelsListFiltered()) {
        let m = seenJudge[model.id]
        if (!m) {
          seenJudge[model.id] = m = { model, selected: true, active: false }
          selectedJudgeIcons.push(m)
        } else {
          m.selected = true
        }
      }
      for (const message of rawMessages) {
        let { role, content, usage, judgement } = message
        const models = getReplies(message)
        content = content || ''
        if (judgement) {
          const { judgements } = judgement
          for (const j of judgements) {
            const { judge, usage } = j
            ////console.log("USAGE", judge, usage)
            if (usage && usage.inputTokens) {
              if (!inTokens[judge]) {
                inTokens[judge] = 0
              }
              if (!outTokens[judge]) {
                outTokens[judge] = 0
              }
              inTokens[judge] += usage.inputTokens
              outTokens[judge] += usage.outputTokens
             const i = (usage.inputTokens / 1000000) * prices[judge].input
              const o = (usage.outputTokens /1000000) * prices[judge].output
              ji$ += i
              jo$ += o
              i$ += i
              o$ += o
            }
            if (!seenJudge[judge]) {
              const m = modelsById[judge]
              if(m) {
                const icon = (m.getModelIcon && m.getModelIcon()) || m.getIcon()
                let m1 = { model: m, active: true }
                seenJudge[m.id] = m1
                selectedJudgeIcons.push(m1)
              } else {
                console.error("model not found", judge)
              }
            } else {
              seenJudge[judge].active = true
            }
          }
        }
        if (role === 'user') {
          if (usage && usage.inputTokens) {
            i = usage.inputTokens
          } else {
            i = 0//countTokens(content)
          }
        } else {
          if (content.trim() && message.model === 'attunewise') {
            const m = modelsById['attunewise']
            vendorActive[m.vendor] = true
            const icon = (m.getModelIcon && m.getModelIcon()) || m.getIcon()
            selectedIconByVendor[m.vendor] = true
            let m1 = seenModel[m.id]
            if (!m1) {
              seenModel[m.id] = m1 = { model: m, active: true }
              selectedIcons.push(m1)
            } else {
              seenModel[m.id].active = true
            }
            let o
            if (usage) {
              o = usage.outputTokens
            } else {
              o = 0//countTokens(content)
            }
            outTokens['attunewise'] += o
          }
          if (models) {
            for (let {content, model, usage} of models) {
              let o
              let i1 
              if (usage && usage.inputTokens) {
                o = usage.outputTokens
                i1 = usage.inputTokens
              } else {
                o = 0//countTokens(content)
                i1 = i
              }
              if (!inTokens[model]) {
                inTokens[model] = 0
              }
              inTokens[model] += i1
              if (!outTokens[model]) {
                outTokens[model] = 0
              }
              outTokens[model] += o
              const modelId = resolveModelId(model)
              const m = modelsById[modelId]
              if (!m) {
                //debugger
                console.error("model not found", model)
                continue
              }
              vendorActive[m.vendor] = true
              const icon = (m.getModelIcon && m.getModelIcon()) || m.getIcon()
              selectedIconByVendor[m.vendor] = true
              let m1 = seenModel[m.id]
              if (!m1) {
                seenModel[m.id] = m1 = { model: m, active: true }
                selectedIcons.push(m1)
              } else {
                m1.active = true
              }
            }
          }
        }
      }
      selectedIcons.sort((a, b) => {
        const x = a.model
        const y = b.model
        if (x.id !== y.id) {
          if (x.id === 'train') return -1
          if (y.id === 'train') return 1
          if (x.id === 'reject') {
            return y.id === 'train' ? 1 : -1
          }
          if (y.id === 'reject') {
            return x.id === 'train' ? -1 : 1
          }
        }
        const cmp = x.title.localeCompare(y.title)
        if (cmp) return cmp
        if (x.isFinetune && !y.isFinetune) {
          return 1
        }
        if (y.isFinetune && !x.isFinetune) {
          return -1
        }
        return y.ts - x.ts
      })
      selectedJudgeIcons.sort((a, b) => {
        const x = a.model
        const y = b.model
        return x.title.localeCompare(y.title)
      })
      for (let id in inTokens) {
        id = resolveModelId(id)
        if (!prices[id]) {
          ////////console.log("NO PRICE", id, prices)
          continue
        }
        i$ += (prices[id].input / 1000000) * inTokens[id]
      }
      for (let id in outTokens) {
        id = resolveModelId(id)
        if (!prices[id]) {
          continue
        }
        o$ += (prices[id].output / 1000000) * outTokens[id]
      }
      const format = this.props.formatPrice || formatPrice
      const price = "$" + format(i$, 3) + "/" + format(o$, 3)
      const judgePrice = "$" + format(ji$, 3) + "/" + format(jo$, 3)
      return { inTokens, outTokens, usage, selectedIcons, price, judgePrice, selectedJudgeIcons}
    }
    const trashModel = async info => {
      const { model } = info
      if (this.props.deleteModel) {
        await this.props.deleteModel(this, {task: this.state.selectedTask, model: model.id})
      } else {
        await this.props.me.deleteModelFromTask({task: this.state.selectedTask.id, model: model.id})
      }
      if (info.selected) {
        this.modelsView.selectModel(info.model.id)
      }
      this.purgeCache()
    }
    const trashJudge = async info => {
      const { model } = info
      if (this.props.deleteJudge) {
        await this.props.deleteJudge(this, {task: this.state.selectedTask, model: model.id})
      } else {
        await this.props.me.deleteJudgeFromTask({task: this.state.selectedTask.id, model: model.id})
      }
      if (info.selected) {
        this.modelsView.selectModel(info.model.id)
      }
      this.purgeCache()
    }
    const toggleModelSelection = info => {
      this.modelsView.selectModel(info.model.id)
      this.forceUpdate()
    }
    const toggleJudgeSelection = info => {
      this.judgeView.selectModel(info.model.id)
    }
    let messageSelectionClassName = 'messageSelection'
    let judgeMessageSelectionClassName = 'messageSelection'
    const modelInfo = calcPrices(this.modelsView, this.judgeView, rawMessages)
    ////console.log({modelInfo})
    participants = modelInfo.selectedIcons.map(x => x.model)
    const modelAdd = async () => this.modelsView.togglePopup()
    const judgeAdd = async () => this.judgeView.togglePopup()
    const currentUsage = {}
    if (rawMessages.length > 0) {
      const last = rawMessages[rawMessages.length-1]
      if (last) {
        for (const reply of getReplies(last)) {
          //debugger
          const { usage, model } = reply
          currentUsage[model] = usage
        }
      }
    }
    const modelSelection = <ModelSelection currentUsage={currentUsage} models={modelInfo.selectedIcons} trash={trashModel} action={this.selectModelIndex} toggleSelection={toggleModelSelection} add={modelAdd} />
    messageSelectionClassName += ' messageSelectionModels'
    const judgeSelection = () => <ModelSelection models={modelInfo.selectedJudgeIcons} trash={trashJudge} action={this.selectJudgeIndex} toggleSelection={toggleJudgeSelection} add={judgeAdd}/>
    ////console.log({rawMessages})
    this.invalidateCache()
    rawMessages.unshift({id: 'system', role: 'system', content: 'placeolder'})
    this.setState({
      uiElements: { modelSelection, judgeSelection, messageSelectionClassName, judgeMessageSelectionClassName, selectedModelCount, participants, selectedIconByVendor, modelInfo},
      rawMessages, selectedModelIds,
    }, () => {
      if (Date.now() - this.taskInit < 1000) {
        this.messages1.scrollToBottom()
      }
      this.updateAutoHeights()
      this.checkSelectedModelLater()
    })
  }

  updateAutoHeights = () => {
  }

  updateUIElementsLater = () => {
    clearTimeout(this.uiElementTimeout)
    this.uiElementTimeout = setTimeout(this.updateUIElements, 16)
  }

  renderChat = () => {
    if (!this.state.selectedTask) return null
    let blurb = ''
    let followUpQuestions  
    let title
    const { selectedModelIds, rawMessages, uiElements } = this.state
    const { modelSelection, judgeSelection, messageSelectionClassName, judgeMessageSelectionClassName, selectedModelCount, participants, selectedIconByVendor, modelInfo } = uiElements
    const vendorSelected = {}
    ////////console.log("rawMessages", rawMessages)
    let buttonIcon = Send
    let buttonAction = async () => {
      return await this.sendChat()
    }
    let copyAction = async () => {
      const messages = this.getMessages()
      let text = ''
      for (const message of messages) {
        const { role, model, models, content } = message
        if (role === 'user') {
          text += role + ":\n"
          text += content
          text += '\n\n'
        }
        else {
          [{model, content}].concat(models || []).forEach((x, i) => {
            let { model, content } = x
            if (model && content) {
              model = this.resolveModel(model)
              text += '#' + (i+1) + ' ' +model.vendor + " " + model.name + ":\n"
              text += content
              text += '\n\n'
            }
          })
          text += '\n\n'
        }
      }
      ////////console.log(text)
      try {
        await this.copyToClipboard(text)
      } catch (err) {
      }
    }
    const shareAction = async() => {
    }
    let busy = false
    let questions = []
    let placeholder = this.props.getPlaceholder ? this.props.getPlaceholder(this) : (this.state.judgeChat ? 'Talk to judge' : 'Ask me anything')
    let currentTask
    if (this.state.slide > 0.5) {
      currentTask = this.state.selectedTask && this.state.selectedTask.id
    } 
    let newTopicButton
    busy = this.state.threadBusy
    let buttonLabel = 'Send'
    if (this.state.selectedTask.autoplay) {
      buttonLabel = "Play"
    }
    if (!currentTask) {
      const newTopic = async () => {
        //////////debugger
        if (this.props.newTopic) {
          return this.props.newTopic(this)
        }
        if (!this.props.onNewFile) {
          this.props.createNewTask()
        }
      }
      newTopicButton = <KeyboardButton className='newTopicButton' label={'Ask'} keepFocus action={newTopic} icon={Hashtag}/>
    }
    const openImage = async (event) => {
      this.fileChooser.click()
    }
    const handleImage = async (event) => {
      this.handleDataTransfer(event, event.target)
    }
    const userSelected = () => this.state.role === 'user'
    const selectUser = () => {
      this.setState({
        role: 'user'
      })
    }
    const systemSelected = () => this.state.role === 'system'
    const selectSystem = () => {
     this.setState({
        role: 'system'
      })
    }
    const showSettings = async () => {
      this.setState({
        showSettings: !this.state.showSettings
      })
    }
    const toggleJson = () => {
      this.state.selectedTask.jsonOutput = !this.state.selectedTask.jsonOutput
      this.forceUpdate()
    }
    const toggleAutoplay = async () => {
      if (this.state.selectedTask.autoplay) {
        this.state.selectedTask.autoplay = null
      } else {
        this.state.selectedTask.autoplay = this.editor.getText()
      }
      this.forceUpdate()
    }
    const text = this.editor && this.editor.getText()
    let showAutoplay = (this.state.selectedTask && this.state.selectedTask.autoplay) || text
    let bottomRow = [<div className='imageChooser' onClick={openImage}>
                      <input
                        ref={this.setFileChooser}
                        type="file"
                        accept={"image/*"}
                        style={{ display: 'none' }} // Hides the file input
                        onChange={handleImage}
                      />                      
                      <ReactSVG src={Image}/>
                     </div>,
                     <Checkbox label="JSON Output" toggle={toggleJson} selected={this.state.selectedTask.jsonOutput}/>,
                     showAutoplay && <Checkbox key={'autoplay'} label="Autoplay" toggle={toggleAutoplay} selected={this.state.selectedTask.autoplay}/>,
                     /*<RadioButtons buttons={[
                       {
                                       icon: UserSaid,
                                       select: selectUser,
                                       selected: userSelected()
                                     },
                                     {
                                       icon: AISaid,
                                       select: selectSystem,
                                       selected: systemSelected()
                                     },
                                     ]}/>*/].filter(x => x)
    let messages = rawMessages.filter(x=>x)
    if (this.editor && !this.editor.getText() && rawMessages.length > 0) {
      const message = messages[messages.length-1]
      if (!this.xhr || message.inReplyTo) {
        const resend = async () => {
          const { inReplyTo } = message
          let request
          if (inReplyTo) {
            request = this.received[inReplyTo]
          } else {
            request = message
          }
          await this.sendChat(request)
          this.forceUpdate()
        }
        if (message.inReplyTo) {
          buttonLabel = this.props.getButtonLabel(this, message)
        }
        buttonAction = resend
      } else {
        buttonAction = async () => {
        }
      }
    }
    if (!this.state.magpie) {
      if (this.state.sending) {
        buttonIcon = Spin
        buttonAction = async () => {}
      }
      if (this.state.autoplayInProgress  || (this.state.isStreaming && !this.state.replay)) {
        buttonAction = () => {
          this.stopChat()
          this.state.waitingForModels = {}
          this.state.isStreaming = false
          this.state.sending = false
          this.state.replay = false
          this.forceUpdate()
          this.state.autoplayInProgress = false
        }
        buttonLabel = "Stop"
        buttonIcon = Stop
      }
    }
    let showSearchField = this.props.isSearchFieldVisible(this, messages)
    const x = (1.0-this.state.slide) * -(Math.min(window.innerWidth, 600) - 10)
    const sliderStyle = {
      //transform: `translate(${x}px, 0)`
    }
    let index = this.state.swipeIndex
    let sliderClassName = 'chatMessagesSlider'
    if (this.state.selectedThread) {
      //sliderClassName += ' chatMessagesSliderEnabled'
    }
    let showKeyboard = this.state.showKeyboard
    let menu
    let questionsTopic
    if (this.state.hallucinationQuestions) {
      questionsTopic = null
      questions = this.state.hallucinationQuestions
      questions.sort((x, y) => {
        return x.question.localeCompare(y.question)
      })
    }
    if (questions.length > 0) {
      const ask = q => this.ask(questionsTopic, q, true)          
      menu = <Questions me={this.props.me} ask={ask} searchTerm={this.state.questionSearchTerm} questions={questions} selectQuestion={ask} editorHeight={this.state.editorHeight}/>
    }
    const sfcStyle = {
      height: this.state.selectedThread ? 40 : 80
    }
    let style = !isDesktop() &&this.state.orient === 'landscape' ? { display: 'none' } : null
    let style2
    if (!this.props.goBack) {
      style2 = { visibility: 'hidden'}
    }
    let style3
    title = null
    let inputControlStyle
    inputControlStyle = {
      //opacity: this.hasTasks() ? this.state.slide : 1
    }
    let mainClassName ='chatGPT'
    let noInput
    let multiJudge
    if (this.state.judgeSelectedd && (this.state.judgeChat && (true || this.judgeView.getSelectedModelIds().length > 1))) {
      noInput = true
      multiJudge = true
   }
    if (noInput) {
      inputControlStyle = { display: 'none' }
      mainClassName += ' chatGPTNoInput'
    }

    const goBack = async () => {
      this.goBack()
    }
    const toggleSize = (size) => {
      if (this.state.sizes[size]) {
        delete this.state.sizes[size]
      } else {
        this.state.sizes[size] = true
      }
      this.forceUpdate(this.saveOptionsLater)
    }
    const toggleSmall = () => toggleSize('small')
    const toggleMedium = () => toggleSize('medium')
    const toggleLarge = () => toggleSize('large')
    let filler = '' 
    const openSettings = async () => {
      this.setState({showSettings: !this.state.showSettings})
    }
    for (const id of selectedModelIds) {
      const model = this.resolveModel(id)
      if (model) {
        const icon = (model.getModelIcon && model.getModelIcon()) || model.getIcon()
        if (icon) {
          vendorSelected[model.vendor] = true
          const selectModel = async () => this.selectModel(model)
        }
      }
    }
    let deleteButton
    const thread = this.state.selectedTask
    if (thread) {
      const trash = async () => {
        if (this.state.confirmDelete !== thread.id) {
          this.state.confirmDelete = thread.id
          //////////console.log("confirmDelete", thread)
        } else {
          thread.busy = true
          this.forceUpdate()
          await this.deleteTask(thread)
          this.state.confirmDelete = null
          delete thread.busy
          this.selectThread(null)
        }
        this.forceUpdate()
      }
      deleteButton = trash && <DeleteButton trash={trash}/>      
    }
    const closeMenu = () => {
      setTimeout(() => {
        this.setState({
          showModelMenu: false
        })
      }, 50)
    }
    const toggleMenu = async () => {
      this.setState({
        showModelMenu: !this.state.showModelMenu
      })
    }
    const menuActive1 = thread  && selectedModelCount === 0
    //////console.log("swipeMessage", this.state.swipeMessage)
    let judgeConversation = this.getJudgeConversation()
    let selectedJudgeCount = 0
    let menuActive2 = this.state.judgeChat && selectedJudgeCount === 0
    const judgeSelected = this.state.judgeSelected
    ////console.log({judgeSelected})
    let sliderContainerStyle = noInput ? null: {
     // height: `calc(100% - ${this.state.editorHeight}px - 5px)`
    }
    let magpie
    if (this.props.magpieEnabled) {
      magpie = async () => {
        await new Promise((resolve, reject) => {
          this.state.magpie = {resolve, reject}
          this.sendChat()
        })
        this.forceUpdate()
      }
    }

    
    const onOptionsChanged = () => {
      this.updateUIElementsLater()
    }
    
    const editSystemPrompt = async () => {
    }
    const openSystemPrompts = async () => {
    }
    let settingsPopup
    if (this.state.showSettings) {
      const getTemp = () => {
        return this.state.temp
      }
      const setTemp = (value) => {
        this.state.temp = value
        this.forceUpdate()
      }
      const getTopP = () => {
        return this.state.topP
      }
      const setTopP = (value) => {
        this.state.topP = value
        this.forceUpdate()
      }
      const getTopK = () => {
        return this.state.topK
      }
      const setTopK = (value) => {
        this.state.topK = value
        this.forceUpdate()
      }
      settingsPopup = <ClickAwayListener onClickAway={showSettings}>
                        <div className='chatSettings1'>
                          <div className='tempSlider'><div className='tempLabel'>Temp</div><Slider onChange={setTemp} value={getTemp()} bounds={[0, 2]}/></div>
                          <div className='tempSlider'><div className='tempLabel'>Top p</div><Slider onChange={setTopP} value={getTopP()} bounds={[0, 1]}/></div>
                          <div className='tempSlider'><div className='tempLabel'>Top k</div><Slider onChange={setTopK} value={getTopK()} bounds={[1, 100]}/></div>
                        </div>
                      </ClickAwayListener>
    }
    if (this.state.judgeChat) {
      mainClassName += ' chatGPTJudge'
    }

    if (isDesktop() && !this.state.judgeChat) {
      style2 = {
        visibility: 'hidden'
      }
    }
    const renderJudgeView = () => {
      return <div className={sliderClassName} style={sliderStyle}>
               <div className='forModelsMenu modelsMenuPopupHidden'>
                 <ModelsView
                   key={'judges'}
                   onOptionsChanged={onOptionsChanged}
                   onCreate={this.setJudgeView}
                   me={this.props.me}
                   category={'judges'}
                   prices={this.props.price}
                   models={(isSelected, select) => this.props.models(this, isSelected, select)}
                   vendors={this.props.vendors}
                   observeOptions={this.props.me.observeModelOptions}
                   saveOptions={this.props.me.saveModelOptions}
                 />
                 <div className='chatHeaderFiller'>{filler}</div>
                 <div className='modelPrice'>{modelInfo.judgePrice}</div>
               </div>
               <div className={messageSelectionClassName || 'messageSelection'}>
                 {judgeSelected && judgeSelection()}
                 {judgeSelected && <InfiniteScroll
                                     items={judgeConversation}
                                     count={judgeConversation.length}
                                     onCreate={this.setMessages2}
                                     getSelector={
                                       id => `[data-message-id="${id}"]`
                                     }
                                     getId={
                                       item => item.id
                                     }
                                     renderItems={
                                       messages => this.renderMessages(messages, {participants})
                                     }
                                   />}
               </div>
             </div>
    }
    const renderSlide2 = () => {
      if (this.state.systemPromptSelected) {
        return this.renderSystemPromptView()
      }
      if (this.state.judgeSelected) {
        return renderJudgeView()
      }
    }
    return <div className={mainClassName}>
             <div className='keyboardHeader' style={style}>
               <div style={style2}><KeyboardButton1 icon={Left} action={goBack}/></div>
               <KeyboardTitle title={this.props.getTitle(this) || "Empty Discussion"}/>
               <div className='deleteButtonHolder'>{isMobile() && deleteButton}</div>
             </div>
             <div className='chatMessagesSliderContainer' ref={this.setSliderContainer} style={sliderContainerStyle}>
               <Swiper
                 preventClicks={false}
                 modules={[Mousewheel]}
                 allowTouchMove={false && !isDesktop()}
                 mousewheel={false && isDesktop() ? { forceToAxis: true, thresholdDelta: 6  } : undefined}
                 onSwiper={this.onJudgeSwiper}
                 onSlideChangeTransitionEnd={this.onJudgeSwipeTransitionEnd}
                 onSlideChangeTransitionStart={this.onJudgeSwipeTransitionStart}
                 onSlideChange={this.onSelectJudge}>
                 <SwiperSlide key='model'>
                   <div className={sliderClassName} style={sliderStyle}>
                     <div className='forModelsMenu modelsMenuPopupHidden'>
                       <ModelsView
                         key={'models'}
                         fineTunedModel={(this.props.fineTunedModel || {}).id}
                         onOptionsChanged={onOptionsChanged}
                         onCreate={this.setModelsView}
                         me={this.props.me}
                         category={'models'}
                         prices={this.props.price}
                         models={(isSelected, select) => this.props.models(this, isSelected, select)}
                         vendors={this.props.vendors}
                         observeOptions={this.props.me.observeModelOptions}
                         configure={this.props.configure}
                         saveOptions={this.props.me.saveModelOptions}/>
                       <SimpleButton icon={Down} action={this.gotoBottom}/>
                       <SimpleButton icon={Up} action={this.gotoTop}/>
                       <div className='chatHeaderFiller'>{filler}</div>
                       {messages.length > 10 && <div key='messageCount' className='messageCount'>{messages.length} messages</div>}
                       <div key='modelPrice' className='modelPrice'>{modelInfo.price}</div>
                       <div className='chatHeaderSelectedModels'>{selectedModelCount}</div>
                     </div>
                     <div className={messageSelectionClassName || 'messageSelection'}>
                       {modelSelection}
                       <div className='uiChatMarginBottom'>
                         <InfiniteScroll
                           debug={true}
                           isBottomAligned={true}
                           items={messages}
                           count={messages.length}
                           onCreate={this.setMessages1}
                           getSelector={
                             id => `[data-message-id="${id}"]`
                           }
                           getId={
                             item => item.id
                           }
                           renderItems={
                             messages => this.renderMessages(messages, {participants})
                           }
                         />
                       </div>
                     </div>
                   </div>
                 </SwiperSlide>
                 <SwiperSlide key='judge'>
                   {renderSlide2()}
                 </SwiperSlide>
               </Swiper>
                      
               {this.state.sendError &&
                <div className='keyboardEditIconAndInstruction sendError'>
                  <div className='keyboardEditInstructionLeftIcon'>
                    <ReactSVG src={Alert}/>
                    </div>
                  <div className='keyboardEditInstructionText'>
                    {this.state.sendError}
                  </div>
                </div>}
             </div>
             <div className={'chatGPTInput' + (menu ? ' chatGPTInputWithMenu' : '')} style={inputControlStyle}>
               <InputControl key='chat'
                             onCreate={this.setInputRef}
                             busy={busy}
                             onDrop={this.onDrop}
                             onPaste={this.onPaste}
                             onKeyDown={this.onKeyDown}
                             placeholder={placeholder} me={this.props.me}
                             onSetEditor={this.setEditor}
                             onClear={this.clearEditor}
                             speechInputNoFocus={true}
                             speechInputActive={this.state.textFieldSpeechInputActive}
                             speechInputAction={this.toggleTextFieldSpeechInput}
                             selectSpeechInputLang={this.selectTextInputLang}
                             selectedLang={this.state.lang}
                             autodetect={this.autodetectLang} label={buttonLabel}
                             icon={buttonIcon} action={buttonAction}
                             copy={copyAction} share={shareAction}
                             cancel={undefined}
                             undo={undefined}
                             redo={undefined}
                             onBlur={
                               () => {
                                 this.setTextInputFocus(false)
                               }
                             }
                             onFocus={
                               () => {
                                 this.setTextInputFocus(true)
                               }
                             }
                             onInput={true ? null: this.onInput}
                             applyCompletion={this.applyCompletion}
                             completions={this.state.completions}
                             bottomRow={bottomRow}
                             menu={magpie ? <KeyboardButton1 icon={UserSaid} action={magpie}/>: null}
                          />
                 <div className='settingsPopup'>
                   <Popup1 popup={settingsPopup}>
                     <GearButton action={showSettings}/>
                   </Popup1>
                 </div>
             </div>
             {multiJudge &&
              <div className='judgeButton'>
                <KeyboardButton icon={Send} label="Judge" action={buttonAction}/>
              </div>}
           </div>
  }

  editorSub1
  setInputRef = ref => {
    if (ref != this.inputRef) {
      if (ref) {
        if (this.editorSub1) {
          this.editorSub1.unsubscribe()
        }
        this.editorSub1 = ref.observeEditorHeight().subscribe(height => {
          ////////debugger
          height = Math.round(height)
          if (height != this.state.editorHeight) {
            this.state.editorHeight = height
            this.forceUpdate()
          }
        })
      }
      this.inputRef = ref
    }
  }

  checkScrollBack2 = async () => {
    if (false) {
      return await this.checkScrollBack()
    }
  }

  
  checkScrollBack = async () => {
    if (this.scrollBusy) {
      return
    }
    this.scrollBusy = true

    let earliestTs = Date.now()
    let earliest = { ts: earliestTs }
    for (const k in this.received) {
      const msg = this.received[k]
      const { ts } = msg
      if (ts < earliestTs) {
        earliestTs = ts
        earliest = msg
      }
    }
    //////////debugger
    const prev = await this.props.getHistory(this, this.state.selectedTask, earliest, this.props.pageSize || 10)
    if (prev.length > 0) {
      for (const msg of prev) {
        this.parseMessage(msg)
        this.received[msg.id] = msg
      }
      this.forceUpdate(() => {
        this.scrollBusy = false
      })
    } else {
      this.scrollBusy = false
    }
  }

  setSearchEditor = editor => {
    if (this.searchEditorSub) {
      this.searchEditorSub.unsubscribe()
      this.searchEditorSub = null
    }
    this.searchEditor = editor
    if (editor) {
      this.searchEditorSub = editor.observeIsEmpty().subscribe(isEmpty => {
        this.setState({
          searchCanApply: !isEmpty
        })
      })
    }
    this.forceUpdate()
  }

  setEditor = ref => {
    //////debugger
    if (this.editorSub) {
      this.editorSub.unsubscribe()
    }
    if (ref) {
      this.state.editorCanApply = true
      this.editor = ref
      this.editorSub = this.editor.observeIsEmpty().subscribe(isEmpty => {
        if (this.state.editorCanApply !== !isEmpty) {
          this.state.editorCanApply = !isEmpty
          this.forceUpdate()
        }
      })
      if (this.state.selectedTask && this.state.selectedTask.autoplay) {
        this.editor.setText(this.state.selectedTask.autoplay)
      }
        
    }
    this.forceUpdate()
  }
  
  clearEditor = () => {
    this.state.completions = []
    this.renderCurrentDocument()
 }

  isKeyboardShowing = () => !isDesktop() && this.state.textInputFocus

  undoEdit = async () => {
    //////////////////////debugger
    if (this.canUndoCurrentDocument()) {
      const doc = this.getCurrentDocument()
      doc.undo()
    } else {
      let i = this.getCurrentInstruction()
      this.state.variableToComplete = null
      if (i) {
        if (i.output) {
          this.state.edits.undo()
          i.undoOutput = i.output
          i.output = null
        } else if (i.inputText) {
          i.inputTemplate = null
          i.variableToComplete = null
          i.instruction = new Document(i.inputText)
          i.inputText = null
        } else {
          this.state.instructions.undo()
          i = this.getCurrentInstruction()
        }
        this.buildNextInstruction(i)
        this.renderCurrentDocument()
        return
      }
      if (this.state.instructions.canUndo()) {
        this.state.instructions.undo()
        const i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      } else {
        this.buildNextInstruction()
      }
    }
    this.renderCurrentDocument()
  }
  
  redoEdit = async () => {
    //////////////////////debugger
    const doc = this.getCurrentDocument()
    if (doc && doc.canRedo()) {
      doc.redo()
    } else {
      let i = this.getCurrentInstruction()
      if (i) {
        if (i.undoOutput) {
          i.output = i.undoOutput
          i.undoOutput = null
          this.state.edits.redo()
          this.buildNextInstruction()
        } else {
          this.state.instructions.redo()
          i = this.getCurrentInstruction()
          this.buildNextInstruction(i)
        }
      } else if (this.state.instructions.canRedo()) {
        this.state.instructions.redo()
        i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      }
    }
    this.renderCurrentDocument()
  }

  showKeyboard = () => {
    if (true) return
    if (false) {
      const back = () => {
        this.setState({
          subpage: null,
          popup: null
        })
      }
      this.setState({
        popup: () => <div className='chatGPTKeyboard'><Keyboard me={this.props.me} sendKeyboardOutput={this.sendKeyboardOutput} cancelKeyboardOutput={back} isWritingAssistant={true}/></div>
      })
    } else {
      this.setState({
        showKeyboard: true
      })
    }
  }
    
  setTextInputFocus = textInputFocus => {
    this.showKeyboard()
    let instructionFocus = this.state.instructionFocus
    if (textInputFocus) {
      instructionFocus = false
    } else {
      this.onBlur()
    }
    this.setState({
      textInputFocus,
      instructionFocus
    },() => {
      this.updateLang()
      if (this.editor && this.editor.focused) {
        //this.onInput()
      }
    })
    if (!instructionFocus && !textInputFocus) {
      //this.stopVoiceInput()
    }
  }
  renderCurrentDocument = () => {
    const text = this.renderText(this.getCurrentDocument())
    if (this.editor.setText(text)) {
      this.focusedText = undefined
    }
    this.forceUpdate(this.showLastInstruction)
    return text
  }

  copyToClipboard = async text => {
    try {
       navigator.clipboard.writeText(text)
    } catch (err) {
      console.error(err)
      //////console.log(text)
    }
    await delay(0.5)
  }

  copy = async () => {
    const text = this.editor.getText()
    return this.copyToClipboard(text)
  }

  renderText = (edit) => {
    return (edit && edit.getCurrent()) || ''
  }

  getCurrentDocument = () => {
    return this.state.edits.getCurrent()
  }

  cancel = () => {
    this.props.back()
  }

  goBack = async () => {
    this.state.systemPromptSelected = false
    if (this.state.judgeChat) {
      this.judgeSwiper.slideTo(0)
      return
    } else if (this.state.selectedTask) {
      this.selectThread(null)
      if (isMobile()) {
        this.threads.openSubpage(null)
        return
      }
    }
    this.setState({
      active: false,
      sendError: null
    })
    this.deinit()
    ////////debugger
    if (this.props.goBack) this.props.goBack()
  }

  canUndo = () => {
  }

  canRedo = () => {
  }


  updateLang = () => {
  }

  inputSeq = 0


  clearError = () => {
  }

  onBlur = e => {
    this.state.editing = false
    this.state.completions = []
    const text = this.editor.getText()
    if (this.getCurrentText() == text) {
      //debugLog("onBlur no change")
      this.forceUpdate()
      return 
    }
    //debugLog("onBlur text changed")
    this.clearError()
    const doc = this.getCurrentDocument()
    ////////////////////////debugger
    this.forceUpdate()
  }

  getCurrentText = () => {
    const doc = this.getCurrentDocument()
    return doc ? doc.getCurrent() || "" : ""
  }

  getCurrentTopic = () => {
    let topic
    if (this.state.selectedThread) {
      topic = this.state.selectedThread.id 
    } else {
      let ts = 0
      let lastMessage
      for (const id in this.received) {
        const message = this.received[id]
        if (message.ts > ts) {
          ts = message.ts
          lastMessage = message
        }
      }
      if (lastMessage) {
        topic = lastMessage.topic
      }
    }
    if (!topic) {
      if (this.state.selectedTask && this.state.selectedTask.lastTopic) {
        topic = this.state.selectedTask.lastTopic.id
      }
    }
    return topic
  }

  waitForUploads = () => {
    return new Promise((resolve, reject) => {
      const checkForUploadsDone = () => {
        if (this.state.uploads.length === 0) {
          resolve()
        } else {
          setTimeout(checkForUploadsDone, 500)
        }
      }
      checkForUploadsDone()
    })
  }

  getContent = () => {
    let content  = []
    let result = ''
    const flush = () => {
      if (result) {
        result = result.replace(/\n•[ ]+/g, "\n•  ");
        content.push({
          type: "text",
          text: result
        })
        result = ''
      }
    }
    const apply = node => {
      walkDOM(node, n => {
        if (n instanceof HTMLVideoElement) {
        }
        else if (n instanceof HTMLImageElement) {
          const url = n.src
          flush()
          content.push({
            type: "image_url",
            image_url: {
              url
            }
          })
        } else if (n.nodeName == "WBR") {
        } else if (n.nodeName == "BR") {
          result += "\n";
        } else if (n.nodeType == 3 && !(n.parentElement instanceof HTMLSpanElement)) {
          let textContent = n.textContent.replace(nbsp, " ");
          result += textContent;
          if (n.parentElement !== node && n.parentElement instanceof HTMLDivElement) {
            result += '\n'
          }
        }
      })
    }
    apply(this.editor.getNode())
    return content
  }

  getSelectedModelIds = () => {
    return (this.modelsView && this.modelsView.getSelectedModelIds()) || []
  }

  modelsById = {}

  getModels = () => {
    if (!this.models) {
      this.models = this.props.models(() => true, () => {}).concat([TRAIN, REJECT])
      this.modelsById = {}
      this.models.forEach(model => {
        this.modelsById[model.id] = model
      })
    }
    return this.models
  }


  sendChat = async (previous, opts = {}) => {
    await delay(0.1)
    return await this.sendChatImpl(previous, opts)
  }

  sendChatImpl = async (previous, opts = {}) => {
    if (this.state.chatDisabled) return
    if (this.state.uploadingFile) return
    if (this.xhr) {
      //return
    }
    let {replay, resolve, reject, onFirstToken} = opts
    this.sendInfo = opts
    if (this.props.availableCredits === 0) {
      this.setState({
        sendError: "You're out of credits!"
      })
      return
    }
    if (!replay && this.state.judgeChat) {
      let message
      if (this.state.swipeMessage) {
        message = this.state.swipeMessage
      } else {
        message = previous
      }
      return await this.judge(message)
    }
    await this.waitForUploads()
    ////////////////////debugger
    let text = ''
    //////debugger
    if (!replay)  {
      let html = this.editor.getHTML()
      text = turndownService.turndown('<pre>' + html + '</pre>')
    }
    //////////console.log('text', text)
    const model = this.getSelectedModelIds()
    const modelArray = Object.keys(model)
    if (!replay && !this.state.magpie && modelArray.length == 0) {
      await delay(0.5)
      this.modelsView.showPopup()
      return
    }
    if (this.state.magpie || previous || text) {
      let contents = ''
      if (!replay) {
        contents = this.getContent()
      }
      this.state.questionSearchTerm = ''
      let topic = this.getCurrentTopic()
      let isNewTopic = false
      if (topic === 'new-thread') {
        topic = undefined
        isNewTopic = true
      }
      let task = this.state.selectedTask
      if (!task) {
        // this is the user's first task
        if (this.props.createNewTask) {
          ////////debugger
        } else {
          task = this.props.me.createNewTask()
          if (task) {
            this.selectThread(task)
            isNewTopic = true
          }
        }
      }
      const sent = Date.now()
      const models = this.getSelectedModelIds()
      console.log("MODELS", models)
      const attunewise = models.indexOf('attunewise') >= 0
      let msg
      if (previous) {
        msg = previous
        this.invalidateCache(msg)
      } else {
        if (this.props.createNewMessage) {
          msg = this.props.createNewMessage(this, { text, contents, models, attunewise, task: task.id })
        } else {
          msg = this.props.me.createNewMessage({ text, contents, models, attunewise, task: task.id })
        }
        if (!this.state.magpie && !replay) {
          this.received[msg.id] = msg
          this.state.rawMessages.push(msg)
          if (this.state.selectedThread) {
            this.state.searchResultsBusy = true
            this.state.searchResults.push(msg)
          }
        }
      }
      this.forceUpdate()
      let spoken = 0
      let completeText = ''
      const defaultGetMessage = () => { // fix me: refactor
        return this.received[msg.id + ".reply"]
      }
      let getMessage
      if (this.props.getMessage){
        getMessage = () => this.props.getMessage(this)
      } else {
        if (!replay) {
          const reply = this.props.me.createNewReply(msg)
          this.received[reply.id] = reply
          this.streaming = {
            id: reply.id,
            model: reply.model || reply.models[0].model
          }
        } else {
          const reply = this.received[msg.id + '.reply']
          this.streaming = {
            id: reply.id,
            model: reply
          }
        }
        getMessage = defaultGetMessage
      }
      const getReplyMessage = () => {
        const reply = this.wasAnswered(msg)
        return reply
      }
      const flushToSpeaker = async (isFinal) => {
        if (this.recognition && isFinal) {
          const reply = getReplyMessage()
          consoleLog("GOT REPLY", reply)
          this.state.speaking = reply.id
          let lang = this.state.lang.iso
          this.props.me.resetAudioSource()
          this.recognition.stop()
          this.forceUpdate()
          return this.props.me.speak(completeText, lang).then(() => {
            this.state.speaking = null
            this.forceUpdate()
            this.recognition.start()
          })
        }
      }
      this.streamSent = msg.sent
      this.setState({
        sendError: null,
        isStreaming: !(this.state.magpie || replay),
        sending: !(this.state.magpie || replay),
        replay
      })
      this.clearEditor()
      try {
        if (!replay) {
          ////debugger
          this.messages1.scrollToBottom()
        }
        let selectedModels = this.state.selectedModelIds
        if (!previous) {
          const MAX_INLINE_LENGTH = 8192
          if (msg.content.length > MAX_INLINE_LENGTH) {
            const ref = await this.props.me.uploadMessageContent(msg.id, msg.content)
            msg.storage = ref.fullPath
            msg.contentLength = msg.content.length
            msg.content = msg.content.substring(0, MAX_INLINE_LENGTH)
            const newLine = msg.content.lastIndexOf('\n')
            if (newLine > msg.content.length / 2) {
              msg.content = msg.content.substring(0, newLine)
            }
          }
        } else {
          const found = getMessage()
          debugger
          if (found && found.models) {
            found.models.forEach(x => {
              x.content = ''
              delete x.corrected
              x.addedToTranscript = null
            })
            this.invalidateCache(found)
          }
        }
        if (this.state.selectedTask.autoplay) {
          this.state.autoplayInProgress = true
        }
        ////console.log("REPLAY", replay)
        this.forceUpdate()
        let cleared = {}
        this.xhr = await this.props.streamChat(this, msg, {
          autoplay: this.state.selectedTask.autoplay,
          models: selectedModels,
          replay,
          magpie: !!this.state.magpie,
          temperature: this.state.temperature,
          assistantModel: this.state.assistantModel,
          onContent: snip => {
            console.log('onContent', JSON.stringify(snip, null, ' '))
            const found = getMessage()
            //////debugger
            const { choices, model, progress } = snip
            if (this.state.sending) {
              this.state.sending = false
            }
            if (choices) {
              if (!cleared[model]) {
                if (found) {
                  const comp = this.aiSaidComps[found.id]
                  if (comp) {
                    comp.clearModelText(model)
                  } else {
                    debugger
                  }
                  cleared[model] = true
                  this.streaming = {
                    id: found.id,
                    model
                  }
                }
              }
              const choice = choices[0]
              if (choice) {
                //////debugger
                const text = choice.delta.text || choice.delta.content || ''
                if (this.state.magpie) {
                  if (text){
                    content += text
                    this.editor.setText(content.trim())
                    this.forceUpdateLater()
                  }
                  return
                }
                if (!found) {
                  this.state.sending = false
                  ////////debugger
                }
                ////console.log({found, text})
                if (found && text) {
                  if (!this.state.isStreaming) {
                    this.state.isStreaming = true
                    if (onFirstToken) {
                      onFirstToken()
                      onFirstToken = null
                    }
                  }
                  const comp = this.aiSaidComps[found.id]
                  if (comp) {
                    comp.appendModelText(model, text)
                  }
                  flushToSpeaker()
                  this.invalidateCache(found)
                  this.forceUpdateLater()
                } else {
                  ////////////////////debugger
                }
              }
            } else if (progress) {
              const { model, addedToTranscript } = progress
              const comp = this.aiSaidComps[found.id]
              if (comp) {
                comp.appendToTranscript({model, addedToTranscript})
              }
            }
          },
          onDone: () => {
            this.xhr = null
            if (this.state.magpie) {
              const { resolve, reject } = this.state.magpie
              this.state.magpie = null
              resolve()
              return
            }
            const found = getMessage()
            const comp = this.aiSaidComps[found.id]
            //////debugger
            if (comp) {
              comp.resetModelText(model)
            }
            if (resolve) return resolve()
            this.streaming = {}
            this.invalidateCache(found)
            this.state.waitingForModels = {}
            this.state.isStreaming = false
            this.state.sending = false
            this.state.replay = false
            this.forceUpdate(async () => {
              this.messages1.autoScroll = true
              if (this.state.selectedTask &&
                  this.state.selectedTask.autoplay &&
                  this.state.autoplayInProgress) {
                if (this.editor) {
                  this.editor.setText(this.state.selectedTask.autoplay, false)
                  await delay(0.5)
                  this.sendChat()
                }
              }
            })
          },
          onError: (err, status) => {
            console.error(err)
            this.state.isStreaming = false
            this.state.waitingForModels = {}
            this.streaming = {}
            if (this.state.magpie) {
              const { resolve, reject } = this.state.magpie
              this.state.magpie = null
              reject(err)
              return
            }
            if (reject) return reject()
            if (status === 400) {
              ////////debugger
              this.setState({
                sendError: "Oof, sorry that didn't work."
              })
            } else {
              this.setState({
                sendError: 'Server unreachable. Please try again later.'
              })
            }
            this.state.replay = false
            this.state.sending = false
            this.state.isStreaming = false
            this.state.sending = false
            this.forceUpdate(() => {
              this.messages1.autoScroll = true
            })
          }
        })
      } catch (err) {
        console.error(err)
        ////////debugger
        this.setState({
          sendError: "Oof, sorry that didn't work."
        })
        this.state.isStreaming = false
        this.forceUpdate()
      }
    }
    if (isMobile()) {
      this.editor.blur()
    }
  }

  forceUpdateLater = (k) => {
    clearTimeout(this.forceUpdateTimeout)
    this.forceUpdateTimeout = setTimeout(() => {
      this.forceUpdate(k)
    }, 33)
  }

  notEnoughTokens = instruction => {
  }

  getThreads = () => {
    let threads
    if (this.state.searchTerm) {
      threads = this.state.searchResults
    } else {
      threads = Object.values(this.tasks)
      if (this.props.sortTasks) {
        threads = this.props.sortTasks(this, threads, this.state.calendarView)
      } else {
        if (this.state.calendarView !== 'all') {
          threads.sort((x, y) => {
            return y.lastUpdated - x.lastUpdated
          })
        } else {
          threads.sort((x, y) => x.title.localeCompare(y.title))
        }
      }
      let filt
      switch(this.state.calendarView) {
        case 'all':
          {
            //threads = threads.slice(0, this.state.recentLimit)
            break
          }
        case 'recent':
          {
            //threads = threads.slice(0, this.state.recentLimit)
            break
          }
        case 'week':
          {
            const t = startOfWeek(this.state.selectedDay).getTime()
            filt = x => {
              const { lastUpdated } = x
              return startOfWeek(lastUpdated).getTime() === t
            }
          }
          break
        case 'day':
          {
            const t = startOfDay(this.state.selectedDay).getTime()
            filt = x => {
              const { lastUpdated } = x
              return startOfDay(lastUpdated).getTime() === t
            }
          }
          break
        default:
          ////////debugger
      }
      //////console.log("FILT", this.state.calendarView, filt, threads)
      if (filt) {
        const selectedId = this.state.selectedThread && this.state.selectedThread.id
        threads = threads.filter(x => x.id === selectedId || filt(x))
      }
    }
    ////console.log({tasks: threads})
    return threads
  }

  setAutocomplete = ref => {
    this.autocomplete = ref
    if (this.inputRef && ref) {
      ref.setInput(this.inputRef)
    }
  }

  selectThread = (task) => {
    if (task && this.state.selectedTask &&
        this.state.selectedTask.id === task.id) {
      return
    }
    if (this.initTimeout) {
      clearTimeout(this.initTimeout)
      this.init()
    }
    let lastTask = this.state.selectedTask
    if (lastTask) {
      this.deinitSelectedTask()
    }
    const back = () => {
      if (lastTask) {
        if (this.tasks[lastTask.id]) {
          if (!this.props.onCloseTask) {
            this.props.me.updateTaskSummary(lastTask)
          } else {
            this.props.onCloseTask(this, lastTask)
        }
        }
      }
    }
    let subpage
    if (task) {
      const render = () => <ChatPage me={this.props.me} chatGPT={this}/>
      if (isMobile()) {
        this.threads.openSubpage(render)
      } else {
      }
    }
    this.setState({
//      subpage,
      selectedTask: task,
    }, () => {
        if (task) {
          this.initSelectedTask()
        } else {
          back()
        }
    })
  }

  onCreateThreads = threads => {
    this.threads = threads
  }

  selectThreadOld = threadOrTask => {
     if (!threadOrTask) {
       if (this.props.task) {
         this.deinit()
         this.props.back()
         return
       }
     }
    let thread
    let task
    let lastTask = this.state.selectedTask
    task = threadOrTask
    if (task && this.state.selectedTask && this.state.selectedTask.id === task.id) return
    localStorage.setItem('selectedTaskId', task ? task.id : '')
    if (task) {
      let { lastTopic } = task
      if (lastTopic) {
        thread = {
          id: lastTopic.topicId,
          topic: lastTopic.topic,
          lastUpdated: task.lastUpdated
        }
      } else {
        //////////////////debugger
      }
      //this.searchEditor.clear()
      this.setState({
        threadBusy: false,
        selectedTask: task,
        selectedThread: thread
      },() => setTimeout(this.initSelectedTask, 250))
    } else {
      this.setState({
        threadBusy: false,
        selectedTask: null,
        selectedThread: null
      })
      setTimeout(this.deinitSelectedTask, 550)
      if (lastTask) {
        //////debugger
        if (this.tasks[lastTask.id]) {
          if (!this.props.onCloseTask) {
            this.props.me.updateTaskSummary(lastTask)
          } else {
            this.props.onCloseTask(this, lastTask)
          }
        }
      }
    }
  }

  seqNum = 0
  search = async searchTerm => {
    this.state.searchTerm = searchTerm.trim()
    this.forceUpdate()
    this.performSearch()
  }

  parseMessage = message => {
    let text = message.content
    if (typeof text !== 'string') {
      debugger
      message.content = '' + text
      return 
    }
    if (text) {
      if (false) {
        const code = parseCode(text)
        if (code) {
          message.code = code
        } else {
        const table = parseTable(text)
          if (table) {
            message.table = table
          }
        }
      }
    } else {
      message.text = ''
    }
    if (message.models) {
      message.models = message.models.filter(x => {
        if (!x) {
          console.warn("null model in message", message)
          return false
        }
        return true
      })
    }
    this.invalidateCache(message)
  }

  performSearch = async () => {
    const seq = ++this.seqNum
    const searchTerm = this.state.searchTerm
    let topic = this.state.selectedThread ? this.state.selectedThread.id : ''
    if (searchTerm || topic) {
      this.state.searching = true
      if (!this.state.searchTerm) {
        this.state.threadBusy = true
      }
      this.forceUpdate()
      let searchResults
      if (this.state.slide > 0.5) {
        let { results, page, out_of }  = await this.props.searchChatMessages(searchTerm)
        results.forEach(message => {
          this.parseMessage(message)
        })
        searchResults = results
      } else {
        let { results, page, out_of }  = await this.props.searchTasks(searchTerm)
        //////////console.log("SEARCH", seq, this.seqNum, results)
        searchResults = results
      }
      if (seq === this.seqNum) {
        this.setState({
          searching: false,
          threadBusy: false,
          searchResults
        })
      }
    } else {
      this.setState({
        searching: false,
        searchResults: [],
        threadBusy: false
      })
    }
  }

  onKeyDown = e => {
    const RETURN = "Enter";
    if (isDesktop()) {
      if (e.key === RETURN && !e.shiftKey) {
        e.preventDefault()
        this.sendChat()
      }
    }
  }

  selectModel = (model) => {
    //////////console.log('selectModel', model)
    if (this.state.selectedModels[model]) {
      delete this.state.selectedModels[model]
    } else {
      this.state.selectedModels[model] = true
    }
    //////////console.log(this.state.selectedModels)
    //localStorage.setItem('selectedModels', JSON.stringify(this.state.selectedModels))
    this.forceUpdate(this.saveOptionsLater)
  }

  isModelSelected = (model) => {
    return this.state.selectedModels[model]
  }

  renderDiscussionSearchField = () => {
    let busy = this.state.searching && this.state.searchTerm
    let searchTerm = ''
    const clear = () => {
      // fixme!!
      this.searchEditor.clear()
      this.search('')
    }
    const onFocus = () => {
    }
    const onBlur = () => {
    }
    const onInput = () => {
      this.search(this.searchEditor.getText())
    }
    const selectThread = thread => {
      this.selectThread(thread)
    }
    let icon
    let label
    let action
    action = async () => {
      //////debugger
      const defaultAction = () => {
        if (this.state.selectedTask && isMobile()) {
          const task = this.state.selectedTask
          this.selectThread(null)
        } else {
          if (this.props.newTopic) {
            this.props.newTopic(this)
            return
          }
          if (this.props.onNewFile) return
          const task = this.props.createNewTask()
          if (task) {
            if (this.props.onOpenTask) {
              this.props.onOpenTask(this, task)
            }
            this.selectThread(task)
          }
        }
      }
      if (this.props.onBack) {
        this.props.onBack(this, defaultAction)
      } else {
        defaultAction()
      }
    }
    if (this.state.slide < 0.5) {
      label = 'New'
      icon = Hashtag
    } else {
      label = 'Back'
      icon = Left
    }
    const newTopicButton = () => <KeyboardButton className={'newTopicButton'} icon={icon} action={action} label={label}/>
    const selectedThread = this.state.selectedThread
    //////////console.log("slide", this.state.slide)
    let threads = this.getThreads()
    const style = {
      //transform: `translate(calc(${1.0-this.state.slide} * -100%), 0)`,
      //display: this.state.slide === 0 ? 'none': undefined
    }
    let style2 
    const selectedModelIds = this.getSelectedModelIds()
    const selectedModelCount = selectedModelIds.length

    let middleLeft
    if (this.props.getNewButton) {
      const button = this.props.getNewButton(this, action)
      if (button)
        middleLeft = <div className='customNewTopicButton'>
                       {button}
                     </div>
    } else {
      middleLeft = newTopicButton()
    }
    if (this.props.onNewFile && !this.state.selectedTask) {
      const openImage = async (event) => {
        this.newFileChooser.click()
      }
      const handleImage = async (event) => {
        this.handleDataTransfer(event, event.target)
        this.newFileChooser.reset()
      }
      const fileChooser = 
        <div className='fileChooser' onClick={openImage}>
          <input
            ref={this.setNewFileChooser}
            type={"file"}
            accept={this.props.newFiletypes}
            style={{ display: 'none' }} // Hides the file input
            onChange={handleImage}
          />                      
          {newTopicButton}
        </div>
      middleLeft = fileChooser
    }

    let inputEnabled = true
    if (this.props.enableInput) {
      inputEnabled = this.props.enableInput(this)
    }
    let filler = ''

    const openSettings = async () => {
      this.setState({showSettings: !this.state.showSettings})
    }

    const setTemp = ({value}) => {
      ////console.log("setTemp", value)
      this.setState({temperature:value})
    }
    const getTemp = () => this.state.temperature

    const toggleSize = (size) => {
      if (this.state.sizes[size]) {
        delete this.state.sizes[size]
      } else {
        this.state.sizes[size] = true
      }
      this.forceUpdate()
    }
    let menu
    if (this.props.onFile) {
      const openFile = async (event) => {
        this.setState({fileChooserActive: true})
        this.newFileChooser2.click()
      }
      const handleFile = async (event) => {
        this.setState({fileChooserActive: false})
        const { files } = event.target
        const MAX_SIZE = 4 * 1000 * 1000
        for (const file of files) {
          if (true || file.size > MAX_SIZE) {
              this.setState({
                uploadError: 'Your file is too large, it must be <= 4MB' 
              })
            return
          }
        }
        this.handleDataTransfer(event, event.target)
      }
      let fileMenuButton = 'fileMenuButton'
      let icon = OpenFile
      if (false && this.state.fileChooserActive) {
        fileMenuButton += ' fileMenuButtonActive'
        icon = Spin
      }
      menu = <div className='fileMenu' onClick={openFile}>
               <input
                 ref={this.setNewFileChooser2}
                 type={"file"}
                 accept={this.props.fileTypes}
                 style={{ display: 'none' }} // Hides the file input
                 onChange={handleFile}
               />
               <div className={fileMenuButton}>
                 <ReactSVG src={icon}/>
               </div>
        </div>
    }
    let uploadError = this.state.uploadError
    const toggleSmall = () => toggleSize('small')
    const toggleMedium = () => toggleSize('medium')
    const toggleLarge = () => toggleSize('large')
    //<div className='chatBack'><KeyboardButton icon={Left} label={'Back'} action={action}/></div>
    const clearErr = () => {
      this.setState({
        uploadError: ''
      })
    }
    return <div key='discussionSearch' className='discussionSearch'>
             <div className='chatHeader'>
               <div className='chatHeaderFiller'>{filler}</div>
               <div className='inputControlContainer'>
                 <InputControl
                   key={'discussionSearch'}
                   busy={busy}
                   middleLeft={middleLeft}
                   me={this.props.me}
                   placeholder={'Search'}
                   onSetEditor={this.setSearchEditor}
                   downward={true}
                   onClear={clear}
                   onFocus={onFocus}
                   onBlur={onBlur}
                   onInput={onInput}
                   menu={menu}
                 />
               </div>
             </div>
             {uploadError && <ClickAwayListener onClickAway={clearErr}>
                               <div key='uploadErr' className='uploadErrorDialog' onClick={clearErr}>
                                 <SimpleIcon src={Alert}/>{uploadError}
                               </div>
                             </ClickAwayListener>}
           </div>
  }



  setNewFileChooser = ref => { this.newFileChooser = ref }
  setNewFileChooser2 = ref => { this.newFileChooser2 = ref }


  deleteTask = async task => {
    await this.props.deleteTask(this, task)
    delete this.tasks[task.id]
    if (this.state.searchTerm) {
      this.state.searchResults = this.state.searchResults.filter(x => {
        return x.id !== task.id
      })
    }
    this.forceUpdate()
  }

  pullHistoryBefore = async (limit) => {
    return await this.pullHistory(limit, '>')
  }
  
  pullHistoryAfter = async (limit) => {
    return await this.pullHistory(limit, '<')
  }

  pullHistory = async (limit, op) => {
    if (!this.tasks || this.state.searchTerm) return { count: 0, update: (then) => {then} }
    let latest = 0
    let earliest = Date.now()
    const tasks = this.getThreads()
    if (tasks.length > 0) {
      latest = tasks[0].lastUpdated
      earliest = tasks[tasks.length-1].lastUpdated
    }
    let lastUpdated = op === '<' ? earliest : latest
    const getThreadsHistory = (this.props.getThreadsHistory &&
                               ((lastUpdated, limit) => this.props.getThreadsHistory(this, lastUpdated, op, limit))
                              ) ||
          this.props.me.getThreadsHistory
    const results = await getThreadsHistory(lastUpdated, op, limit) 
    for (const task of results) {
      this.tasks[task.id] = task
    }
    const count = results.length
    debugger
    return {
      count,
      update: (then) => this.forceUpdate2(then)
    }
  }

  forceUpdate2 = then => {
    this.forceUpdate(() => {
      this.forceUpdate(then)
    })
  }

  threadsHistBusy = false
  getThreadsHistory = async () => {
    //////console.log("get threads history")
    if (!this.tasks) return
    if (this.threadsHistBusy) return
    this.threadsHistBusy = true
    let lastUpdated = Date.now()
    for (const id in this.tasks) {
      const task = this.tasks[id]
      lastUpdated = Math.min(task.lastUpdated, lastUpdated)
    }
    const getThreadsHistory = (this.props.getThreadsHistory &&
                               ((lastUpdated, limit) => this.props.getThreadsHistory(this, lastUpdated, limit))
                              ) ||
          this.props.me.getThreadsHistory
    const results = await getThreadsHistory(lastUpdated, 15) 
    for (const task of results) {
      this.tasks[task.id] = task
    }
    this.threadsHistBusy = false
    this.forceUpdate()
  }

  toggleTextFieldSpeechInput = async () => {
    const textFieldSpeechInputActive = !this.state.textFieldSpeechInputActive
    if (this.state.voiceRecognitionActive) {
      this.toggleVoiceRecognition()
    }
    this.setState({
      textFieldSpeechInputActive,
      instructionSpeechInputActive: false
    }, () => {
      if (textFieldSpeechInputActive) {
        this.toggleVoiceRecognition()
      }
    })
  }
  
  toggleVoiceRecognition = async e => {
    if (e) e.preventDefault()
    ////////////////////////debugger
    this.state.voiceRecognitionActive = !this.state.voiceRecognitionActive
    if (this.recognition) {
      this.recognition.stop()
      this.recognition = null
    }
    if (!this.state.voiceRecognitionActive) {
      this.setState({
        instruction: null
      })
      if (this.sub3) {
        this.sub3.unsubscribe()
        this.sub3 = null
      }
      if (this.sub4) {
        this.sub4.unsubscribe()
        this.sub4 = null
      }
    } else {
      this.recognition = this.props.me.getVoiceRecognizer()
      this.sub3 = this.recognition.observeIsActive().subscribe(isActive => {
        if (this.state.voiceRecognitionActive !== isActive) {
          //////////console.log("isActive", isActive)
          this.state.voiceRecognitionActive = isActive
          this.forceUpdate()
        }
      })
      this.sub4 = this.recognition.observeInstruction().subscribe(instruction => {
        //////////console.log("instruction", instruction)
        this.receiveVoiceInput(instruction)
      })
      this.updateLang()
      this.recognition.start()
    }
    this.forceUpdate(this.updateLang)
  }

  selectTextInputLang = lang => {
    localStorage.setItem('keyboard.text.lang', JSON.stringify(lang))
    this.setState({
      lang
    }, this.updateLang)
  }
  
  updateLang = () => {
    if (this.state.instructionSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.instructionLang.iso)
      }
    }
    else if (this.state.textFieldSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.lang.iso)
      }
    } else {

    }
  }

  speakText = async (cancel) => {
    if (cancel) {
      this.props.me.cancelSpeak()
    } else {
      const text = this.editor.getText()
      this.props.me.resetAudioSource()
      await this.props.me.speak(text, this.state.lang.iso)
    }
  }

  receiveVoiceInput = async input => {
    consoleLog("voice input", input)
    if (this.state.instructionSpeechInputActive) {
      const { instruction } = this.state.nextInstruction
      const editor = this.instructionEditor
      editor.insertTextAtCaret(input)
      const text = editor.getText()
      instruction.advance(text)
      const { isComplete, corrected } = await this.props.me.autocorrect({input: text, lang: this.state.instructionLang.iso})
      if (corrected && text != corrected) {
        instruction.undo()
        instruction.advance(corrected)
        editor.setText(corrected)
      }
    } else if (this.state.textFieldSpeechInputActive) {
      this.editor.insertTextAtCaret(input)
      const text = this.editor.getText()
      const data  = await this.props.me.autocorrect({input: text, lang: this.state.lang.iso})
      const { isComplete, corrected } = data
      if (corrected && text != corrected) {
        this.editor.setText(corrected)
      }
      if (isComplete && !this.state.isStreaming) {
        this.sendChat()
      }
    } else {
      console.error("voice input fail")
    }
    this.forceUpdate()
  }

  factCheck = async (id) => {
    return await this.props.me.factCheck(id)
  }

  uploadFile = async file => {
    this.state.uploadingFile = true
    const upload = {
      file: file,
      progress: 0,
      blobUrl: URL.createObjectURL(file)
    }
    const progress = percent => {
      upload.progress = percent;
      this.forceUpdate();
    }
    this.state.uploads.push(upload)
    this.forceUpdate()
    let setImage
    if (file.type && file.type.startsWith("image/")) {
      const url = URL.createObjectURL(file);
      setImage = this.editor.insertImage(url)
      if (isDesktop()) {
        this.editor.focus()
      }
    }
    const name = file.name.toLowerCase();
    //////////////debugger
    if (name.endsWith(".mov")) {
      try {
        file = new File(file, name.replace(".mov", ".mp4"));
      } catch (err) {
        this.props.me.nativeLog(err)
      }
    }
    try {
      const ref = await this.props.uploadFile(file, progress, false)
      debugger
      if (setImage) {
        setImage(await ref.getDownloadURL())
        this.state.uploadingFile = false
        this.forceUpdate()
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.setState({
        uploadingFile: false,
        uploads: this.state.uploads.filter(x => x.file != file),
      })
    }
  }

  handleDataTransfer = (event, transfer)=> {
    if (transfer.files.length > 0) {
      event.preventDefault();
      for (const file of transfer.files) {
        this.uploadFile(file);
      }
      return true;
    }
    if (false) {
      let plainText = e.clipboardData.getData('text/plain')
      if (!plainText) {
        const text = e.clipboardData.getData('text/html')
        plainText = makeTextPlain(text)
      }
      if (plainText.length > 100 * 1000) {
        event.preventDefault()
        this.props.me.uploadMessageContent(plainText)
        return true
      }
    }
    return false;
  }
  
  onPaste = e => {
    if (this.handleDataTransfer(e, e.clipboardData)) {
      return
    }
  }
  
  onUpdate = e => {
  }

  onDrop = e => {
    const transfer = e.dataTransfer;
    this.handleDataTransfer(e, transfer);
  }

}

