import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindCallback, of, concat, from, Subject, merge as mergeN, combineLatest } from 'rxjs'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import{ isMobile, isDesktop } from '../../classes/Platform.js'
import { BnLabel1, BnLabel2 } from '../Label'
import { UComponent, BnPage, BnSubpage } from '../Page'
import { getComponents, pasteText, ModelConfig, RadioButtons, GearButton, DeleteButton, FileChooser, SearchField, PlayButton, SimpleIcon, SimpleButton, toThen, fromNow, Thread, ChatGPT } from '../ChatGPT'
import { Model, ModelIcon, ModelLabel, ModelPrice, ModelsView } from '../ChatGPT/ModelsMenu.js'
import { Slider } from '../ChatGPT/Slider.js'
import { Markdown } from '../ChatGPT/Markdown.js'
import Update from '../../assets/Icons/Update.svg'
import Alert from '../../assets/Icons/Alert.svg'
import Spin from '../../assets/Icons/Spin.svg'
import File from '../../assets/Icons/File.svg'
import HF from '../../assets/Icons/Platforms/HuggingFace.svg'
import EditIcon from '../../assets/Icons/Edit.svg'
import Send from '../../assets/Icons/Send.svg'
import Share from '../../assets/Icons/Share.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Cross from '../../assets/Icons/Cross.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Cut from '../../assets/Icons/Share.svg'
import Paste from '../../assets/Icons/Paste.svg'
import OpenFile from '../../assets/Icons/OpenFile.svg'
import NewFolder from '../../assets/Icons/NewFolder.svg'
import Folder from '../../assets/Icons/Folder.svg'
import HuggingFace from '../../assets/Icons/Platforms/HuggingFace.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Question from '../../assets/Icons/Question.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import ClickAwayListener from 'react-click-away-listener'
import { Calendar } from './Usage.js'
import { scrollOnKeyDown, clone, hash, formatTokens, capitalize, delay, startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, endOfDay} from '../../classes/Util.js'
import { getMetrics, storeMetrics } from '../../classes/Metrics.js'
import {InMemorySearch} from '../../classes/InMemorySearch'
import { SaveAs } from 'file-saver'
import { EditMenu } from './EditMenu.js'
import { ActionMenu } from './ActionMenu.js'
import moment from 'moment'
import momentDuration from 'moment-duration-format'
import { LossGraph } from './LossGraph.js'
import './Datasets.css'

export const HuggingFaceDataset = props => {
  const { dataset } = props
  const [provider, label] =  dataset.id.split('/')
  return <div className='huggingFaceDataset'>
           <div className='huggingFaceDatasetRight'>
             <div className='datasetProvider'>{provider}</div>
             <div className='datasetLabel'>{label}</div>
           </div>
         </div>
}

const ImportedDataset = props => {
  const { dataset, importDataset, deleteDataset, isImported } = props
  const date = fromNow(Date.parse(dataset.lastModified))
  let button
  if (isImported) {
    button = <DeleteButton icon={Cross} trash={deleteDataset}/>
  } else {
    button = <SimpleButton icon={Right} action={importDataset}/>
  }
  let size = dataset.tags.find(x => x.startsWith("size_categories:"))
  let exampleCount
  if (size) {
    const [key, value] = size.split(':')
    exampleCount = value
  }
  return <div className='importedModel'>
           <div className='importedDatasetTopRow'>
             <div className='huggingFaceDatasetLeft'>
               <SimpleIcon src={HF}/>
             </div>
             <HuggingFaceDataset dataset={dataset}/>
             <div className='importedDatsetTopRowRight'>
               <div className='importedDatasetExampleCount'>{exampleCount}</div>
               {button}
             </div>
           </div>
         </div>
}

export class HuggingFaceDatasets extends BnSubpage {

  constructor(props) {
    super(props)
    this.state.samples = 10
  }
    

  onSearch = async searchTerm => {
    this.state.busy = true
    this.state.searchTerm = searchTerm
    this.forceUpdate()
    const response = await this.props.me.listDatasetsToImport('hf', searchTerm)
    if (searchTerm !== this.state.searchTerm) return
    this.state.searchResults = response.searchResults
    this.state.busy = false
    this.forceUpdate()
  }

  refresh = () => {
    this.onSearch(this.state.searchTerm)
  }

  datasets = {}

  importDataset = dataset => {
    return this.props.importDataset({dataset, numSamples: Math.round(this.state.samples)})
  }
  
  componentDidMount() {
    this.onSearch('')
  }
  
  renderContent() {
    const seen = {}
    const datasets = Object.values(this.datasets).concat(this.state.searchResults || []).filter(x => {
      if (!seen[x.id]) {
        seen[x.id] = true
        return true
      }
    })
    const onChangeSamples = samples => {
      this.setState({
        samples
      })
    }
    const renderedDatasets = datasets.map(dataset => {
        const deleteDataset = () => this.deleteDataset(dataset)
        const importDataset = () => this.importDataset(dataset)
        return <ImportedDataset
                 key={dataset.id}
                 dataset={dataset}
                 deleteDataset={deleteDataset}
                 importDataset={importDataset}
                 isImported={this.datasets[dataset.id]} />
    })
    return <div className='providerView'>
             <div className='providerViewTopLine'>
               <SimpleButton icon={this.state.busy ? Spin : Update} action={this.refresh}/>
               <SearchField placeholder="Search Datasets" onSearch={this.onSearch} me={this.props.me}/>
             </div>
             <div className='providerViewSamples'>
               <Slider label='Samples' onChange={onChangeSamples} value={this.state.samples} bounds={[1, 500]} />
             </div>
             <div className='providerViewModels'>
               {
                 renderedDatasets
               }
             </div>
           </div>
  }
}


const getContent = x => {
  if (x.messages.length > 0) {
    if (typeof x.messages[0].content  === 'string') {
      return x.messages[0].content 
    } else {
      //debugger
      return ''
    }
  }
  return "New Example"
}

export const getFineTuningJobInfo = (model, fineTuningJob) => {
  const { job } = fineTuningJob
  let trainedTokens = 0
  let error
  let dur
  let message
  let price
  let status
  let eta
  switch (fineTuningJob.platform) {
    case 'openai':
      {
        status = capitalize(job.status.split('_').join(' '))
        trainedTokens += job.trained_tokens
        if (model) {
          if (model.finetune) {
            price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
          } else {
          }
        } else {
          message = (job.error.code || '').split('_').join(' ') 
        }
        dur = (job.finished_at ? job.finished_at * 1000 : Date.now()) - job.created_at * 1000
        dur = moment.duration(dur).format("hh:mm:ss")
        if (!job.finished_at && job.estimated_finish && job.estimated_finish * 1000 > Date.now()) {
          eta = toThen(job.estimated_finish * 1000)
        }
        break
      }
      case 'gemini':
        {
          const hasError = job.state === "JOB_STATE_FAILED"
          if (typeof job.state !== 'string') {
            //debugger
          }
          if (hasError) {
            message = job.error.message
          } 
          status = capitalize(job.state.split('_')[2].toLowerCase())
          const { tuningDataStats, supervisedTuningSpec, createTime, endTime } = job
          dur = (endTime ? Date.parse(endTime) : Date.now()) - Date.parse(createTime)
          dur = moment.duration(dur).format("hh:mm:ss")
          if (tuningDataStats) {
            const { hyperParameters } = supervisedTuningSpec
            const { learningRateMultiplier } = hyperParameters
            if (learningRateMultiplier && fineTuningJob.learningRateBoost) {
              learningRate = 'boost '+Math.round(fineTuningJob.learningRateBoost)
            }
            const { supervisedTuningDataStats } = tuningDataStats
            const { totalBillableTokenCount } =  supervisedTuningDataStats
            trainedTokens = totalBillableTokenCount
          }
          if (model) {
            price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
          } else {
            ////debugger
          }
          break
        }
      case 'mistral':
        status = capitalize(job.status.split('_').join(' ').toLowerCase())
        let tokens = job.metadata.train_tokens || job.trainedTokens
        if (tokens) {
          trainedTokens += tokens
        }
        if (job.metadata.cost) {
          price = '€' +job.metadata.cost
        }
        if (job.events) { 
         const [event] = job.events
          if (event.data.error) {
            hasError = true
            message = event.data.error
          }
        }
        break
      case 'fireworksAI':
        {
          let state = typeof(job.state) === 'number' ? "PENDING" : job.state
          if (!state) {
            state = 'Failed'
          }
          if (typeof(state) !== 'string') {
            state = '' + state
          }
          status = capitalize(state.toLowerCase())
          trainedTokens = fineTuningJob.trainedTokens
          price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
        }
        break
  }
  return { price, status, trainedTokens, message, eta }
}


class TwoWaySliderButton extends Component {
  render() {
    const { icon, action} = this.props
    return <div className='twoWaySliderButton'>
             <SimpleButton
               autoRepeat
               icon={icon}
               action={action}/>
           </div>
  }
}

class TwoWaySlider extends Component {

  render() {
    const { lowerLabel, upperLabel, onChange, value } = this.props
    const v1 = Math.floor(value * 100)
    const v2 = 100 - v1
    console.log("value", value, {v1, v2})
    const start = async () => {
      onChange(1)
    }
    const end = async () => {
      onChange(0)
    }
    const up = async () => {
      const {value} = this.props
      const v1 = Math.floor(value * 100)
      const v2 = 100 - v1
      if (v2 < 100) {
        const dec = Math.max((v1 - 1)/100, 0)
        console.log("up", v1, v2, 'dec', dec)
        onChange(dec)
      }
    }
    const down = async () => {
      const {value} = this.props
      const v1 = Math.floor(value * 100)
      const v2 = 100 - v1
      if (v1 < 100) {
        const inc = Math.min((v1 + 1)/100, 1)
        console.log("down", v1, v2, 'inc', inc)
        onChange(inc)
      }
    }
    const num = v => {
      return <div className='twoWaySliderPct'>{v}</div>
    }
    const bg = pct => {
      const result = '#3b65c9' + ((pct/100)*255).toString(16).padStart(2, '0')
      console.log('bg', pct, result)
      return result
    }
    const leftStyle = {
      background: bg(v1)
    }
    const rightStyle = {
      background: bg(v2)
    }
    return <div className='twoWaySlider'>
             <div className='twoWaySliderLabel' onClick={start} style={leftStyle}>
               {lowerLabel}
             </div>
             <div className='twoWaySliderSlider'>
               <TwoWaySliderButton key='lo' icon={Left} action={down}/>
               <div className="twoWaySliderValue">
                 {num(v1)}/{num(v2)}%
               </div>
               <TwoWaySliderButton key='hi' icon={Right} action={up}/>
             </div>
             <div className='twoWaySliderLabel' onClick={end} style={rightStyle}>
               {upperLabel}
             </div>
           </div>
  }
}


class FineTuningJobLauncher extends Component {
  constructor (props) {
    super(props)
    this.state = {
      files: [],
      isOpen: {}
    }
  }

  fileStatus = {}
  
  componentDidMount() {
    this.props.me.getDatasetFiles(this.props.dataset).then(files => {
      let defaultValue = files.length === 1 ? 0.85 : 1
      files.forEach(file => {
        this.fileStatus[file.id] = defaultValue
      })
      this.setState({
        files
      })
    })
  }

  render() {
    const getFileStatus = file => {
      return this.fileStatus[file.id]
    }
    const setFileStatus = (file, status) => {
      this.fileStatus[file.id] = status
      this.forceUpdate()
    }
    const dataset = this.props.dataset
    let action = () => this.props.action(this.fileStatus)
    const buttons = <SimpleButton key='fine-tune' label='Fine-tune' icon={Send} action={action}/>
    const hidden = { display: 'none' }
    let style1
    if (this.state.files.length <= 1) {
      style1 = { display: 'none' }
    }
    return <div className='trainingInputs'>
             <div className='trainingFileLabel' style={style1}>
               <SimpleIcon src={Folder}/>
               <div className='trainingFileName'>
                 {capitalize(dataset.name)}
               </div>
             </div>
             <div className='trainingFiles'>
               {
                 this.state.files.map(file => {
                   const onChange = value => {
                     console.log("onChange", file.name, getFileStatus(file), '=>', value)
                     setFileStatus(file, value)
                   }
                   const toggleConfig = async () => {
                     this.state.isOpen[file.id] = !this.state.isOpen[file.id]
                     this.forceUpdate()
                   }
                   const value = getFileStatus(file)
                   const isOpen = this.state.isOpen[file.id]
                   let style
                   if (!isOpen) {
                     style = hidden
                   }
                   let icon
                   let legacyIconSize
                   if (file.isHuggingFace) {
                     icon = HuggingFace
                   } else {
                     icon = File
                     legacyIconSize = true
                   }
                   return <div key={file.id} className='trainingFile'>
                            <div className='trainingFileTopRow'>
                              <div key='label' className='trainingFileLabel'>
                                <SimpleIcon src={icon} legacyIconSize={legacyIconSize}/>
                                <div key='name' className='trainingFileName'>
                                  {capitalize(file.name)}
                                </div>
                              </div>
                              <div className='trainingFileConfig'>
                                <GearButton action={toggleConfig}/>
                              </div>
                            </div>
                            <div key='status' className='trainingFileStatus' style={style}>
                              <TwoWaySlider key='trainingSplit' lowerLabel="Train" upperLabel="Validate" value={value} onChange={onChange}/>
                            </div>
                          </div>
                 })
               }
             </div>
             <div className='model-popup-buttons'>
               {buttons}
             </div>
           </div>
  }
}

export class FineTuneConfig extends Component {

  constructor(props) {
    super(props)
    this.state = {
      epochs: 1,
      batchSize: 1,
      learningRateBoost: 0,
    }
  }

  componentDidMount() {
    this.props.onCreate(this)
  }

  getOpts = () => {
    const opts = {}
    for (const opt in this.state) {
      opts[opt] = Math.round(this.state[opt])
    }
    return opts
  }

  setEpochs = (epochs) => {
    this.setState({epochs})
  }

  getEpochs = () => {
    return this.state.epochs
  }
  
  setBatchSize = (batchSize) => {
    this.setState({
      batchSize
    })
  }

  getBatchSize = () => {
    return this.state.batchSize
  }

  setLearningRate = (learningRate) => {
    this.setState({
      learningRateBoost: learningRate
    })
  }

  getLearningRate = () => this.state.learningRateBoost

  renderFineTuneConfig() {
    return <div className='modelConfig'>
             <div className='tempSlider'><Slider label='Epochs' onChange={this.setEpochs} value={this.getEpochs()} bounds={[1, 99]}/></div>
             <div className='tempSlider'><Slider label='Batch' onChange={this.setBatchSize} value={this.getBatchSize()} bounds={[1, 32]}/></div>
             <div className='tempSlider'><Slider label='Boost' onChange={this.setLearningRate} value={this.getLearningRate()} bounds={[-10, 10]}/></div>
           </div>
  }

  render() {
    return this.renderFineTuneConfig()
  }
}

function toGradient(dataArray) {
  // Interpolate between two colors (low and high) based on a value between 0 and 1
  function interpolateColor(lowColor, highColor, value) {
    const r = Math.round(lowColor[0] + (highColor[0] - lowColor[0]) * value);
    const g = Math.round(lowColor[1] + (highColor[1] - lowColor[1]) * value);
    const b = Math.round(lowColor[2] + (highColor[2] - lowColor[2]) * value);
    return `rgb(${r}, ${g}, ${b})`;
  }

  // Define the low and high colors
  const lowColor = [95, 18, 10];
  const highColor = [0, 102, 65];

  // Generate gradient stops based on steps and `train_mean_token_accuracy` values
  const stops = dataArray.map(item => {
    const position = ((item.step - Math.min(...dataArray.map(i => i.step))) / 
                      (Math.max(...dataArray.map(i => i.step)) - Math.min(...dataArray.map(i => i.step)))) * 100;
    const color = interpolateColor(lowColor, highColor, item.train_mean_token_accuracy); // Use train_mean_token_accuracy to get the color
    return `${color} ${position}%`;
  });

  // Create the linear gradient string
  const gradient = `linear-gradient(to right, ${stops.join(', ')})`;
  return gradient;
}

const formatPrice = price => price.toFixed(2)

const copySubject = new Subject()
let clipboardDataset

const observeClipboard = () => {
  if (clipboardDataset) {
    return concat(of(clipboardDataset), copySubject)
  }
  return copySubject
}

export const copyDataset = async (dataset) => {
  await delay(0.3)
  copySubject.next(clipboardDataset = dataset)
}


class DatasetFineTuningJob extends Component {

  constructor (props) {
    super(props)
    this.state = {
      lastEventTimestamp: 0
    }
  }

  pollJob = async () => {
    if (this.inProgress) {
      return 
    }
    this.inProgress = true
    clearTimeout(this.pollTimeout)
    const { job, checkpoints, platform, outputModel, id } = this.props.fineTuningJob
    switch (platform) {
      case 'fireworksAI':
        {
          switch (job.status) {
            case 'FAILED':
            case 'COMPLETED':
              return
          }
        }
        break
      case 'openai':
        {
          switch (job.status) {
            case 'failed':
            case 'cancelled':
              return
            case 'running':
              if (this.state.lastEventTimestamp === 0) {
                const cached = await getMetrics(id)
                if (cached) {
                  this.state.metrics = cached
                  this.forceUpdate()
                  this.lastEventTimestamp = cached[cached.length-1].created_at * 1000
                  this.metricsSubject.next(cached)
                }
              }
          }
        }
        break
      case 'gemini':
        {
          switch (job.state) {
            case 'JOB_STATE_FAILED':
            case 'JOB_STATE_CANCELLED':
              return
          }
        }
        break
      case 'mistral':
        {
          switch (job.status) {
            case 'FAILED':
            case 'FAILED_VALIDATION':
              return
          }
        }
    }
    if (outputModel) {
      return
    }
    const { events } = await this.props.me.pollDatasetFineTuningJob(this.props.fineTuningJob.id,
                                                                    {
                                                                      after: this.state.lastEventTimestamp
                                                                    }
                                                                   )
    if (this.unmounted) {
      return
    }
    if (events && events.length > 0) {
      this.state.lastEventTimestamp = events[0].created_at * 1000
      let metrics = this.state.metrics || []
      events.reverse()
      for (const event of events) {
        if (event.type === 'metrics') {
          metrics = metrics.concat(event.data)
        }
      }
      this.state.metrics = metrics
      this.forceUpdate()
      //debugger
      this.metricsSubject.next(metrics)
    }
    this.inProgress = false
    this.forceUpdate()
    this.pollTimeout = setTimeout(this.pollJob, 45000)
  }

  observeMetrics = () => {
    const { platform, job } = this.props.fineTuningJob
    if (platform === 'openai' && job.status === 'running' && !this.props.preview) {
      return concat(of(this.state.metrics|| []), this.metricsSubject)
    }
    return from([])
  }

  metricsSubject = new Subject()

  continueJob = async (files) => {
    const opts = this.modelConfig.getOpts()
    const { epochs, batchSize, learningRate } = opts
    let model = this.props.fineTuningJob.model
    if (this.props.fineTuningJob.platform == 'openai' && this.props.fineTuningJob.outputModel) {
      model = this.props.fineTuningJob.outputModel
    }
    await this.props.me.createFineTuningJob({
      dataset: this.props.dataset.id,
      datasetSplits: files,
      previous: this.props.fineTuningJob.id,
      models: [{
        epochs,
        batchSize,
        learningRateBoost: learningRate,
        model,
      }]
    })
  }

  deleteJob = async () => {
    await this.props.me.deleteDatasetFineTuningJob(this.props.fineTuningJob.id)
  }

  cancelJob = async () => {
    await this.props.me.cancelDatasetFineTuningJob(this.props.fineTuningJob.id)
  }

  setRef = ref => {
    this.ref = ref
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      //debugger
      this.props.select(this)
      this.ref.scrollIntoView({behavior: 'smooth', block: 'nearest'})
    }
  }

  setModelConfig = ref => {
    this.modelConfig = ref
    let { dataset, fineTuningJob } = this.props
    const { learningRateBoost, epochs, batchSize } = fineTuningJob
    this.modelConfig.setLearningRate(isNaN(learningRateBoost) ? 0 : learningRateBoost)
    this.modelConfig.setEpochs(epochs)
    this.modelConfig.setBatchSize(batchSize)
  }

  componentDidMount() {
    setTimeout(this.pollJob, 500)
    if (this.props.selected) {
      this.props.select(this)
    }
  }

  componentWillUnmount() {
    this.unmounted = true
    clearTimeout(this.pollTimeout)
  }

  
  render() {
    let { dataset, fineTuningJob } = this.props
    let {platform, outputModel, model, events, checkpoints, id, learningRateBoost, batchSize, epochs, seed, job, lastUpdated } = fineTuningJob
    epochs = Math.round(epochs) // hack
    console.log({fineTuningJob, model})
    if (outputModel) {
      model = this.props.resolveModel(outputModel) 
    } else {
      model = this.props.resolveModel(model)
    }
    let icon
    let legacyIconSize
    let canCancel = true
    let canContinue = false
    let isDone
    let Continue = "Continue"
    let jobStatus
    let learningRate
    switch (platform) {
      case 'gemini':
        {
          canContinue = false
          Continue = "Retry"
          switch (job.state) {
            case 'JOB_STATE_FAILED':
            case 'JOB_STATE_CANCELLED':
              icon = Alert
              canCancel = false
              isDone = true
              jobStatus = 'failed'
              canContinue = true
              break
            case 'JOB_STATE_SUCCEEDED':
              console.log('resolvedModel', outputModel, model)
              icon = File
              legacyIconSize=true
              canCancel = false
              isDone = true
              jobStatus = 'succeeded'
              canContinue = true
              break
            default:
              icon = Spin
          }
        }
        break
      case 'fireworksAI':
        {
          canContinue = false
          switch (job.state) {
            case undefined:
            case 'FAILED':
              icon = Alert
              canCancel = false
              isDone = true
              jobStatus = 'failed'
              break
            case 'COMPLETED':
              console.log('resolvedModel', outputModel, model)
              icon = File
              legacyIconSize=true
              canCancel = false
              isDone = true
              jobStatus = 'succeeded'
              break
            default:
              icon = Spin
          }
        }
        break
      case 'mistral':
        {
          canContinue = false
          const { hyperparameters } = job
          const { epochs, learning_rate, training_steps } = hyperparameters
          learningRate = learning_rate
          switch (job.status) {
            case 'FAILED':
            case 'FAILED_VALIDATION':
            case 'CANCELLED':
              icon = Alert
              canCancel = false
              isDone = true
              jobStatus = 'failed'
              break
            case 'SUCCESS':
              console.log('resolvedModel', outputModel, model)
              icon = File
              legacyIconSize=true
              canCancel = false
              isDone = true
              jobStatus = 'succeeded'
              canContinue = true
              Continue = 'Retry'
              break
            default:
              icon = Spin
          }
        }
        break
      case 'openai':
        jobStatus = job.status
        let totalEpochs = 0
        let m = this.props.resolveModel(outputModel)
        while (m) {
          if (m.job) {
            const hyperparameters = m.job.hyperparameters ||
                  (m.job.method && m.job.method.dpo &&  m.job.method.dpo.hyperparameters) ||
                  (m.job.method && m.job.method.supervised &&  m.job.method.supervised.hyperparameters)
            if (!hyperparameters) {
              debugger
            } else {
              totalEpochs += hyperparameters.n_epochs
            }
          }
          m = this.props.resolveModel(m.baseModelId)
        }
        if (totalEpochs) {
          epochs = totalEpochs
        }
        switch (job.status) {
          case 'failed':
          case 'cancelled':
            icon = Alert
            canCancel = false
            isDone = true
            canContinue = true
            Continue = 'Retry'
            break
          case 'succeeded':
            icon = File
            legacyIconSize=true
            canCancel = false
            canContinue = true
            isDone = true
            break
          default:
            icon = Spin
        }
        break
    }
    let className = 'fineTunedModel' + " fineTunedModel-"+jobStatus
    let trainedTokens = 0
    let hasError
    let error
    let message
    let price
    const date = fromNow(lastUpdated)
    let style
    let checkpointsDiv
    let status
    let dur
    let eta
    switch (fineTuningJob.platform) {
      case 'openai':
        {
          if (job.learning_rate_muliplier) {
            learningRate = 'x '+job.learning_rate_multiplier
          }
          hasError = Object.values(job.error).length > 0
          status = capitalize(job.status.split('_').join(' '))
          trainedTokens += job.trained_tokens
          if (model) {
            if (model.finetune) {
              price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
            } else {
              //debugger
            }
          } else {
            error = (job.error.code || '').split('_').join(' ') 
          }
          dur = (job.finished_at ? job.finished_at * 1000 : Date.now()) - job.created_at * 1000
          dur = moment.duration(dur).format("hh:mm:ss")
          if (dur.indexOf(":") < 0) {
            dur = null
          }
          if (checkpoints) {
            const background = toGradient(checkpoints.map(chkpt => chkpt.metrics))
            style = {
              background
            }
            checkpointsDiv = <div className='checkpoints'>
                               {
                                 checkpoints.map(chkpt => {
                                   return <div className='checkpoint'>
                                            {Math.round(chkpt.metrics.train_mean_token_accuracy*100)}%  
                                          </div>
                                 })
                               }
                             </div>
            
          }
          
          if (!job.finished_at && job.estimated_finish && job.estimated_finish * 1000 > Date.now()) {
            eta = toThen(job.estimated_finish * 1000)
          }
          break
        }
      case 'gemini':
        {
          hasError = job.state === "JOB_STATE_FAILED"
          if (typeof job.state !== 'string') {
            //debugger
          }
          if (hasError) {
            message = job.error.message
          } 
          status = capitalize(job.state.split('_')[2].toLowerCase())
          const { tuningDataStats, supervisedTuningSpec, createTime, endTime } = job
          if (endTime) {
            dur = (endTime ? Date.parse(endTime) : Date.now()) - Date.parse(createTime)
            dur = moment.duration(dur, 'milliseconds').format("hh:mm:ss")
            if (dur.indexOf(":") < 0) {
              dur = null
            }
          }
          if (tuningDataStats) {
            const { hyperParameters } = supervisedTuningSpec
            const { learningRateMultiplier } = hyperParameters
            if (learningRateMultiplier && fineTuningJob.learningRateBoost) {
              learningRate = 'boost '+Math.round(fineTuningJob.learningRateBoost)
            }
            const { supervisedTuningDataStats } = tuningDataStats
            const { totalBillableTokenCount } =  supervisedTuningDataStats
            trainedTokens = totalBillableTokenCount
          }
          if (model) {
            price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
          } else {

          }
          break
        }
      case 'mistral':
        status = capitalize(job.status.split('_').join(' ').toLowerCase())
        let tokens = job.metadata.train_tokens || job.trainedTokens
        if (tokens) {
          trainedTokens += tokens
        }
        if (job.metadata.cost) {
          price = '€' +job.metadata.cost
        }
        if (job.events) {
          const [event] = job.events
          if (event.data.error) {
            hasError = true
            message = event.data.error
          }
        }
        break
      case 'fireworksAI':
        {
          let state = typeof(job.state) === 'number' ? "PENDING" : job.state
          if (!state) {
            state = "Failed"
          }
          state = '' + state
          status = capitalize(state.toLowerCase())
          trainedTokens = fineTuningJob.trainedTokens
          price = "$" + formatPrice(trainedTokens/(1000*1000) * model.finetune.price.training.price)
        }
        break
    }
    let event
    if (events) {
      event = events[0]
    }
    const toggleConfig = () => {
      this.setState({showConfig: !this.state.showConfig})
    }
    let modelConfigStyle
    if (!this.state.showConfig) {
      modelConfigStyle = { display: 'none'}
    }
    console.log({eta})
    let actions = []
    let copyModel
    if (fineTuningJob.outputModel) {
      copyModel = async () => {
        try {
          navigator.clipboard.writeText(fineTuningJob.outputModel)
        } catch (err) {
          console.error(err)
          //console.log(text)
        }
        await delay(0.3)
      }
    }
    if (copyModel) {
      actions.push({
        label: "Copy",
        icon: Copy,
        action: copyModel
      })
    }
    actions.push({
      button: close => <DeleteButton
                         label="Delete"
                         trash={async () => {
                           await this.deleteJob()
                           close()
                         }}/>
    })
    if (this.props.selected) {
      className += ' fineTuningJobSelected'
    }
    const onClick = () => {
      this.props.select(this)
      this.pollJob()
    }
    return <div key={job.id} className={className} style={style} ref={this.setRef} onClick={onClick}>
             <div className='datasetLeft'>
               <div className='datasetLeftIcon'>
                 <SimpleIcon legacyIconSize={legacyIconSize} src={icon}/>
               </div>
             </div>
             <div className='projectMiddle'>
               <div className='projectMiddleTop'>
                 <div className='projectTitle'>
                   <div className='projectName'>{dataset.name}</div>
                   <div className='projectDate'>{eta || date}</div>
                 </div>
                 {model && <div key='model' className='projectModelRow'>
                             <ModelIcon model={model}/>
                             <ModelLabel model={model}/>
                           </div>}
                 {message && <div key='msg' className='projectErrInfo'>{message}</div>}
                 {event && <div key='event' className='projectModelInfoRow modelPriceStyle'>
                             {event.message}
                           </div>}
                 {<div key='pricesEtc' className='projectModelInfoRow modelPriceStyle'>
                    {[status, `${epochs} epochs`, learningRate && `learning rate ${learningRate}`, formatTokens(trainedTokens), price, dur].filter(x=>x).map(x => <div className='infoItem'>{x}<div className='infoSep'>|</div></div>)}
                  </div>}
               </div>
               {checkpointsDiv}
               {canContinue &&
                <div key='continue' className='continueTrain'>
                  <div className='continueTrainButtons'>
                    <div className='continueTrainButtonsTopRow'>
                      <SimpleButton label={Continue} icon={Send} action={this.continueJob}/>
                      <GearButton action={toggleConfig}/>
                    </div>
                    <div className='continueFineTuneConfig' style={modelConfigStyle}>
                      <FineTuneConfig onCreate={this.setModelConfig} model={model}/>
                    </div>
                  </div>
                </div>
               }
               {canCancel && <div key='cancel' className='continueTrain'>
                               <div className='continueTrainButtons'>
                                 <SimpleButton label='Cancel' icon={Cross} action={this.cancelJob}/>
                               </div>
                             </div>}
             </div>
             <div className='datasetRight'>
               <ActionMenu className='fineTuningJobMenu' actions={actions}/>
               {this.props.open && <SimpleButton key='right' icon={Right} action={this.props.open}/> }
             </div>
           </div>


  }
}


export class DatasetFineTuningView extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.options = {}
    this.state.selectedJobMetrics = []
  }

  setModelsView = view => {
    this.modelsView = view
    if (view) {
      if (!view.getOption('fineTunable')) {
        view.toggleOption('fineTunable')
      }
    }
  }

  onViewChange = view => {
    this.setState({
      calView: view
    })
  }
  
  onDayChange = day => {
    this.setState({
      calDay: day
   })
  }
  
  componentDidMount() {
    this.updateObserver()
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  datasetsById = {}

  resolveDataset = dataset => {
    return this.datasetsById[dataset]
  }

  updateObserver = () => {
    if (this.sub) {
      this.sub.unsubscribe()
    }
    const dataset = this.props.dataset
    const observable = this.props.me.observeDatasetFineTuningJobs({dataset: this.props.dataset})
    this.sub = observable.subscribe(change => {
      const { type, fineTuningJob } = change
      if (type == 'removed') {
        delete this.fineTuningJobs[fineTuningJob.id]
      } else {
        this.fineTuningJobs[fineTuningJob.id] = fineTuningJob
      }
      this.updateFineTuningJobsLater()
    })
  }

  fineTuningJobs = {}

  updateFineTuningJobsLater = () => {
    this.updateLater('fineTuningJobs', this.updateFineTuningJobs)
  }

  updateFineTuningJobs = () => {
    const fineTuningJobs = Object.values(this.fineTuningJobs)
    const events = fineTuningJobs.map((elem) => {
      const { id, lastUpdated } = elem
      const start = new Date(lastUpdated)
      return {
        id,
        start,
        text: '',
        daily: elem
      }
    })
    this.setState({
      fineTuningJobs,
      events
    })
  }

  startFineTune = async (files) => {
    let models = this.modelsView.getSelectedModelIds().map(model => {
      const config = this.configs[model]
      let opts = { epochs: 1, batchSizse: 1, learningRateBoost: 0 }
      if (config) {
        opts = config.getOpts()
      }
      const { epochs, batchSize, learningRateBoost, seed } = opts
        return {
          epochs: Math.round(epochs|| 1), batchSize: Math.round(batchSize || 1),
          learningRateBoost, seed, model
        }
    })
    if (models.length === 0) {
      await delay(0.5)
      this.modelsView.hidePopup()
      return
    }
    await this.props.me.createFineTuningJob({
      dataset: this.props.dataset.id,
      models,
      datasetSplits: files
    })
    this.modelsView.hidePopup()
  }

  configs = {}
  setModelConfig = (model, ref) => {
    //debugger
    this.configs[model.id] = ref
  }

  renderModelConfig = model => {
    return <div key={model.id} className='continueFineTuneConfig'>
             <FineTuneConfig onCreate={(ref) => this.setModelConfig(model, ref)} model={model}/>
           </div>
  }
  
  getModelsForFineTuning = () => {
    const models = (isSelected, select) =>  {
      const allModels = this.props.getModels()
      console.log({allModels})
      const finetunables = allModels.filter(x => x.finetune)
      const finetunables1 = finetunables.filter(x => !x.isFinetune)
      return finetunables.map(x => {
        x.isSelected = () => isSelected(x.id)
        x.select = () => select(x.id)
        return x
      })
    }
    const getPrice = model => {
      const { finetune } = model
      const { price } = finetune
      const { training } = price
      return {
        input: training.price,
        output: training.price
      }
    }
    const vendors = this.props.vendors.filter(x => {
                       switch(x.id) {
                         case 'google':
                         case 'meta':
                         case 'mistral':
                         case 'openai':
                           return true
                       }
    })
    return { vendors, getPrice, models }
  }

  onSearch = async searchTerm => {
    this.setState({
      searchTerm
    })
    const searchResults =  await this.props.me.searchDatasetFineTuneJobs(searchTerm)
    this.setState({
      searchResults
    })
  }

  optionsSubject = new Subject()

  renderDetail() {
    return this.renderLossGraph()
  }

  renderContent() {
    const { models, vendors, getPrice } = this.getModelsForFineTuning()
    let fineTuningJobs = this.state.fineTuningJobs || []
    fineTuningJobs.sort((x, y) => {
      return y.lastUpdated - x.lastUpdated
    })
    let filt
    switch (this.state.calView) {
      case 'recent':
        break
      case 'day':
        filt = job => {
          const { lastUpdated } = job
          return startOfDay(this.state.calDay) < lastUpdated && endOfDay(this.state.calDay) > lastUpdated
        }
        break
      case 'month':
        filt = job => {
          const { lastUpdated } = job
          return startOfMonth(this.state.calDay) < lastUpdated && endOfMonth(this.state.calDay) > lastUpdated
        }
        break
    }
    if (filt) {
      fineTuningJobs = fineTuningJobs.filter(filt)
    }
    const onOptionsChanged = options => {
    }
    const observeOptions = () => {
      return this.optionsSubject
    }
    const saveOptions = async () => {
    }
    const onChange = () => {
    }
    const { dataset  } = this.props
    const menu = (job) => {
      const  action = async (files) => {
        if (job) {
          await this.continueFineTune(job, files)
        } else {
          await this.startFineTune(files)
        }
      }
      let id = job ? job.id : this.props.dataset.id
      const buttons = <SimpleButton label='Fine-tune' icon={Send} action={action}/>
      return <div className='modelsViewPopupButton'><ModelsView
                                                      key={id + '-fine-tune-' + 'menu'}
                                                      className={'fineTunedModelsView'}
                                                      onOptionsChanged={onOptionsChanged}
                                                      onCreate={this.setModelsView}
                                                      me={this.props.me}
                                                      category={'dataset-fine-tuning'}
                                                      getPrice={getPrice}
                                                      models={models}
                                                      vendors={vendors}
                                                      onChange={onChange}
                                                      observeOptions={observeOptions}
                                                      saveOptions={saveOptions}
                                                      position={'bottom right'}
                                                      configure={this.renderModelConfig}
                                                    >
                                                      <FineTuningJobLauncher key={dataset.id+"-launcher"} me={this.props.me} action={action} dataset={dataset}/>
                                                    </ModelsView>
             </div>
    }
    let onKeyDown
    let tabIndex
    let autoFocus
    if (isDesktop()) {
      onKeyDown = scrollOnKeyDown({
        getIndex: () => {
          let index = 0
          for (const fineTuningJob of fineTuningJobs) {
            if (this.state.selectedJob && this.state.selectedJob.id === fineTuningJob.id) {
              break
            }
            index++
          }
          return index
        },
        getLength: () => fineTuningJobs.length,
        setIndex: index => {
          this.selectJob(fineTuningJobs[index])
        }
      })
      tabIndex = 0
      autoFocus = true
    }
    return <div className='fineTunedModelsPage bnSubpageTopLevel'>
             <div className='datasetsTopLine'>
               {this.props.dataset && menu()}               
               <SearchField  me={this.props.me}  onSearch={this.onSearch}/>
             </div>
             {this.props.dataset && <div className='datasetsFinetuneJobsMainTitle'>
               <SimpleIcon src={Folder}/><div className='datasetsTitleName'>{dataset.name}</div>
                                    </div>}
             <div className='datasetsCal'>
               <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={this.state.events} onDayChange={this.onDayChange} initialView={'recent'} viewSelection={['recent', 'day', 'month']} />
             </div>
             <div className='datasetsScroller'
                  onKeyDown={onKeyDown}
                  tabIndex={tabIndex}
                  autoFocus={autoFocus}
             >
               {
                 fineTuningJobs.map(fineTuningJob => this.renderFineTuningJob({fineTuningJob,
                                                                               menu,
                                                                               models}))
               }
             </div>            
           </div>
  }

  renderLossGraph = () => {
    let lossGraphView
    let jobId = 'none'
    let model
    let busy
    let dataset = this.props.dataset
    if (this.state.selectedJob) {
      busy = this.state.selectedJob.platform === 'openai' &&
        this.state.selectedJob.job.finished_at &&
        (this.state.selectedJobMetrics || []).length === 0
      
      
      jobId = this.state.selectedJob.id
      model = <Model busy={busy} single={true} model={this.props.resolveModel(this.state.selectedJob.outputModel ||
                                                                              this.state.selectedJob.model)}/>
      if (!dataset) {
        dataset = {
          id: this.state.selectedJob.datset,
          name: this.state.selectedJob.name
        }
      }
    }
    return <LossGraph
             key={jobId}
             jobId={jobId}
             busy={busy}
             dataset={dataset}
             model={model}
             metrics={this.state.selectedJobMetrics}/>
  }

  selectJob = async (fineTuningJob, comp) => {
    if (this.state.selectedJob &&
        this.state.selectedJob.id === fineTuningJob.id &&
        this.listener) {
      return
    }
    this.setState({
      selectedJob: fineTuningJob,
      selectedJobMetrics: []
    })
    if (fineTuningJob.platform === 'openai') {
      if (fineTuningJob.job.finished_at) {
        this.loadSelectedJobMetrics(fineTuningJob)
      } else if (comp) {
        if (this.listener) {
          this.listener.unsubscribe()
        }
        this.listener = comp.observeMetrics().subscribe(metrics => {
          //debugger
          this.setState({selectedJobMetrics: metrics})
        })
      }
    }
  }

  loadSelectedJobMetrics = async fineTuningJob => {
    //debugger
    if (fineTuningJob.platform === 'openai') {
      debugger
      let selectedJobMetrics = await getMetrics(fineTuningJob.id)
      if (!selectedJobMetrics) {
        const { metrics } = await this.props.me.getDatasetFineTuneMetrics(fineTuningJob.id)
        await storeMetrics(fineTuningJob.id, metrics)
        selectedJobMetrics = metrics
      }
      if (this.state.selectedJob && this.state.selectedJob.id === fineTuningJob.id) {
        this.setState({
          selectedJobMetrics
        })
      }
    }
  }

  renderFineTuningJob = ({fineTuningJob, models, menu}) => {
    const model = this.props.resolveModel(fineTuningJob.model)
    const dataset = {
      id: fineTuningJob.dataset,
      name: fineTuningJob.name
    }
    let open
    let openJob = this.openJob
    if (openJob) {
      open = async () => {
        const getSubpage = await openJob({dataset, fineTuningJob, model})
        const subpage = getSubpage(this.back)
        this.setState({
          subpage
        })
      }
    }
    return <DatasetFineTuningJob
             preview={this.props.preview}
             selected={this.state.selectedJob && this.state.selectedJob.id === fineTuningJob.id}
             select={(comp) => this.selectJob(fineTuningJob, comp)}
             key={fineTuningJob.id}
             open={open}
             me={this.props.me}
             resolveModel={this.props.resolveModel}
             dataset={dataset}
             fineTuningJob={fineTuningJob}
             menu={menu}
             model={model}
           />
    
  }

  openJob = async ({dataset, fineTuningJob, model}) => {
    dataset = await this.props.me.resolveDataset(dataset.id)
    let fineTunedModel
    if (fineTuningJob.outputModel) {
      fineTunedModel = await this.props.resolveModel(fineTuningJob.outputModel)
    }
    return (back) => () => <DatasetExamplesView
                             openDetail={this.props.openDetail}
                             copySystemPrompt={this.props.copySystemPrompt}
                             cutSystemPrompt={this.props.cutSystemPrompt}
                             systemPrompts={this.props.systemPrompts}
                             fineTunedModel={fineTunedModel}
                             fineTuningJob={fineTuningJob}
                             copyTask={this.props.copyTask}
                             key={dataset.id}
                             title={<ModelLabel model={fineTunedModel || model}/>}
                             dataset={dataset}
                             getModels={this.props.getModels}
                             me={this.props.me}
                             back={back}
                             vendors={this.props.vendors}
                             resolveModel={this.props.resolveModel}
                           />
  }
  
}

export class DatasetExamplesView extends UComponent {

  constructor (props) {
    super(props)
    this.state = {
      examples: [],
      tasks: []
    }
  }
  
  componentDidMount() {
    this.searchSub = this.taskSubject.subscribe(() => {
      // invalidate search index
      this.searchIndex = null
    })
    this.updateObserver()
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  examples = {}
  exampleCount = 0
  byHashCode = {}
  snapshot = {}
  datasetsById = {}
  
  updateObserver = async () => {
    if (this.sub) {
      this.sub.unsubscribe()
    }
    this.examples = {}
    this.exampleCount = 0
    
    const dataset = this.props.dataset
    const { datasets, observable } = await this.props.me.observeDatasetExamples(dataset)
    this.datasetsById = {}
    for (const dataset of datasets) {
      this.datasetsById[dataset.id] = dataset
    }
    this.sub = observable.subscribe(async change => {
      const { type, example } = change
      if (change.type === 'removed' || example.deleted) {
        if (this.examples[example.id]) {
          delete this.examples[example.id]
          this.exampleCount--
          for (const id in this.chatGPT.received) {
            if (id.startsWith(example.id)) {
              const message = this.chatGPT.received[id]
              this.chatGPT.invalidateCache(message)
              delete this.chatGPT.received[id]
            }
          }
          if (this.tasks[example.id]) {
            const task = this.tasks[example.id]
            delete this.tasks[example.id]
            this.taskSubject.next({
              type: 'removed',
              task
            })
          }
        }
      } else {
        let { line, id, messages } = example
        const participants = ['train'].concat(dataset.models)
        let inReplyTo
        let ts = 1
        let hashCode = await (messages ? hash(JSON.stringify(messages)) : hash(line))
        example.hashCode = hashCode
        this.byHashCode[hashCode] = example
        if (this.snapshot[hashCode]) {
          example.metrics = this.snapshot[hashCode].metrics
          console.log("linked metrics", example)
        }
        if (!messages) {
          messages = JSON.parse(line).messages
          messages.forEach((message, i) => {
            if (message.role === 'assistant') {
              const content = message.content
              if (!message.models) {
                message.models = participants.slice(1).map(model => {
                  return {
                    model,
                    content: ''
                  }
                })
              }
            } 
            message.id = example.id + '-'+i
            message.ts = ts + i
            message.model = 'train'
            message.inReplyTo = inReplyTo
            inReplyTo = message.id
          })
          delete example.line
          example.messages = messages
        } else {
          let seen = {}
          for (const model of participants) {
            seen[model] = true
          }
          messages.forEach(message => {
            if (message.role === 'assistant') {
              if (!message.model) {
                message.model = 'train'
              }
              if (message.models) for (const {model} of message.models) {
                if (!seen[model]) {
                  participants.push(model)
                  seen[model] = true
                }
              }
            }
          })
          messages.forEach((message, i) => {
            message.id = example.id + '-'+i
            message.ts = ts + i
            if (message.role === 'user') {
              message.model = 'train'
              message.models = participants
            } else {
              message.model = 'train'
              message.inReplyTo = inReplyTo
            }
            inReplyTo = message.id
          })
        }
        messages.forEach(message => {
          message.task = example.id
          if (this.chatGPT && this.chatGPT.state.selectedTask) {
            if (message.task === this.chatGPT.state.selectedTask.id) {
              message.content = message.content || ''
              this.chatGPT.received[message.id] = message
              this.chatGPT.invalidateCache(message)
              this.messageSubject.next({
                type: 'added',
                message
              })
            }
          }
        })
        console.log({messages})
        if (!this.examples[example.id]) {
          this.exampleCount++
          this.examples[example.id] = example
        } else {
          this.examples[example.id] = example
          if (this.chatGPT) {
            const task = this.chatGPT.tasks[example.id]
            task.example = example
            task.systemPrompt = example.systemPrompt || this.getSystemPrompt()
            console.log("update task", task)
            this.updateTaskLater(task)
          }
        }
      }
      this.updateExamplesLater()
    })
    if (this.props.fineTuningJob && this.props.fineTuningJob.outputModel) {
      this.props.me.getDatasetFineTuneData(this.props.fineTuningJob.id).then(async result => {
        console.log('fineTuneData', result)
        const { trainingFile, validationFile, metrics, type } = result
        this.setState({
          metrics
        })
        let i = 0
        const examples = []
        if (trainingFile) for (const line of trainingFile.split('\n')) {
          this.hasSnapshot = true
          const hashCode = await hash(line)
          const example = {
            index: i,
            line,
            hashCode,
            messages: JSON.parse(line)
          }
          this.snapshot[hashCode] = example
          examples.push(example)
          i++
        }
        if (metrics) metrics.forEach((metric, i) => {
          const example = examples[i % examples.length]
          let d = this.byHashCode[example.hashCode]
          if (d) {
            if (!d.metrics) {
              d.metrics = []
            }
            d.metrics.push(metric)
          }
          d = this.snapshot[example.hashCode]
          if (d) {
            if (!d.metrics) {
              d.metrics = []
            }
            d.metrics.push(metric)
          }
        })
        this.forceUpdate()
      })
    }
  }

  updateTaskLater = task => {
    this.updateLater(task.id, () => this.updateTask(task))
  }

  updateTask = task => {
    this.chatGPT.updateTask(task)
  }

  updateExamplesLater = () => {
    this.updateLater('updateExamples', this.updateExamples)
  }

  getSystemPrompt = () => {
    let systemPrompt
    if (this.props.parent) {
      systemPrompt = this.props.parent.systemPrompt
    }
    //debugger
    if (!systemPrompt && this.props.parents) {
      for (let i = this.props.parents.length - 1; i >= 0; i--) {
        systemPrompt = this.props.parents[i].systemPrompt 
        if (systemPrompt) {
          break
        }
      }
    }
    return systemPrompt
  }

  mkThread = (example, lineNumber, content) => {
    const { id, dataset, messages, lastUpdated } = example
    let title = this.datasetsById[dataset].name + " #" +(lineNumber+1)
    if (!content) content = getContent(example)
    const thread = {
      wasBroadcast: this.tasks[example.id],
      id,
      title,
      description: content,
      example,
      lastUpdated,
      messages: messages.length,
      example,
      systemPrompt: example.systemPrompt || this.getSystemPrompt()
    }
    return thread
  }

  getThreads = () => {
    return this.state.tasks || []
  }

  tasks = {}

  firstTime = true

  updateExamples = () => {
    const examples = Object.values(this.examples)
    examples.sort((x, y) => {
      return getContent(x).localeCompare(getContent(y))
    })
    examples.forEach((x, i) => {
      x.lineNumber = i
    })
    const tasks = examples.map((example, i) => this.mkThread(example, example.lineNumber))
    for (const task of tasks) {
      const type = this.tasks[task.example.id] ? 'updated' : 'added'
      this.tasks[task.example.id] = task
      this.taskSubject.next({
        type,
        task
      })
    }
    this.setState({
      examples,
      tasks
    }, this.checkFirstTaskLater)
  }

  checkFirstTaskLater = () => {
    this.updateLater("checkFirstTask", this.checkFirstTask)
  }

  checkFirstTask = () => {
    if (!isMobile() && this.firstTime && this.state.tasks.length > 0 && this.chatGPT) {
      this.firstTime = false
      const first = this.state.tasks[0]
      this.chatGPT.selectThread(first)
      this.forceUpdate()
    }
  }

  back = () => {
    this.props.back()
  }

  taskSubject = new Subject()
  
  onCreateChatGPT = chatGPT => {
    this.chatGPT = chatGPT
    this.checkFirstTask()
  }

  getSearchIndex = () => {
    const documents = Object.values(this.tasks)
    if (!this.searchIndex) {
      this.searchIndex = new InMemorySearch({
        id: 'id',
        fields: ['description', 'title', 'models'],
        documents,
      })
    }
    return this.searchIndex
  }

  messageSubject = new Subject()
  
  render() {

    const trashDataset = async () => {
      await this.props.trash()
      this.back()
    }

    const getThreadsHistory = (lastUpdated, limit) => {
      return this.getThreads().filter(t => t.lastUpdated < lastUpdated).slice(0, limit)
    }
    
    const observeTasks = (date) => {
      const start = startOfWeek(date)
      const end = endOfWeek(date)
      const filt = t => t.lastUpdated >= start && t.lastUpdated <= end
      const tasks = this.getThreads().filter(filter).map(task => {
        return { type: 'added', task  }
      })
      return concat(from(tasks), this.taskSubject)
    }

    const observeTaskMessages = ({task, limit}) => {
      const { example } = task
      return concat(from(example.messages.map(message => {
        return { type: 'added', message}
      })), this.messageSubject)
    }

    const observeRecentTasks = () => {
      const tasks = this.getThreads().map(task => {
        return { type: 'added', task }
      })
      let len = tasks.length
      return concat(from(tasks), this.taskSubject)
    }
    
    const deleteChatMessage = async (chatGPT, messageId) => {
      const { example } = chatGPT.state.selectedTask
      await this.props.me.deleteDatasetExampleMessage(example, messageId)
    }
    
    const searchChatMessages = () => {
      return []
    }
    
    const onFile = async chatGPT => {
    }
    
    const deleteTask = async (chatGPT, task) => {
      const { example } = task
      if (example.id === 'new-task') {
        await delay(0.5)
      } else {
        await this.props.me.deleteDatasetExample(example.id)
      }
      if (this.examples[example.id]) {
        delete this.examples[example.id]
        this.exampleCount--
      }
      delete chatGPT.tasks[task.id]
      delete this.tasks[task.id]
      this.forceUpdate()
    }
    
    const searchTasks = async (searchTerm) => {
      const results = await (this.getSearchIndex().search(searchTerm))
      if (results.length > 0) {
        console.log({results})
      }
      this.forceUpdate()
      return {results}
    }
    const createNewTask = () => {
      this.props.me.createNewDatasetExample(this.props.dataset.id).then(example => {
        const task = this.mkThread(example, 0, "New Training Example")
        this.tasks[task.id] = task
        this.taskSubject.next({
          type: 'added',
          task
        })
        this.chatGPT.selectThread(task)
      })
      return null
    }
    const onCloseTask = (chatGPT, task) => {
      const { example } = task
      if (example.id === 'new-task') {
        
      }
    }
    const uploadFile = () => {
    }
    const uploadTask = async chatGPT => {
    }
    const getMessage = (chatGPT) => {
      const msg = chatGPT.received[this.streamingId]
      if (!msg) {
      }
      return msg
    }
    const correct = async (chatGPT, {task, message, reply, model, judges}) => {
      const { example } = task
      let i = 0
      for (const message of example.messages) {
        if (message.id === reply.id) {
          break
        }
        i++
      }
      await this.props.me.correctDatasetExample({
        dataset: this.props.dataset.id,
        exampleId: example.id,
        messageIndex: i,
        judges,
        model
      })
    }

    const createNewMessage = (chatGPT, opts) => {
      const { text, contents, models, task } = opts
      const example = this.examples[task]
      return {
        role: 'user',
        id: example.id + '-'+example.messages.length,
        inReplyTo: example.id + '-'+(example.messages.length-1),
        content: text,
        contents,
        models,
        task,
        ts: Date.now(),
        utcOffset: this.props.me.utcOffset,
      }
    }

    const streamChat = async (chatGPT, msg, opts) => {
      let judges
      if (chatGPT.state.judgeChat) {
        judges = chatGPT.judgeView.getSelectedModelIds()
      }
      const dataset = this.props.dataset.id
      const example = this.examples[chatGPT.state.selectedTask.id]
      const exampleId = example.id
      let {id, role, content, utcOffset} = msg
      let models = chatGPT.modelsView.getSelectedModelIds()
      const { replay } = opts
      debugger
      if (replay) {
        models = [replay]
        role = 'assistant'
        const rsp = example.messages.find(x => x.inReplyTo === id)
        if (!rsp) {
          throw new Error("Can't find response")
        }
        this.streamingId = rsp.id 
      } else {
        const rsp = example.messages.find(x => x.inReplyTo === id)
        if (rsp) {
          this.streamingId = rsp.id
        } else {
          debugger
        }
      }
      const data = {id: this.streamingId, dataset, exampleId, role, content, models, judges, magpie: opts.magpie}
      return await this.props.me.streamInferenceExample(data, opts)
    }
    
    const getModels = () => {
      return [{
        size: "small",
        id: 'train',
        title: 'Train',
        getIcon: () => Hashtag,
        name: 'Train',
        vendor: "N/A",
        vendorId: "n/a",
        contexts: [{
          price: { input: 0, output: 0 }
        }],
        sortOrder: -1,
        isModel: id => id === 'train'
      }].concat(this.props.getModels())
    }

    const saveUserEdit = async (chatGPT, {task, message, content}) => {
      const {example} = task
      const lastUpdated = Date.now()
      example.lastUpdated = lastUpdated
      const found = example.messages.find(x => x.id === message.id)
      if (content !== undefined) {
        found.content = content
        task.description = content
      }
      await this.props.me.updateDatasetExample(example.id, { lastUpdated, messages: example.messages })
      this.forceUpdate()
    }

    const saveReplyEdit = async (chatGPT, {task, model, message, content, corrected}) => {
      const {example} = task
      let found = message
      if (model !== 'train') {
        found = message.models.find(x => x.model === model)
      }
      const lastUpdated = Date.now()
      example.lastUpdated = lastUpdated
      if (!found) {
        message.models.push({
          model, content, corrected
        })
      } else {
        found.content = content
        found.corrected = corrected
      }
      chatGPT.purgeCache(message)
      if (this.props.fineTuningJob) {
        this.forceUpdate()
        return
      }
      await this.props.me.updateDatasetExample(example.id, { lastUpdated, messages: example.messages })
    }

    const judge = async (chatGPT, judges, req, reply, useMsg) => {
      const { example } = chatGPT.state.selectedTask
      const dataset = this.props.dataset.id
      const exampleId = example.id
      let messageIndex = 0
      for (const message of example.messages) {
        if (message.id === reply.id) {
          break
        }
        messageIndex++
      }
      if (messageIndex === example.messages.length) {
        console.error("can't match reply")
        return
      }
      return await this.props.me.judgeExample({dataset, exampleId, judges, messageIndex })
    }
    const applyReply = async (chatGPT, {message, reply}) => {
      const { example } = chatGPT.state.selectedTask
      await this.props.me.applyReplyToExample({dataset: this.props.dataset.id, example, message, reply})
    }


    const deleteModel = async (chatGPT, {task, model }) => {
      const { example } = task
      example.messages.forEach(message => {
        switch (message.role) {
          case 'user':
            message.models = message.models.filter(x => x !== model)
            break
          case 'assistant':
            message.models = message.models.filter(x => x.model !== model)
        }
      })
      return await this.props.me.deleteModelFromExample({
        dataset: this.props.dataset.id,
        exampleId: example.id,
        model
      })
    }
    let copy
    let pasteTask
    let getNewButton
    if (!this.props.dataset.isFolder) {
      getNewButton = (chatGPT, defaultNewAction) => {
        const actions = [
          {
            icon: Hashtag,
            label: "New Example",
            action: defaultNewAction
          },
          {
            label: 'Copy ' + this.props.dataset.name,
            icon: Copy,
            action: this.props.copyDataset
          },
          {
            icon: Share,
            label: 'Export ' + this.props.dataset.name,
            action: this.openExportPopup
          }
        ]
        if (this.props.pasteTask) {
          actions.push({
            icon: Paste,
            label: "Paste Example",
            action: async () => {
              await this.props.pasteTask(this.props.dataset)
              if (this.cal) {
                this.cal.selectView('recent')
              }
            }
          })
        }
        return <ActionMenu actions={actions} position={'bottom left'}/>
      }
    } else {
      getNewButton = () => null
    }
    const dataset = this.props.dataset
    let subpages
    if (this.props.showFineTune) {
      const fineTunePage = (back) => <DatasetFineTuningView
                                       key={'fine-tuning-'+dataset.id}
                                       title={dataset.name}
                                       back={back}
                                       title={'Fine-tune'}
                                       me={this.props.me}
                                       dataset={dataset}
                                       getModels={this.props.getModels}
                                       resolveModel={this.props.resolveModel}
                                       vendors={this.props.vendors}
                                       fineTunedModels={this.props.fineTunedModels}
                                     />
 
      subpages =[
                 {
                   label: "Fine-tune",
                   action: chatGPT => () => fineTunePage(chatGPT.back)
                 }
      ]
    }
    const renderTask = (chatGPT, {task, onClick, dateComp, dollars, content}) => {
      const self = this
      let copyTask
      let cutTask
      if (this.props.copyTask) {
        copyTask = async () => {
          const self = this
          await this.props.copyTask(this.chatGPT, task)
        }
      }
      if (this.props.cutTask) {
        cutTask = async () => {
          const self = this
          await this.props.cutTask(this.chatGPT, task)
        }
      }
      const trash = async () => {
        const self = this
        await deleteTask(this.chatGPT, task)
      }
      let actions =  [
      ]
      if (cutTask) {
        actions.push({
          icon: Cut,
          action: cutTask,
          label: "Cut"
        })
      }
      actions.push({
        icon: Copy,
        action: copyTask,
        label: "Copy"
      })
      if (trash) {
        actions.push({
          button: (close) => <DeleteButton key='deleteTask' label='Delete' trash={
                                             async () => {
                                               await trash()
                                               close()
                                             }
                                           }/>
        })
      }

      const { example } = task
      let { metrics, hashCode } = example
      let isTrain 
      if (!metrics && hashCode) {
        const snap = this.snapshot[hashCode]
        if (snap) {
          metrics = snap.metrics
          isTrain = true
        }
      }
      let style
      if (metrics) {
        style = {
          background: toGradient(metrics)
        } 
      }  else {
        if (isTrain) {
          background = 'rgb(0, 102, 65)'
        }
      }
      let models
      if (example.messages) {
        let Models = {}
        for (const message of example.messages) {
          if (message.role === 'assistant') {
            if (message.models) {
              for (const {model} of message.models) {
                Models[model] = model
              }
            }
          }
        }
        const active = Object.keys(Models)
        models = active.map(x => this.props.resolveModel(x)).filter(x => x)
      }
      onClick
      let action
      if (isMobile()) {
        action = onClick
        onClick = null
      }
      let className = 'taskTitle'
      if (chatGPT.state.selectedTask && chatGPT.state.selectedTask.id == task.id) {
        className += ' taskTitleSelected'
      }
      return <div key={example.id} className={className} style={style} onClick={onClick} data-task-id={example.id}>
               <div  className='taskTitleLeft'>
	         <div className='keyboardMenuItemIcon'><ReactSVG src={Hashtag}/></div>
               </div>
	       <div className='keyboardMenuItemLabel taskDescriptionLabel'>
                 {content}
                 <div className='taskModels'>{models.map(model => <ModelLabel model={model}/>)}</div>
               </div>
               <div className='keyboardMenuItemRight'>
                 <div className='modelPrice'>{dollars}</div>
                 {dateComp}
                 <div className='keyboardMenuItemActions fineTuningTaskActions'>
                   <ActionMenu className='fineTuningTaskMenu' actions={actions}/>
                   {action && <SimpleButton icon={Right} action={action}/>}
                 </div>
               </div>
             </div>
    }
    let className = 'datasetExamplesViewChatGPT bnSubpageTopLevel'
    if (this.props.fineTuningJob) {
      className += ' datasetExamplesViewFineTune'
      const exportData = async () => {
        const { trainingFile, validationFile, metrics, type } = 
              await this.props.me.getDatasetFineTuneData(this.props.fineTuningJob.id)
        const dataset = await this.props.me.resolveDataset(this.props.fineTuningJob.dataset)
        if (trainingFile) {
          const filename = dataset.name + "-train.jsonl"
          const blob = new Blob([trainingFile], {type: "text/plain;charset=utf-8"})
          await saveAs(blob, filename)
        }
        if (validationFile) {
          const filename = dataset.name + "-validate.jsonl"
          const blob = new Blob([validationFile], {type: "text/plain;charset=utf-8"})
          await saveAs(blob, filename)
        }
      }
      getNewButton = (chatGPT, defaultNewAction) => {
        const actions = [
          {
            icon: Share,
            label: "Export Data",
            action: exportData
          }
        ]
        return <ActionMenu actions={actions} position={'bottom left'}/>
      }
    }
    const selectSystemPrompt = async (chatGPT, task, systemPrompt) => {
      await this.props.me.saveDatasetExampleSystemPrompt(task, systemPrompt)
      this.forceUpdate()
    }
    const deleteSystemPrompt = async (chatGPT, task) => {
      const { example } = task
      example.systemPrompt = task.systemPrompt = null
      this.forceUpdate()
      await this.props.me.saveDatasetExampleSystemPrompt(task, null)
      this.forceUpdate()
    }
    const renderModelConfig = model => {
      const onCreate = ref => {
      }
      return <div key={model.id} className='continueFineTuneConfig'>
               <ModelConfig onCreate={onCreate} model={model}/>
             </div>
    }
    const sortTasks = (chatGPT, tasks, view) => {
      if (view === 'all') {
        tasks.sort((x, y) => {
          return x.description.localeCompare(y.description)
        })
      } else {
        tasks.sort((x, y) => {
          const cmp = y.lastUpdated = x.lastUpdated
          if (cmp) {
            return cmp
          }
          return x.example.lineNumber - y.example.lineNumber
        })
      }
      return tasks
    }
    return <div className={className}>
             <ChatGPT
               trash={trashDataset}
               calendarViewSelection={['all', 'recent', 'week', 'day']}
               sortTasks={sortTasks}
               chatClass='datasetChat'
               key={this.props.dataset.id}
               fineTunedModel={this.props.fineTunedModel}
               resource={this.props.dataset.id}
               configure={renderModelConfig}
               copySystemPrompt={this.props.copySystemPrompt}
               cutSystemPrompt={this.props.cutSystemPrompt}
               deleteSystemPrompt={deleteSystemPrompt}
               selectSystemPrompt={selectSystemPrompt}
               systemPrompts={this.props.systemPrompts}
               subpages={subpages}
               copyTask={this.props.copyTask}
               cutTask={this.props.cutTask}
               pasteTask={this.props.pasteTask}
               renderTask = {renderTask}
               getNewButton={getNewButton}
               deleteModel={deleteModel}
               applyReply={applyReply}
               judge={judge}
               correct={correct}
               saveUserEdit={saveUserEdit}
               saveReplyEdit={saveReplyEdit}
               getPlaceholder={(chatGPT) => chatGPT.state.judgeChat? "Talk to judge":  "Enter a prompt"}
               getDiscussionsLabel={() => 'Examples'}
               availableCredits={Math.max(this.props.wordsPurchased - this.props.wordsUsed, 0)}
               prices={this.prices}
               vendors={this.props.vendors}
               me={this.props.me}
               goBack={this.back}
               defaultModel={chatGPT => 'Attunewise Mini'}
               onCreate={this.onCreateChatGPT}
               fileTypes=".jsonl"
               deleteTask = {deleteTask}
               getTitle={
                 (chatGPT) => {
                   return chatGPT.state.judgeChat ? 'Judge': chatGPT.state.selectedTask.title
                 }
               }
               getTasksTitle={
                 chatGPT => this.props.dataset.name
               }
               getButtonLabel ={
                 (chatGPT, message) => {
                   if (chatGPT.state.judgeChat) {
                     return "Judge"
                   }
                   else if (!chatGPT.state.sending && message.inReplyTo) {
                     return 'Replay'
                   }
                   return 'Send'
                 }
               }
               vendors={this.props.vendors}
               models={
                 (chatGPT, isSelected, select) => getModels().map(x => {
                   x.isSelected = () => isSelected(x.id)
                   x.select = () => select(x.id)
                   return x
                 })
             }
               isSearchFieldVisible={
                 (chatGPT, messages) => {
                   return chatGPT.state.slide === 0
                 }
               }
               observeTaskMessages={observeTaskMessages}
               observeTasks={observeTasks}
               observeRecentTasks={observeRecentTasks}
               deleteChatMessage={deleteChatMessage}
               getHistory={(chatGPT, task, earliest, limit) => []}
               getThreadsHistory = {getThreadsHistory}
               searchChatMessages={searchChatMessages}
               searchTasks={searchTasks}
               getMessage={getMessage}
               createNewMessage={createNewMessage}
               streamChat={streamChat}
               createNewTask={createNewTask}
               uploadFile={this.props.me.uploadFile}
               onCloseTask={onCloseTask}
             />
           </div>
  }
}


class DatasetFolderView extends Component {

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.commitEdit()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      this.cancelEdit()
    }
  }

  commitEdit = async () => {
    await this.props.me.setDatasetName(this.props.dataset, this.state.name)
    this.setState({
      editing: false
    })
  }

  cancelEdit = () => {
    this.setState({
      editing: false
    })
  }

  setInput = ref => {
    if (ref !== this.input) {
      if (this.input) {
        if (isDesktop()) {
          this.input.removeEventListener('keydown', this.handleKeyDown)
        }
      }
      this.input = ref
      if (this.input) {
        if (this.state.name === 'New Folder') {
          this.input.select()
        }
        if (isDesktop()) {
          this.input.addEventListener('keydown', this.handleKeyDown)
        }
      }
    }
  }

  componentDidMount() {
    this.sub = observeClipboard().subscribe(dataset => {
      this.forceUpdate()
    })
  }

  componentWillUnmoun() {
    this.sub.unsubscribe()
  }

  render() {
    const { dataset } = this.props
    const { lastUpdated } = dataset
    let name = dataset.name || 'New Folder'
    let icon = Folder
    let contents = dataset.contents || []
    let date = fromNow(lastUpdated)
    const trash = async () => {
      await this.props.me.deleteDataset(dataset)
    }
    const edit = async () => {
      this.setState({
        name,
        editing: true
      })
    }
    let nameDiv
    if (!this.state.editing) {
      nameDiv = <div className='projectName'>{name}</div>
    } else {
      const onChange = (event) => {
        this.setState({
          name: event.target.value
        })
      }
      let onBlur
      if (!isDesktop()) {
        onBlur = e => this.commitEdit()
      }
      nameDiv = <ClickAwayListener onClickAway={this.cancelEdit}>
                  <div className='projectName projectNameEditor'><input ref={this.setInput} onBlur={onBlur} autoFocus value={this.state.name} onChange={onChange}/></div>
                </ClickAwayListener>
    }
    let icon1 = <div className='projectIcon projectFolderIcon' ><ReactSVG src={icon}/></div>
    const onDrop = e => this.props.onDrop(this)
    const onPaste = event => {
      const items = event.clipboardData.items;
      for (let item of items) {
        if (item.type === 'application/attunewiseDataset') {
          item.getAsString((data) => {
            const componentData = JSON.parse(data)
          })
        }
      }
    }
    let cut
    if (this.props.parent) {
      cut = async () => {
        await copyDataset(this.props.dataset)
        await this.props.me.removeDatasetParent({
          child: this.props.dataset,
          parent: this.props.parent.id
        })
      }
    }
    const copy = async () => copyDataset(this.props.dataset)
    let paste
    if (clipboardDataset &&
        clipboardDataset.id !== this.props.dataset.id &&
        !this.props.parents.find(x => x.id === clipboardDataset.id)) {
      paste = () => this.props.me.addDatasetParent({child: clipboard,
                                                    parent: this.props.dataset.id})
    }
    let actions =  [
      {
        icon: EditIcon,
        action: edit,
        label: "Edit"
      }
    ]
    actions.push({
      icon: Share,
      action: cut,
      label: "Cut"
    })
    actions.push({
      icon: Copy,
      action: copy,
      label: "Copy"
    })
    if (paste) {
      actions.push({
        icon: Paste,
        action: paste,
        label: "Paste"
      })
    }
    if (trash) {
      actions.push({
        button: (close) => <DeleteButton noClickAway label='Delete' trash={async () => {
                                           await trash()
                                           close()
                                         }}/>
      })
    }
    let markdown
    const {displayInfo} = dataset
    if (displayInfo) {
      markdown = `
${displayInfo.summary.replaceAll("his dataset", "his folder")}
`
    }
    return <div key={dataset.id}
                className='dataset datasetFolder'
                onPaste={onPaste}
                onDrop={onDrop}>
             <div className='projectLeft' >
               {icon1}
             </div>
             <div className='projectMiddle' onClick={() => this.props.open()}>
               <div className='projectTitle'>
                 {nameDiv}
                 <div className='projectDate'>{date}</div>
               </div>
               <div className='projectModelInfoRow modelPriceStyle'>
               </div>
               {markdown &&<div className='projectSummary' >
                 <Markdown components={getComponents()}>{markdown}</Markdown>
                           </div>}
             </div>
             <div className='projectRight'>
               <ActionMenu actions={actions}/>
             </div>
           </div>
  }
}


export class DatasetsView extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.datasets = []
    this.state.events = []
    this.state.searchResults = []
  }

  openDataset = ({dataset, models}) => {
    const trash = async () => {
      await this.props.me.deleteDataset(dataset)
    }
    this.setState({
      subpage: () => <DatasetExamplesView
                       copySystemPrompt={this.props.copySystemPrompt}
                       cutSystemPrompt={this.props.cutSystemPrompt}
                       systemPrompts={this.props.systemPrompts}
                       showFineTune={true}
                       parents={this.props.parents}
                       copyDataset={() => copyDataset(dataset)}
                       copyTask={this.props.copyTask}
                       cutTask={this.props.cutTask}
                       pasteTask={this.props.pasteTask}
                       key={dataset.id}
                       title={dataset.name}
                       dataset={dataset} models={models}
                       me={this.props.me}
                       back={this.back}
                       trash={trash}
                       vendors={this.props.vendors}
                       resolveModel={this.resolveModel}
                       getModels={this.props.getModels}/>
    })
  }

  openModel = (model) => {
    this.setState({
      popup: () => <ModelConfig model={model} me={this.props.me} title={<ModelLabel model={model}/>} back={this.back}/>
    })
  }

  setModelsView = view => {
    this.modelsView = view
    if (view) {
      if (!view.getOption('fineTunable')) {
        view.toggleOption('fineTunable')
      }
    }
  }
  datasets = {}
  componentDidMount() {
    let parent = this.props.parent ? this.props.parent.id : undefined
    this.datasetsSub = this.props.me.observeDatasets({parent}).subscribe(change => {
      const { type, dataset } = change
      if (type === 'removed') {
        delete this.datasets[dataset.id]
      } else {
        this.datasets[dataset.id] = dataset
      }
      this.updateDatasetsLater()
    })
    this.clipboardSub = observeClipboard().subscribe(() => {
      this.forceUpdate()
    })
    if (this.props.onCreate) this.props.onCreate(this)
  }

  componentWillUnmount() {
    if (this.datasetsSub) {
      this.datasetsSub.unsubscribe()
    }
    if (this.clipboardSub) {
      this.clipboardSub.unsubscribe()
    }
  }

  updateDatasetsLater = () => {
    this.updateLater('updateDatasets', this.updateDatasets)
  }

  updateDatasets = () => {
    const datasets = Object.values(this.datasets)
    const events = datasets.map((elem) => {
      const { id, lastUpdated } = elem
      const start = new Date(lastUpdated)
      return {
        id,
        start,
        text: '',
        daily: elem
      }
    })
    this.setState({
      datasets,
      events
    })
  }

  resolveModel = id => this.props.models.find(model => model.id === id)
  
  openExportPopup = () => {
    
  }

  openFineTuningPopup = () => {
    const dataset = this.props.parent
    const buttons = <SimpleButton label='Fine-tune' icon={Send} action={this.startFineTune}/>
    this.setState({
      subpage: () => <DatasetFineTuningView
                       key={'fine-tuning-'+dataset.id}
                       title={dataset.name}
                       back={this.back}
                       title={'Fine-tune '}
                       me={this.props.me}
                       dataset={dataset}
                       getModels={this.props.getModels}
                       resolveModel={this.resolveModel}
                       vendors={this.props.vendors}
                       fineTunedModels={this.props.fineTunedModels}
                     />
      
    })
  }

  openModelPopup = ({title, buttons, configure}) => {
    const subject = new Subject()
    const observeOptions = () => {
      return subject
    }
    const saveOptions = async () => {
    }
    const onChange = () => {
    }
    const { vendors, models, getPrice } = this.getModelsForFineTuning()
    this.setState({
      subpage: () => <ModelPopup dataset={this.props.parent} title={title} me={this.props.me} back={this.back}
                                 getPrice={getPrice}
                                 models={models}
                                 vendors={vendors}
                                 onChange={onChange}
                                 fineTunedModels={this.props.fineTunedModels}
                                 observeOptions={observeOptions}
                                 saveOptions={saveOptions}
                                 configure={configure}
                     >
                       <div className='model-popup-buttons'>
                         {buttons}
                       </div>
                       </ModelPopup>
    })
  }
  
  openFolder = dataset => {
    this.setState({
      subpage: () => <DatasetsView
                       resolveModel={this.resolveModel}
                       copySystemPrompt={this.props.copySystemPrompt}
                       cutSystemPrompt={this.props.cutSystemPrompt}
                       systemPrompts={this.props.systemPrompts}
                       copyTask={this.props.copyTask}
                       cutTask={this.props.cutTask}
                       parents={(this.props.parents || []).concat([dataset])}
                       parent={dataset}
                       getModels={this.props.getModels}
                       prices={this.props.prices}
                       datasets={this.props.datasets}
                       models={this.props.models}
                       fineTunedModels={this.props.fineTunedModels}
                       vendors={this.props.vendors}
                       me={this.props.me}
                       back={this.back}
                       title={dataset.name || 'New Folder'}/>
    })
  }

  onDayChange = day => {
    this.setState({
      calDay: day
    })
  }


  onViewChange = view => {
    this.setState({
      calView: view
    })
  }

  getModelsForFineTuning = () => {
    const models = (isSelected, select) => this.props.getModels().filter(x => x.finetune).map(x => {
      x.isSelected = () => isSelected(x.id)
      x.select = () => select(x.id)
      return x
    })
    const getPrice = model => {
      const { finetune } = model
      const { price } = finetune
      const { training } = price
      return {
        input: training.price,
        output: training.price
      }
    }
    const vendors = this.props.vendors.filter(x => {
                       switch(x.id) {
                         case 'google':
                         case 'meta':
                         case 'mistral':
                         case 'openai':
                           return true
                       }
    })
    return { vendors, getPrice, models }
  }

  setFileChooser = ref => {
    this.fileChooser = ref
  }

  onBack() {
    this.state.huggingFaceOpen = false
    super.onBack()
  }

  renderHuggingFace = () => {
    const importDataset = async opts => {
      const { dataset, numSamples } = opts
      const { url } = dataset
      this.back()
      await this.props.me.uploadDataset({
        url,
        parent: this.props.parent ? this.props.parent.id : undefined,
        numSamples 
      })
    }
    let back
    let cancel
    if (isMobile()) {
      back = this.back
    } else {
      cancel = this.back
    }
    return  () => <HuggingFaceDatasets
                    me={this.props.me}
                    importDataset={importDataset}
                    title={'HuggingFace'}
                    cancel={cancel}
                    back={back}/>
  }

  openHuggingFace = () => {
    let subpage
    if (isMobile()) {
      subpage = this.renderHuggingFace()
    }
    this.setState({
      huggingFaceOpen: true,
      subpage
    })
  }

  getDatasets = () => {
    let datasets
    if (this.state.searchTerm) {
      datasets = this.state.searchResults
    } else {
      datasets = this.state.datasets || []
    }
    datasets.sort((x, y) => y.lastUpdated - x.lastUpdated)
    let filt
    switch (this.state.calView) {
      case 'recent':
        break
      case 'day':
        filt = job => {
          const { lastUpdated } = job
          return startOfDay(this.state.calDay) < lastUpdated && endOfDay(this.state.calDay) > lastUpdated
        }
        break
      case 'month':
        filt = job => {
          const { lastUpdated } = job
          return startOfMonth(this.state.calDay) < lastUpdated && endOfMonth(this.state.calDay) > lastUpdated
        }
        break
    }
    if (filt) {
      datasets = datasets.filter(filt)
    }
    return datasets
  }

  setSystemPrompt = () => {
    const select = async systemPrompt => {
      const { parent } = this.props
      systemPrompt = clone(systemPrompt)
      parent.systemPrompt = systemPrompt
      await this.props.me.saveDatasetOptions({dataset: parent.id, options: { systemPrompt }})
      this.back()
    }
    const subpage = () => <SystemPrompts
                            copy={this.props.copySystemPrompt}
                            cut={this.props.cutSystemPrompt}
                            me={this.props.me}
                            title={'System Prompts'}
                            select={select}
                            systemPrompts={this.props.systemPrompts}
                            back={this.back}/>
    this.setState({
      subpage
    })
  }

  renderDetail() {
    const datasets = this.getDatasets()
    let style1 = this.state.huggingFaceOpen ? { display: 'none' } : null
    let renderHuggingFace
    if (this.state.huggingFaceOpen) {
      renderHuggingFace = this.renderHuggingFace()
    }
    return <div className='datasetsRight'>
             <div className='datasetsRightFolderTitle' style={style1}>
             </div>
             <div className='datasetsScroller' style={style1}>
               {this.renderDatasets(datasets.filter(x => !x.isFolder))}
             </div>
             {renderHuggingFace && renderHuggingFace()}
           </div>
  }

  
  
  renderDatasets = datasets => {
    const { fineTunedModels } = this.props
    const { models, vendors, getPrice } = this.getModelsForFineTuning()
    const {
      onDropDataset,
      onPasteDataset,
      onDragDataset,
      onDrop,
      onSearch,
      handleDataTransfer
    } = this.getEventHandlers()
    return datasets.map(dataset => {
      if (dataset.isFolder) {
        const open = () =>  this.openFolder(dataset)
        return <DatasetFolderView
                 dataset={dataset}
                 resolveModel={this.props.resolveModel}
                 parents={this.props.parents}
                 parent={this.props.parent}                                             
                 onDrop={onDropDataset}
                 me={this.props.me}
                 getModels={this.props.getModels}
                 open={open}
                 dataset={dataset}
                 fineTunedModels={fineTunedModels}
                 getModels={models}
                 vendors={vendors}
                 me={this.props.me}
                 getPrice={getPrice}
                 models={models}/>
      } else {
        const open = () => this.openDataset({dataset, models})
        return <DatasetView key={dataset.id}
                            cutTask={this.props.cutTask}
                            configure={null}
                            parents={this.props.parents}
                            parent={this.props.parent}
                            onDrag={onDragDataset}
                            getModels={this.props.getModels}
                            openDataset={open}
                            dataset={dataset}
                            fineTunedModels={fineTunedModels}
                            getModels={models}
                            vendors={vendors}
                            me={this.props.me}
                            getPrice={getPrice}
                            models={models}
                            resolveModel={this.props.resolveModel}
               />
      }
    })
  }

  getEventHandlers = () => {
    const handleDataTransfer = async (event, transfer) => {
      const models = []//this.modelsView.getSelectedModelIds()
      if (transfer.files.length > 0) {
        event.preventDefault();
        const parent = this.props.parent ? this.props.parent.id : undefined
        for (const file of transfer.files) {
          const dataset = await this.props.me.createNewDataset({parentDataset: parent, summary: "Loading dataset...",
                                                                name: file.name, generatingDataset: true})
          await this.props.me.uploadDataset({dataset: dataset.id, parent, file, models})
        }
        return true;
      } else {
        const uriList = transfer.getData('text/uri-list')
        if (uriList) {
          event.preventDefault()
          // Split the URI list by newline to handle multiple URLs
          const urls = uriList.split('\n').filter(url => url.trim() !== '')
          console.log('URLs detected:', urls)
          for (const url of urls) {
            await this.props.me.uploadDataset({url, models})
          }
        }
      }
    }
    const onSearch = async (searchTerm) => {
      this.setState({
        searchTerm
      })
      const { results }  = await this.props.me.searchDatasets(this.props.parent, searchTerm)
      //debugger
      this.setState({
        searchResults: results
      })
    }
    const onDrop = event => {
      handleDataTransfer(event, event.dataTransfer)
    }

    const onDragDataset = dataset => {
      this.setState({
        selectedDataset: dataset
      })
    }

    const onPasteDataset = async dataset => {
      const src = this.props.parent
      if (src.id !== dataset.id) {
        await this.props.me.addDatasetParent({child: dataset, parent: this.props.parent.id})
      }
    }
    
    const onDropDataset = async dataset => {
      //debugger
      const src = this.state.selectedDataset || this.props.parent
      const target = dataset
      if (src !== target) {
        if (target.props.dataset.isFolder) {
          if (await this.props.me.addDatasetParent({child: src.props.dataset, parent: target.props.dataset.id})) {
            this.openFolder(target.props.dataset)
          }
        }
      }
    }
    return {
      onDropDataset,
      onPasteDataset,
      onDragDataset,
      onDrop,
      onSearch,
      handleDataTransfer
    }
  }

  renderContent() {
    const { fineTunedModels } = this.props
    const datasets = this.getDatasets()
    const {
      onDropDataset,
      onPasteDataset,
      onDragDataset,
      onDrop,
      onSearch,
      handleDataTransfer
    } = this.getEventHandlers()
    console.log({datasets})
    const onOptionsChanged = () => {
    }
    const onChange = () => {
    }
    let filler = ''
    const { models, vendors, getPrice } = this.getModelsForFineTuning()
    const newDataset = async () => {
      await this.props.me.createNewDataset({parentDataset: this.props.parent})
    }
    const newFolder = async () => {
      await this.props.me.createNewDatasetFolder(this.props.parent)
    }

    let fileChooser = <div className='fileMenu'><FileChooser onCreate={this.setFileChooser} handleDataTransfer={handleDataTransfer}/></div>

    const actions = [
      {
        label: "New Dataset",
        action: newDataset,
        icon: File,
        legacyIconSize: true
      },
      {
        label: "New Folder",
        action: newFolder,
        icon: Folder
      },
      {
        label: "Import File",
        action: () => this.fileChooser.chooseFile(),
        legacyIconSize: true,
        icon: OpenFile
      },
      {
        label: "Import from HuggingFace",
        action: () => this.openHuggingFace(),
        icon: HF
      }
      ]
    if (this.props.parent) {
      const copy = () => copyDataset(this.props.dataset)
      actions.push(
        {
          label: "Copy",
          action: copy,
          icon: Copy
        }
      )
      if (clipboardDataset &&
          !this.props.parents.find(x => x.id === clipboardDataset.id)) {
        actions.push({
          label: "Paste",
          icon: Paste,
          action: () => onPasteDataset(clipboardDataset)
        })
      }
      actions.push({
        icon: Share,
        label: "Export",
        action: this.openExportPopup
      })
    }
    
    let rightContent
    let leftContent
    if (isMobile()) {
      leftContent = this.renderDatasets(datasets)
    } else  {
      leftContent = this.renderDatasets(datasets.filter(x => x.isFolder))
    }
    const menu = <ActionMenu actions={actions} position={"bottom left"}/>
    let sysPromptTitle
    if (this.props.parent) {
      sysPromptTitle = 'System Prompt'      
      if (this.props.parent.systemPrompt) {
        sysPromptTitle = this.props.parent.systemPrompt.title
      }
    }
    return <div className='fineTunedModelsPage bnSubPageTopLevel'>
             <div className='datasetsTopLine'>
               {menu}
               <SearchField me={this.props.me}  menu={fileChooser} onSearch={onSearch}/>
             </div>
             <div className='datasetsCal'>
               <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={this.state.events} onDayChange={this.onDayChange} initialView={'recent'} viewSelection={['recent', 'day', 'month']} />
             </div>
             {this.props.parent && <div className='datasetsFinetuneJobsLine'>
                                     <div className='datasetsFinetuneJobsLineRow'>
                                       <div className='datasetFineTuneJobsTitle' onClick={this.setSystemPrompt} >{sysPromptTitle}</div><SimpleButton icon={Right} action={this.setSystemPrompt}/>
                                     </div>
                                     <div className='datasetsFinetuneJobsLineRow'>
                                       <div className='datasetFineTuneJobsTitle' onClick={this.openFineTuningPopup} >Fine-tune</div><SimpleButton icon={Right} action={this.openFineTuningPopup}/>
                                     </div>
                                   </div>
             }
             <div className='datasetsScroller' onDrop={onDrop}>
               {
                 leftContent
               }
             </div>
             {
               rightContent
             }
           </div>
  }
}

class DatasetView extends Component {

  constructor (props) {
    super(props)
    this.state = {
      options: {}
    }
  }

  optionsSubject = new Subject()

  setModelsView = view => {
    this.modelsView = view
  }

  renderModelConfig = model => {
    console.log("renderModelConfig", model)
    let opts = this.state.options[model.id]
    if (!opts) {
      opts = this.state.options[model.id] = {
        epochs: 1,
        batchSize: 1,
        learningRateBoost: 0,
      }
    }
    const setEpochs = (epochs) => {
      opts.epochs = epochs
      this.forceUpdate()
    }
    const getEpochs = () => {
      return opts.epochs
    }
    const setBatchSize = (batchSize) => {
      opts.batchSize = batchSize
      this.forceUpdate()
    }
    const getBatchSize = () => {
      return opts.batchSize
    }
    const setLearningRate = (learningRate) => {
      opts.learningRateBoost = learningRate
      this.forceUpdate()
    }
    const getLearningRate = () => {
      return opts.learningRateBoost
    }
    return <div key={'modelConfig-'+model.id} className='modelConfig'>
             <div className='tempSlider'><Slider label='Epochs' onChange={setEpochs} value={getEpochs()} bounds={[1, 99]}/></div>
             <div className='tempSlider'><Slider label='Batch' onChange={setBatchSize} value={getBatchSize()} bounds={[1, 32]}/></div>
             <div className='tempSlider'><Slider label='Boost' onChange={setLearningRate} value={getLearningRate()} bounds={[-10, 10]}/></div>
           </div>
  }

  commitEdit = async () => {
    await this.props.me.saveDatasetSummary({dataset: this.props.dataset.id, summary: this.editable.innerText})
    this.setState({
      editingSummary: false
    })
  }

  handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.commitEdit()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      this.cancelEdit()
    }
  }

  cancelEdit = () => {
    this.setState({
      editingSummary: false
    })
  }

  setEditable = ref => {
    if (!this.editable) {
      ref.innerText = this.props.dataset.summary || ""
      this.editable = ref
      ref.focus()
    }
  }

  renderEditable = () => {
    const stopEditing = async () => {
      if (!this.state.editingSummary) {
        return
      }
      this.state.editingSummary = false
      if (isMobile()) {
        await this.commitEdit()
      } else {
        this.cancelEdit()
      }
    }
    const cancelEdit = async () => {
      this.cancelEdit()
    }
    return <ClickAwayListener mouseEvent={'mousedown'} onClickAway={cancelEdit}>
             <div className='editableContentContainer'>
               <div ref={this.setEditable} className='editableContent editableContentSummary'
                    onKeyDown={isDesktop() && this.handleKeyDown}
                    onPaste={pasteText}
                    contentEditable={true}
                    onBlur={stopEditing}/>
             </div>
           </ClickAwayListener>
  }
  
  render() {
    const { dataset } = this.props
    const { id, models, lastUpdated, summary, generatingDataset, examples } = dataset
    const model = models && models[0]
    let trainedTokens = 0
    let epochs = 0
    const open = () => this.props.openDataset({dataset, models})
    const edit = async () => {
      this.setState({
        editingSummary: true
      })
    }
    const play = async () => {
    }
    const trash = async () => {
      if (this.props.parent) {
        await this.props.me.removeDatasetParent({child: dataset, parent: this.props.parent.id})
      } else {
        await this.props.me.deleteDataset(dataset)
      }
    }
    let playing
    let date = fromNow(lastUpdated)

    const onOptionsChanged = () => {
    }

    const observeOptions = () => {
      return this.optionsSubject
    }

    const saveOptions = async (options) => {
      console.log("SAVE OPTIONS", options)
      await this.props.me.saveDatasetOptions({dataset: this.props.dataset.id,
                                              options,
                                              fineTuningOptions: this.state.options})
    }

    const onChange = () => {
    }

    const startFineTune = async (files) => {
      let models = this.modelsView.getSelectedModelIds().map(model => {
        const opts = this.state.options[model] || {
          epochs: 1,
          batchSize: 1,
          learningRateBoost: 0
        }
        for (const field of ['epochs', 'batchSize', 'learningRateBoost']) {
          opts[field] = Math.round(opts.field)
        }
        const { epochs, batchSize, learningRateBoost, seed } = opts
        return {
          epochs, batchSize, learningRateBoost, seed, model
        }
      })
      if (models.length === 0) {
        await delay(0.5)
        return
      }
      await this.props.me.createFineTune({
        dataset: dataset.id,
        datasetSplits: files,
        models,
      })
    }

    const exportDataset = async () => {
      let models = this.modelsView.getSelectedModelIds()
      if (models.length === 0) models = ['train']
      const fileContents = await this.props.me.getDatasetExamples(dataset.id, models)
      let i = 0
      for (const fileContent of fileContents) {
        const blob = new Blob([fileContent], {type: "text/plain;charset=utf-8"})
        let filename
        const model = models[i]
        if (model === 'train') {
          filename = this.props.dataset.name + ".jsonl"
        } else {
          filename = this.props.dataset.name + "-" + model + ".jsonl"
        }
        await saveAs(blob, filename)
        i++
      }
    }

    const button = ({icon, label, action}) => <SimpleButton label={label} action={action} icon={icon}/>
    
    const menu = (label, icon, buttons, configure) => <ModelsView
                                                        key={dataset.id + '-' +label + '-menu'}
                                                        getIcon={() => icon}
                                                        className={'fineTunedModelsView'}
                                                        onOptionsChanged={onOptionsChanged}
                                                        onCreate={this.setModelsView}
                                                        me={this.props.me}
                                                        category={'dataset'}
                                                        getPrice={this.props.getPrice}
                                                        models={this.props.getModels}
                                                        vendors={this.props.vendors}
                                                        onChange={onChange}
                                                        observeOptions={observeOptions}
                                                        saveOptions={saveOptions}
                                                        configure={configure}
                                                      >
                                                        <div className='dataset-control-buttons'>
                                                          {buttons}
                                                        </div>
                                                      </ModelsView>
    const exportButton = button({icon: Share, label: "Export", action: exportDataset})
    const exportMenu = menu('export', Share, exportButton)
    const showSettings = () => {
    }
    const fineTuneMenu = menu('fine-tune',
                              Send,
                              button({icon: Send, label: "Fine-tune", action: startFineTune}),
                              this.renderModelConfig
                             )

    let icon1
    let legacyIconSize
    if (dataset.isHuggingFace) {
      icon1 = HuggingFace
    } else {
      icon1 = File
      legacyIconSize=true
    }
    
    let icon = <div className='datasetIcon' onClick={open}><SimpleIcon legacyIconSize={legacyIconSize} src={icon1}/></div>    
    if (generatingDataset) {
      icon = <div className='projectLeftBusy'><SimpleIcon src={Spin}/></div>
    }
    const onDrop = event => {
      this.props.onDrop(this)
    }
    const onDragStart = event => {
      this.props.onDrag(this)
    }
    let copy = () => copyDataset(dataset)
    let cut
    if (this.props.parent) {
      cut = async () => {
        await copyDataset(this.props.dataset)
        await this.props.me.removeDatasetParent({
          child: this.props.dataset,
          parent: this.props.parent.id
        })
      }
    }
    let paste

    let summaryDiv
    if (this.state.editingSummary) {
      summaryDiv = this.renderEditable()
    } else {
      summaryDiv = <Markdown>{summary}</Markdown>
    }
    let actions =  [
      {
        icon: EditIcon,
        action: edit,
        label: "Edit"
      }
    ]
    actions.push({
      icon: Share,
      action: cut,
      label: "Cut"
    })
    actions.push({
      icon: Copy,
      action: copy,
      label: "Copy"
    })
    if (paste) {
      actions.push({
        icon: Paste,
        action: paste,
        label: "Paste"
      })
    }
    if (trash) {
      actions.push({
        button: (close) => <DeleteButton noClickAway label='Delete' trash={async () => {
                                           await trash()
                                           close()
                                         }}/>
      })
    }
    let markdown
    let { displayInfo } = dataset
    if (displayInfo) {
      markdown = `
## ${displayInfo.heading}
${displayInfo.summary}
`
    } else {
      markdown = dataset.summary || ''
    }
    return <div key={dataset.id}
                className='dataset'
                draggable={!this.state.editingSummary}
                onDragStart={onDragStart}
                >
             <div className='projectLeft'>
               {icon}
             </div>
             <div className='projectMiddle' onClick={!this.state.editingSummary ? open: undefined}>
               <div className='projectTitle'>
                 <div className='projectName'>{dataset.name}</div>
                 <div className='projectDate'>{date}</div>
               </div>
               <div className='projectModelInfoRow modelPriceStyle'>
               </div>
               <div className='projectSummary' >
                 <Markdown components={getComponents()}>{markdown}</Markdown>
               </div>
             </div>
             <div className='projectRight'>
               <ActionMenu actions={actions}/>
             </div>
           </div>

  }
}

