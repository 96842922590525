import React from 'react';
import Right from '../../assets/Icons/Forward.svg'
import Left from '../../assets/Icons/Back.svg'
import Down from '../../assets/Icons/Down.svg'
import Up from '../../assets/Icons/Up.svg'
import {SimpleButton} from '../ChatGPT'
import './card-grid.css';


const styles = {
  container: {
    backgroundColor: '#1a1a1a',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: '20px'
  },
  grid: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  card: {
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    position: 'absolute',
    width: 'calc(33.333% - 16px)',
    height: 'fit-content',
    willChange: 'transform'
  },
  cardHeader: {
    padding: '16px',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardContent: {
    padding: 0,
    opacity: 1
  }
};

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef();
    this.state = {
      cardRect: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isExpanded !== this.props.isExpanded && this.cardRef.current) {
      this.setState({
        cardRect: this.cardRef.current.getBoundingClientRect()
      });
    }
  }

  componentDidMount() {
    this.props.onCreate(this)
  }

  getOffscreenPosition(index, selectedIndex, columns = 3) {
    if (selectedIndex === null) return {};

    const selectedCol = selectedIndex % columns;
    const currentCol = index % columns;
    
    const colDiff = currentCol - selectedCol;
    
    let x = 0;
    if (colDiff < 0) x = -100;
    if (colDiff > 0) x = 100;

    return {
      transform: `translateX(${x}vw)`,
      opacity: 1
    };
  }

  getExpandedStyles() {
    const { cardRect } = this.state;
    const { containerRect } = this.props;
    
    if (!cardRect || !containerRect) return {};

    const translateX = -cardRect.left + containerRect.left;
    const translateY = -cardRect.top + containerRect.top;

    return {
      position: 'absolute',
      top: `${cardRect.top - containerRect.top}px`,
      left: `${cardRect.left - containerRect.left}px`,
      width: `${containerRect.width}px`,
      height: `${containerRect.height}px`,
      transform: `translate(${translateX}px, ${translateY}px)`,
      transformOrigin: '0 0',
      zIndex: 10
    };
  }

  render() {
    const { title, index, isExpanded, selectedIndex, onClick, columnPosition } = this.props;
    const isOtherCard = selectedIndex !== null && selectedIndex !== index;
    
    const left = `${(columnPosition.column * (100/3))}%`;
    
    return (
      <div 
        ref={this.cardRef}
        style={{
          ...styles.card,
          left,
          top: columnPosition.offsetY,
          ...(isExpanded ? this.getExpandedStyles() : {}),
          ...(isOtherCard ? this.getOffscreenPosition(index, selectedIndex) : {})
        }}
        onClick={onClick}
      >
        <div style={styles.cardContent}>
          {this.props.renderContent(isExpanded)}
        </div>
      </div>
    );
  }
}

export class ExpandableCardList extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      selectedIndex: null,
      containerRect: null,
      columnHeights: [0, 0, 0] // Track height of each column
    };
  }

  componentDidMount() {
    if (this.containerRef.current) {
      this.setState({
        containerRect: this.containerRef.current.getBoundingClientRect()
      });
    }
  }

  cardComps = {}
  onCreate = (cardComp, index) => {
    this.cardComps[index] = cardComp
  }

  saved = {}
  getColumnPosition(index) {
    const { columnHeights } = this.state;
    const column = index % 3;
    const offsetY = columnHeights[column];
    if (this.state.selectedIndex) {
      return this.saved[index]
    }
    // Using a more realistic estimation for card height
    const estimatedHeight = this.cardComps[index] ? this.cardComps[index].cardRef.current.getBoundingClientRect().height : 0
    columnHeights[column] += estimatedHeight + 16; // Add gap
    
    return this.saved[index] = { column, offsetY };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedIndex !== prevProps.selectedIndex) {
      this.setState({
        selectedIndex: this.props.selectedIndex
      })
    }
  }

  render() {
    const { selectedIndex, containerRect } = this.state;

    // Reset column heights before re-render
    this.state.columnHeights = [0, 0, 0];

    const cards0 = [
      {
        id: 1,
        title: 'Short Card',
        content: <p>Brief note</p>
      },
      {
        id: 2,
        title: 'Tall Card',
        content: (
          <>
            <p>Line one of content</p>
            <p>Second line here</p>
            <p>Third line here</p>
            <p>Fourth line here</p>
            <p>Fifth line here</p>
          </>
        )
      },
      {
        id: 3,
        title: 'Medium Card',
        content: (
          <>
            <p>First point</p>
            <p>Second point</p>
          </>
        )
      },
      {
        id: 4,
        title: 'Another Short',
        content: <p>Quick update</p>
      },
      {
        id: 5,
        title: 'Big Card',
        content: (
          <>
            <p>Starting with this</p>
            <p>Adding more content</p>
            <p>Keep going here</p>
            <p>Almost done now</p>
            <p>Final thoughts here</p>
            <p>One last point</p>
          </>
        )
      }
    ];

    const cards = this.props.cards

    return (
      <div ref={this.containerRef} style={styles.container}>
        <div style={styles.grid}>
          {cards.map((card, index) => (
            <Card
              onCreate={comp => this.onCreate(comp, index)}
              renderContent={card.renderContent}
              index={index}
              isExpanded={selectedIndex === index}
              selectedIndex={selectedIndex}
              containerRect={containerRect}
              columnPosition={this.getColumnPosition(index)}

            >
              {card.content}
            </Card>
          ))}
        </div>
      </div>
    );
  }
}


