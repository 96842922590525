import React from 'react';
import Viewport from './Viewport';

class VirtualizedView extends React.Component {
  constructor(props) {
    super(props);
    this.localOffset = 0;
    this.handleNearTop = this.handleNearTop.bind(this);
    this.handleNearBottom = this.handleNearBottom.bind(this);
  }

  componentDidMount() {
    if (this.props.onCreate) this.props.onCreate(this)
  }

  onCreateViewport = viewport => {
    this.viewport = viewport
    viewport.registerScrollHandlers(this.handleNearTop, this.handleNearBottom);
  }

  async handleNearTop() {
    if (this.busy) return
    // If we can move locally up, do it. Otherwise, try loading more before.
    if (this.localOffset > 0) {
      this.localOffset = Math.max(0, this.localOffset - this.props.windowSize);
      this.forceUpdate();
    } else {
      // localOffset == 0 means we're at top, try loading more.
      const { onLoadMore } = this.props;
      this.busy = true
      await onLoadMore('before')
      this.busy = false
    }
  }

  async handleNearBottom() {
    if (this.busy) return
    const { baseOffset, windowSize, items, onLoadMore } = this.props;
    const finalOffset = baseOffset + this.localOffset;
    const end = finalOffset + windowSize;

    if (end < items.length) {
      // We can move window down by one
      this.localOffset = Math.min(this.localOffset + windowSize, this.props.items.length)
      this.forceUpdate();
    } else {
      // We're at the bottom of what we have, try loading more
      this.busy = true
      await onLoadMore('after')
      this.busy = false
    }
  }

  maybeUnload() {
    const { items, onUnload, windowSize, baseOffset } = this.props;
    const maxBuffer = 50;
    const finalOffset = baseOffset + this.localOffset;

    // If too many items before finalOffset
    if (finalOffset > maxBuffer) {
      const unloadCount = finalOffset - 20;
      onUnload('before', unloadCount);
      // DataProvider adjusts baseOffset down.
    }

    // If too many items after window
    const end = finalOffset + windowSize;
    if (items.length - end > maxBuffer) {
      const unloadCount = (items.length - end) - 20;
      onUnload('after', unloadCount);
      // No baseOffset change needed for unloading after.
    }
  }

  scrollToItem = async (item, userOptions = {}) => {
    if (!item) return
    const options = {behavior: 'smooth', block: 'nearest'}
    for (const option in userOptions) {
      options[option] = userOptions[option]
    }
    const { items, baseOffset, windowSize, getSelector, getId } = this.props
    const finalOffset = baseOffset + this.localOffset
    const visibleEnd = Math.min(finalOffset + windowSize, items.length)
    const visibleStart = Math.max(finalOffset-windowSize, 0)
    const id = getId(item)
    if (this.props.debug) console.log(`Attempting to scroll to item ${id}`);
    for (let index = 0; index < items.length; index++) {
      const item1 = items[index];
      if (getId(item1) === id) {
        this.targetItem = item1 
        const wasCancelled = () => {
          if (this.targetItem !== item1) {
            if (this.props.debug) console.log("was canceled", item1)
            return true
          }
          return false
        }
        if (!this.viewport) return
        if (index >= visibleStart &&
            index < visibleEnd) {
          const element = this.viewport.containerRef.current.querySelector(getSelector(id))
          if (element) {
            if (this.props.debug) console.log('element found 1')
            await this.viewport.scrollIntoView(element, options, wasCancelled)
            return
          }
        }
        const {start, end } = this.getOffsetBounds(index)
        this.localOffset = start + (end-start)/2
        return await new Promise(resolve => {
          this.forceUpdate2(async () => {
            const element = document.querySelector(getSelector(id))
            if (element) {
              if (this.props.debug) console.log('element found 2')
              if (wasCancelled()) {
                resolve()
                return
              }
              await this.viewport.scrollIntoView(element, options, wasCancelled)

            } else {
              if (this.props.debug) console.log("element not found")
            }
            resolve()
          })
        })
      }
    }
  }

  forceUpdate2 = then => {
    this.forceUpdate(() => {
      this.forceUpdate(then)
    })
  }

  resolveOffset = offset => {
    return this.props.baseOffset + offset
  }

  getOffsetBounds = index => {
    const { items, windowSize } = this.props
    const offset = this.resolveOffset(index)
    const sliceStart = Math.max(offset-windowSize, 0)
    const sliceEnd = Math.min(offset + windowSize, items.length);
    return {
      start: sliceStart,
      end: sliceEnd
    }
  }

  onScroll = scrollTop => {
    return this.props.onScroll({
      offset: this.localOffset,
      scrollTop
    })
  }

  setScrollPos = async ({
    offset,
    scrollTop
  }) => {
    if (this.viewport) {
      this.localOffset = offset
      await new Promise(resolve => {
        this.forceUpdate(() => {
          this.viewport.setScrollTop(scrollTop)
          resolve()
        })
      })
    }
  }
    

  render() {
    const { items, baseOffset, windowSize, getSelector, getId } = this.props;
    const finalOffset = this.resolveOffset(this.localOffset)
    const sliceStart = Math.max(finalOffset-windowSize, 0)
    const sliceEnd = Math.min(finalOffset + windowSize, items.length);
    const visibleItems = items.slice(sliceStart, sliceEnd);
    return  <Viewport
              onScroll={this.props.onScroll ? this.onScroll: undefined}
              isBottomAligned={this.props.isBottomAligned}
              onKeyDown={this.props.onKeyDown}
              autoFocus={this.props.autoFocus}
              onCreate={this.onCreateViewport}
              onNearBottom={this.handleNearBottom}
              onNearTop={this.handleNearTop}
              ref={this.viewportRef} 
              items={visibleItems}
              getSelector={getSelector}
              renderItems={this.props.renderItems}
              getId={getId}
              debug={this.props.debug}
            />
  }
}

export default VirtualizedView;
