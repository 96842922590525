import React from 'react';
import { scrollIntoView } from './ScrollIntoView.js'
import { isFirefoxDesktop, isChrome } from '../../classes/Platform.js'

class Viewport extends React.Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.topAnchorRef = React.createRef();
    this.bottomAnchorRef = React.createRef();
    this.onNearTopHandler = null;
    this.onNearBottomHandler = null;
  }

  registerScrollHandlers(onNearTop, onNearBottom) {
    this.onNearTopHandler = onNearTop;
    this.onNearBottomHandler = onNearBottom;
  }

  isBottomLock = this.props.isBottomAligned

  componentDidMount() {
    const container = this.containerRef.current;
    if (container) {
      container.addEventListener('scroll', this.handleScroll);
      container.addEventListener('wheel', this.handleUserScroll);
      container.addEventListener('touchmove', this.handleUserScroll);
    }
    this.props.onCreate(this)
    this.resizeObserver = new ResizeObserver(() => {
      const update = this.newScrollTop
      this.newScrollTop = null
      if (update) update()
      console.log('scrollTop after resize:', container.scrollTop);
    });
    this.resizeObserver.observe(container);
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
    const container = this.containerRef.current;
    if (container) {
      container.removeEventListener('scroll', this.handleScroll);
      container.removeEventListener('wheel', this.handleUserScroll);
      container.removeEventListener('touchmove', this.handleUserScroll);
    }
  }


  componentWillUnmount() {
    const container = this.containerRef.current;
    if (container) {
      container.removeEventListener('scroll', this.handleScroll);
    }
  }

  scrollIntoView = async (element, options, wasCancelled)  => {
    console.log("SCROLL INTO VIEW")
    this.scrollDisabled = true
    await scrollIntoView(element, options, wasCancelled, this.props.debug)
    this.isNearBottom = this.isNearTop = false
    this.scrollDisabled = false
  }

  scrollToTop = () => {
    console.log("SCROLL TO TOP")
    const container = this.containerRef.current;
    if (container) {
      this.scrollDisabled = true
      container.scrollTop = 0
      this.scrollDisabled = false
      this.isBottomLock = false
    }    
  }

  scrollToBottom = () => {
    console.log("SCROLL TO BOTTOM")
    const container = this.containerRef.current;
    if (container) {
      this.scrollDisabled = true
      container.scrollTop = container.scrollHeight  - container.clientHeight
      this.scrollDisabled = false
    }    
  }

  getViewportBounds = (idStart, idEnd) => {
    const container = this.containerRef.current;
    if (!container) return
    const { getSelector } = this.props
    const startElem = container.querySelector(getSelector(idStart))
    const endElem = container.querySelector(getSelector(idEnd))
    let top1
    let bottom1
    let top2
    let bottom2
    const containerRect = container.getBoundingClientRect();
    if (startElem) {
      top1 = startElem.getBoundingClientRect().top - containerRect.top
      bottom1 = containerRect.bottom - startElem.getBoundingClientRect().bottom 
    }
    if (endElem) {
      top2 = endElem.getBoundingClientRect().top - containerRect.top
      bottom2 = containerRect.bottom - endElem.getBoundingClientRect().bottom 
    }
    return { top1, bottom1, top2, bottom2 }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const container = this.containerRef.current;
    if (!container) return
    const containerRect = container.getBoundingClientRect();
    const { idStart, idEnd } = this
    const { top1, bottom1, top2,  bottom2 } = this.getViewportBounds(idStart, idEnd)
    const  { scrollTop, scrollHeight, clientHeight } = container
    const scrollBottom = scrollHeight - (scrollTop + clientHeight)
    return {
      top1, bottom1, top2, bottom2, scrollTop, scrollBottom, scrollHeight, clientHeight, idStart, idEnd
    }
  }

  renderCount = 0

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!snapshot) return;
    this.renderCount++
    const container = this.containerRef.current;
    if (!container) return
    const containerRect = container.getBoundingClientRect();
    const {
      top1, bottom1, top2, bottom2, scrollTop, scrollBottom, scrollHeight, clientHeight, idStart, idEnd,
    } = snapshot

    console.log({snapshot})

    const scrollTopDelta = container.scrollTop - scrollTop
    const heightDelta = container.scrollHeight - scrollHeight
    const newBounds = this.getViewportBounds(idStart, idEnd)
    // debugger
    let deltaTop = 0
    let deltaBottom = 0
    if (newBounds.top1 !== undefined && top1 !== undefined) {
      deltaTop = newBounds.top1 - top1
    } else if (newBounds.top2 !== undefined && top2 !== undefined) {
      deltaTop = newBounds.top2 - top2
    }
    if (newBounds.bottom1 !== undefined && bottom1 !== undefined) {
      deltaBottom = newBounds.bottom1 - bottom1
    }      
    else if (newBounds.bottom2 !== undefined && bottom2 !== undefined) {
      deltaBottom = newBounds.bottom2 -  bottom2
    }      
    {
      const { scrollTop, scrollHeight } = container
      console.log('after render', {newBounds, scrollTop, scrollHeight, heightDelta, scrollTopDelta, deltaTop, deltaBottom})
    }
    if (deltaTop < 1 && isChrome()) {
      deltaTop = 0
    }
    if (deltaTop) {
      // insert or delete above the viewport
      let newScrollTop = Math.round(scrollTop + deltaTop)
      this.scrollDisabled = true
      const current = container.scrollTop
      container.offsetHeight
      container.scrollTop = newScrollTop
      console.log('old', current, newScrollTop, '=>', container.scrollTop)
      this.lastScrollTop = container.scrollTop
      this.scrollDisabled =false
    } 
    if (deltaBottom) {
      // insert or delete below the viewport
      //this.scrollDisabled = true
      //container.scrollTop = scrollBottom + deltaBottom - (container.scrollHeight  + container.clientHeight)
      //this.scrollDisabled = false
    }
    if (this.props.debug) console.log({deltaTop, deltaBottom})
    if (deltaTop || deltaBottom || isFirefoxDesktop()) {
      this.checkMomentumScroll()
    } else if (this.isBottomLock && this.props.isBottomAligned) {
      this.scrollToBottom()
    }
  }

  handleUserScroll(e: Event) {

    const { scrollHeight, scrollTop, clientHeight } = this.container;

    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);

    

    // Update user's intended position

    this.lastUserScrollPosition = scrollTop;

    this.isUserNearBottom = distanceFromBottom <= this.threshold;

  }

  lastDeltaY = 0
  lastDeltaYTime = 0
  checkMomentumScroll = () => {
    const ref = this.containerRef.current
    if (!ref) return;
    const lastDeltaY = this.lastDeltaY
    const timeDelta = (performance.now() - this.lastDeltaYTime)
    console.log({lastDeltaY, timeDelta})
    if (isChrome()) {
      return
    }
    if (this.lastDeltaY && timeDelta < 600) {
      this.handleMomentumScroll(ref, this.lastDeltaY, 300);
      this.lastDeltaY = 0;
    }
  }
  
  handleMomentumScroll = (targetElement, initialDeltaY, duration) => {
    if (!targetElement) return;
    let clamp = 15
    initialDeltaY = Math.max(-1*clamp, Math.min(clamp, initialDeltaY));
    const steps = 20;
    const frameDelay = Math.round(duration / steps / (1000 / 60)); // Frames between steps
    let currentDeltaY = initialDeltaY;
    let frameCount = 0;
    
    const animate = () => {
      if (!targetElement || Math.abs(currentDeltaY) < 1) {
        this.endMomentumScroll()
        return;
      }
      //console.log("momentum", {frameCount, frameDelay, currentDeltaY})
      frameCount++;
      if (frameCount >= frameDelay) {
        frameCount = 0;
        targetElement.scrollTop += currentDeltaY;
        currentDeltaY *= 0.90; // Keep original decay
        requestAnimationFrame(animate);
      } else {
        requestAnimationFrame(animate);
      }
    };
    this.startMomentumScroll()
    requestAnimationFrame(animate);
  }

  startMomentumScroll = () => {
    this.scrollDisabled = true
  }

  endMomentumScroll = () => {
    this.scrollDisabled = false
  }

  lastUserScrollTop = 0
  lastUserDeltaY = 0
  lastUserDeltaYTime = 0
  isUserNearBottom = this.props.isBottomAligned

  handleUserScroll = () => {
    const container = this.containerRef.current;
    if (!container) return;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const scrollBottom = scrollHeight - (scrollTop + clientHeight);
    const delta = scrollTop - this.lastUserScrollTop
    this.lastUserScrollTop = scrollTop
    const now = performance.now()
    const timeDelta = now - this.lastUserDeltaYTime
    const nearThreshold = 2;
    if (delta >= 0) {
      if (scrollBottom < nearThreshold) {
        if (!this.isBottomLock) {
          debugger
        }
        this.isBottomLock = true
      }
    } else {
      if (this.isBottomLock) {
        debugger
      }
      this.isBottomLock = false
    }
    this.lastUserDeltaY = delta
    this.lastUserDeltaYTime = now
  }

  handleScroll = () => {
    const container = this.containerRef.current;
    if (!container) return;
    if (this.scrollTopOverride) {
      container.scrollTop = this.scrollTopOverride
      this.scrollTopOverride = 0
      return;
    }
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const scrollBottom = scrollHeight - (scrollTop + clientHeight);
    const { scrollDisabled, lastScrollTop } = this
    if (scrollDisabled) {
      console.log("handleScroll", {
        scrollTop, scrollHeight, clientHeight, scrollBottom, scrollDisabled, lastScrollTop
      })
    }
    if (scrollDisabled) {
      this.lastScrollTop = container.scrollTop
      return
    }
    if (this.lastScrollTop !== undefined) {
    }
    this.lastScrollTop = scrollTop

    const nearThreshold = 2;
    if (scrollTop < nearThreshold) {
      if (!this.isNearTop) {
        this.isNearTop = true
        if (this.onNearTopHandler) {
          this.onNearTopHandler();
        }
      }
    } else {
      this.isNearTop = false
    }
    if (scrollBottom < nearThreshold) {
      if (!this.isNearBottom) {
        //debugger
        if (this.onNearBottomHandler) {
          this.onNearBottomHandler();
        } else {
          this.isNearBottom = true
        }
      }
    } else {
      this.isNearBottom = false
    }
    if (this.props.onScroll) {
      this.props.onScroll(container.scrollTop)
    }
  }

  setScrollTop = scrollTop => {
    const container = this.containerRef.current;
    if (container) {
      container.scrollTop = scrollTop
    }
  }
  
  render() {
    const { items, getId, renderItems } = this.props;
    if (items.length > 0) {
      this.idStart = getId(items[0])
      this.idEnd = getId(items[items.length-1])
    } else {
      this.idStart = ''
      this.idEnd = ''
    }
    return (
      <div
        ref={this.containerRef}
        onKeyDown={this.props.onKeyDown}
        autoFocus={this.props.autoFocus}
        tabIndex={this.props.onKeyDown ? 0 : undefined}
        className='itemScroller'
      >
        {renderItems(items)}
      </div>
    );
  }
}

export default Viewport;
