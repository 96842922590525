import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './LossGraph.css';

export const LossGraph = ({ metrics, model, dataset, jobId }) => {
  const chartRef = useRef(null);
  let max = 10000;
  if (metrics.length > max) {
    const stride = Math.floor(metrics.length / max);
    if (stride > 1) {
      metrics = metrics.filter((x, i) => i % stride === 0);
    }
  }
  const sortedLosses = metrics.map((point) => point.train_loss).sort((a, b) => a - b);
  const lowerBound = sortedLosses[0];
  const upperBound = sortedLosses[Math.floor(0.75 * sortedLosses.length)]; // 95th percentile


  // Prepare data for Chart.js
  const chartData = {
    labels: metrics.map((point) => point.step),
    datasets: [
      {
        label: 'Training Loss',
        data: metrics.map((point) => point.train_loss),
        borderColor: '#2563eb',
        backgroundColor: 'rgba(37, 99, 235, 0.2)',
        borderWidth: 1.5,
        tension: 0.4, // Smooth line
        pointRadius: 0, // No points (dots) on the line
      },
    ],
  };

  // Chart.js options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `Loss: ${context.raw.toFixed(4)}`,
          title: (context) => `Step: ${context[0].label}`,
        },
        backgroundColor: '#1a2b32',
        titleColor: '#fff',
        bodyColor: '#fff',
        borderColor: '#fff',
        borderWidth: 1,
      },
      legend: {
        display: false,
        labels: {
          color: '#fff',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Training Step',
          color: '#fff',
        },
        ticks: {
          color: '#fff',
        },
        min: Math.min(...metrics.map((point) => point.step)), // Ensure full range
        max: Math.max(...metrics.map((point) => point.step)), 
      },
      y: {
        suggestedMax: upperBound,
        title: {
          display: true,
          text: 'Loss',
          color: '#fff',
        },
        ticks: {
          color: '#fff',
          callback: (value) => {
            return value.toFixed(2)
          },
        },
        beginAtZero: false, // Dynamically adjusts to data
      },
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = chartData; // Update data
      chartRef.current.options = chartOptions; // Update options
      chartRef.current.update(); // Force chart update
    }
  }, [jobId]);

  return (
    <div className="lossGraphContainerStyle">
      {model}
      {dataset && <div className="lossGraphDataset">{dataset.name}</div>}
      <h2 className="lossGraphTitleStyle">Training Loss Over Time</h2>
      <div className="lossGraphGraph">
        <Line key={jobId} data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};
