import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import Pack from '../../assets/Icons/Pack.svg'
import Tick from '../../assets/Icons/Tick.svg'
import Spin from '../../assets/Icons/Spin.svg'
import { BnSubpage } from '../Page'
import { CreditsPreview, getWord, HomeButton, Account } from '../Home'
import { KeyboardButton } from '../Keyboard'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { StripePaymentMethodInput } from './Purchase.js'
import { SimpleButton } from '../ChatGPT'
import Cross from '../../assets/Icons/Cross.svg'
/*
import {Elements} from '@stripe/react-stripe-js'
import {ElementsConsumer, CardElement, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js'
*/
import './index.css'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { BnRemoveButton, BnButton } from '../Button'
import { delay } from '../../classes/Util.js'




const SummaryLine = props => {
  let className = 'keyboardWordPackSummaryLine'
  if (props.className) {
    className += ' ' + props.className
  }
  return <div className={className}>
           <div className='keyboardWordPackSummaryLineLabel'>
             {props.label}
           </div>
           <div className='keyboardWordPackSummaryLineValue'>
             {props.value}
           </div>
         </div>
}
 
const TotalLine = props => {
  const { value } = props
  return SummaryLine( {
    label: 'Total',
    value,
    className: 'keyboardWordPackTotalLine'
  })
}


export class WordPackPurchase extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.wordPacks = []
    const { word, Word } = getWord(this.props.me)
    this.state.status = `Add ${word}s to your account`
    this.state.isPurchase = false
    this.state.paymentMethods = []
  }

  wordPacks = {}
  
  componentDidMount() {
    if (!this.props.wordPack) {
      this.sub = this.props.me.observeWordPacks().subscribe(change => {
        ////debugger
        if (this.state.busy) {
          const resolve = this.state.busy
          this.state.busy = null
          this.forceUpdate()
          resolve()
        }
        const {type, wordPack } = change
        if (type == 'removed') {
          delete this.wordPacks[workdPack.id]
        } else {
          this.wordPacks[wordPack.id] = wordPack
        }
        this.updateWordPacksLater()
      })
    } else {
      this.setState({
        status: 'payment summary',
        wordPacks: [this.props.wordPack],
        isPurchase: true
      })
      this.props.me.createPaymentIntent(this.props.wordPack.productId).then(({paymentIntent}) => {
        ////debugger
        this.setState({
          paymentIntent
        })
      })
    }
  }
  
  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
    if (this.paymentSub) {
      this.paymentSub.unsubscribe()
    }
  }

  updateWordPacksLater = () => {
    clearTimeout(this.wordPackUpdater)
    this.wordPackUpdater = setTimeout(this.updateWordPacksNow, 200)
  }

  updateWordPacksNow = () => {
    const wordPacks = Object.values(this.wordPacks)
    wordPacks.sort((x, y) => {
      return y.price - x.price
    })
    this.setState({
      wordPacks 
    }, () => {
      if (!this.isInline() && !this.state.isPurchase) {
        this.setState({
          selectedWordPack: wordPacks[wordPacks.length-1]
        })
      }
    })
  }

  
  renderPurchase = (wordPack) => {
    let back
    debugger
    if (this.isInline()) {
      back = this.back
    }
    return <WordPackPurchase
             key={wordPack.id}
             isInline={this.isInline()}
             title={`Purchase Credits`}
             wordPack={wordPack}
             me={this.props.me}
             back={back}
             available={this.props.available}/>
  }
  
  purchase = async (wordPack, status) => {
    const { word, Word } = getWord(this.props.me)
    if (this.state.isPurchase) {
      return await this.buy(wordPack, status)
    } else {
      if (this.isInline()) {
        this.setState({
          subpage: () => this.renderPurchase(wordPack)
        })
      } else {
        this.setState({
          selectedWordPack: wordPack
        })
      }
    }
  }

  buyWithStripe = async (wordPack, status) => {
    const paymentIntent = this.state.paymentIntent
    if (this.props.back) this.props.back()
  }

  buy = async (wordPack, status) => {
    if (!this.props.me.isNative()) {
      return this.buyWithStripe(wordPack, status)
    }
    this.state.status = "processing payment"
    this.state.purchaseStatus = 'purchase'
    this.forceUpdate()
    const outcome = await this.props.me.purchaseProduct(wordPack.id)
    ////debugger
    const { verified, failure } = outcome
    if (verified) {
      console.log('purchase complete', wordPack)
      this.props.back()
    } else {
      console.log('purchase canceled')
    }
  }

  activateReferralCode = () => {
    this.setState({
      subpage: () => <Account title={"Account"} me={this.props.me}
                              keys={{}}
                              getSetting={this.props.getSetting}
                              toggleSetting={this.props.toggleSetting}
                              back={this.back}/>
    })
  }

  renderDetail() {
    if (this.state.selectedWordPack) {
      const renderPurchase = wordPack => {
        return <WordPackPurchase
                 wordPack={wordPack}
                 me={this.props.me}
                 available={this.props.available}/>
      }
      return <div className='desktopPurchase'>
               {renderPurchase(this.state.selectedWordPack)}
             </div>
    }
  }

  render() {
    if (this.state.isPurchase && !this.isInline()) {
      return this.renderMe()
    }
    return super.render()
  }

  
  renderContent() {
    return this.renderMe()
  }

  isInline() {
    return this.props.isInline || isMobile()
  }
  
  renderMe() {
    const { word, Word } = getWord(this.props.me)
    let showAvail = (this.isInline() || this.state.isPurchase)

    return <div className='bnSubpageTopLevel'>
             { (this.props.me.isAdmin || this.props.me.isNative() || !this.props.me.isNative()) &&
               <div className='keyboardWordPackContent'>
             <div className='keyboardWordPackHeader'>
               <div className='keyboardWordPackHeaderLabel'>
                 {this.state.status}
               </div>
               {false && showAvail &&<div className='keyboardWordPackHeaderAvailable'>
                              <div className='keyboardWordPackHeaderAvailableAmount'>
                                {this.props.availableFmt}
                              </div>
                              <div className='keyboardWordPackHeaderAvailableLabel'>
                                available
                              </div>
                            </div>}
             </div>
                 {!this.state.isPurchase && <div className='wordPackCreditsPreview'>
                   <CreditsPreview
                     available={this.props.available}
                     used={this.props.used}
                     purchased={this.props.purchased}
                     Purchased={this.props.Purchased}
                   />
                               </div>}
             <div className='keyboardWordPurchase'>
               {
                 this.state.wordPacks.map(wordPack => {
                   return <WordPackButton
                            selected={wordPack === this.state.selectedWordPack}
                            key={wordPack.id} wordPack={wordPack}
                            me={this.props.me}
                            back={this.props.back}
                            isPayment={this.state.isPurchase}
                            paymentIntent={this.state.paymentIntent}
                            paymentStatus={this.state.paymentStatus}
                            purchase={()=>this.purchase(wordPack)}/>
                 })
               }
            </div>
               </div>}
             {false && !this.state.isPurchase &&
             <div key='refer' className='keyboardWordPackRefer'>
               <HomeButton icon={Pack} label='Refer your friends' buttonLabel=<span>Get <span className='FREE'>FREE</span> {word}s</span> action={this.activateReferralCode}/>
               />
                 </div>}
             </div>
  }
}

class WordPackButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }

  onPaymentMethodStatusChange = status => {
    this.setState(status)
  }

  onCreatePaymentMethod = ref => {
    this.paymentMethodInput = ref
  }

  buy = async () => {
    ////debugger
    if (this.paymentMethodInput) {
      this.setState({
        paymentMethodError: null
      })
      const result = await this.paymentMethodInput.apply()
      if (result.error) {
        this.setState({
          paymentMethodError: result.error
        })
        return
      }
    }
    return await this.props.purchase()
  }
  
  render() {
    let msg
    let statusClass = 'keyboardWordPackStatus'
    const formatAmount = wordCount => {
      if (wordCount < 1000) {
        return wordCount
      } else if (wordCount < 1000000) {
        return Math.round(wordCount / 1000)+'K'
      }
      else {
        let n = wordCount / 1000000
        if (n % 1 !== 0) {
          n = n.toFixed(1)
        }
        return n + 'M'
      }
    }
    const { word, Word } = getWord(this.props.me)
    const formatWordCount = wordCount =>  <span><span className='wordCount'>{formatAmount(wordCount)}</span>&nbsp;{`${word}s`}</span>
    const formatPrice = price => '$'+price.toFixed(2) + ' USD'
    const busy = this.state.busy
    const wordPack = this.props.wordPack
    let { name, creditCount, price } = wordPack
    let ourPrice = price
    let apple = formatPrice(price) 
    let subtotal = formatPrice(ourPrice)
    let total
    if (!this.props.me.isNative()) {
      total = subtotal
    } else {
      total = formatPrice(price)
    }
    let className = 'keyboardWordPack' + (this.props.isPayment ? ' keyboardWordPackPayment' : '')
    let statusRight = <div className='keyboardWordPackWordPrice'>{total}</div>
    const renderStatus = msg => {
      return <div className='keyboardWordPackPaymentStatus'>{msg}</div>
    }
    let buyButton
    let spin = false
    if (this.props.isPayment) {
      buyButton = <SimpleButton label='Buy' icon={Tick} action={this.buy}/>          
      switch (this.props.paymentStatus) {
        case 'canceled':
          className += ' keyboardWordPackPaymentStatusCanceled'
          buyButton = null
          statusRight = renderStatus(this.props.paymentStatusMsg)
          break
        case 'complete':
          className += ' keyboardWordPackPaymentStatusComplete'
          statusRight = renderStatus(this.props.paymentStatusMsg)
          buyButton = <KeyboardButton label='Done' icon={Tick} action={this.props.back}/>          
          break
        case 'purchase':
        case 'verify':
          spin = true
          statusRight = renderStatus(this.props.paymentStatusMsg)
          className += ' keyboardWordPackPaymentStatusProcessing'
          buyButton = null
          break
      }
      if (!this.props.me.isNative()) {
        if (false && !this.props.paymentIntent) {
          spin = true
          buyButton = null
        }
      }
    }

    let feeLine
    if (!!this.props.me.isNative()) {
      feeLine = <SummaryLine label='Apple Fee' value={Math.round(price * 100 * 0.15) + " Credits"}/>
    } else {

    }
    console.log("paymentIntent", this.state.paymentIntent)
    if (this.props.selected) {
      className += ' wordPackButtonSelected'
    }
    return <div className={className}>
             <div className='keyboardWordPackRow1' onClick={buyButton ? undefined: this.props.purchase}>
             <div className='keyboardWordPackLeft'>
               <div className='keyboardWordPackBuyButtonIcon'>
                 <ReactSVG src={Pack}/>
               </div>
               <div className='keyboardWordPackWordCount'>{formatWordCount(creditCount)}</div>
             </div>
             <div className='keyboardWordPackRight'>
               {statusRight}
             </div>
             </div>
             {this.props.paymentIntent && <div key='row2' className='keyboardWordPackRow2'>
                                        {!this.props.me.isNative() &&
                                         <StripePaymentMethodInput me={this.props.me} paymentIntent={this.props.paymentIntent} onCreate={this.onCreatePaymentMethod} onStatusChange={this.onPaymentStatusChange}/>}
                                        <div className='keyboardWordPackOrderSummary'>
                                          <SummaryLine label='Price' value={subtotal}/>
                                          {feeLine}
                                          <TotalLine l value={total}/>
                                        </div>
                                        {buyButton}
                                        {this.state.paymentMethodError &&
                                         <div className='wordpackPaymentMethodError'>
                                           <HomeButton icon={Cross} buttonLabel={this.state.paymentMethodError.message}/>
                                         </div>
                                        }
                                           
                                      </div>}
             {spin && <div key='spin' className='wordPackSpinContainer'>
                        <ReactSVG src={Spin}/>
                      </div>}
           </div>
  }

}


