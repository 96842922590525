import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindCallback, of, concat, from, Subject, merge as mergeN, combineLatest } from 'rxjs'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import VerticalDots from '../../assets/Icons/VerticalDots.svg'
import Send from '../../assets/Icons/Send.svg'
import Pack from '../../assets/Icons/Pack.svg'
import Cross from '../../assets/Icons/Cross.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import Spin from '../../assets/Icons/Spin.svg'
import Stop from '../../assets/Icons/Stop.svg'
import Buy from '../../assets/Icons/Buy.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import Chat from '../../assets/Icons/Search/Chat.svg'
import Down from '../../assets/Icons/Down.svg'
import Up from '../../assets/Icons/Up.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import Image from '../../assets/Icons/Search/Image.svg'
import Lucky from '../../assets/Icons/Search/Lucky.svg'
import Trending from '../../assets/Icons/Search/Trending.svg'
import Search from '../../assets/Icons/Search/Search.svg'
import LogoSmall from '../../assets/Icons/Search/SS_AW_Logo_164_28.svg'
import LogoLarge from '../../assets/Icons/Search/SL_AW_Logo_216_38.svg'
import { UComponent, BnPage, BnSubpage } from '../Page'
//const { Model } = require('../ChatGPT/ModelsMenu.js')
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { delay } from '../../classes/Util.js'
import { FadeComponents } from '../FadeSpan'
import { FileChooser, Navigation, SimpleIcon, getComponents, SimpleButton, ChatGPT, SearchField } from '../ChatGPT'
import { ModelsView, formatPrice } from '../ChatGPT/ModelsMenu.js'
import {  CreditsPreview, getWord, Home, Usage, HomeCard, Vendors, getModelIcon } from './index.js'
import { Markdown } from '../ChatGPT/Markdown.js'
import ClickAwayListener from 'react-click-away-listener'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Scrollbar, Mousewheel } from 'swiper/modules';
import './SearchGPT.css'

const GoogleSearchResults = props => {
  const { results, renderMarkdown, openLink, q } = props
  return <div className='googleSearch'>
           <div className='fakeInputFieldContainer'>
             <div className='fakeInputField'>
               {q}
             </div>
           </div>
           <div className="google-results">
             {results.map((result, index) => {
               let url = result.url
               const h = url.indexOf('#')
               if (h > 0) {
                 url = url.substring(0, h)
               }
               return <div key={index} className="result-item">
                        <span className="result-url">{url}</span>
                        <div className className="aiLink result-title" onClick={e => openLink(url)}>
                          {result.title}
                        </div>
                        <div className="result-snippet">{renderMarkdown(result.snippet)}</div>
                      </div>
             })}
           </div>
         </div>
}



const hasImage = searchTerm => {
  const markdownImageRegex = /!\[([^\]]*)\]\(([^)\s]+)(?:\s+"([^"]*)")?\)/g;
  return markdownImageRegex.test(searchTerm)
}

const Model = props => {
  let { className = '', model, isSelected, action, busy } = props
  let { title } = model
  if (model.id == 'attunewise') {
    title = "Attunewise"
  }
  if (model.id == 'mistral-large') {
    title = "Mistral Large 2"
  }
  else if (model.id == 'mistral-small') {
    title = "Mistral Small"
  }
  else if (model.id == 'deepseek-chat') {
    title = "DeepSeek V3"
  }
  else if (model.id == 'deepseek-reasoner') {
    title = "DeepSeek R1"
  }
  else if (model.id == 'reka-core') {
    title = "Reka Core"
  }
  else if (model.id == 'reka-core') {
    title = "Reka Core"
  }
  else if (model.id == 'reka-flash') {
    title = "Reka Flash"
  }
  else if (model.id == 'r1-distill-llama-70b') {
    title = "R1 Distill LLama 70b"
  }
  className = 'searchGptModelButton' + (className ? ' ' + className : '')
  if (isSelected) {
    className += ' searchGptModelButtonSelected'
  }
  return <div className={className}>
           <SimpleButton icon={busy ? Spin: model.getIcon()} label={title} action={action}/>
         </div>
}

const AttunewiseHeader = props => {
  return <div className='attunewiseHeader'>
           <div className='attunewiseHeaderBottomRow'>
             <div className='attunewiseHeaderBottomRowLeft'>
               <img src={AttunewiseLogo}/>
               <div className='attunewise'>
                 <div className='attune'>ATTUNE</div>
                 <div className='wise'> WISE</div>
               </div>
             </div>
             <div className='attunewiseHeaderBottomRowRight'>
               <SimpleButton icon={VerticalDots} action={props.action}/>
             </div>
           </div>
         </div>
}


const SearchGptAutocomplete = props => {
  const { icon, autocomplete, news, onClick, components } = props
  let className = 'searchGptAutocomplete'
  return <div className={className} onClick={onClick}>
           <div className='searchGptAutocompleteImg'>
             <SimpleIcon src={icon}/>
           </div>
           <div className='searchGptAutocompleteText'>
             <Markdown components={components}>{autocomplete}</Markdown>             
           </div>
         </div>
}


const modelIds = [
  "attunewise",
  "claude-3.5-haiku",
  "claude-3.5-sonnet",
  //"claude-3-haiku",
  //"gemini-1.5-flash",
  "deepseek-chat",
  "deepseek-reasoner",
  "gemini-2.0-flash-exp",
  "gpt-4o-mini",
  "gpt-4o",
  "grok-2",
  //"llama-3.1-70b",
  "llama-3.3-70b",
  "llama-3.1-405b",
  "r1-distill-llama-70b",
  "mistral-large",
  "nova-lite",
  "nova-pro",
  "o1-mini",
//  "qwen-2.5-32b-coder",
//  "cohere-command-r-plus",
//  "cohere-command-r",
//  "reka-core", 
//  "reka-flash"
    
]

class SearchGPTSettings extends Component {
  render() {
    const { word, Word } = getWord()
    let Settings = 'Account'
    let available = Math.max(this.props.wordsPurchased - this.props.wordsUsed, 0)
    if (this.props.me.isSignedInAnonymously()) {
      Settings = "Sign-in"
      if (available <= 0) {
        //Settings = "Purchase Credits"
      }
    }
    console.log("props", this.props)
    let isLowMessage = this.props.wordsPurchased - this.props.wordsUsed < 10
    const close = e => {
      if (e instanceof FocusEvent) {
        return
      }
      this.props.closeSettings()
    }
    return <ClickAwayListener onClickAway={close}>
             <div className='searchGptSettings' style={{opacity: 1}}>
               <HomeCard isButton={true} label="settings" buttonLabel='Settings' action={close} icon={Cross}>
               </HomeCard>
               <div className='scrollableSettings'>
               <HomeCard label="track costs" buttonLabel='Usage' action={this.props.usage}>
                 <div className='recentUsage'>
                   {
                     this.props.me.self &&
                       <Usage
                         preview
                         models={this.props.models}
                         vendors={this.props.vendors}
                         me={this.props.me}
                       />
                   }
                 </div>
               </HomeCard>
               <HomeCard label="Available Models" buttonLabel='Models' action={this.props.openModels}>
                 <div className='recentUsage' >
                   {this.props.renderModelOptions({'key': 'searchModels', category: 'searchModels', preview: true, vendorOnly: true, unselectable: true})}
                   </div>
               </HomeCard>
               <HomeCard className={'wordPackBuyButton' + (
                           isLowMessage ? ' wordPackBuyButtonLow' : '')}  label={`add ${word}s to your account`} buttonLabel={`${Word}s`} action={this.props.buy}>
                 <CreditsPreview
                   available={available}
                   used={this.props.wordsUsed}
                   purchased={this.props.wordsPurchased}
                   Purchased={this.props.Purchased}
                 />
               </HomeCard>               
               <HomeCard isButton={true} label="Account" buttonLabel={Settings} action={this.props.account}>
               </HomeCard>
               </div>
             </div>
           </ClickAwayListener>
  }
}

class MiniHome extends Home {

  constructor (props) {
    super(props)
    this.openDetail = null
  }

  isInline() {
    return true
  }

  filterModels(models) {
    return models.filter(x => modelIds.find(y => x.id === y))
  }

  filterVendors(vendors) {
    const result = vendors.filter(x => modelIds.find(y => this.models[y] && this.models[y].vendorId === x.id))
    console.log({filteredVendors: result})
    return result
  }

  renderContent() {
    const self = this
    class ModelPage extends BnSubpage {
      renderContent() {
        return self.renderModelOptions({
          key:'vendors',
          category: 'searchModels',
          preview: true,
          unselectable: true,
          vendorOnly: false
        })
      }
    }
    const openModels = () => {
      this.setState({
        subpage: () => <ModelPage title={'Models'} back={this.back} me={this.props.me}/>
      })
    }
    return <SearchGPTSettings
             me={this.props.me}
             closeSettings={this.props.closeSettings}
             usage={() => this.openUsage({includeCalendar:true})}
             models={
               this.state.models.map(x => {
                 x.isSelected = () => false
                 x.select = () => {}
                 return x
               })
             }
             openModels={openModels}
             renderModelOptions={(options) => this.renderModelOptions(options)}
             vendors={this.state.vendors || []}
             available={Math.max(this.state.wordsPurchased - this.state.wordsUsed, 0)}
             wordsPurchased={this.state.wordsPurchased}
             wordsUsed={this.state.wordsUsed}
             Purchased={this.state.nonFree ? 'Purchased' : 'Free'}
             account={() => this.openAccount({title: "Account"})}
             buy={this.buy}
           />
  }

  hasCreditsLoaded = () => this.state.wordsPurchased
  getCredits = () => false ? 0 : (this.state.wordsPurchased || 0) - (this.state.wordsUsed || 0)
  
  render() {
    let subpage = this.state.subpage ? this.state.subpage() : null
    const content = this.renderContent()
    return <div className='miniHomePage'>
             <BnPage me={this.props.me} subpage={subpage} safeArea={true}>
              {content}
             </BnPage>
           </div>
  }
}

export class SearchGPT extends UComponent {

  handleDataTransfer = async (event, transfer)=> {
    if (transfer.files.length > 0) {
      event.preventDefault();
      event.stopPropagation()
      this.uploadInProgress = true
      this.forceUpdate()
      for (const file of transfer.files) {
        const url = URL.createObjectURL(file);
        const setSource = this.searchField.insertImage(url)
        const ref = await this.props.me.uploadFile(file)
        setSource(await ref.getDownloadURL())
      }
      this.searchField.focus()
      this.uploadInProgress = false
      this.forceUpdate()
      return true
    }
  }

  onCreateMiniHome = ref => {
    this.miniHome = ref
  }
  
  onPaste = e => {
    if (this.handleDataTransfer(e, e.clipboardData)) {
      return
    }
  }
  

  constructor (props) {
    super(props)
    this.state = {}
    const state = {
      searchProgress: [],
      autocompletes: [],
      searchTerm: '',
      searchResult: '',
      topNews: [],
      history: [],
      searchFieldHeight: 40
    }
    for (const id in state) {
      this.state[id] = state[id]
    }
  }

  transcript = []
  remoteTranscript = []

  setSearchFieldHeight = searchFieldHeight => {
    this.setState({
      searchFieldHeight
    })
  }

  onSearch = async searchTerm => {
    searchTerm = searchTerm.trim()
    // this is fun
    console.log("onSearch", searchTerm)
    if (this.state.searchTerm === searchTerm) {
      this.forceUpdate()
      return
    }
    this.state.searchTerm = searchTerm
    
    if (searchTerm && !this.state.searchTerm) {
      this.state.showAutocompletes = true
    }
    this.state.searchTerm = searchTerm
    if (!searchTerm) {
      this.clearSearchResult()
      this.getAutocompletes()
    } else {
      this.getSuggestions(searchTerm)
    }
    this.forceUpdate()
  }

  getSuggestions = async (searchTerm) => {
    if (searchTerm && this.state.showAutocompletes && !this.uploadInProgress) {
      const { suggestions } = await this.props.me.autocompleteSuggestions(this.state.searchTerm)
      if (searchTerm === this.state.searchTerm) {
        this.state.suggestions = suggestions
        this.forceUpdate()
      }
    }
  }

  cancel = () => {
    debugger
    if (this.xhr) {
      this.xhr.abort()
      this.xhr = null
    }
  }

  clearSearchResult = () => {
    this.cancel()
    if (this.state.messageContent) {
      const message = {
        role: 'assistant',
        content: this.messageContent
      }
      this.transcript.push({
        when: Date.now(),
        message,
      })
      this.remoteTranscript.push(message)
    }
    this.state.searching = false
    this.state.searchResult = ''
    this.state.searchProgress = []
    this.state.searchResult = ''
    //this.state.autocompletes = []
    this.state.suggestions = []
    this.forceUpdate()
  }

  removeFromHistory = searchTerm => {
    let history = this.state.history
    history = history.filter(x => x !== searchTerm)
    this.state.history = history
  }

  maybeAddToHistory = (searchTerm, news) => {
    if (news) return
    this.removeFromHistory(searchTerm)
    let history = this.state.history
    history.push(searchTerm)
  }

  doSearchFromAutocomplete = (searchTerm, news) => {
    if (news) {
      this.doSearch(searchTerm, news)
    } else {
      let text = searchTerm
      this.searchField.focus()
      this.searchField.setText(text)
      this.setState({
        searchTerm,
        showAutocompletes: false
      })
    }
  }

  doSearchFromHistory = (searchTerm, news) => {
    this.searchField.search(searchTerm)
  }
  
  doSearch = (searchTerm, news) => {
    this.clearSearchResult()
    this.state.searching = true
    this.searchField.search(searchTerm)
    if (news) {
      const { topic, date } = news
      const ago = date.indexOf("ago") > 0 ? '' : " ago"
      searchTerm = `Could you find information on: ${searchTerm} ${topic} [from ${date}${ago}]`
    }
    this.maybeAddToHistory(searchTerm)
    this.performSearch(searchTerm)
  }

  lastTime = 0
  getAutocompletes = async () => {
    let now = Date.now()
    if (now - this.lastTime > 5 * 60000) {
      this.state.busy = true
      this.forceUpdate()
      this.autocompletes = await this.props.me.searchAutocomplete()
      this.lastTime = now
    }
    const { autocompletes } = this.autocompletes
    this.setState({
      busy: false,
      autocompletes,
    })
  }

  handleResize = e => {
    this.forceUpdate()
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    return {
      isBottomLock: this.isBottomLock
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isBottomLock } = snapshot
    if (isBottomLock && this.state.messageContent) {
      const container = this.scrollRef
      container.scrollTop = container.scrollHeight  - container.clientHeight
    }
  }

  
  openLink = url => {
    if (url.startsWith("#")) {
      const el = document.findElementById(url.substring(1))
      if(el) {
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        })
      } else {
        debugger
      }
    } else {
      if (url) {
        this.props.me.openWindow(url)
      }
    }
  }

  componentDidMount() {
    this.fadeComponents = new FadeComponents({id: 'searchGpt'})
    this.fadeComponents.pre = opts => {
      return this.fadeComponents.div(opts)
    }
    this.components = getComponents({openLink: this.openLink})
    const { a } = this.components
    this.components.a = props => {
      const { node, children } = props
      const { href, name } = node.properties
      try {
        const u = new URL(href)
      } catch (err) {
        return null
      }
      return a(props)
    }
    const PreBlock = ({node, children, ...rest}) => {
      return <div className='aiPre' {...rest}>{children}</div>;
    };
    this.components.pre = PreBlock
    this.initListeners()
  }

  componentWillUnmount() {
    this.deinitListeners()
  }

  initListeners() {
    window.addEventListener('resize', this.handleResize);
    this.selfSub = this.props.me.observeSelf().subscribe(async self => {
      if (self) {
        this.init()
        this.getAutocompletes()
      } else {
        this.deinit()
      }
    })
  }

  deinitListeners() {
    this.init()
    this.getAutocompletes()
    this.deinit()
    window.removeEventListener('resize', this.handleResize);
  }

  deinit = () => {
    if (this.modelsSub) {
      this.modelsSub.unsubscribe()
      this.modelsSub = null
    }
  }

  models = {}
  updateModelsLater = () => {
    this.updateLater('models', this.updateModels)
  }

  updateModels = () => {
    const models = Object.values(this.models)
    this.setState({
      models
    })
  }
  
  init = () => {
    const searchQuery = this.props.me.searchQuery || ''

    if (searchQuery) {
      this.state.searchQuery = searchQuery
      this.searchField.search(searchQuery)
    }
    this.modelsSub = this.props.me.observeModels().subscribe(change => {
      const { type, model } = change
      model.title = model.name // bw compatibilty for now
      console.log("model", model)
      if (type === 'removed') {
        delete this.models[model.id]
      } else {
        this.models[model.id] = model
      }
      this.updateModelsLater()
    })
  }

  forceUpdateLater = () => {
    clearTimeout(this.updateTimeout1)
    this.updateTimeout1 = setTimeout(() => this.forceUpdate(), 33)
  }

  
  performSearch = async (searchTerm) => {
    if (this.uploadInProgress) return
    this.cancel()
    const available = this.miniHome.getCredits()
    if (available <= 0) {
      return
    }
    this.state.searching = true
    this.state.showAutocompletes = false
    this.searchField.clear()
    this.state.searchTerm = ''
    this.state.searchProgress = []
    this.forceUpdate()
    const self = this
    const transcript = this.transcript
    const message = {
        role: 'user',
        content: searchTerm
    }
    this.transcript.push({
      when: Date.now(),
      message: message
    })
    this.remoteTranscript.push(message)
    console.log({transcript})
    this.state.searchQuery = searchTerm
    this.state.cost = 0
    this.state.messageContent = ''
    this.xhr = await this.props.me.performSearch({transcript: this.remoteTranscript}, (progress) => {
      if (!this.state.searching) {
        this.cancel()
        return
      }
      let {choices, errorMessage, progressMessages, progressMessage, done, error, remainingCredits, cost } = progress
      console.log('progress', progress)
      if (choices) {
        const content = choices[0].delta.content
        this.state.messageContent += content
        return this.forceUpdate()
      }

      if (typeof cost == 'number') {
        this.state.cost += cost
      }
      if (progressMessages && !progressMessage) {
        progressMessage = progressMessages.join('\n\n')
      }
      if (progressMessage || errorMessage) {
        console.log(progressMessage || errorMessage)
        self.state.searchProgress = self.state.searchProgress.filter(x => !x.progressMessage || x.progressMessage.trim() !== "Thinking")
        self.state.searchProgress.push({progressMessage, errorMessage })
        this.forceUpdateLater()
      }
      if (progress.transcript) {
        this.state.messageContent = null
        debugger
        const start = this.remoteTranscript.length-1 // including user message
        const end = start + progress.transcript.length
        let cost = 0
        const price = this.searchModel.contexts[0].price
        debugger
        for (const x of progress.transcript) {
          let { message, usage, content, role } = x //@TODO
          if (!message) {
            message = {
              content,
              role
            }
          }
          if (usage) {
            cost += (usage.inputTokens / (1000 * 1000)) * price.input
            cost += (usage.outputTokens / (1000 * 1000)) * price.output
            cost += (usage.cacheReadTokens || 0) / (1000 * 1000) * (price.cached || 0)
            cost += (usage.cacheWriteTokens || 0) / (1000 * 1000) * (price.cacheWrite || 0)
          }
          this.remoteTranscript.push(message)
        }
        this.xhr = null
        this.searchField.clear()
        const message = this.remoteTranscript[this.remoteTranscript.length-1]
        const transcriptMessage = {
          when: Date.now(),
          cost,
          message,
          searchProgress: this.remoteTranscript.slice(start+1, end),
          start, end
        }
        debugger
        this.transcript.push(transcriptMessage)
        this.setState({
          searchProgress: [],
          searching: false,
          busy: false
        })
        if (this.props.onMessge) {
          this.props.onMessage(message)
        }
      }
      if (error) {
        this.xhr = null
        this.setState({
          searchError: error.message,
          searching: false,
          busy: false
        })
      }
    })
  }

  setSearchField = ref => {
    this.searchField = ref
  }

  clearSearch = () => {
    this.searchField.search('')
    this.state.searchTerm = ''
  }

  searchButtonPress = async () => {
    if (this.state.searching) {
      this.cancel()
      this.searchSearching = false
    }
    if (this.state.searchTerm) {
      this.maybeAddToHistory(this.state.searchTerm)
      this.performSearch(this.state.searchTerm)
      this.state.busy = true
      await delay(1)
    }
  }

  resolveModel = modelId => {
    const model = this.models[modelId]
    if (model) {
      model.getIcon = getModelIcon(model.vendorId, modelId)
    }
    return model
  }

  getSearchModel = () => {
    if (!this.searchModel) {
      let searchModel = this.resolveModel(this.props.me.searchModel)
      if (searchModel) {
        this.searchModel = searchModel
      }
    }
    return this.searchModel
  }

  toggleAutocompletes = () => {
    this.setState({
      showAutocompletes: !this.state.showAutocompletes
    }, () => {
      this.getSuggestions(this.state.searchTerm)
    })
  }

  hideAutocompletes = () => {
    this.setState({
      showAutocompletes: false
    })
  }

  onKeyDown = e => {
    const RETURN = "Enter";

    if (isDesktop()) {
      if (e.key === RETURN && !e.shiftKey) {
        e.preventDefault()
        this.searchButtonPress()
      }
      else if (e.key === "Escape") {
        e.preventDefault()
        this.hideAutocompletes()
      }
    }
  }

  render() {
    return this.renderContent()
  }

  setScrollRef = ref => {
    this.scrollRef = ref
  }

  onScroll = e => {
    const ref = this.scrollRef
    if (ref) {
      const { scrollTop, scrollHeight, clientHeight } = ref
      const scrollBottom = scrollHeight - (scrollTop + clientHeight);
      if (scrollBottom < 1) {
        this.isBottomLock = true
      } else {
        this.isBottomLock = false
      }
    }
    
  }

  initiatePurchase = () => {
    this.setState({
      showSettingsMenu: true
    }, () => {
      this.miniHome.buy()
    })
  }

  renderContent() {
    const closeSettings = () => {
      this.setState({
        showSettingsMenu: false
      })
    }
    const searchModel = this.getSearchModel()
    const dotsAction = async () => {
    }
    let autocompleteStyle
    if (this.state.searchTerm) {
      autocompleteStyle = { display: 'none' }
    }
    console.log({progressMessages: this.state.searchProgress})
    let autocompletes
    const N = 10
    if (this.state.showAutocompletes) {
      if (!this.state.searchTerm) {
        autocompletes = this.state.autocompletes.slice(0, N).map((x, i) => {
          const onClick = () => {
            this.doSearchFromAutocomplete(x.summary, x)
          }
          return <SearchGptAutocomplete components={this.components} icon={Trending} autocomplete={x.summary} news={x} onClick={onClick}/>
        })
        let lastFew = []
        lastFew = [].concat(this.state.history)
        lastFew.reverse()
        lastFew = lastFew.slice(0, 3)
        lastFew = lastFew.map(x => {
          const onClick = () => {
            this.doSearchFromHistory(x)
          }
          return <SearchGptAutocomplete components={this.components} icon={Chat} autocomplete={x} onClick={onClick}/>
        })
        autocompletes = lastFew.concat(autocompletes)
      } else {
        autocompletes = (this.state.suggestions || []).slice(0, 4).map(x => {
          const onClick = () => {
            this.doSearchFromAutocomplete(x)
          }
          return <SearchGptAutocomplete components={this.components} icon={Search} autocomplete={x} onClick={onClick}/>
        })
      }
    }

    let searchIcon
    let searchClass
    if (true || (!this.state.searching && !this.state.busy)) {
      searchIcon = Search
      searchClass = 'searchIconSearch'
    } else if (this.state.busy) {
      searchClass = 'searchIconSpin'
      searchIcon = Spin
    } else {
      searchClass = 'searchIconStop'
      searchIcon = Stop
    }
    const onClickAway = (event) => {
      this.hideAutocompletes()
    }
    let reversedTranscript = []
    for (let i = 0; i < this.transcript.length; i += 2) {
      const user = this.transcript[i]
      const assistant = this.transcript[i+1]
      reversedTranscript.push({
        user, assistant, i
      })
    }
    const menuAction = async () => {
      let scrollPos = this.scrollRef.scrollTop
      this.isBottomLock = false
      this.setState({
        showSettingsMenu: !this.state.showSettingsMenu
      }, () => {
        if (this.state.showSettingsMenu) {
          this.scrollRef.scrollTop = scrollPos
        }
      })
    }
    const models = modelIds.map(modelId => {
      const model = this.resolveModel(modelId)
      if (model) {
        const action = async () => {
          await this.props.me.openSearchModel(model.id, this.state.searchQuery)
        }
        return  <Model action={action} key={model.id} model={model} isSelected={this.searchModel && this.searchModel.id == model.id}/>
      }
    }).filter(x=>x)
    let className1 = 'searchGpt'
    if (this.transcript.length > 0) {
      className1 += ' searchGptNonEmpty'
    }
    let desktopLayout = isDesktop() && this.transcript.length > 0
    let searchButtonStyle = { display: 'none' }
    if (this.state.searchTerm) {
      searchButtonStyle = undefined
    }
    console.log({searchButtonStyle, searchTerm: this.state.searchTerm, uploadInProgress: this.uploadInProgress})
    let modelsStyle
    if (isDesktop() && window.innerWidth < 1200) {
      modelsStyle = {
        justifyContent: 'flex-start',
        overflowX: 'auto'
      }
    }

    let searchProgress = this.state.searchProgress.map((x, i) => {
      const getProgress = (x) => {
        if (x.startsWith("Thought")) {
          return x
        }
        x = x.trim()
        if (i + 1 === this.state.searchProgress.length) {
          if (!x.endsWith('...')) {
            return x + "..."
          }
          return x
        }
        if (x.endsWith('...')) {
          return x.substring(0, x.length-3)
        }
        return x
      }
      if (x.progressMessage) {
        x.progressMessage = getProgress(x.progressMessage)
      }
      return x
    })
    const getPos = () => {
      return this.swiperRef.activeIndex
    }
    const setPos = (pos) => {
      this.swiperRef.slideTo(pos)
      this.forceUpdate()
    }
    const left = () => {
      if (getPos() > 0) {
        setPos(getPos() - 1)
      }
    }
    const right = () => {
      if (getPos() + 1 < models.length) {
        setPos(getPos() + 1)
      }
    }

    let leftStyle = ''
    let rightStyle = ''


    const setSwiperRef = (swiper) => {
      this.swiperRef = swiper;
      if (swiper) {
        const index = modelIds.indexOf(this.props.me.searchModel)
        swiper.slideTo(index)
      }
    }

    const handleSlideChange = () => {
      console.log("active", this.swiperRef.activeIndex)
      this.forceUpdate()
    }

    const handleTranslate = () => {
      this.forceUpdate()
    }

    if (this.swiperRef && this.swiperRef.isBeginning) {
      leftStyle = 'swiperNavHidden '
    }

    if (this.swiperRef && (this.swiperRef.isEnd || this.swiperRef.activeIndex + 1 === models.length)) {
      rightStyle = ' swiperNavHidden '
    }

    if (this.state.showSettingsMenu) {
      className1 += ' searchGptSettingsOpen'
    }

    const renderMarkdown = text => <Markdown components={this.components}>{text}</Markdown>

    let errorMessage
    let errorButton
    if (this.miniHome && this.miniHome.hasCreditsLoaded() && this.miniHome.getCredits() <= 0) {
      errorMessage = "I'm sorry, you're out of credits."
      const purchase = async () => {
        this.initiatePurchase()
      }
      errorButton = <SimpleButton label={'Purchase'} action={purchase}/>
    }
    return <div className={className1} ref={this.setScrollRef} onScroll={this.onScroll}>
             <div className='searchGptContent'>
               {!desktopLayout && <div className='searchGptHeader'>
                                   <ReactSVG src={LogoLarge}/>
                                </div>}
               <div className='searchGptBody'>
                 <div className='searchGptBodyTopRow'>
                   {desktopLayout && <div className='searchGptHeaderLeft'>
                                       <ReactSVG src={LogoSmall}/>
                                     </div>}
                   <div className='searchGptInputContainer'>
                   <div className='searchGptInput'>
                     <ClickAwayListener onClickAway={onClickAway}>
                       <div className='searchGptSearch'>
                         <div className={'searchGptSearchTopRow '+searchClass}>
                           <SearchField
                             onKeyDown={this.onKeyDown}
                             placeholder={true ? ' ' : "How can I assist you?"}
                             onEditorHeightChanged={this.setSearchFieldHeight}
                             onCreate={this.setSearchField }
                             icon={Search}
                             onClick={this.toggleAutocompletes}
                             onPaste={this.onPaste}
                             onSearch={this.onSearch}
                             
                           />
                           <div className='searchFieldFileChooser'>
                             <FileChooser icon={Image} handleDataTransfer={this.handleDataTransfer}/>
                           </div>
                           <div className='searchGptButton' style={searchButtonStyle}>
                             <SimpleButton icon={searchIcon} action={this.searchButtonPress}/>
                           </div>
                         </div>
                         <div className='searchGptSpacer' style={{height: this.state.searchFieldHeight - 40}} />
                         <div className='searchGptAutocompletes'>
                           {
                             autocompletes
                           }
                         </div>
                       </div>
                     </ClickAwayListener>
                   </div>
                 </div>
                   {desktopLayout && <div className='searchGptHeaderRight'>
                                       <div className='searchGptHeaderRightSpacer'/>
                                     </div>}
                 </div>
                 <div className='searchGptModels'>
                   <div className={'searchGptButtonNav ' +leftStyle + 'modelsBack' }>
                     <SimpleButton icon={Left} action={left}/>
                   </div>
                   <Swiper
                     onSwiper={setSwiperRef}
                     preventClicks={false}
                     modules={[Mousewheel]}
                     allowTouchMove={!isDesktop()}
                     slidesPerView={'auto'}
                     onSetTranslate={handleTranslate}
                     onSlideChange={handleSlideChange}
                     mousewheel={isDesktop() ? { forceToAxis: true, releaseOnEdges: true  } : undefined}>
                     {
                       models.map(m => <SwiperSlide>{m}</SwiperSlide>)
                     }
                   </Swiper>
                   <div className={'searchGptButtonNav ' +  rightStyle + 'modelsFwd'}>
                     <SimpleButton icon={Right} action={right}/>
                   </div>
                 </div>
                 {errorMessage && <div key='error' className='searchError'>
                                    <div className='searchErrorMessage'>
                                      {errorMessage}
                                    </div>
                                    <div className='searchErrorButton'>
                                      {errorButton}
                                    </div>
                                  </div>}
                 <div key='transcript' className='searchTranscript'>
                   {
                     reversedTranscript.map(({user, assistant, i}, j) => {
                       const deleteSearchResult = async () => {
                         if (i === 0) this.cancel()
                         debugger
                         this.removeFromHistory(user.content)
                         this.transcript.splice(i, 2)
                         if (assistant) {
                           this.remoteTranscript.splice(assistant.start, assistant.end - assistant.start+1)
                         } else {
                           this.remoteTranscript = []
                         }
                         this.forceUpdate()
                       }
                       const renderModelOutput = (content, options = {}) => {
                         let{ fade, key, busy } = options
                         fade = false
                         function fixUnterminatedLink(markdown) {
                           // Count [ and ] from the end
                           const openBrackets = (markdown.match(/\[/g) || []).length;
                           const closeBrackets = (markdown.match(/\]/g) || []).length;
                           
                           // Count ( and ) from the end
                           const openParens = (markdown.match(/\(/g) || []).length;
                           const closeParens = (markdown.match(/\)/g) || []).length;
                           
                           let result = markdown;
                           
                           // Add missing brackets
                           if (openBrackets > closeBrackets) {
                             result += ']';
                           }
                           
                           // Add missing parentheses
                           if (openParens > closeParens) {
                             result += ')';
                           }
                           return result;
                         }
                         function fixUnterminatedImageAtEnd(markdown) {
                           // If no ![, not our problem
                           const lastBang = markdown.lastIndexOf('![');
                           if (lastBang === -1) return markdown;
                           debugger
                           // If there's a ] after the last ![, it's terminated
                           const lastBracket = markdown.lastIndexOf(']');
                           if (lastBracket > lastBang) return markdown;
                           
                           // Otherwise, get everything before the last ![
                           const prefix = markdown.slice(0, lastBang);
                           
                           // Get the alt text (everything between ![ and ( or end)
                           const rest = markdown.slice(lastBang + 2);
                           const altText = rest.split(/[()]/)[0];
                           
                           // Option 1: Return with empty URL
                           //return prefix + '![' + altText + ']()';
                           
                           // Option 2: Just show alt text
                            return prefix + altText;
                         }
                         let fixed = fixUnterminatedLink(content)
                         if (fixed !== content) {
                           debugger
                           content = fixed
                         } else {
                           fixed = fixUnterminatedImageAtEnd(content)
                           if (fixed !== content) {
                             debugger
                             content = fixed
                           }
                         }
                         const className = options.className || 'searchGptSearchResult'
                         return <div key={key ? 'div-'+key : undefined} className={className+ ' keyboardEditDocumentText'}>
                                  {searchModel && <Model busy={busy} className='modelResponse' isSelected={true} model={searchModel}/>}
                                  <Markdown key={key} components={fade ? this.fadeComponents: this.components}>{content}</Markdown>
                                </div>
                       }
                       let assistantContent
                       if (assistant) {
                         const messages = this.remoteTranscript.slice(assistant.start, assistant.end+1)
                         let seen = {}
                         assistantContent = messages.filter(x => {
                           if (x.role === 'assistant' && x.content) {
                             if (!seen[x.content]) {
                               seen[x.content] = true
                               return true
                             }
                           }
                         }).map(x=>x.content).join("\n\n")
                       }
                       const searchProgress = assistant && assistant.searchProgress
                       return <div className='searchResultContainer'>
                              <div className={'deleteResult'}>
                                <SimpleButton icon={Cross} action={deleteSearchResult}/>
                              </div>
                              <div className='searchQuery'>
                                <div className='searchQueryIcon'><SimpleIcon src={UserSaid}/></div>
                                <div className='searchQueryContent'>
                                  <Markdown components={this.components}>{user.message.content}</Markdown>
                                  </div>
                              </div>
                                {
                                  assistant ? renderModelOutput(assistantContent) : renderModelOutput(this.state.messageContent || "Thinking...", {fade: true, busy: true, key:"fading"})
                                }
                              <div key='progress' className='searchGptSearchProgressContainer'>
                                <div className='searchGptSearchProgressDisclosure'>
                                  {assistant && <div className='searchProgressDuration'>
                                                  <div className='searchTime'>{parseFloat(((assistant.when - user.when) / 1000).toFixed(1))} seconds</div>
                                                  <div className='modelPrice'>${formatPrice(assistant.cost || 0)}</div>
                                                </div>}
                                  {assistant && <SimpleButton icon={assistant.open ? Up : Down} action={() => {
                                                                assistant.open = !assistant.open
                                                                this.forceUpdate()
                                                              }}/>}
                                </div>
                                <div key='progress' className='searchGptSearchProgress' style={!assistant || assistant.open ? null : { display: 'none'}}>
                                  {
                                    searchProgress && searchProgress.filter(x=>x).map(message => {
                                      let { content, tool_call_id } = message
                                      if (message.tool_calls) {
                                        if (true) return null
                                        content = message.tool_calls.map(tool_call => {
                                        return '```\n' + tool_call.function.name +' ' +tool_call.function.arguments + '\n```'
                                        }).join('\n\n')
                                      } else {
                                        let tool_call
                                        if (tool_call_id) {

                                          for (const p of searchProgress) {
                                            if (p.tool_calls) {
                                              for (const t of p.tool_calls) {
                                                if (t.id === tool_call_id) {

                                                  tool_call = t
                                                  break
                                                }
                                              }
                                            }
                                            if (tool_call) break
                                          }
                                        } else {
                                          return null
                                        }
                                        if (tool_call) {
                                          const args = JSON.parse(tool_call.function.arguments)
                                          let target
                                          let q
                                          if (args) {
                                            target = args.target
                                          }
                                          if (tool_call.function.name === 'searchWeb') {
                                            try {
                                              return <GoogleSearchResults openLink={this.openLink} renderMarkdown={renderMarkdown} q={args.q} results={JSON.parse(content)}/>
                                            } catch (err) {
                                            }
                                          } else if (tool_call.function.name === 'playwright') {
                                          } else if (tool_call.function.name === 'cheerio') {
                                          } else if (tool_call.function.name === 'getLinkPreviews') {
                                          }
                                          if (target) {
                                            if (false) { 
                                              return <div className='embedFrame'>
                                                       <iframe
                                                         src={target}
                                                         title={content}
                                                         style={{
                                                           width: '100%',
                                                           height: '100%',
                                                           border: 'none',
                                                         }}
                                                />
                                                     </div>
                                            }
                                            try {
                                              const parsed = JSON.parse(content)
                                              if (true) {
                                                const { ogTitle, ogDescription, ogUrl, ogImage, ogSiteName } = parsed.data.metadata
                                                const div = (
                                                  <div className='website-preview'>
                                                    <div className='website-preview-site'>
                                                      <p>{ogSiteName}</p>
                                                    </div>
                                                    <div className='website-preview-title'>                                                       
                                                      <a href={ogUrl}>{ogTitle}</a>
                                                    </div>
                                                  
                                                    <div className='website-preview-image'><img alt={""} src={ogImage}/></div>
                                                    <div className='website-preview-description'> 
                                                      <p >{ogDescription}</p> 
                                                    </div>
                                                  </div>);
                                              return div;
                                            }
                                            
                                            return <div className='searchGptSearchResult keyboardEditDocumentText webPageResult'>
                                                       <Markdown components={this.components}>{parsed.data.markdown}</Markdown>
                                                     </div>
                                            } catch (err) {
                                            debugger
                                          }
                                          }
                                          content = '```\n' + tool_call.function.name +' ' +tool_call.function.arguments + '\n```\n\n'
                                          + '```\n' + content + "\n```"
                                        }
                                      }
                                      return renderModelOutput(content)
                                    }).filter(x=>x)
                                  }
                                </div>
                              </div>
                            </div>
                     })
                   }
                 </div>
               </div>
             </div>
             <div className='searchGptMenuContainer' style={isMobile() && (this.state.showSettingsMenu ? { background: 'rgb(0, 0, 0, 0.6)'}  : { height: 40}) || undefined}>
                <div className='searchGptMenuButton'>
                  <SimpleButton icon={MenuUp} action={menuAction}/>
                </div>
                <div className='miniHomeContainer' style={this.state.showSettingsMenu ? null : { display: 'none'}}>
                  <MiniHome
                    key={this.props.me && this.props.me.self && this.props.me.self.uid}
                    closeSettings={closeSettings}
                    me={this.props.me}
                    onCreate={this.onCreateMiniHome}
                    getSetting={this.props.getSetting}
                    toggleSetting={this.props.toggleSetting}
                    notEnoughTokens={this.props.notEnoughTokens} />
                 </div>
              </div>
           </div>
  }

  
}

