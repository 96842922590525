import { docData, doc, collectionChanges, collectionData } from "rxfire/firestore"
import { bindCallback, of, concat, from, Subject, merge as mergeN, combineLatest } from 'rxjs'
import { catchError, tap, scan, filter, map, mergeMap, flatMap, take, merge, distinctUntilChanged  } from 'rxjs/operators'
import axios from 'axios'
import { startOfDay, startOfMonth, endOfMonth, startOfWeek, endOfWeek, randomElement, clone, capitalize, mergeFields, formatAddress } from './Util.js'
import phone from 'phone'
import { isIOS, isAndroid, isDesktop } from './Platform.js'
import { resolveDialect } from './Lang.js'
import { saveAs } from 'file-saver'
import { streamReply } from './Stream.js'
import MarkdownIt  from 'markdown-it'
import {getAccessToken} from 'web-auth-library/google'

const getContent = x => x.messages.length > 0 ? x.messages[0].content : "New Example"

const md = new MarkdownIt();

class FileSystem {

  constructor ({me, uid, firebase, getFunc, collection, upload, field, newTitle}) {
    this.me = me
    this.uid = uid
    this.firebase = firebase
    this.getFunc = getFunc
    this.collection = collection
    this.uploadFunc = upload
    this.field = field
    this.newTitle = newTitle
  }

  getCollection = () => this.firebase.firestore().collection(this.collection)

  upload = async ({parent, file, url, models}, progress) => {
    const func = this.getFunc(this.uploadFunc)
    if (file) {
      const fileRef = await this.me.uploadFileImpl(this.collection, this.uid, file, progress)
      const filePath = fileRef.fullPath
      return await func({parent, filename: file.name, contentType: file.type, filePath, models})
    } else {
      return await func({parent, url})
    }
  }

  observe = ({folder, limit, start, end}) => {
    const c = this.getCollection()
    let q = c.where('uid', '==', this.uid)
    if (folder) {
      q = q.where('parents', 'array-contains', folder.id)
    }
    if (start) {
      q = q.where('lastUpdated', '>=', start)
    }
    if (end) {
      q = q.where('lastUpdated', '<=', end)
    }
    q = q.orderBy('lastUpdated', 'desc')
    if (limit) {
      debugger
      q = q.limit(limit)
    }
    
    const recentlyUpdated$ = collectionChanges(q).pipe(
      flatMap(changes => changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        const result = { type }
        data.name = data.name || this.newTitle
        data.title = data.name 
        result[this.field] = data
        result.type = type
        return result
      }))
    )
    
    const recentlyViewed$ = this.observeRecentlyViewed({start, end})
    
    return mergeN(recentlyUpdated$, recentlyViewed$).pipe(filter(x => {
      if (!x[this.field]) {
        debugger
        return false
      }
      return true
    }))
  }
  
  createNew = async (opts = {}) => {
    const {parentFolder, updates = {}} = opts
    const c = this.getCollection()
    const ref = c.doc()
    const updated = {
      lastUpdated: Date.now(),
      uid: this.uid,
      name: this.newTitle,
      parents: parentFolder ? [parentFolder.id] : undefined,
      id: ref.id
    }
    for (const field in updates) {
      updated[field] = updates[field]
    }
    await ref.set(updated)
    return updated
  }

  open = async file => this.markRecentlyViewed(file)

  close = file => this.markRecentlyViewed(file)

  queued = []

  markRecentlyViewed = async (file) => {
    const { id } = file
    const found = this.queued.find(x => x.id === file.id)
    const lastUpdated = Date.now()
    if (found) {
      found.lastUpdated = lastUpdated
    } else {
      this.queued.push({id, lastUpdated})
    }
    clearTimeout(this.queueTimeout)
    this.queueTimeout = setTimeout(() => {
      let ids = this.queued
      this.queued = []
      this.me.updateRecentlyViewed(this.collection, ids)
    }, 5000)
    this.recentlyViewedSubject.next(file)
  }

  recentlyViewedSubject = new Subject()
  
  observeRecentlyViewed = (opts={}) => {
    const {start, end} = opts
    try {
      return this.recentlyViewedSubject.pipe(
        filter(item => {
          const timestamp = item.lastUpdated
          if (start && timestamp < start) return false
          if (end && timestamp > end) return false
          return true
        })
      )
    } finally {
      this.getRecentlyViewed({start, end}).then(recentlyViewed => 
        recentlyViewed.map(x => this.recentlyViewedSubject.next(x))
      )
    }
  }
  
  getRecentlyViewed = async ({start, end}) => {
    const c = this.getCollection()
    let recentlyViewed
    try {
      recentlyViewed = await this.me.getRecentlyViewed(this.collection)
    } catch (err) {
      debugger
    }
    
    // Filter by date range
    if (start || end) {
      recentlyViewed = recentlyViewed.filter(x => {
        if (start && x.lastUpdated < start) return false
        if (end && x.lastUpdated > end) return false
        return true
      })
    }
    return await Promise.all(recentlyViewed.map(async x => {
      const { id, lastUpdated } = x
      const snap = await c.doc(id).get()
      const data = snap.data()
      data.id = snap.id
      data.lastUpdated = Math.max(lastUpdated, data.lastUpdated)
      const result = { type: 'added' }
      result[this.field] = data
      return result
    }))
  }

  save = async (id, updates) => {
    const c = this.getCollection()
    const ref = c.doc(id)
    const updated = {
      lastUpdated: Date.now(),
    }
    for (const field in updates) {
      updated[field] = updates[field]
    }
    await ref.set(updated, { merge: true })
  }

  remove = async (id) => {
    const c = this.getCollection()
    const ref = c.doc(id)
    try {
      await ref.delete()
    } catch (err) {
      console.error(err)
    }
  }

  createNewFolder = async ({parentFolder, updates}) => {
    const c = this.getCollection()
    const ref = c.doc()
    const { uid } = this
    const updated = {
      isFolder: true,
      uid,
      parents: parentFolder ? [parentFolder.id] : undefined,
      lastUpdated: Date.now()
    }
    for (const field in updates) {
      updated[field] = updates[field]
    }
    await ref.set(updated)
  }
  
  addParent = async ({child, parent}) => {
    const c = this.getCollection()
    if (parent) {
      if (child.parents && child.parents.find(x => x === parent)) {
        return false
      }
      if (!child.parents) {
        child.parents = []
      }
      child.parents.push(parent)
      const ref = c.doc(child.id)
      await ref.set({parents: child.parents, lastUpdated: Date.now()}, { merge: true})
    } else {
      const dup = clone(child)
      const ref = c.doc()
      dup.id = ref.id
      dup.parents = []
      dup.lastUpdated = Date.now()
      await ref.set(dup)
    }
    return true
  }

  removeParent = async ({child, parent}) => {
    const c = this.getCollection()
    child.parents = child.parents.filter(x => x !== parent)
    const ref = c.doc(child.id)
    await ref.set({parents: child.parents, lastUpdated: Date.now()}, { merge: true})
  }

  getRecentlyViewed = async () => {
    const c = this.getCollection()
    let recentlyViewed
    try {
      recentlyViewed = await this.me.getRecentlyViewed(this.collection)
    } catch (err) {
      debugger
    }
    let orphans
    const results = (await Promise.all(recentlyViewed.map(async x => {
      const {
        id, lastUpdated
      } = x
      let snap 
      try {
        snap = await c.doc(id).get()
      } catch (err) {
        debugger
        if (!orphans) {
          orphans = {}
        }
        orphans[id] = true
        return null
      }
      const data = snap.data()
      data.id = snap.id
      const result = {
        type: 'added'
      }
      data.lastUpdated = Math.max(lastUpdated, data.lastUpdated)
      result[this.field] = data
      return result
    }))).filter(x=>x)
    if (orphans) {
      recentlyViewed = recentlyViewed.filter(x => !orphans[x.id])
      await this.me.updateRecentlyViewed(this.collection, recentlyViewed)
    }
    return results
  }
}

export const resetUsage = messages => {
  for (const message of messages) {
    if (message.role === 'assistant' && message.models) {
      for (const model of message.models) {
        model.usage = {
          inputTokens: 0,
          outputTokens: 0
        }
      }
    }
    if (message.judgement) {
      for (const judgement of message.judgement.judgements) {
        judgement.usage = {
          inputTokens: 0,
          outputTokens: 0
        }
      }
    }
    if (message.addedToTranscript) {
      for (const item of message.addedToTranscript) {
        delete item.usage
      }
    }
    if (message.models) {
      for (const model of message.models) {
        if (model.addedToTranscript) {
          for (const item of model.addedToTranscript) {
            delete item.usage
          }
        }
      }
    }
  }
}

function convertJsonToMarkdown(jsonData) {
  let markdownOutput = ""
  jsonData.forEach(item => {
    if (item.type === 'text') {
      markdownOutput += item.text + "\n\n";
    } else if (item.type === 'image_url' && item.image_url && item.image_url.url) {
      markdownOutput += `![Image](${item.image_url.url})\n\n`;
    }
  })
  return markdownOutput
}

const consoleLog = (...args) => {
  ////console.log(...args)
}

const debugLog = (...args) => {
  consoleLog(...args)
}

let server
if (false) {
  server = 'http://192.168.1.106:8080'
} else {
          //https://functions-sp45acvogq-uc.a.run.app
  server = 'https://functions-80135616279.us-central1.run.app'
}

try {
  speechSynthesis.getVoices()
} catch (ignored) {
  
}
let LANG
const MAX_TOKENS = 30
const SOURCE = new URLSearchParams(window.location.search).get('src')
const WHISPER = new URLSearchParams(window.location.search).get('whisper')
const Token = new URLSearchParams(window.location.search).get('token')
const InviteCode = new URLSearchParams(window.location.search).get('referralCode')

const RedirectURI = new URLSearchParams(window.location.search).get('redirectURI')
const State = new URLSearchParams(window.location.search).get('state')

const delay = seconds => new Promise(resolve => setTimeout(resolve, seconds*1000))

const xmlEscape = xml => {
  if (!xml) return ''
  return xml.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
}

const xml2json = xml => {
  try {
    var obj = {};
    if (xml.children.length > 0) {
      for (var i = 0; i < xml.children.length; i++) {
        var item = xml.children.item(i);
        var nodeName = item.nodeName;

        if (typeof (obj[nodeName]) == "undefined") {
          obj[nodeName] = xml2json(item);
        } else {
          if (typeof (obj[nodeName].push) == "undefined") {
            var old = obj[nodeName];

            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xml2json(item));
        }
      }
    } else {
      obj = xml.textContent;
    }
    return obj;
  } catch (e) {
      consoleLog(e.message);
  }
}

class WhisperVoiceRecognizer {
  instructionSubject = new Subject()
  isActiveSubject = new Subject()
  isActive = false

  observeInstruction = () => {
    return this.instructionSubject
  }

  setLang = lang => {
    this.lang = lang
    //////////debugger
  }

  observeIsActive = () => {
    return concat(this.isActive, this.isActiveSubject)
  }

  start = async () => {
    if (!this.vad) {
      this.vad = await window.vad.MicVAD.new({
        onSpeechEnd: async audio => {
          consoleLog("speech end", this.lang)
          const blob = encodeMP3([audio])
          //saveAs(blob, "audio.mp3")
          //return
          const file = new File([blob], 'audio.mp3', {
            type: 'audio/mp3'
          })
          const response = await axios.post('http://localhost:8080/transcribe?lang='+(this.lang || ''),
                                            file)
          const { text } = response.data
          this.instructionSubject.next(text)
        }
      })
    }
    this.vad.start()
    this.isActiveSubject.next(this.isActive = true)
  }

  stop = () => {
    this.isActive = false
    this.isActiveSubject.next(false)
    this.vad.pause()
  }
}


class SystemVoiceRecognizer {

  constructor (autocorrect) {
    this.autocorrect = autocorrect
  }

  instructionSubject = new Subject()

  observeInstruction = () => {
    return this.instructionSubject
  }

  onResult = async event => {
    let prompt = ''
    consoleLog("onResult", event)
    for (var i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        prompt += event.results[i][0].transcript
        break
      }
    }
    this.instructionSubject.next(prompt)
  }

  stop = () => {
    this.active = false
    consoleLog("STOP RECOGNITION")
    if (this.recognition) {
      this.recognition.abort()
      this.recognition.stop()
    }
    this.onActiveSubject.next(false)
  }

  start = () => {
    if (this.active) return
    this.active = true
    if (!this.recognition) {
      const recognition = new window.webkitSpeechRecognition()
      if (LANG) {
        recognition.lang = LANG
      }
      consoleLog("LANG", LANG)
      recognition.continuous = true
      recognition.interimResults = false
      recognition.onstart = this.onStart
      recognition.onresult =  this.onResult
      recognition.onerror = this.onError
      recognition.onend = this.onEnd
      this.recognition = recognition
    }
    this.recognition.start()
    consoleLog("START RECOGNITION")
    this.onActiveSubject.next(true)
  }

  onError = event =>{
    consoleLog('error:', event)
  }

  onActiveSubject = new Subject()

  observeIsActive = () => {
    return concat(of(this.active), this.onActiveSubject)
  }

  onEnd = event => {
    consoleLog('end:', event)
    if (this.active) {
      this.active = false
      this.start()
    }
  }

  setLang = lang => {
    if (LANG != lang) {
      const wasActive = this.isActive
      //////////debugger
      if (wasActive) {
        this.stop()
      }
      LANG = lang
      this.recognition = null
      if (wasActive) {
        this.start()
      }
    }
  }
}

class VoiceRecognizer {
  setLang = lang => {
    this.lang = lang
    if (this.impl) {
    }
  }

  canUseWhisper = () => {
    return false
  }

  start = () => {
    if (!this.impl) {
      if (this.canUseWhisper()) {
        this.impl = new WhisperVoiceRecognizer()
      } else {
        this.impl = new SystemVoiceRecognizer(this.autocorrect)
      }
      this.sub1 = this.impl.observeInstruction().subscribe(instruction => {
        this.instructionSubject.next(instruction)
      })
      this.sub2 = this.impl.observeIsActive().subscribe(isActive => {
        this.isActiveSubject.next(isActive)
      })
    }
  }
  stop = () => {
    this.impl.stop()
    this.impl = null
    this.sub1.unsubscribe()
    this.sub2.unsubscribe()
    this.sub1 = null
    this.sub2 = null
  }

  instructionSubject = new Subject()
  isActiveSubject = new Subject()
  
  observeInstruction = () => {
    return this.instructionSubject
  }

  observeIsActive = () => {
    const impl = this.impl
    if (impl) {
      return concat(impl.isActive, this.isActiveSubject)
    }
    return this.isActiveSubject
  }
}

export class Me {

  openSearchModel = (modelId, searchTerm) => {
    this.openWindow(window.origin + "?demo=search&model="+modelId+"&q="+encodeURIComponent(searchTerm || ''))
  }
  
  isSearchGPT = () => {
    if (this.searchGptRequested === undefined) {
      const searchParams = new URLSearchParams(window.location.search)
      const paramValue = searchParams.get('demo');
      ////debugger
      this.searchGptRequested = paramValue === 'search' ||
        window.location.hostname.startsWith("attunewise-search") ||
        window.location.hostname.startsWith("search.attunewise") 
      if (this.searchGptRequested) {
        this.searchModel = searchParams.get('model') || 'attunewise'
        this.searchQuery = searchParams.get('q') || ''
      }
    }
    return this.searchGptRequested
  }

  searchAutocomplete = async prefix => {
    const func = this.getFunc('searchAutocomplete')
    const response = await func({prefix})
    const result = response.data
    return result
  }

  autocompleteSuggestions = async prefix => {
    const func = this.getFunc('autocompleteSuggestions')
    const response = await func({prefix})
    const result = response.data
    return result
  }

  performSearch = async ({transcript}, onProgress)  => {
    const streamReply = (url, data, token, opts) => {
      //console.log('streamReply', opts)
      const { onContent, onDone, onError, resolve, reject } = opts
      const xhr = new XMLHttpRequest();
      //console.log('POST', url, data, opts)
      xhr.open('POST', url)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('Authorization', 'Bearer ' + token)
      let contentLength = 0
      let bufferString = ''
      const dataPrefix = 'data: '
      const doneData = `${dataPrefix}[DONE]`
      xhr.onprogress = e => {
        const chunk = xhr.responseText.substring(contentLength)
        bufferString += chunk 
        contentLength += chunk.length
        let end = bufferString.lastIndexOf('\n\n')
        ////console.log('end', end)
        let dataString
        if (end > 0) {
          dataString = bufferString.substring(0, end + 2)
          bufferString = bufferString.substring(end + 2)
        }
        if (dataString) {
          //console.log('dataString', dataString)
          try {
            const isFinal = dataString.includes(doneData)
            const dataJsonLines = dataString
                  .split(doneData)
                  .join('')
                  .trim()
                  .split(dataPrefix)
                  .filter((v) => !!v) // Remove empty lines
            const contentSnippets = dataJsonLines.map((dataJson) => {
              const parsed = JSON.parse(dataJson)
              return parsed
            })
            for (const snip of contentSnippets) {
              console.log("SNIP", snip)
              onContent(snip)
            }
          } catch (err) {
            console.error(err)
          }
        }
      }
      xhr.onreadystatechange = () => {
        var _a, _b;
        if (xhr.readyState === XMLHttpRequest.DONE) {
          const status = xhr.status;
          // In local files, status is 0 upon success in Mozilla Firefox
          // See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readystatechange_event#examples
          if (status === 0 || (status >= 200 && status < 400)) {
            if (onDone) onDone(xhr);
            if (resolve) resolve()
          }
          else {
            const error = new Error(`Error processing stream completion (XHR readyState ${xhr.readyState}, status ${xhr.status}).`)
            onError(error, xhr.status, xhr)
            if (reject) reject(error)
          }
        }
      }
      xhr.onerror = (event) => {
        onError(new Error(`Error processing stream completion (XHR readyState ${xhr.readyState}, status ${xhr.status}).`), xhr.status, xhr)
      }
      xhr.send(JSON.stringify(data))
      return xhr
    }
    const token = await this.getToken()
    const url = `${server}/performSearch`
    ////debugger
    return streamReply(url, {
      transcript,
      searchModel: this.searchModel
    }, token, {
      onContent: json => {
        onProgress(json)
      },
      onDone: () => {
        onProgress({done: true })
      },
      onError: err => {
        onProgress({error: err})
      }
    })
  }

  isKeyboardExtension = () => {
    return !!Token 
  }

  isContainingApp = () => {
    return !Token 
  }

  nativeLog = msg => {
    if (this.isNative()) {
      this.sendNativeMessage({
        type: 'log',
        message: msg
      })
    } else {
      consoleLog(msg)
    }
  }

  nativeInit () {
    if (!this.isNative()) {
      return
    }
    if (!this.nativeInitDone) {
      this.nativeInitDone = true
      if (this.isNative()) {
        this.nativeLog("native init done: "+window.postMessage);
        this.sendNativeMessage({
          type: 'config',
          config: this.config
        })
        try {
          const errorOverlay = require('react-native/Libraries/Core/Devtools/parseErrorStack');
          errorOverlay.reportError = (error) => {
            console.warn(error);
          }
        } catch (err) {
          console.error(err)
        }
      }
    }    
  }

  sendNativeMessage = msg => {
    if (this.isNative()) {
      window.ReactNativeWebView.postMessage(JSON.stringify(msg))
    }
  }
  
  isNative = () => {
    return typeof window !== 'undefined' && (window.ReactNativeWebView ||
                                             (window.webkit &&
                                              window.webkit.messageHandlers &&
                                              window.webkit.messageHandlers.interOp)
                                             ||
                                            window.electronAPI)
  }

  customToken = null
  customTokenTimestamp = 0
  getIdToken = async () => {
    return await this.self.getIdToken(true)
  }
  
  getCustomToken = async () => {
    const now = Date.now()
    if(now - this.customTokenTimestamp > 30 * 60 * 1000) {
      this.customTokenTimestamp = now
      this.customToken = null
    }
    if (this.customToken) return this.customToken
    const func = this.firebase.functions().httpsCallable('getCustomToken')
    const result = await func({})
    const { customToken } = result.data
    this.customToken = customToken
    return this.customToken
  }

  constructor (firebase, config) {
    this.firebase = firebase
    const db = firebase.firestore()
    db.settings({ ignoreUndefinedProperties: true })
    this.config = config
    const auth = this.firebase.auth();
    auth.onAuthStateChanged(this.onAuthStateChanged);
    //this.onAuthStateChanged(auth.currentUser);
    this.config = config
    window.postMessage = this.onNativeMessage
    window.observeContactOnline = this.observeContactOnline
    this.nativeInit()
    window.blockInput = () => {
    }
    window.unblockInput = () => {
    }
    if (Token) {
      this.firebase.auth().signInWithCustomToken(Token)
    }
    window.addEventListener('resize', this.applyOrientation)
    this.applyOrientation()
    this.connectivitySub = this.observeServerConnectivity().subscribe(isReachable => {
      consoleLog("server is reachable", isReachable)
    })
    consoleLog("signing in anonymously")

  }

  applyOrientation = () => {
    if (window.innerHeight > window.innerWidth) {
      this.orient = 'portrait'
    } else {
      this.orient = 'landscape'
    }
    this.orientSubject.next(this.orient)
  }

  orientSubject = new Subject()
  observeOrientation() {
    if (this.orient) {
      return concat(of(this.orient), this.orientSubject)
    }
    return this.orientSubject
  }

  reqs = []
  callId = 0
  nativeCall = (type, data) => {
    const id = ++this.callId
    return new Promise(resolve => {
      this.reqs[id] = resolve
      const call = {
        type,
      }
      call[type] = data
      this.sendNativeMessage({
        type: 'call',
        reqId: id,
        call
      })
    })
  }

  getCurrentLocation = () => {
    if (typeof window !== 'undefined' && window.ReactNativeWebView) {
      return this.nativeCall({
        type: 'location'
      }).then(response => {
        return response.coords
      })
    }
    return getCurrentPosition()
  }

  saveAs = async (blob, filename) => {
    if (window.ReactNativeWebView) {
      const storage = this.firebase.storage()
      const ref = storage.ref(`Uploads/${this.self.uid}/${filename}`)
      await ref.put(blob)
      const url = await ref.getDownloadURL()
      await this.nativeCall('downloadFile', {
        url: url,
        name: filename,
        mimeType: blob.type
      })
      await ref.delete()
    } else {
      saveAs(blob, filename)
    }
  }

  validatePurchase = async arg => {
    const { success, failure } = arg
    this.nativeLog("validatePurchase!!!!")
    if (success) {
      let { transactionId, verificationResultIOS, transactionReceipt } = success
      this.nativeLog("validatePurchase: " + JSON.stringify({transactionId}))
      try {
        let func
        let receiptData
        if (isAndroid()) {
          func = this.getFunc("verifyGoogleReceipt")
          transactionReceipt = atob(transactionReceipt)
          receiptData = transactionReceipt
        } else if (isIOS()) {
          func = this.getFunc("verifyAppleReceipt")
          receiptData = verificationResultIOS
        } else {
          throw new Error("not a supported platform")
        }
        const response = await func({transactionId, packageName: "com.badnano.attunewise", receiptData})
        this.nativeLog("GOT RESPONSE: " + JSON.stringify(response.data, null, ' '))
        return response.data
      } catch (err) {
        this.nativeLog("GOT ERROR: " + err.message)
        return { failure: err.message}
      }
    } else {
      return { failure }
    }
  }

  purchaseSubject = new Subject()

  observePurchaseOutcome = () => {
    return this.purchaseSubject
  }

  purchaseProduct = async productId => {
    return new Promise(async (resolve, reject) => {
      this.pendingPurchase = {resolve, reject}
      if (this.isNative()) {
        const outcome = await this.nativeCall('purchase', productId)
        this.nativeLog("purchase outcome: " + JSON.stringify(outcome))
        if (!outcome) {
          resolve({ failure: 'Transaction verification in progress.' })
        } else {
          resolve(outcome)
        }
      } else {
        // simulate purchase
        setTimeout(() => {
          this.validatePurchase({
            transactionId: 'test',
            transactionReceipt: 'test'
          })
        }, 1000)
      }
    })
  }
  
  onNativeMessage = json => {
    //this.nativeLog('onNativeMessage: ' + json)
    //if (json.source) return
    consoleLog('onNativeMessage', json)
    let msg
    try {
      msg = JSON.parse(json)
    } catch (err) {
      this.nativeLog('JSON.parse failed: ' + err.message + ": " + json)
      return
    }
    if (msg.type === 'call') {
      const {call} = msg
      const { reqId, op } = call
      const arg = call[op]
      switch (op) {
        case 'validatePurchase':
          const apply = async () => {
            let resolved
            let rejected
            try {
              resolved = await this.validatePurchase(arg)
              //this.nativeLog("resolved: " + JSON.stringify(resolved, null, ' '))
            } catch (err) {
              console.error(err)
              rejected = err.message
            }
            const msg = {
              type: 'return'
            }
            msg['return'] = {
              reqId,
              resolved,
              rejected
            }
            this.sendNativeMessage(msg)
          }
          apply()
          break
        default:
          this.sendNativeMessage({
            type: 'return',
            'return': {
              reqId,
              rejected: "No such operation: " + op
            }
          })
      }
    } else if (msg.type === 'token') {
      try {
        this.saveToken(msg.token)
      } catch (err) {
        console.error(err)
        this.nativeLog('saveToken ' +err.message)
      }
    } else if (msg.type === 'purchase') {
      const { productId, outcome } = msg
      this.purchaseSubject.next({productId, outcome})
    } else if (msg.type === 'notification') {
      //this.nativeLog("received not: " + msg.notification.data.type)
      this.notificationSubject.next(msg.notification)
    } else if (msg.type === 'safeArea') {
      window.safeAreaInsets = msg.safeArea

      this.nativeLog("window.safeAreaInsets: "+JSON.stringify(window.safeAreaInsets));
    } else if (msg.type === 'url') {
      //alert("initial url: " + msg.url)
      this.url = msg.url
      this.urlSubject.next(this.url)
    } else if (msg.type === 'creds') {
      this.creds = msg
      this.credsSubject.next(this.creds)
    } else if (msg.type === 'response') {
      this.nativeLog('response ' + JSON.stringify(msg))
      const resolve = this.reqs[msg.reqId]
      if (resolve) {
        delete this.reqs[msg.reqId]
        resolve(msg.response)
      }
    }
  }

  urlSubject = new Subject()

  utcOffset = -(new Date().getTimezoneOffset()*60*1000)

  setStatusBarColor = color => {
    consoleLog('set status bar color:', color)
    this.sendNativeMessage({
      type: 'statusBarColor',
      color: color 
    })
  }

  selfSubject = new Subject()

  observeSelf = () => {
    const existing = this.self ? [this.self] : []
    return concat(existing, this.selfSubject)
  }


  onAuthStateChanged = user => {
    if (user && this.user && user.uid == this.user.uid) {
      this.self = user
      return
    }
    this.referralCode = undefined
    this.apiKey = undefined
    this.self = user
    if (user) {
      //console.log('self', user.uid)
    }
    this.selfSubject.next(user)
    if (this.self) {
      this.firebase.firestore().collection('Admin').where('uid', '==', this.self.uid).get().then( ({ docs }) => {
        this.isAdmin = docs.length > 0
      }).catch(ignored => {
      })
      this.firebase.firestore().collection('Review').where('uid', '==', this.self.uid).get().then( ({ docs }) => {
        this.isReview = docs.length > 0
      }).catch(ignored => {
      })
      this.getAzureVoices()
    } else {
      if (this.profileSub) {
        this.profileSub.unsubscribe()
        this.profileSub = null
      }
      this.signInAnonymously()
    }
    if (this.isSignedInAnonymously()) {
      this.checkCC()
    }
    this.firebase.auth()
      .getRedirectResult()
      .then((result) => {
        const { credential, user, accessToken } = result
        if (!credential && !user) return
        //console.log("REDIRECT", result)
        if (this.redirectSubject) {
          this.redirectSubject.next({ result })
        } else {
          this.redirect = {  result }
        }
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        if (this.redirectSubject) {
          this.redirectSubject.next({error})
        } else {
          this.redirect = { error }
        }
      });
  }

  observeRedirect = () => {
    if (!this.redirectSubject) {
      this.redirectSubject = new Subject()
    }
    if (this.redirect) {
      const v = [this.redirect]
      this.redirect = null
      return from(v)
    } else {
      return this.redirectSubject
    }
  }

  checkCC = async () => {
    if (localStorage.getItem('cc')) {
      return
    }
    const func = this.getFunc("cc")
    await func({})
    localStorage.setItem('cc', 'yes')
  }

  getAzureVoices = async () => {
    //////////debugger
    const result = await this.getVoices()
    //////////debugger
    this.azureVoices = result
  }


  emailExists = async email => {
    email = email.trim().toLowerCase()
    const func = this.getFunc('emailExists')
    const result = await func({email})
    return result.data
  }

  phoneNumberExists = async phoneNumber => {
    const func = this.getFunc('phoneNumberExists')
    const result = await func({phoneNumber})
    return result.data
  }

  
  signIn = async (email, password) => {
    email = email.trim()
    password = password.trim()
    consoleLog("signin in")
    const result = await this.firebase.auth().signInWithEmailAndPassword(email, password)
    this.onAuthStateChanged(result.user)
    const creds = {
      type: 'login',
      email: email,
      password: password,
      phoneNumber: result.user.phoneNumber
    }
    //alert('login ' + JSON.stringify(creds))
    this.sendNativeMessage(creds)
  }

  isSignedInAnonymously = () => {
    const user = this.firebase.auth().currentUser
    const result =  user && user.isAnonymous
    consoleLog("isAnonymous", result)
    return result
  }

  signInAnonymously = async () => {
    return await this.firebase.auth().signInAnonymously()
  }

  signInWithPhoneNumber = async (phoneNumber, recaptcha) => {
    const { exists }  = await this.phoneNumberExists(phoneNumber)
    if (!exists && await this.isSignedInAnonymously()) {
      return await this.firebase.auth().currentUser.linkWithPhoneNumber(phoneNumber, recaptcha)
    }
    return await this.firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha)
  }

  updatePassword = async newPassword => {
    await this.firebase.auth().currentUser.updatePassword(newPassword)
  }

  resetPassword = async email => {
    await this.firebase.auth().sendPasswordResetEmail(email);
  }

  signOut = async () => {
    this.signUpDisplayName = null;
    this.creds = null
    this.selfSubject.next(null)
    this.sendNativeMessage({
      type: 'signOut'
    })
    if (window.webkit && window.webkit.messageHandlers) {
      const msg = {
        type: 'signOut',
      }
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
    await this.firebase.auth().signOut()
  }

  observeModelOptions = (category) => {
    return this.observeSelf().pipe(flatMap(self => {
      if (self) {
        const ref = this.firebase.firestore().collection("UserOptions").doc(self.uid)
        return docData(ref).pipe(map(data => {
          //console.log('observe options', data)
          return {options: data.modelSelectionOptions}
        }), catchError(err => {
          //////debugger
        }))
      }
      return from([])
    }))
  }

  saveModelOptions = async (modelSelectionOptions) => {
    const { uid } = this.self
    //console.log('save model options', modelSelectionOptions)
    const ref = this.firebase.firestore().collection("UserOptions").doc(uid)
    return await ref.set({uid, modelSelectionOptions}, { merge: true})
  }

  uploadMessageContent = async (id, content, progress) => {
    try {
      const targetRef = this.firebase.storage().ref('Messages').child(this.self.uid).child(id)
      const uploadTask = targetRef.putString(content)
      if (progress) {
        uploadTask.on('state_changed', snap => {
          const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
          debugLog("progress:", percent)
          if (progress) {
            progress(percent);
          }
        })
      }
      await uploadTask
      return targetRef
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  uploadFileImpl = async (type, channel, file, progress) => {
    const filename =  (Math.random().toString(36).substring(2, 15) + Math.random().toString(23).substring(2, 5)) +'-'+ file.name
    consoleLog("upload file impl", filename)
    const targetRef = this.firebase.storage().ref(type).child(channel).child(filename)
    if (!file.type.startsWith('video/')) {
      // just upload directly
      const uploadTask = targetRef.put(file)
      if (progress) {
        uploadTask.on('state_changed', snap => {
          const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
          debugLog("progress:", percent)
          if (progress) {
            progress(percent);
          }
        })
      }
      await uploadTask
      return targetRef
    }
    const videoDimensions = await this.getVideoDimensions(file)
    // invoke support for transcoding video
    const func = this.firebase.functions().httpsCallable('createUpload')
    const result = await func({
      contentType: file.type,
      type: type,
      channel: channel,
      filename: filename,
      videoDimensions
    })
    const { uploadId, path } = result.data
    const ref = this.firebase.storage().ref(path)
    const uploadTask = ref.put(file)
    if (progress) {
      uploadTask.on('state_changed', snap => {
        const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
        debugLog("progress:", percent)
        if (progress) {
          progress(percent);
        }
      })
    }
    await uploadTask
    const p = new Promise((resolve, reject) => {
      const unsubscribe = this.firebase.firestore().collection('Uploads').doc(uploadId).onSnapshot(snap => {
        const data = snap.data()
        if (data) {
          switch (data.status) {
            case 'completed':
              unsubscribe()
              if (progress) progress(100)
              resolve()
              break
            case 'failed':
              unsubscribe()
              if (progress) progress(100)
              reject(new Error(data.failureReason))
              break
          }
        }
      })
    })
    await p
    return targetRef
  }

  getVideoDimensions = async file => {
    const url = URL.createObjectURL(file)
    const video = document.createElement('video')
    video.src = url
    const videoDimensions = await new Promise(resolve => {
      video.onloadedmetadata = e => {
        resolve({
          height: video.height,
          width: video.width
        })
      }
      video.load()
    })
    return await videoDimensions
  }

  uploadDataset = async ({dataset, parent, file, url, models, numSamples}, progress) => {
    const func = this.getFunc('uploadDataset')
    if (file) {
      const fileRef = await this.uploadFileImpl('Datasets', this.self.uid, file, progress)
      const filePath = fileRef.fullPath
      return await func({dataset, parent, filename: file.name, contentType: file.type, filePath, models, numSamples})
    } else {
      //////debugger
      return await func({dataset, parent, url, numSamples})
    }
  }

  uploadSystemPrompts = async ({parent, file, url, models}, progress) => {
    const func = this.getFunc('uploadSystemPrompts')
    if (file) {
      const fileRef = await this.uploadFileImpl('SystemPrompts', this.self.uid, file, progress)
      const filePath = fileRef.fullPath
      return await func({parent, filename: file.name, contentType: file.type, filePath, models})
    } else {
      return await func({parent, url})
    }
  }

  uploadFileToChannel = async (channel, file, progress) => {
    return await this.uploadFileImpl('Channels', channel, file, progress)
  }

  
  uploadFile = (file, progress) => {
    consoleLog("upload file")
    return this.uploadFileImpl('Files', this.self.uid, file, progress)
  }

  uploadProfileImage = (file, progress) => {
    const ref = this.firebase.storage().ref("ProfileImages").child(this.self.uid);
    return new Promise((resolve, reject) => {
      const uploadTask = ref.put(file)
      if (progress) {
        progress(0)
      }
      uploadTask.on("state_changed", snap => {
        //debugLog("state_changed", snap);
        const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        if (progress) {
          progress(percent)
        }
      }, reject, () => {
        return resolve(ref.getDownloadURL());
      })
    })
  }

  lastViewed = 0
  lastViewedSubject = new Subject()

  getLastViewed = () => this.lastViewed

  observeLastViewed = () => {
    return concat(of(this.lastViewed), this.lastViewedSubject)
  }

  markViewed = async move => {
    if (move.posted > this.lastViewed) {
      await updateProfile({
        lastViewed: move.posted
      })
    }
  }

  saveProfile = async updates => {
    consoleLog('saveProfile', updates)
    //////////debugger
    const profileUpdates = {}
    for (const field of  ['bio', 'username', 'displayName', 'profileImage']) {
      profileUpdates[field] = updates[field]
    }
    const userUpdates = {}
    for (const field of ['email', 'phoneNumber', 'password']) {
      profileUpdates[field] = updates[field] || undefined
    }
    if (profileUpdates.phoneNumber) {
      const converted = phone(profileUpdates.phoneNumber);
      profileUpdates.phoneNumber = converted[0]
    }
    const func = this.firebase.functions().httpsCallable('saveProfile')
    const result = await func(profileUpdates)
    return result.data
  }

  //@TODO remove this
  updateProfile = async updates => {
    const ref = this.firebase.firestore().collection('Users').doc(this.self.uid)
    await ref.set(updates, { merge: true })
  }

  electronCallId = 0
  electronCalls = {}
  electron = (type, value) => {
    if (window.electronAPI) {
      return new Promise((resolve, reject) => {
        const id = ++this.electronCallId
        const msg = {
          id,
          type,
        }
        msg[type] = value
        this.electronCalls[id] = {
          resolve, reject
        }
        window.electronAPI.sendMessage(JSON.stringify(msg))
        window.electronAPI.onReply(arg => {
          window.electronReply(arg)
        })
      })
    }
  }
  
  openWindow = (url, arg) => {
    if(window.electronAPI) {
      return this.electron('openURL', url)
    }
    if (this.isNative()) {
      const msg = {
        type: 'openURL',
        openURL: url
      }
      if (window.webkit) {
        window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
        return
      }
      this.sendNativeMessage(msg)
      return
    }
    consoleLog("window open", url)
    return window.open(url, arg)
  }

  getLang = async input => {
    let server = "https://autocomplete-o4pon4eraa-uc.a.run.app"
    //server = "http://192.168.1.106:8080"
    const url = `${server}/lang?input=${encodeURIComponent(input)}`
    const response = await axios.get(url)
    let { lang } = response.data
    return  resolveDialect(lang)
  }

  cancelSpeak = () => {
    if (this.currentSource) {
      this.currentSource.stop()
      this.currentSource = null
      return
    }
    speechSynthesis.cancel()
  }

  getVoices = async () => {
    const token = await this.getToken()
    //const response = await axios.post(`${server}/listVoices`, {})
    //return response.data
    return {}
  }

  getVoiceLangs = () => {
    const Langs = {}
    if (true) return Langs
    if (this.googleVoices) {
      this.googleVoices.forEach(voice => {
        voice.languageCodes.forEach(lang => {
          Langs[lang] = voice
        })
      })
    } else if (this.azureVoices) {
      this.azureVoices.forEach(voice => {
        const lang = voice.Locale
        Langs[lang] = voice
      })
    } else {
      const voices = speechSynthesis.getVoices()
      voices.forEach(voice => {
        let arr = Langs[voice]
        if (!arr) {
          arr = [voice]
          Langs[voice.lang] = arr
        } else {
          arr.push(voice)
        }
      })
    }
    return Langs
  }

  azureSpeak = async (text, lang) => {
    consoleLog("speak", lang)
    const self = this
    const token = await this.getToken()
    const voices = this.azureVoices.filter(x => {
      return x.Locale === lang
    })
    const voice = randomElement(voices)
    const response = await axios.post(`${server}/speak`, { text, lang, voice: voice.ShortName }, {
      responseType: "arraybuffer",
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    const audioBlob = response.data
    consoleLog("audio length", audioBlob.byteLength)
    //////////debugger
    // Create a new AudioContext
    // Set the buffer property of the AudioBufferSourceNode to the decoded data
    return new Promise((resolve, reject) => {
      // Decode the audio data from the blob
      if (self.currentSource) {
        self.currentSource.onended = resolve
        self.currentAudioContext.decodeAudioData(audioBlob, function(decodedData) {
          if (self.currentSource) {
            const source = self.currentSource
            source.buffer = decodedData;
            source.start(0)
          } else {
            resolve()
          }
        })
      } else {
        resolve()
      }
    })
  }
  
  speak = (text, lang) => {
    if (true) {
      return this.azureSpeak(text, lang)
    }
    speechSynthesis.cancel()
    return new Promise(resolve => {
      const utterThis = new SpeechSynthesisUtterance(text)
      consoleLog("setting utterance lang", lang)
      const dialect = resolveDialect(lang)
      if (dialect) {
        utterThis.lang = dialect.iso
      }
      const voices = speechSynthesis.getVoices().filter(voice => voice.lang === utterThis.lang)
      consoleLog(voices)
      //////////debugger
      let voice
      if (lang == 'en-US') {
        voice = voices.find(voice => voice.name === "Samantha")
      } else {
        voice = voices[0]
      }
      if (!voice) {
        const lang = utterThis.lang.split('-')[0] + '-'
        voice = speechSynthesis.getVoices().find(voice => voice.lang.startsWith(lang))
      }
      utterThis.voice = voice
      this.utterance = utterThis
      utterThis.onend = resolve
      speechSynthesis.speak(utterThis)
    })
  }

  shouldUseWhisper = () => !!WHISPER || window.electronAPI 

  getVoiceRecognizer = () => {
    //return new VoiceRecognizer()
    if (!this.voiceRecognizer) {
      this.voiceRecognizer = this.shouldUseWhisper() ?
        new WhisperVoiceRecognizer() : 
        new SystemVoiceRecognizer(this.autocorrect)
    }
    return this.voiceRecognizer
  }

  getSafeAreaInsetTop = () => {
    const result =
          getComputedStyle(document.documentElement).getPropertyValue("--sat")
    return parseFloat(result.substring(0,
                                       result.length-2))
  }

  getSafeAreaInsetBottom = () => {
    const result =
          getComputedStyle(document.documentElement).getPropertyValue("--sab")
    return parseFloat(result.substring(0,
                                       result.length-2))
  }

  getSafeAreaInsetLeft = () => {
    const result =
          getComputedStyle(document.documentElement).getPropertyValue("--sal")
    return parseFloat(result.substring(0,
                                       result.length-2))
  }


  deleteAccount = async () => {
    const func = this.getFunc('deleteAccount')
    await func()
    this.signOut()
  }

  updatePhoneNumber = async (phoneNumber) => {
    await delay(5.0)
  }

  openAIStatusSubject = new Subject()

  checkOpenAIStatus = async () => {
    const url = 'https://status.openai.com/api/v2/status.json'
    let response
    try {
      response = await axios.get(url)
    } catch (err) {
      console.error(err)
      return
    }
    const { status } = response.data
    if (status) {
      const { indicator, description } = status
      if (!this.openAIStatus ||
          this.openAIStatus.status != status ||
          this.openAIStatus.description != description) {
        consoleLog("openAI status changed", status)
        this.openAIStatus = status
        this.openAIStatusSubject.next(status)
      }
    }
  }
  
  observeOpenAIStatus = () => {
    if (!this.statusInterval) {
      this.statusInterval = setInterval(this.checkOpenAIStatus, 30000)
    }
    setTimeout(this.checkOpenAIStatus, 1000/60)
    return this.openAIStatusSubject
  }

  checkForServerConnectivity = async () => {
    let result = false
    consoleLog("origin", window.location.origin)
    if (window.location.host.startsWith("localhost")) {
      result = Math.random () > 0.5 ? true : false
    } else {
      const file = window.location.origin + '/index.html'
      const  randomNum = Math.round(Math.random() * 10000)
      try {
        const response = await axios.head(file + "?rand=" + randomNum)
        consoleLog("head result", response.status)
        result = true
        const now = Date.now()
        if (this.lastServerReachableTime > 0 &&
            now - this.lastServerReachableTime > 60000) {
          //window.location.reload()
        }          
        this.lastServerReachableTime = now
      } catch (err) {
        consoleLog(err.message)
      }
    }
    this.serverReachable = result
    this.serverConnectivitySubject.next(result)
  }

  doCheckForServerConnectivity = async () => {
    this.serverReachable = result
    this.serverConnectivitySubject.next(result)
  }
  lastServerReachableTime = 0
  serverConnectivitySubject = new Subject()
  observeServerConnectivity = () => {
    if (true) {
      return of(true)
    }
    if (!this.serverConnectivityTimer) {
      let interval = 15000
      if (window.location.host.startsWith("localhost")) {
        interval = 3000
      }
      this.serverConnectivityTimer = setInterval(this.checkForServerConnectivity, interval)
      this.checkForServerConnectivity()
    }
    if (this.serverReachable !== undefined) {
      return concat(of(this.serverReachable), this.serverConnectivitySubject)
    }
    return this.serverConnectivitySubject
  }
  getFineTunedModelsRef = () => this.firebase.firestore().collection('FineTunedModels')
  getDataRef = () => this.firebase.firestore().collection('Data')
  getDatasetsRef = () => this.firebase.firestore().collection('Datasets')
  getSystemPromptsRef = () => this.firebase.firestore().collection('SystemPrompts')
  getToolsetsRef = () => this.firebase.firestore().collection('Toolsets')
  getMessagesRef = () => this.firebase.firestore().collection('UserChannel').doc(this.self.uid).collection('Messages')
  getThreadsRef = () => this.firebase.firestore().collection('UserChannel').doc(this.self.uid).collection('Threads')
  getTasksRef = () => this.firebase.firestore().collection('UserChannel').doc(this.self.uid).collection('Tasks')


  getPlaygroundMessages = async task => {
    const c = this.getMessagesRef()
    const { docs } = await c.where('task', '==', task.id).get()
    const messages = docs.map(doc => {
      const message = doc.data()
      message.id = doc.id
      return message
    })
    messages.sort((x, y) => {
      return x.ts - y.ts
    })
    return messages
  }

  getDatasetExampleMessages = async task => {
    return task.example.messages
  }

  searchChatMessages = async (q, topic, model, task) => {
    if (!q && !topic || topic === 'new-thread') {
      return {
        results: [],
        page: 0,
        out_of: 0
      }
    }
    const func = await this.getFunc("searchChatMessages")
    const response = await func({q, topic, model, task})
    const { results } = response.data
    results.forEach(result => {
      if (Array.isArray(result.reaction)) {
        result.reaction = result.reaction.join('')
      }
    })
    return response.data
  }

  searchTasks = async q => {
    const func = await this.getFunc("searchTasks")
    const response = await func({q})
    return response.data
  }

  observeHallucinations = () => {
    return from([])
  }

  observeChatMessages = (model) => {
    return this.observeChatMessagesImpl({model, limit: 15})
  }

  projectsRef = () => this.firebase.firestore.collection("Projects")
  teamsRef = () => this.firebase.firestore.collection("Teams")
  teamMembersRef = () => this.firebase.firestore.collection("TeamMembership")


  createNewTeam = async (name) => {
    const c = this.teamsRef()
    const ref = c.doc()
    const updates = {
      name,
      owner: this.self.uid,
      lastUpdated: Date.now(),
    }
    await ref.set(updates)
  }

  modifyTeam = async (updates) => {
  }

  deleteTeam = async () => {
  }

  addTeamMember = async (team, member) => {
  }

  removeTeamMember = async (member) => {
  }

  modifyTeamMember = async (member) => {
  }

  observeTeamMembership = () => {
    const q = this.teamMembersRef().where("member", '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      //console.log(changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return { type, member: data }
      })
    }))
  }

  observeTeam = team => {
    return collectionData(this.teamsRef().doc(team))
  }

  observeTaskMessages = (opts) => {
    return this.observeChatMessagesImpl(opts)
  }

  observeChatMessagesImpl = (opts) => {
    const { task, limit } = opts
    let q = this.getMessagesRef()
    if (task) q = q.where('task', '==', task.id)
    q = q.orderBy('ts', 'desc')
    if (limit) {
      q = q.limit(limit)
    }
    let ob = collectionChanges(q).pipe(flatMap(changes => {
      ////////debugger
      //console.log(changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        //console.log('message', data)
        if (Array.isArray(data.content)) {
          data.content = convertJsonToMarkdown(data.content)
        }
        if (!data.content) {
          data.content = ''
        }
        if (data.role === 'assistant' &&
            data.models) {
          data.models.forEach(x => {
            const { addedToTranscript } = x
            if (addedToTranscript && addedToTranscript.length > 0) {
              let last = ''
              if (false) x.content = addedToTranscript.map(j => {
                let y = j.message || j
                let content = ''
                if (y.tool_call_id) {
                  try {
                    let parsed = JSON.parse(y.content)
                    content += JSON.stringify(parsed, null, '    ')
                                
                  } catch (ignored) {
                    content += y.content
                  }
                  content = `

\`\`\`
${content}
\`\`\`

`
                  
                } else if (y.tool_calls) for (const tool_call of y.tool_calls) {
                  const { name } = tool_call.function
                  const args = JSON.parse(tool_call.function.arguments || "{}")
                  if (!last || !last.startsWith(y.content)) {
                    content += y.content
                  }
                  content += `

\`\`\`
${name}(${Object.keys(args).map(arg => (`${arg}: ${JSON.stringify(args[arg], null, '    ')}`)).join(', ')})
\`\`\`

`
                } else if (!last || !last.startsWith(y.content)) {
                  content += y.content
                  last = y.content
                }
                //debugger
                return content
              }).join('\n\n')
            }
          })
        }
        return {
          type,
          message: data
        }
      })
    }))
    return ob
  }

  getThreadsHistory = async (lastUpdated, op, limit) => {
    let q = this.getTasksRef()
    q = q.orderBy('lastUpdated', 'desc').where("lastUpdated", op, lastUpdated).limit(limit)
    const { docs } = await q.get()
    return docs.map(doc  => {
      const data = doc.data()
      data.id = doc.id
      return data
    })
  }
  
  observeChatThreads = (opts) => {
    const { model, task } = opts
    let q = this.getThreadsRef()
    if (task) {
      q = q.where('task', '==', task)
    }
    if (model) {
      q = q.where('model', '==', model)
    }
    q = q.orderBy('lastUpdated', 'desc').limit(50)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          thread: data
        }
      })
    }))
  }

  observeFunctions = () => {
    
  }

  observeRecentTasks = (opts = {limit: 15}) => {
    let q = this.getTasksRef()
    console.log("OBSERVE RECENT TASKS")
    q = q.orderBy('lastUpdated', 'desc').limit(opts.limit)
    return this.observeTasksImpl(q)
  }
  
  observeTasks = (ts) => {
    let q = this.getTasksRef()
    const start = startOfWeek(ts).getTime()
    const end = endOfWeek(ts).getTime()
    console.log("OBSERVE TASKS", {ts, start, end})
    q = q.where('lastUpdated', '>=', start).where('lastUpdated', '<=', end).orderBy('lastUpdated', 'desc')
    return this.observeTasksImpl(q)
  }

  observeTasksImpl = q => {
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        ////console.log("TASK", data)
        data.id = doc.id
        return {
          type,
          task: data,
        }
      })
    }))
  }
  
  
  observeSubthreads = (topicId) => {
    const q = this.getThreadsRef().where('subtopic', '==', topicId).orderBy('lastUpdated', 'desc').limit(50)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          thread: data
        }
      })
    }))
  }
  

  getHistory = async (task, earliest, limit) => {
    const taskId = task.id
    //////////debugger
    const { ts } = earliest
    let q = this.getMessagesRef()
    q = q.where('task', '==', taskId)
    q = q.orderBy("ts", "desc").where("ts", "<", ts).limit(limit*2)
    const { docs } = await q.get()
    return docs.map(snap => {
      const data = snap.data()
      data.id = snap.id
      return data
    })
  }
  
  getToken = () => this.self ? this.self.getIdToken(false) : null

  shouldTraceChatGPT = () => {
    return window.location.hostname.startsWith('localhost')
  }

  getFunc = name => {
    //console.log("getFunc", name)
    return async (data) => {
      const token = await this.getToken()
      const url = `${server}/${name}`
      consoleLog('name', data)
      const response = await axios.post(url, data, {
        headers: {
          Authorization: 'Bearer '  + token
        }
      })
      consoleLog("result", name, response.data)
      return response
    }
  }

  deleteChatMessage = async id => {
    const func = this.getFunc('deleteChatMessage')
    const response = await func({id})
    return response.data
  }

  createNewMessage = ({text, contents, models, task, continueTaskInReplyTo}) => {
    const c = this.getMessagesRef()
    const ref = c.doc()
    if (!contents.find(x => x.type === 'image_url')) {
      contents = undefined
    }
    return {
      role: 'user',
      id: ref.id,
      content: text,
      contents,
      models,
      task,
      ts: Date.now(),
      utcOffset: this.utcOffset,
      continueTaskInReplyTo
    }
  }

  createNewReply = msg => {
    const c = this.getMessagesRef()
    const ref = c.doc(msg.id + '.reply')
    return {
      role: 'assistant',
      id: ref.id,
      content: '',
      model: msg.model,
      stream: msg.model ? '' : undefined,
      models: msg.models.map(model => {
        return {
          model,
          stream: '',
          content: ''
        }
      }),
      inReplyTo: msg.id,
      task: msg.task,
      ts: Math.max(Date.now(), msg.ts + 1),
      utcOffset: this.utcOffset,
    }
  }

  updateTask = async (task, updates ) => {
    const ref = this.getTasksRef().doc()
    await ref.set(updates, { merge: true })
  }
  
  createNewTask = () => {
    const ref = this.getTasksRef().doc()
    const data = {
      messages: 0,
      description: "New Discussion",
      lastUpdated: Date.now()
    }
    ref.set(data)
    data.id = ref.id
    return data
  }

  judge = async (judges, req, reply, userMsg) => {
    const func = this.getFunc('judge')
    const { utcOffset } = this
    await func({id: req.id, judges, userMsg, utcOffset})
  }

  correctDatasetExample = async ({dataset, exampleId, messageIndex, model, judges}) => {
    const func = this.getFunc('correctExample')
    await func({dataset, exampleId, messageIndex, judges, model})
  }

  streamChatLocal = async (url, token, messages, params, opts) => {
    const {
      temperature, model, top_p, top_k, stop, max_tokens
    } = params
    const message = messages[message.length-1]
    const { onContent, onDone, onError } = opts
    const response = await new Promise((resolve, reject) => {
      return streamReply(url, {
        messages: messages.map(x => {
          let { role, content, models } = x
          if (role === 'assistant') {
            content = models.find(y => y.model === 'model')
          }
          return { role, content }
        }),
        temperature,
        top_p,
        top_k,
        stop,
        max_tokens,
        stream: true
      }, token, {
        onContent, onDone, onError, resolve, reject
      })
    })
    return response
  }

  streamChat = async (msg, opts) => {
    const token = await this.getToken()
    const url = `${server}/streamChat`
    const dup = {}
    for (const field in msg) {
      dup[field] = msg[field]
    }
    dup.utcOffset = this.utcOffset
    dup.models = opts.models
    dup.magpie = opts.magpie
    dup.temperature = opts.temperature
    dup.replay = opts.replay
    return streamReply(url, dup, token, opts)
  }

  streamInferenceExample = async (data, opts) => {
    const token = await this.getToken()
    const url = `${server}/inferenceExample`
    await new Promise((resolve, reject) => {
      const newOpts = {resolve, reject}
      for (const opt in opts) {
        newOpts[opt] = opts[opt]
      }
      streamReply(url, data, token, newOpts)
    })
  }

  sendChat = async (msg) => {
    const func = this.getFunc('sendChat')
    const dup = clone(msg)
    dup.thread = dup.topic
    delete dup.topic
    dup.utcOffset = this.utcOffset
    const response = await func(dup)
    return response.data || {}
  }

  cancelStreamEdit = async id => {
    const db = this.firebase.firestore()
    const ref = db.collection('Edits').doc(id)
    const updates = {
      uid: this.self.uid,
      stopped: true,
      done: true
    }
    consoleLog("CANCEL STREAM EDIT", id, updates)
    await ref.set(updates, { merge: true })
  }

  streamEdit = async (instruction, input, buttonId, temperature, max_tokens, opts) => {
    const url = `${server}/streamEdit`
    const token = await this.getToken()
    return streamReply(url, {instruction, input, buttonId, temperature, max_tokens}, token, opts)
  }

  observeEdit = (instruction, input) => {
    if (!instruction) {
      throw new Error("instruction is required")
    }
    const db = this.firebase.firestore()
    const c = db.collection('Edits')
    const ref = c.doc()
    const updates = {
      ts: Date.now(),
      uid: this.self.uid,
      instruction,
      input: input || ''
    }
    return {
      cancel: () => this.cancelStreamEdit(ref.id),
      subscribe: observer => {
        let sub
        let output = ''
        const c1 = ref.collection('output').orderBy('ts', 'asc')
        const refs = []
        const deleteOutput = async () => {
          const batch = db.batch()
          refs.forEach(ref => batch.delete(ref))
          batch.delete(ref)
          await batch.commit()
        }
        ref.set(updates).then(async () => {
          consoleLog("STREAM EDIT", ref.id)
          const q = c1.where('id', '==', ref.id)
          sub = collectionChanges(q).pipe(catchError(err => {
            //////////debugger
            throw "fun"
          })).subscribe(changes => {
            changes.map(change => {
              if (change.type == 'added') {
                refs.push(change.doc.ref)
                const data = change.doc.data()
                consoleLog("newText", data.newText)
                output += data.newText
                observer({
                  id: ref.id,
                  instruction,
                  input,
                  output,
                  done: data.isFinal
                })
              }
            })
          })
          await this.streamEdit(ref.id)
        })
        return {
          unsubscribe: () => {
            sub.unsubscribe()
            deleteOutput()
          }
        }
      }
    }
  }

  observeHallucinationQuestions = () => {
    if (true) return from([])
    const db = this.firebase.firestore()
    const q =  db.collection('HallucinationQuestions')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          question: data,
          type,
          isAdmin: data.isAdmin,
          non: data.non
        }
      })
    }),filter(data => {
      consoleLog("hallucination", data)
      if ((data.non || data.isAdmin) && !this.isAdmin) {
        return false
      }
      return true
    }))
  }

  deleteTask = async task => {
    const func = this.getFunc('deleteTask')
    await func({ taskId: task.id })
  }

  setDatasetName = async (dataset, name) => {
    const c = this.getDatasetsRef()
    const ref = c.doc(dataset.id)
    await ref.set({name}, { merge: true})
  }

  saveDatasetOptions = async ({dataset, options, fineTuningOptions}) => {
    const updates = clone(options)
    updates.fineTuningOptions = fineTuningOptions
    const c = this.getDatasetsRef()
    const ref = c.doc(dataset)
    await ref.set(updates, { merge: true })
  }

  saveDatasetSummary = async ({dataset, summary}) => {
    const c = this.getDatasetsRef()
    const ref = c.doc(dataset)
    await ref.set({summary}, { merge: true })
  }

  removeDatasetParent = async ({child, parent}) => {
    child.parents = child.parents.filter(x => x !== parent)
    const c = this.getDatasetsRef()
    const ref = c.doc(child.id)
    await ref.set({parents: child.parents, lastUpdated: Date.now()}, { merge: true})
  }

  deleteDataset = async dataset => {
    const func = this.getFunc('deleteDataset')
    await func({ dataset: dataset.id })
  }

  deleteDatasetExample = async exampleId => {
    const func = this.getFunc('deleteDatasetExample')
    await func({ exampleId})
  }

  deleteDatasetExampleMessage = async (example, messageId) => {
    let  { messages } = example
    messages = messages.filter((x, i) => {
      if (x.id == messageId || x.inReplyTo === messageId) {
        return false
      }
      return true
    })
    const ref = this.firebase.firestore().collection('DatasetExamples').doc(example.id)
    const updates = { messages, lastUpdated: Date.now()}
    if (getContent(example) !== example.title) {
      updates.title = example.title = getContent(example)
    }
    await ref.set(updates, { merge: true })
  }

  cancelDatasetFineTuningJob = async id => {
    const func = this.getFunc('cancelDatasetFineTuningJob')
    await func({id})
  }

  deleteDatasetFineTuningJob = async id => {
    const func = this.getFunc('deleteDatasetFineTuningJob')
    await func({id})
  }
  
  deleteFinetuneJob = async task => {
    const func = this.getFunc('deleteFineTuneJob')
    await func({ jobId: task.id })
  }

  continueTask = async (task, inReplyTo) => {
    const func = this.getFunc('continueTask')
    await func({ taskId: task.id, utcOffset: this.utcOffset, inReplyTo })
  }

  updateTaskSummary = async (task, force)  => {
    const func = this.getFunc('updateTaskSummary')
    return await func({ taskId: task.id, utcOffset: this.utcOffset, force })
  }

  updateTask = async (taskId, updates) => {
    const ref = this.getTasksRef().doc(taskId)
    await ref.set(updates, { merge: true })
  }

  loadData = async (dataset, ts, limit) => {
  }

  getDatasetHistory = async (task, earliest, limit) => {
    const dataset = task.id
    let q = this.getDataRef()
    //////debugger
    q = q.where('uid', '==', this.self.uid).where('dataset', '==', dataset).orderBy('lineNumber', 'desc').startAt(earliest.lineNumber).limit(limit)
    const { docs} = await q.get()
    return docs.flatMap(doc => this.convertData(doc))
  }


  observeData = (opts) => {
    //////debugger
    const { dataset, limit } = opts
    let q = this.getDataRef()
    q = q.where('uid', '==', this.self.uid).where('dataset', '==', dataset).orderBy('lineNumber', 'asc').limit(limit)
    let ts = 0
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.flatMap(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return { type, data }
      })
    }))
  }

  addDatasetExample = async ({dataset, title, description, messages}) => {
    const db = this.firebase.firestore()
    const c = db.collection('DatasetExamples')
    const ref = c.doc()
    //////debugger
    messages = clone(messages)
    resetUsage(messages)
    const line = JSON.stringify(messages.map(message => {
      let { role, content, models } = message
      return { role, content, models }
    }))
    const { uid } = this.self
    const batch = db.batch()
    batch.set(ref, {uid, dataset, title, description, line, messages, lastUpdated: Date.now()}, { merge: true})
    const c1 = this.getDatasetsRef()
    const datasetRef = c1.doc(dataset)
    batch.set(datasetRef, {
      examples: this.firebase.firestore.FieldValue.increment(1),
      lastUpdated: Date.now()
    }, { merge: true})
    await batch.commit()
  }

  cutConversation = async (taskId, messageId, direction) => {
    const db = this.firebase.firestore()
    const c0 = this.getTasksRef()
    const c = this.getMessagesRef()
    const batch = db.batch()
    const taskRef = c0.doc(taskId)
    let snap = await taskRef.get()
    const { title, description, systemPrompt } = snap.data()
    const { docs } = await c.where('task','==', taskId).get()
    const messages = docs.map(snap => {
      const message = snap.data()
      message.id = snap.id
      return message
    })
    messages.sort((x, y) => {
      return x.ts - y.ts
    })
    let mark = messages.findIndex(x => x.id === messageId)
    let beforeEnd 
    let afterStart
    switch (direction) {
      case 'up':
        beforeEnd = mark
        afterStart = mark
        break
      case 'down':
        beforeEnd = mark + 2
        afterStart = mark + 2
        break
      case 'split':
        beforeEnd = mark
        afterStart = mark
        break
    }
    const before = messages.slice(0, beforeEnd)
    const after = messages.slice(afterStart)
    debugger
    switch (direction) {
      case 'up':
        await this.updatePlaygroundExample({id: taskId, messages: after, title, description, systemPrompt})
        return { messages: after, cut: before }
        break
      case 'down':
        await this.updatePlaygroundExample({id: taskId, messages: before, title, description, systemPrompt})
        return { messages: before, cut: after } 
        break
      case 'split':
        {
          const lastUpdated = Date.now()
          await this.addPlaygroundExample({id: taskId, messages: before, lastUpdated: lastUpdated+1, title, description, systemPrompt, keepUsage: true})
          await this.updatePlaygroundExample({id: taskId, messages: after, title, description, lastUpated: lastUpdated, systemPrompt})
          return { messages: after }
        }
        break
      default:
        throw new Error("bad direction: " + direction)
    }
  }

  updatePlaygroundExample = async ({id, messages, title, description, systemPrompt, lastUpdated}) => {
    lastUpdated = lastUpdated || Date.now()
    let updates = {
      messages: messages.length,
      lastUpdated,
      title,
      description,
      systemPrompt
    }
    const keep = {}
    for (const message of messages) {
      keep[message.id] = true
    }
    const db = this.firebase.firestore()
    const c0 = this.getTasksRef()
    const c = this.getMessagesRef()
    const batch = db.batch()
    const taskRef = c0.doc(id)
    const { docs } = await c.where('task','==', id).get()
    for (const snap of docs) {
      if (!keep[snap.id]) {
        batch.delete(snap.ref)
      }
    }
    if (messages[0].inReplyTo && !keep[messages[0].inReplyTo]) {
      batch.set(c.doc(messages[0].id), {
        inReplyTo: null
      }, { merge: true} )
    }
    batch.set(taskRef, updates)
    await batch.commit()
    updates.id = taskRef.id
    return updates
  }

  addPlaygroundExample = async ({messages, title, description, keepUsage}) => {
    messages = clone(messages)
    if (!keepUsage) {
      resetUsage(messages)
    }
    const db = this.firebase.firestore()
    const c0 = this.getTasksRef()
    const c = this.getMessagesRef()
    let prev
    const batch = db.batch()
    let ts = Date.now()
    const taskRef = c0.doc()
    for (const message of messages) {
      let ref
      if (message.role === 'assistant') {  
        ref = c.doc(prev + ".reply") //oof, this required by Cloud/Chat.js
      } else {
        ref = c.doc()
      }
      message.task = taskRef.id
      message.id = ref.id
      message.inReplyTo = prev
      message.ts = ts
      message.utcOffset = this.utcOffset
      message.model
      prev = message.id
      ts++
      batch.set(ref, message)
    }
    //console.log("MESSAGES", messages)
    let updates = {
      messages: messages.length,
      lastUpdated: ts,
      title,
      description
    }    
    batch.set(taskRef, updates)
    await batch.commit()
    updates.id = taskRef.id
    return updates
  }

  addDatasetParent = async ({child, parent}) => {
    if (child.parents && child.parents.find(x => x === parent)) {
      return false
    }
    child.parents.push(parent)
    const c = this.getDatasetsRef()
    const ref = c.doc(child.id)
    await ref.set({parents: child.parents, lastUpdated: Date.now()}, { merge: true})
    return true
  }

  observeDatasets = (opts) => {
    opts = opts || {}
    const {parent, limit} = opts
    let q = this.getDatasetsRef()
    //////debugger
    q = q.where('uid', '==', this.self.uid)
    if (parent) {
      q = q.where('parents', 'array-contains', parent)
    }
    if (limit) {
      q = q.orderBy('lastUpdated', 'desc')
      q = q.limit(limit)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        if (!data.parents) {
          data.parents = []
        }
        return {
          type,
          dataset: data,
        }
      })
    }))
  }

  deleteDatasetMessage = async () => {
  }

  searchDatasets = async (parent, searchTerm) => {
    const func = this.getFunc('searchDatasets')
    const response = await func({
      parent: parent ? parent.id: undefined,
      q: searchTerm
    })
    return response.data
  }
  
  searchDatasetMessages = async (dataset, searchTerm) => {
    const func = this.getFunc('searchDatasetMessages')
    const response = await func({
      dataset: dataset.id,
      searchTerm
    })
    return response.data
  }

  observeFineTunedModels = (opts) => {
    opts = opts || {}
    let q = this.getFineTunedModelsRef()
    q = q.where('uid', '==', this.self.uid)
    const ob = collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        const { rootModel } = data
        if (rootModel) {
          data.title = data.name.substring(0, rootModel.length)
        } else {
          data.title = data.name
        }
        data.id = doc.id
        return {
          type,
          model: data,
        }
      })
    }))
    return ob
  }

  observeFineTuningJobMessages = (opts) => {
    const { task, limit } = opts
    const { job } = task
    const { datasets } = job
    return this.observeData({ task: { id: datasets[0]}, limit})
  }

  observeFineTuningJobs = () => {
    let q = this.getFineTuningJobsRef()
    q = q.where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          job: data,
        }
      })
    }))
  }

  searchFineTuningJobs = async (dataset, searchTerm) => {
    const func = this.getFunc("searchFineTuningJobs")
    const response = await func({
      dataset: dataset ? dataset.id : undefined,
      searchTerm
    })
    return responseData
  }
  
  searchFineTuningJobMessages = async () => {
  }

  observeUsage = ({year, month}) => {
    const { uid } = this.self
    const db = this.firebase.firestore()
    const c = db.collection('Usage')
    //console.log("observeUsage", uid, year, month)
    let q = c.where('uid', '==', uid).where('year', '==', String(year)).where('month', '==', String(month))
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        //console.log('usage', data)
        return {
          type,
          usage: data
        }
      })
    }), catchError(err => {
      console.error(err)
      //////debugger
    }))
  }

  observeModels = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Models')
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          model: data,
          type
        }
      })
    }), filter(x => !x.model.disabled))
  }

  observeImportedModels = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('ImportedModels').where('uid', '==', this.self.uid)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          model: data,
          type
        }
      })
    }))
  }

  observeVendors = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Vendors')
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          vendor: data,
          type
        }
      })
    }))
  }

  observePrices = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('CurrentPrices')
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          price: data,
          type
        }
      })
    }))
  }

  observeCredits = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('AccountDollars').doc(this.self.uid)
    return docData(ref).pipe(map(x => {
      return x || { purchased: 0, used: 0 }
    }, catchError (err => {
      //////debugger
      return { purchased: 0, used: 0 }
    })))
  }

  

  observePurchases = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Purchases')
    const q = ref.where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          purchase: data,
          type
        }
      })
    }))
  }


  
  observeWordPacks = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Products')
    const filt = change => {
      const { wordPack } = change
      if (wordPack.isDraft) {
        return this.isAdmin || this.isReview
      }
      return true
    }
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          wordPack: data,
          type
        }
      })
    }), filter(filt))
  }
  // stripe related

  createPaymentIntent = async productId => {
    const func = this.getFunc('createPaymentIntent')
    const result = await func({productId})
    return result.data
  }

  cancelPaymentIntent = async (id) => {
    const func = this.getFunc('cancelPaymentIntent')
    const result = await func({id})
    return result.data
  }

  signInWithGoogle = async noLink => {
    if (isDesktop()) {
      return this.signInWithGooglePopup(noLink)
    } else {
      return this.signInWithGoogleRedirect(noLink)
    }
  }

  signInWithGoogleRedirect = async (noLink) => {
    const firebase = this.firebase
    const  provider = new firebase.auth.GoogleAuthProvider()
    try {
      if (!noLink) {
        return  await this.self.linkWithRedirect(provider)
      } else {
        return await firebase.auth().signInWithRedirect(provider)
      }
    } catch (err) {
      if (err.code === 'auth/popup-closed-by-user') {
        return
      }
      if (!noLink && err.code === 'auth/credential-already-in-use') {
        return this.signInWithGoogleRedirect(true)
      }
      return { error: err }
    }
    return {}

  }

  signInWithGooglePopup = async (noLink) => {
    const firebase = this.firebase
    const  provider = new firebase.auth.GoogleAuthProvider()
    try {
      if (!noLink) {
        return await this.self.linkWithPopup(provider)
      } else {
        return await firebase.auth().signInWithPopup(provider)
      }
    } catch (err) {
      if (err.code === 'auth/popup-closed-by-user') {
        return
      }
      if (!noLink && err.code === 'auth/credential-already-in-use') {
        return this.signInWithGooglePopup(true)
      }
      return { error: err }
    }
    return {}
  }

  deleteModelFromTask = async ({ task, model }) => {
    const func = this.getFunc('deleteModelFromTask')
    await func({task, model})
  }

  deleteModelFromExample = async ({ dataset, exampleId, model }) => {
    const func = this.getFunc('deleteModelFromExample')
    await func({dataset, exampleId, model})
  }

  observeOpenAIFineTunes = base => {
  }

  observeGeminFineTunes = base => {
    return from([])
  }

  observeFinetunes = base => {
    const { id, vendor } = base
    switch (vendor) {
      case "Google":
        return this.observeOpenGeminiFinetunes(base)
        break
      case "OpenAI":
        return this.observeOpenAIFinetunes(base)
        break
    }
  }

  getGoogleAccessToken = async credentials => {
    const accessToken = await GoogleAuth.getAccessToken({
      credentials,
      scope: ["https://www.googleapis.com/auth/cloud-platform",
              "https://www.googleapis.com/auth/generative-language.tuning"]
    })
  }

  importModel = async (platform, model) => {
    const clone = {}
    for (const field in model) {
      if (typeof field !== 'function') {
        clone[field] = model[field]
      }
    }
    //////debugger
    const func = this.getFunc("importModel")
    await func({platform, model: clone})
  }

  deleteImportedModel = async (platform, model) => {
    //////debugger
    const func = this.getFunc("deleteImportedModel")
    await func({platform, model: model.id})
  }

  listModelsToImport = async (platform, q) => {
    const func = this.getFunc('listModelsToImport')
    //console.log("listModelsToImport", platform, q)
    const response = await func({platform, q})
    return response.data
  }

  listDatasetsToImport = async (platform, q) => {
    const func = this.getFunc('listDatasetsToImport')
    //console.log("listDatasetsToImport", platform, q)
    const response = await func({platform, q})
    return response.data
  }

  saveOpenAIApiKey = async ({apiKey}) => {
    const func = this.getFunc('saveKey')
    await func({
      openAI: {apiKey}
    })
  }

  saveHuggingFaceToken = async ({token}) => {
    const func = this.getFunc('saveKey')
    await func({
      hf: {token}
    })
  }

  saveMistralApiKey = async ({apiKey}) => {
    const func = this.getFunc('saveKey')
    await func({
      mistral: {apiKey}
    })
  }

  saveFireworksAIApiKey = async ({accountId, apiKey}) => {
    const func = this.getFunc('saveKey')
    await func({
      fireworksAI: {accountId, apiKey}
    })
  }

  saveGoogleCredentials = async ({region, serviceAccountKey, storageBucket}) => {
    const func = this.getFunc('saveKey')
    await func({
      google: {region, serviceAccountKey, storageBucket}
    })
  }

  saveBedrockCredentials = async ({region, secretKeyId, secretKey}) => {
    const func = this.getFunc('saveKey')
    await func({
      bedrock: {region, secretKeyId, secretKey}
    })
  }

  observeProjects = () => {
    const q = this.firebase.firestore().collection("Projects").where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      //console.log(changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        const { job } = data
        data.id = doc.id
        data.model = data.model || job.fine_tuned_model
        data.checkpoints = data.checkpoints || data.ckpts.map(ckpt => {
          return {
            model: ckpt.fine_tuned_model_checkpoint,
            step: ckpt.step_number
          }
        })
        data.models = data.models || data.checkpoints.map(ckpt => ckpt.model)
        data.lastUpdated = data.lastUpdated || (job.finished_at || job.created_at) * 1000
        return { type, project: data }
      })
    }))
  }

  judgeExample = async ({dataset, project, exampleId, judges, messageIndex}) => {
    const func = this.getFunc('judgeExample')
    const { utcOffset } = this
    await func({dataset, project, utcOffset, judges, messageIndex, exampleId})
  }
  
  applyReplyToExample = async ({dataset, example, message, reply}) => {
    const func = this.getFunc('applyReplyToExample')
    message.content = reply.content
    let messageIndex = 0
    for (; messageIndex < example.messages.length; messageIndex++) {
      if (example.messages[messageIndex].id == message.id) {
        break
      }
    }
    //////debugger
    if (reply.corrected) {
      if (reply.corrected.disclaimer) {
        message.content = reply.corrected.disclaimer
      } else {
        message.content = reply.content
      }
    } else {
      message.corrected = reply
    }
    const found = message.models.find(t => t.model === 'train')
    if (found) {
      found.content = reply.content
    }
    await func({dataset, exampleId: example.id, messageIndex, model: reply.model})
  }

  deleteProjectExample = async exampleId => {
    const ref = this.firebase.firestore().collection('ProjectExamples').doc(exampleId)
    await ref.set({deleted: true}, { merge: true})
  }

  observeProjectExamples = (projectId, limit) => {
    let q = this.firebase.firestore().collection("ProjectExamples").where('uid', '==', this.self.uid).where('project', '==', projectId).orderBy('lastUpdated', 'desc')
    if (limit) {
      q = q.limit(limit)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      //console.log(changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return { type, example: data }
      })
    }))
  }

  updateDatasetExample = async (exampleId, updates) => {
    const ref = this.firebase.firestore().collection('DatasetExamples').doc(exampleId)
    await ref.set(updates, { merge: true })
  }

  getDatasetFiles = async (dataset) => {
    const c = this.getDatasetsRef()
    const files = []
    const visit = async dataset => {
      if (dataset.isFolder) {
        const { docs } = await c.where('uid', '==', this.self.uid).where('parents', 'array-contains', dataset.id).get()
        for (const snap of docs) {
          const dataset = snap.data()
          dataset.id = snap.id
          await visit(dataset)
        }
      } else {
        files.push(dataset)
      }
    }
    await visit(dataset)
    return files
  }

  observeDatasetExamples = async (dataset, limit) => {
   const observe = async (dataset, results) => {
      if (dataset.isFolder) {
        const { docs } = await this.firebase.firestore().collection('Datasets').where('uid', '==', this.self.uid).where('parents', 'array-contains', dataset.id).get()
        for (const snap of docs) {
          const dataset = snap.data()
          dataset.id = snap.id
          await observe(dataset, results)
        }
      } else {
        let q = this.firebase.firestore().collection("DatasetExamples").where('uid', '==', this.self.uid).where('dataset', '==', dataset.id).orderBy('lastUpdated', 'desc')
        if (limit) {
          q = q.limit(limit)
        }
        const ob = collectionChanges(q).pipe(flatMap(changes => {
          //console.log(changes.length)
          return changes.map(change => {
            const { type, doc } = change
            const data = doc.data()
            data.id = doc.id
            return { type, example: data }
          })
        }))
        results.push({dataset, ob})
      }
    }
    const datasets = []
    await observe(dataset, datasets)
    //////debugger
    datasets.sort((x, y) => {
      const a = x.dataset
      const b = y.dataset
      return a.id.localeCompare(b.id)
    })
    return {
      datasets: datasets.map(x => x.dataset),
      observable: mergeN(...datasets.map(x => x.ob))
    }
  }

  observeDatasetFineTuningJobs = ({dataset, limit}) => {
    let q = this.firebase.firestore().collection("DatasetFineTuningJobs").where('uid', '==', this.self.uid)
    if (dataset) {
      q = q.where('dataset', '==', dataset.id)
    }
    q = q.orderBy('lastUpdated', 'desc')
    if (limit) {
      q = q.limit(limit)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      //console.log(changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return { type, fineTuningJob: data }
      })
    }))
  }
  

  observeKeys = () => {
    const ref = this.firebase.firestore().collection("Keys").doc(this.self.uid)
    return docData(ref).pipe(map(x => x || {}))
  }

  pollFineTunedModels = async () => {
    const func = this.getFunc('pollFineTunedModels')
    await func({
    })
  }

  createFineTuningJob = async ({dataset, models}) => {
    const func = this.getFunc('createFineTuningJob')
    await func({
      datasetId: dataset, models
    })
  }

  pollDatasetFineTuningJob = async (id, opts = {after}) => {
    const { after } = opts 
    const func = this.getFunc('pollDatasetFineTuningJob')
    const response = await func({
      id,
      after
    })
    return response.data
  }

  saveReplyEdit = async ({message, model, content}) => {
    if (message.model === model) {
      message.content = content
      message.addedToTranscript = null
    } else {
      let found = message.models.find(x => x.model === model)
      if (!found) {
        message.models.push({
          model, content
      })
      } else {
        found.content = content
        found.addedToTranscript = null
        found.corrected = null
      }
    }
    const c = this.getMessagesRef()
    const ref = c.doc(message.id)
    //////debugger
    await ref.set(message, { merge :true })
  }

  saveUserEdit = async ({task, message, content}) => {
    const c = this.getMessagesRef()
    const ref = c.doc(message.id)
    const updates = {}
    let updated = false
    if (content !== undefined) {
      updates.content = content
      message.content = content
      updated = true
    }
    if (updated) {
      await ref.set(updates, { merge :true })
    }
  }

  createNewDatasetExample = async dataset => {
    const c = this.firebase.firestore().collection('DatasetExamples')
    const ref = c.doc()
    const messages = [
      {
        role: "user",
        content: "New Question",
      },
      {
        role: 'assistant',
        model: 'train',
        content: 'Preferred Answer',
        models: [{
          model: 'reject',
          content: 'Dispreferred Answer'
      }]
    }]
    const example = {
      id: ref.id,
      messages,
      line: JSON.stringify({
        messages: messages.map(message => {
          const { role, content } = message
          return { role, content }
        })
      }),
      lastUpdated: Date.now(),
      uid: this.self.uid,
      dataset,
      utfOffset: this.utcOffset
    }
    ref.set(example)
    let last
    let ts = example.lastUpdated
    example.messages.forEach((message, i) => {
      message.id = example.id + '-' + i
      message.ts = ts + i
      message.model = 'train'
      if (message.role === 'assistant') {
        message.inReplyTo = last
      }
      last = message.id
    })
    return example
  }

  createNewDataset = async ({parentDataset, summary, name, generatingDataset = false}) => {
    const c = this.getDatasetsRef()
    const ref = c.doc()
    const { uid } = this.self
    let parent
    if (parentDataset) {
      parent = parentDataset.id
    }
    const data = {
      summary: summary || "0 examples",
      name: name || "New Dataset",
      uid,
      parents: parent ? [parent] : undefined,
      lastUpdated: Date.now(),
      generatingDataset,
    }
    await ref.set(data)
    data.id = ref.id
    return data
  }

  createNewDatasetFolder = async (parentDataset) => {
    const c = this.getDatasetsRef()
    const ref = c.doc()
    const { uid } = this.self
    let parent
    if (parentDataset) {
      parent = parentDataset.id
    }
    await ref.set({
      isFolder: true,
      uid,
      parents: parent ? [parent] : undefined,
      lastUpdated: Date.now()
    })
  }

  resolveDataset = async id => {
    const db = this.firebase.firestore()
    const ref = db.collection('Datasets').doc(id)
    const snap = await ref.get()
    const dataset = snap.data()
    dataset.id = snap.id
    return dataset
  }

  deleteDataset = async dataset => {
    const func = this.getFunc('deleteDataset')
    await func({ dataset: dataset.id })
  }

  deleteJudgeFromTask = async ({task, model, messageId}) => { // empty message id means all messages in task
    const func = this.getFunc('deleteJudgeFromTask')
    await func({ task, model, messageId })
  }

  getDatasetExamples = async (dataset, models) => {
    const db = this.firebase.firestore()
    const uid = this.self.uid
    const q = db.collection('DatasetExamples').where('uid', '==', uid).where('dataset', '==', dataset)
    const { docs } = await q.get()
    const examples = docs.map(snap => snap.data())
    examples.sort((x, y) => y.lastUpdated - x.lastUpdated)
    return models.map(model => {
      const content = examples.map(x => {
        if (!x.messages) {
          return x.line
        }
        const messages = x.messages.map(x => {
          let { role, content, corrected, models } = x
          if (x.role === 'user') {
            return { role, content }
          }
          if (corrected) {
            if (corrected.disclaimer) {
              content = corrected.disclaimer
            } else {
              content = corrected.content
            }
          }
          if (x.model == 'train') {
            return { role, content} 
          }
          const found = models.find(y => y.model == model)
          if (found) {
            if (found.corrected && found.corrected.disclaimer) {
              content = found.corrected.disclaimer
            } else if (found.content) {
              content = found.content
            }
          }
          return { role, content}
        })
        return JSON.stringify({messages})
      }).join('\n')
      return content
    })
  }


  getDatasetFineTuneData = async (jobId) => {
    const func = this.getFunc("getDatasetFineTuneData")
    const response = await func({id: jobId})
    return response.data
  }

  getDatasetFineTuneMetrics = async (jobId) => {
    const func = this.getFunc("getDatasetFineTuneMetrics")
    const response = await func({id: jobId})
    return response.data
  }

  createOpenAICompatibleAPIProvider = async (opts) => {
    const { label, endpoint, apiKey } = opts
    const func = this.getFunc("createAPIProvider")
    await func({
      label, endpoint, apiKey
    })
  }

  importOpenAICompatibleAPIProviderModel = async (provider, model) => {
    const models = provider.models || []
    models.push(model.id)
    const db = this.firebase.firestore()
    const c = db.collection('CustomProviders')
    const ref = c.doc(provider.id)
    await ref.set({models}, { merge: true})
  }
  
  updateOpenAICompatibleAPIProvider = async provider => {
    const db = this.firebase.firestore()
    const c = db.collection('CustomProviders')
    const ref = c.doc(provider.id)
    await ref.set(provider, { merge: true})
  }

  deleteOpenAICompatibleAPIProviderModel = async (provider, model) => {
    const db = this.firebase.firestore()
    const models = provider.models
    if (models) {
      models = models.filter(x => x !== model.id)
      const c = db.collection('CustomProviders')
      const ref = c.doc(provider.id)
      await ref.set({models}, { merge: true})
    }
  }

  deleteOpenAICompatibleAPIProvider = async provider => {
    const db = this.firebase.firestore()
    const c = db.collection('CustomProviders')
    const ref = c.doc(provider.id)
    await ref.delete()
  }

  observeOpenAICompatibleAPIProviders = () => {
    const q = this.firebase.firestore().collection('CustomProviders').where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      //console.log(changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return { type, provider: data }
      })
    }))
  }

  observeSystemPrompt = id => {
    const db = this.firebase.firestore()
    const c = db.collection('SystemPrompts')
    const observeFolder = id => {
      let q = c.where('uid', '==', this.self.uid).where('parents', 'array-contains', id)
      return collectionChanges(q).pipe(
        mergeMap(changes => from(changes)),
        mergeMap(change => {
          const { type, doc } = change
          const data = doc.data()
          data.id = doc.id
          if (data.isFolder) {
            return observeFolder(data.id)
          }
          return of({ type, systemPrompt: data })
        })
      )
    }
    const ref = c.doc(id)
    return docData(ref).pipe(
      mergeMap(data => {
        if (data.isFolder) {
          return observeFolder(id)
        }
        return of({type: 'added', systemPrompt: data})
      })
    )
  }

  observeSystemPrompts = ({folder, limit}) => {
    const db = this.firebase.firestore()
    const ref = db.collection('SystemPrompts')
    let q = ref.where('uid', '==', this.self.uid)
    if (folder) {
      q = q.where('parents', 'array-contains', folder.id)
    }
    q = q.orderBy('lastUpdated', 'desc')
    if (limit) {
      q = q.limit(limit)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        data.title = data.name
        return {
          systemPrompt: data,
          type
        }
      })
    }))
  }

  uploadSystemPrompts = async ({parent, file, url, models}, progress) => {
    const func = this.getFunc('uploadSystemPrompts')
    if (file) {
      const fileRef = await this.uploadFileImpl('SystemPrompts', this.self.uid, file, progress)
      const filePath = fileRef.fullPath
      return await func({parent, filename: file.name, contentType: file.type, filePath, models})
    } else {
      return await func({parent, url})
    }
  }
  
  createNewSystemPrompt = async (parentFolder) => {
    const db = this.firebase.firestore()
    const c = db.collection('SystemPrompts')
    const ref = c.doc()
    const updates = {
      lastUpdated: Date.now(),
      uid: this.self.uid,
      title: 'New System Prompt',
      content: '',
      parents: parentFolder ? [parentFolder.id] : undefined,
      functions: [],
      id: ref.id
    }
    await ref.set(updates)
    return updates
  }

  saveSystemPrompt = async (systemPrompt) => {
    const {id, content, toolset, name } = systemPrompt
    const db = this.firebase.firestore()
    const c = db.collection('SystemPrompts')
    const ref = c.doc(id)
    const updates = {
      lastUpdated: Date.now(),
      content,
      toolset,
      name
    }
    await ref.set(updates, { merge: true })
  }

  deleteSystemPrompt = async (id) => {
    const db = this.firebase.firestore()
    const c = db.collection('SystemPrompts')
    const ref = c.doc(id)
    await ref.delete()
  }

  createNewSystemPromptFolder = async (parentFolder) => {
    const c = this.getSystemPromptsRef()
    const ref = c.doc()
    const { uid } = this.self
    await ref.set({
      isFolder: true,
      title: 'New Folder',
      content: "",
      uid,
      parents: parentFolder ? [parentFolder.id] : undefined,
      lastUpdated: Date.now()
    })
  }
  
  addSystemPromptParent = async ({child, parent}) => {
    const c = this.getSystemPromptsRef()
    if (parent) {
      if (child.parents && child.parents.find(x => x === parent)) {
        return false
      }
      if (!child.parents) {
        child.parents = []
      }
      child.parents.push(parent)
      const ref = c.doc(child.id)
      await ref.set({parents: child.parents, lastUpdated: Date.now()}, { merge: true})
    } else {
      const dup = clone(child)
      const ref = c.doc()
      dup.id = ref.id
      dup.parents = []
      dup.lastUpdated = Date.now()
      await ref.set(dup)
    }
    return true
  }

  removeSystemPromptParent = async ({child, parent}) => {
    child.parents = child.parents.filter(x => x !== parent)
    const c = this.getSystemPromptsRef()
    const ref = c.doc(child.id)
    await ref.set({parents: child.parents, lastUpdated: Date.now()}, { merge: true})
  }

  uploadToolset = async ({parent, file, url, models}, progress) => {
    const func = this.getFunc('uploadToolset')
    if (file) {
      const fileRef = await this.uploadFileImpl('Toolsets', this.self.uid, file, progress)
      const filePath = fileRef.fullPath
      return await func({parent, filename: file.name, contentType: file.type, filePath, models})
    } else {
      return await func({parent, url})
    }
  }

  getFileSystem = ({collection, upload, field}) => {
    const { firebase, getFunc } = this
    const { uid } = this.self
    return new FileSystem({me: this, uid, firebase, getFunc, collection, upload, field})
  }

  getPlaygroundFuncs = () => this.getFileSystem({collection: "Playgrounds", upload: "uploadDiscussion", field: "discussion", newTitle: "New Discussion"})

  getDatasetFuncs = () => this.getFileSystem({collection: "Datasets", upload: "uploadDataset", field: "dataset", newTitle: "New Dataset"})

  getToolsetFuncs = () => this.getFileSystem({collection: "Toolsets", upload: "uploadToolset", field: "toolset", newTitle: "New Toolset"})

  getSystemPromptFuncs = () => this.getFileSystem({collection: "SystemPrompts", upload: "uploadSystemPrompts", field: "systemPrompt", newTitle: "New System Prompt"})

  getToolServerFuncs = () => this.getFileSystem({collection: "ToolServers", field: "toolServer", newTitle: "New Tool Server"})

  getGeneratorFuncs = () => this.getFileSystem({collection: "Generators", field: "generator", newTitle: "New Generator"})

  electronLogin = () => {
  }

  async remoteSignIn() {
    if (remote) {
      const remote = this.getRemote()
      const uid = this.self.uid()
      const func = this.getFunc("getCustomToken")
      const { customToken } = await func({})
      this.remote.register("onToolServer", this.onLocalToolServerChanged)
      await remote.signIn(uid, customToken)
    }
  }

  getRemote = () => {
    if (window.Remote) {
      if (!this.remote) {
        this.remote = window.Remote.create()
      }
    }
    return this.remote
  }

  onLocalToolServerChanged = change => {
    const { type, toolServer } = change
    if (change.type === 'removed') {
      delete this.localToolServers[toolServer.id]
    } else {
      this.localToolServers[toolServer.id] = toolServer
    }
    this.localToolServerSubject.next(change)
  }
  localToolServers = {}
  localToolServerSubject = new Subject()
  observeLocalToolServers = () => {
    return concat(from(Object.values(this.localToolServers).map(toolServer => {
      return {
        type: 'added',
        toolServer
      }
    })), this.localToolServerSubject)
  }

  observeToolset = id => {
    const db = this.firebase.firestore()
    const c = db.collection('Toolsets')
    return docData(c.doc(id))
  }

  observeToolsetsRecursively = (id, visitedIds = new Set()) => {
    if (visitedIds.has(id)) {
      return of(null);
    }
    visitedIds.add(id);
    
    const db = this.firebase.firestore();
    const c = db.collection('Toolsets');
    
    // For the top-level document, we only care about isFolder and parents
    return docData(c.doc(id)).pipe(
      map(data => {
        data.id = id
        return data
      }),
      // Only re-emit when parents array changes for the top document
      distinctUntilChanged((prev, curr) => {
        if (!prev || !curr) return false;
        return JSON.stringify(prev.parents) === JSON.stringify(curr.parents);
      }),
      flatMap(toolset => {
        if (!toolset) {
          return of(null);
        }
        if (toolset.isFolder) {
          // Query for all documents that have this folder in their parents array
          const childrenQuery = c.where('parents', 'array-contains', id);
          
          return mergeN(
            of({ type: 'added', toolset}),
            collectionChanges(childrenQuery).pipe(
              // Filter out modifications that don't affect parents array
              filter(changes => 
                changes.every(change => 
                  change.type === 'added' || 
                    change.type === 'removed' ||
                    (change.type === 'modified' && 
                     JSON.stringify(change.doc.data().parents) !== JSON.stringify(change.oldDoc.data().parents))
                )
              ),
              flatMap(changes => 
                mergeN(...changes.map(change => {
                  const childData = change.doc.data();
                  childData.id = change.doc.id
                  switch (change.type) {
                   case 'added':
                    case 'modified':
                      return this.observeToolsetsRecursively(childData.id, new Set(visitedIds))
                    case 'removed':
                      return of({
                        type: 'removed',
                        toolset: childData
                      });
                    default:
                      return of(null);
                  }
                }))
              )
            )
          );
        }
        // For non-folders, just return minimal info since we treat isFolder as immutable
        return of({type: 'added', toolset})
      }),
      filter(change => change !== null)
    );
  }
  
  observeToolsets = ({folder, limit}) => {
    const db = this.firebase.firestore()
    const ref = db.collection('Toolsets')
    let q = ref.where('uid', '==', this.self.uid)
    if (folder) {
      q = q.where('parents', 'array-contains', folder.id)
    }
    q = q.orderBy('lastUpdated', 'desc')
    if (limit) {
      q = q.limit(limit)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        data.title = data.title || data.name
        return {
          toolset: data,
          type
        }
      })
    }))
  }

  loadToolset = async id => {
    const db = this.firebase.firestore()
    const c = db.collection('Toolsets')
    const toolsets = []
    const { uid } = this.self
    const load = async snap => {
      const toolset = snap.data()
      if (toolset.isFolder) {
        const q = c.where('uid', '==', uid).where('parents', 'array-contains', toolset.id)
        const { docs } = await q.get()
        for (const snap of docs) {
          await load(snap)
        }
      } else {
        toolsets.push(toolset)
      }
    }
    //debugger
    const ref = c.doc(id)
    const snap = await ref.get()
    await load(snap)
    return toolsets
  }
  
  createNewToolset = async (parentFolder) => {
    const db = this.firebase.firestore()
    const c = db.collection('Toolsets')
    const ref = c.doc()
    const updates = {
      lastUpdated: Date.now(),
      uid: this.self.uid,
      name: 'New Toolset',
      content: '',
      parents: parentFolder ? [parentFolder.id] : undefined,
      functions: [],
      id: ref.id
    }
    await ref.set(updates)
    return updates
  }

  saveToolset = async (toolset) => {
    const {id, content, tools, name } = toolset
    const db = this.firebase.firestore()
    const c = db.collection('Toolsets')
    const ref = c.doc(id)
    const updates = {
      lastUpdated: Date.now(),
      content,
      tools,
      name,
    }
    await ref.set(updates, { merge: true })
  }

  deleteToolset = async (id) => {
    const db = this.firebase.firestore()
    const c = db.collection('Toolsets')
    const ref = c.doc(id)
    try {
      await ref.delete()
    } catch (err) {
      console.error(err)
    }
  }

  createNewToolsetFolder = async (parentFolder) => {
    const c = this.getToolsetsRef()
    const ref = c.doc()
    const { uid } = this.self
    await ref.set({
      isFolder: true,
      title: 'New Folder',
      content: "",
      uid,
      parents: parentFolder ? [parentFolder.id] : undefined,
      lastUpdated: Date.now()
    })
  }
  
  addToolsetParent = async ({child, parent}) => {
    const c = this.getToolsetsRef()
    if (parent) {
      if (child.parents && child.parents.find(x => x === parent)) {
        return false
      }
      if (!child.parents) {
        child.parents = []
      }
      child.parents.push(parent)
      const ref = c.doc(child.id)
      await ref.set({parents: child.parents, lastUpdated: Date.now()}, { merge: true})
    } else {
      const dup = clone(child)
      const ref = c.doc()
      dup.id = ref.id
      dup.parents = []
      dup.lastUpdated = Date.now()
      await ref.set(dup)
    }
    return true
  }

  removeToolsetParent = async ({child, parent}) => {
    child.parents = child.parents.filter(x => x !== parent)
    const c = this.getToolsRef()
    const ref = c.doc(child.id)
    await ref.set({parents: child.parents, lastUpdated: Date.now()}, { merge: true})
  }
  
  savePlaygroundSystemPrompt = async (task, systemPrompt) => {
    const tasks = this.getTasksRef()
    const ref = tasks.doc(task.id)
    task.systemPrompt = systemPrompt
    await ref.set({systemPrompt}, { merge: true })
  }

  saveDatasetExampleSystemPrompt = async (task, systemPrompt) => {
    const { example } = task
    const ref = this.firebase.firestore().collection('DatasetExamples').doc(example.id)
    example.systemPrompt = task.systemPrompt = systemPrompt
    await ref.set({systemPrompt}, { merge: true })
  }

  updateRecentlyViewed = async (collection, recentlyViewed) => {
    return
    const uid = this.self.uid
    const { utcOffset } = this
    const db = this.firebase.firestore()
    const c = db.collection("MyRecentlyViewed")
    const batch = db.batch()
    const ref = c.doc(uid)
    const snap = await ref.get()
    const lastUpdated = Date.now()
    let updates
    debugger
    return
    if (!snap.exists) {
      updates = {
        uid,
        recentlyViewed: {
          [collection]: recentlyViewed,
        }
      }
    } else {
      const existing = snap.data().recentlyViewed[collection] || []
      const seen = {}
      recentlyViewed.sort((x, y) => y.lastUpdated - x.lastUpdated).filter(x => {
        if (!seen[x.id]) {
          seen[x.id] = true
          return true
        }
      })
      updates = {
        recentlyViewed: {
          [collection]: recentlyViewed
        }
      }
    }
    batch.set(ref, updates, {merge: true})
    await batch.commit()
  }

  getRecentlyViewed = async (collection) => {
    return []
    const db = this.firebase.firestore()
    const c = db.collection("MyRecentlyViewed")
    const uid = this.self.uid
    const ref = c.doc(uid)
    const snap = await ref.get()
    if (!snap.exists) {
      return []
    }
    const { recentlyViewed } = snap.data()
    const result = recentlyViewed[collection]
    return result || []
  }
}



