import React from 'react';
import VirtualizedView from './VirtualizedView';

class DataProvider extends React.Component {
  constructor(props) {
    super(props);
    this.baseOffset = 0
  }

  componentDidMount() {
    this.props.onCreate(this)
  }

  onCreateView = view => {
    this.view = view
  }

  removeBottomLock = () => {
    if (this.view && this.view.viewport) {
      this.view.viewport.isBottomLock = false
    }
  }

  scrollToItem = async (item, options={behavior: 'smooth', block: 'nearest'}) => {
    if (this.view) {
      return await this.view.scrollToItem(item, options)
    }
  }
  
  scrollToBottom = () => this.scrollToItem(this.props.items[this.props.items.length-1]).then(() => this.view && this.view.viewport && this.view.viewport.scrollToBottom())

  scrollToTop = () =>  {
    this.removeBottomLock()
    return this.scrollToItem(this.props.items[0]).then(() => this.view && this.view.viewport && this.view.viewport.scrollToTop())
  }

  scrollToTopItem = () => {
    this.removeBottomLock()
    return this.scrollToItem(this.props.items[0])
  }

  scrollToBottomItem = () => this.scrollToItem(this.props.items[this.props.items.length-1])

  handleLoadMore = (direction) => {
    const { onLoadMore } = this.props;
    if (!onLoadMore) {
      return Promise.resolve(this.props.items.count)
    }
    return onLoadMore(direction).then(({count, update}) => {
      if (direction === 'before' && count > 0) {
        // Increase baseOffset for newly prepended items
        this.baseOffset += count
      } else {
        if (count > 0) { // fix me move this down the hierarchy
          this.view.viewport.isBottomLock = false
          this.view.viewport.isNearBottom = false
        } else {
          this.view.viewport.isBottomLock = true
          this.view.viewport.isNearBottom = true
        }
      }
      update()
    });
  }

  handleUnload = (direction, count) => {
    const { onUnload } = this.props;
    if (!onUnload) {
      return 
    }
    return onUnload(direction, count).then(({count, update}) => {
      if (direction === 'before') {
        this.baseOffset -= count
        update()
      }
    })
  }

  setScrollPos = async ({
    baseOffset,
    offset,
    scrollTop
  }) => {
    this.baseOffset = baseOffset
    if (this.view) {
      this.view.setScrollPos({
        offset,
        scrollTop
      })
    }
  }

  onScroll = ({
    offset,
    scrollTop
  }) => {
    const { baseOffset } =  this
    this.props.onScroll({
      baseOffset,
      offset,
      scrollTop
    })
  }

  render() {
    const { items, windowSize } = this.props;
    const { baseOffset } = this
    return (
      <VirtualizedView
        onScroll={this.props.onScroll ? this.onScroll : undefined}
        isBottomAligned={this.props.isBottomAligned}
        onKeyDown={this.props.onKeyDown}
        autoFocus={this.props.autoFocus}
        onCreate={this.onCreateView}
        items={items}
        baseOffset={baseOffset}
        windowSize={windowSize}
        onLoadMore={this.handleLoadMore}
        onUnload={this.handleUnload}
        renderItems={this.props.renderItems}
        getSelector={this.props.getSelector}
        getId={this.props.getId}
        debug={this.props.debug}
      />
    );
  }
}


export { DataProvider }
