import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ReactSVG } from 'react-svg'
import { isMobile, isAndroid, isIPad, isDesktop } from '../../classes/Platform'
import { BnForm, BnFormFieldSeparator as Sep } from '../Form'
import { BnAnimatedLabel, BnLabel1, BnLabel2 } from '../Label'
import { BnButton, BnBackButton, BnOKButton, BnLogoSmall, BnMenu, BnSearchResult } from '../Button'
import { DeleteButton } from '../ChatGPT/Buttons.js'
import { getPortal } from '../Client'
import lerpColor from '@sunify/lerp-color'
import './index.css'

export class UComponent extends Component {

  updateLaters = {}
  updateLater = (key, f) => {
    if (!f) {
      throw new Error(key + ": update function cannot be null")
    }
    this.updateLaters[key] = f
    clearTimeout(this.updateLaterTimeout)
    this.updateLaterTimeout = setTimeout(() => {
      const updates = Object.values(this.updateLaters)
      this.updateLaters = {}
      updates.forEach(update => update())
    }, 200)
  }

  componentDidUpdate(prevProps, prevState) {
  }
/*
  cbs = []

  setState(updates, cb) {
    if (cb) this.cbs.push(cb)
    for (const k in updates) {
      this.state[k] = updates[k]
    }
    return this.forceUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    //super.componentDidUpdate(prevProps, prevState)
    const cbs = this.cbs
    if (cbs.length > 0) {
      this.cbs = []
      cbs.forEach(f => f())
    }
  }

  forceUpdate = cb => {
    if (cb) this.cbs.push(cb)
    return super.forceUpdate()
    }
    */
}


export class BnSubpage extends UComponent {
  constructor (props) {
    super(props)
    this.state = {
      form: {}
    }
  }


  renderContent() {
    return <div className='bnContentPage'>
      </div>
  }

  onBack() {
  }

  back = () => {
    this.onBack()
    this.setState({
      subpage: null,
      popup: null
    })
  }

  getForm = () => this.props.form || this.state.form
  getFormErr = () => this.state.formErr || this.props.formErr

  clearFormErr = () => {
    this.reportFormErr(null)
  }
  
  reportFormErr = formErr => {
    console.log(formErr)
    if (this.props.reportFormErr) {
      return this.props.reportFormErr(formErr)
    }
    this.setState({
      formErr
    })
  }

  set = (field, value) => {
    this.onChange(field, value)
  }

  get = field => {
    return this.getForm()[field]
  }

  onChange = (field, value) => {
    if (this.props.onChange) {
      this.reportFormErr(null)
      return this.props.onChange(field, value)
    }
    const form = this.state.form
    if (this.state.form[field] !== value) {
      this.state.form[field] = value
      this.forceUpdate()
    } else {
      console.log("NO CHANGE", field, value, 'form', this.state.form[field])
    }
  }

  renderTitle = () => {
    if (!this.props.title) return null
    return <div className='bnPageTitle'>
             <div className='bnPageTitleContent'>{this.props.title}</div>
           </div>
  }

  renderPopupTitle() {
    return <div className='bnPagePopupTitle'>
      {this.renderTitle()}
      </div>
  }

  async performDefaultAction() {
    this.props.action(this.getForm())
  }

  async performAction() {
    await this.performDefaultAction()
  }

  renderDetail() {
  }
 
  render () {
    let subpage
    let popup
    if (this.state.subpage) {
      subpage = this.state.subpage()
    }
    if (this.state.popup) {
      popup = this.state.popup()
    }
    let content = this.renderContent()
    //console.log('content', content)
    //console.log('actionLabel: ', this.props.actionLabel)
    let className = 'bnContentPage'
    if (this.props.pageClassName) {
      className += ' ' + this.props.pageClassName
    }
    let bnPageContentArea = 'bnPageContentArea'
    if (this.props.back || this.props.action) {
      bnPageContentArea += ' bnPageContentAreaWithHeader'
    }
    let title = this.renderTitle()
    let detail = this.renderDetail()
    if (!this.props.isInline && isDesktop()) {
      className += ' bnPageWithDetail'
      content = <div className='bnPageContentWithDetail'>
                  <div className='bnPageContentSidebarContainer'>
                    <div className='bnPageContentSidebar'>
                      {content}
                    </div>
                  </div>
                  <div className='bnPageContentDetail'>
                    {detail}
                  </div>
                </div>
    }
    return <div className={className}>
             <BnPage me={this.props.me} subpage={subpage} popup={popup} safeArea={this.props.safeArea}>
               {(this.props.back || title || this.props.cancel || this.props.action) &&
                [<div key='buttons' className='bnPageButtons'>
                   {this.props.back && <BnBackButton isCancel={false} action={this.props.back}/>}
                   {title}
                   {this.props.cancel && <BnBackButton isCancel={true} action={this.props.cancel}/>}
                   {this.props.trash && <DeleteButton trash={this.props.trash}/>}
                 </div>,
                ] }
               
               <div className={bnPageContentArea}>
                 {content}
               </div>
             </BnPage>
           </div>
  }
}


export class BnPage extends UComponent {

  constructor (props) {
    super(props)
    this.state = {
      alpha: 0
    }
    if (!props.me) {
      debugger
    }
    this.newSlide = true
  }

  componentDidMount () {
    if (this.props.onCreate) this.props.onCreate(this)
    const popup = this.props.popup
    if (popup) this.slide(1)
  }

  componentDidUpdate (prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)
    const subpage = this.props.subpage
    if ((subpage && !prevProps.subpage) || (!subpage && prevProps.subpage)) {
      if (subpage) {
        this.slide(1)
      } else {
        this.slide(0)
      }
    }
    const popup = this.props.popup
    if ((popup && !prevProps.popup) || (!popup && prevProps.popup)) {
      if (popup) {
        this.slide(1)
      } else {
        this.slide(0)
      }
    }
    if (subpage) {
      this.state.subpage = subpage
      this.state.popup = null
    }
    if (popup) {
      this.state.popup = popup
      this.state.subpage = null
    }
  }

  slide = (target) => {
    this.setState({
      alpha: target,
      alphaTransition: true,
      transitionSubpage: this.state.subpage
    })
    clearTimeout(this.endSlideTimeout)
    this.endSlideTimeout = setTimeout(this.endSlide, 400)
  }


  endSlide = () => {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    if (this.state.alpha == 0) {
      if (!this.props.me.self) {
        //this.props.me.setStatusBarColor('dark-content')
      } else {
        this.props.me.setStatusBarColor('light-content')
      }
    }
   
    const updates = {
      alphaTransition: false,
      transitionSubpage: null,
    }
    if (this.state.popup) {
      updates.popup = null
    } else {
      updates.subpage = null
    }
    this.setState(updates)
  }

  renderPopup () {
    const popup = this.props.popup || this.state.popup;
    if (popup) {
      let bnPageBg = 'bnPagePopupBg'
      let bnPagePopup = 'bnPagePopup'
      if (!this.props.me.isNative() && !isIPad()) {
        bnPagePopup += " bnMobileWebPopup"
      }
      if (this.state.alpha > 0) {
        bnPageBg += ' bnPagePopupBgSlideIn'
        bnPagePopup += ' bnPagePopupSlideIn'
      } else {
        bnPageBg += ' bnPagePopupBgSlideOut'
        bnPagePopup += ' bnPagePopupSlideOut'
      }
      const n = <div key='popup' className={bnPageBg}><div className={bnPagePopup}>{popup}</div></div>
      let portalRef = document.getElementById('popup-root')
      if (!portalRef) portalRef = getPortal()
      return ReactDOM.createPortal(n, portalRef)
    }
    return null
  }

  scrollPos = 0;

  setScrollRef = n => {
    if (n) {
      this.pageScroller = n
      const self = this
      this.pageScroller.onscroll = (e) => {
        console.log('scroll: ', this.props.title, n.scrollTop)
        if (this.state.popup || this.state.subpage) {
          e.preventDefault()
          n.scrollTo(0, this.scrollPos)
          return
        }
        const pos = n.scrollTop
        //console.log('pos=', pos)
        if ((this.scrollPos <= 40 && pos > 40) || (this.scrollPos > 40 && pos <= 40)) {
          this.scrollPos = pos
          this.setState({
            scrolled: pos > 40
          })
        } else {
          this.scrollPos = pos
        }
      }
      if (this.scrollPos !== n.scrollTop) {
        this.scrollPos = n.scrollTop
      }
    }
  }

  back = async () => {
    //console.log("alpha:", this.state.alpha, "leaving:", this.leaving)
    if (this.state.alpha !== 0) {
      return
    }
    return this.props.back()
  }

  cancel = async () => {
    if (this.state.alpha !== 0) {
      return
    }
    return this.props.cancel()
  }

  next = async (arg) => {
    if (this.state.alpha !== 0) {
      return
    }
    return this.props.done(arg)
  }
  
  renderSafeArea = () => {
    const self = this
    if (window.ReactNativeWebView || this.props.safeArea) {
      let style
      if (isAndroid() && window.safeAreaInsets) {
        const { top, bottom } = window.safeAreaInsets
        style = {
          height: (top + bottom)+'px'
        }
      }
      return <div className='bnPageHeaderSafeArea' style={style}/>
    }
    return null
  }

  render () {
    const ipad = isIPad() || (isDesktop() && window.innerWidth > 600)
    const popup = this.props.popup || this.state.popup || null
    const subpage = this.props.subpage || this.state.subpage || this.state.transitionSubpage || null
    let pageStyle
    let popupStyle
    let bodyStyle
    let cameraStyle = {
      pointerEvents: 'auto'
    }
    let popupBgStyle
    let scrollerStyle
    let scrollTitle
    let isMobileWeb = false
    if (!this.props.me.isNative() && !isIPad()) {
      isMobileWeb = true
    }
    //console.log("render.scroll", this.props.title, this.scrollPos)
    if (this.state.scrolled || this.props.embed) {
      scrollTitle = this.props.title
    }
    if (this.props.noHeader) {
      scrollerStyle = { maxHeight: '100%' }
    }
    if (this.props.header) {
      scrollerStyle = { maxHeight: '100%' }
    }
    let safeY = 0
    if (this.props.safeArea && window.safeAreaInsets) {
      const { top } = window.safeAreaInsets
      safeY = top
    }
    if (this.props.customSearch) {
      bodyStyle = {
        height: 'calc(100% - 64px)'
      }
    }
    const topStyle1 = {
      transform: `translate(0, ${safeY}px)`
    }
    let topStyle
    let className = 'bnPage'  + (this.props.embed ? ' bnPageEmbed' : '')
    if (this.props.safeArea) {
      className += ' bnPageSafeArea'
    }
    let bnPageSubpage = 'bnPageSubpage'
    let bnPageContent = 'bnPageContent'
    let bnPageBody = 'bnPageBody'
    if (this.newSlide) {
      if (subpage) {
        if (this.state.alpha > 0) {
          bnPageContent += ' bnPageContentSlideIn'
          bnPageSubpage += ' bnPageContentFadeIn'
          bnPageBody +=  ' bnPageContentFadeOut'
        } else {
          bnPageContent += ' bnPageContentSlideOut'
          bnPageSubpage += ' bnPageContentFadeOut'
          bnPageBody +=  ' bnPageContentFadeIn'
        }
        pageStyle = null
      }
    }
    return (
      <div className='bnPageBackground'>
        <div className={className}>
          {this.renderSafeArea()}
          <div className='bnPageCamera' style={cameraStyle}>
            <div className={bnPageContent} style={pageStyle}>
              <div className='bnPageScroller' ref={this.setScrollRef} style={scrollerStyle}>
                <div className={bnPageBody} style={bodyStyle}>
                  {this.props.children}
                </div>
                {this.props.subpages ? <div key='subpage' className={bnPageSubpage} style={topStyle}>
                 {
                   this.props.subpages.map(page => {
                       return <div className='bnSubpageContainer' style={subpage ? null : { display: 'none'}}>
                       {page}
                     </div>
                   })
                 }
             </div> : subpage ? <div style={topStyle} className={bnPageSubpage}>{subpage}</div> : null}

              <div className='bnPageFooter'>{this.props.footer}</div>
        
      </div>
        </div>
          </div>
          {this.renderPopup()}
        </div>
      </div>
    )
  }
}
